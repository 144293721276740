import { Card, Col, Form, Row } from "antd";
import { CardTitle } from "./CardTitle";
import { Button, Typography } from "../../../../../../design-systems";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StatusTag } from "./StatusTag";
import { PersonalInformationForm, ManuallyApproveKycForm } from "./forms";
import moment from "moment";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { hideBankingData, stripLetters } from "../../../../../../utils";
import { useSelector } from "react-redux";
import { showErrorMessage, showSuccessMessage, updatePersonalInformation } from "../../../../../../redux/actions";
import { langs } from "../../../../../../i18n";
import { useDispatch } from "react-redux";
import { KycSuccessModal } from "./forms/KycSuccessModal";
import MaskData from "../../../../../common/MaskData";
import { ROLE_CONSTANT } from "../../../../../../utils/constants";

export const PersonalInformation = ({ getEmployeeData }) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const dispatch = useDispatch()
  const [showSSN, setShowSSN] = useState(false);
  const [allowKyc, setAllowKyc] = useState(false);
  const [captureKyc, setCaptureKyc] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [editPersonalInformation, setEditPersonalInformation] = useState(false);
  const [manuallyApproveKyc, setManuallyApproveKyc] = useState(false);
  const { employeeDetails } = useSelector((state) => state.employees);
  const onRunKYC = () => {
    const data = {};
    data.run_kyc = employeeDetails?.allowKYC?.value;
    data.capture_kyc = employeeDetails?.captureKyc?.value;
    dispatch(updatePersonalInformation(employeeDetails?.employeeID?.value, data, (res) => {
      if (!(res instanceof Error)) {
        if (res.error) {
          dispatch(showErrorMessage(langs.messages.kyc_not_allowed));
        } else {
          getEmployeeData()
          dispatch(showSuccessMessage(langs.messages.kyc_updated));
        }
      }
    }))
  }

  const getName = () => {
    if (!employeeDetails?.firstName?.value && !employeeDetails?.middleName?.value && !employeeDetails?.lastName?.value) {
      return "-"
    }
    return `${employeeDetails?.firstName?.value ?? ""} ${employeeDetails?.middleName?.value ?? ""} ${employeeDetails?.lastName?.value ?? ""}`
  }

  const role = useSelector((state) =>
    state.userDetails && state.userDetails.roleType
      ? state.userDetails.roleType
      : ROLE_CONSTANT.ADMIN,
  );
  const data = useMemo(
    () => [
      {
        label: "Name",
        value: getName(),
      },
      {
        label: "Account holder status",
        value: <StatusTag status={employeeDetails?.accountHolderStatus?.value} />,
      },
      {
        label: "Street address 1",
        value: `${employeeDetails?.addr1?.value ?? "-"}`,
      },
      {
        label: "Street address 2",
        value: `${employeeDetails?.addr2?.value ?? "-"}`,
      },
      {
        label: "City",
        value: `${employeeDetails?.city?.value ?? "-"}`,
      },
      {
        label: "State",
        value: `${employeeDetails?.st?.value ?? "-"}`,
      },
      {
        label: "Zip code",
        value: `${employeeDetails?.postal?.value ?? "-"}`,
      },
      {
        label: "Date of birth",
        value: employeeDetails?.DOB?.value ? `${moment(employeeDetails?.DOB?.value).format("MM/DD/YYYY")}` : "-",
      },
      {
        label: "Work email",
        value: `${employeeDetails?.workEmail?.value ?? "-"}`,
      },
      {
        label: "Mobile phone number",
        value: `${employeeDetails?.mobileNum?.value ?? "-"}`,
      },
      {
        label: "SSN",
        value: `${employeeDetails?.SSN?.value ?? "-"}`,
      },
      {
        label: "KYC status",
        value: <StatusTag status={`${employeeDetails?.kycStatus?.value}`} />,
      },
      {
        label: "kyc_btn",
        value: (
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Button
                className={"pass-kyc-manually-btn link-btn"}
                variant="ghost"
                label="Pass KYC Manually"
                disabled={employeeDetails ? !Object.keys(employeeDetails)?.length : false}
                onclickCallback={() => setManuallyApproveKyc(true)}
              />
            </Col>
            <Col xs={24} md={24} lg={12}>
              <Button
                variant="secondary"
                disabled={employeeDetails ? !Object.keys(employeeDetails)?.length : false}
                label="Run KYC"
                onclickCallback={onRunKYC}
              />
            </Col>
          </Row>
        ),
      },
    ],
    [employeeDetails],
  );

  const setInitialFieldsValue = () => {
    const initialFieldsValue = {
      first_name: employeeDetails?.firstName?.value,
      middle_name: employeeDetails?.middleName?.value,
      last_name: employeeDetails?.lastName?.value,
      addr1: employeeDetails?.addr1?.value,
      mobile_phone_num: employeeDetails?.mobileNum?.value,
      work_email: employeeDetails?.workEmail?.value?.toLowerCase(),
      residential_address_1: employeeDetails?.addr1?.value,
      residential_address_2: employeeDetails?.addr2?.value,
      residential_city: employeeDetails?.city?.value,
      residential_state: employeeDetails?.st?.value,
      residential_postal_code: employeeDetails?.postal?.value,
      ssn: null,
      date_of_birth: employeeDetails?.DOB?.value ? moment(employeeDetails?.DOB?.value) : null,
    };
    form.setFieldsValue(initialFieldsValue);
  };
  const showKycButtons = role === ROLE_CONSTANT.ZENDA_ADMIN;
  return (
    <Card
      title={
        <CardTitle
          title="Personal Information"
          onEdit={() => {
            setInitialFieldsValue()
            setEditPersonalInformation(true)
          }}
          accountHolderStatus={employeeDetails?.accountHolderStatus?.value}
          page="personalInfo"
        />
      }
      bordered
      className="emp-detail-card personal-information-wrapper"
    >
      <Row gutter={[4, 8]}>
        {data.map(({ label, value }) => {
          if (label === "SSN") {
            return (
              <>
                <Col xs={12}>
                  <Typography
                    variant="body-3"
                    label={label}
                    className="card-info-label"
                  />
                </Col>
                <Col xs={12}>
                  <Typography
                    variant="body-2"
                    label={value}
                    className="card-info-value"
                  />
                </Col>
              </>
            )
          }
          if (label === "Date of birth") {
            return <>
              <Col xs={12}>
                <Typography
                  variant="body-3"
                  label={label}
                  className="card-info-label"
                />
              </Col>
              <Col xs={12}>
                <MaskData
                  date={value}
                />
              </Col>
            </>
          }
          if (label === "kyc_btn" && (!showKycButtons || employeeDetails?.kycStatus?.value.toLowerCase() === "kyc success" ||
            employeeDetails?.kycStatus?.value.toLowerCase() === "na"
          )) {
            return null
          }
          return (
            <>
              <Col xs={12}>
                <Typography
                  variant="body-3"
                  label={label === "kyc_btn" ? "" : label}
                  className="card-info-label"
                />
              </Col>
              <Col xs={12}>
                <Typography
                  variant="body-2"
                  label={value}
                  className="card-info-value"
                />
              </Col>
            </>
          )
        })}
      </Row>
      <PersonalInformationForm
        form={form}
        visible={editPersonalInformation}
        onCancel={() => setEditPersonalInformation(false)}
        allowKyc={allowKyc}
        captureKyc={captureKyc}
        setAllowKyc={setAllowKyc}
        setCaptureKyc={setCaptureKyc}
      />
      <ManuallyApproveKycForm
        form={form2}
        getEmployeeData={getEmployeeData}
        visible={manuallyApproveKyc}
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        onCancel={() => setManuallyApproveKyc(false)}
      />

      <KycSuccessModal
        open={openSuccess}
        onCancel={() => setOpenSuccess(false)}
      />
    </Card>
  );
};
