import { langs } from "../../i18n";

const whiteRex = /\s/;
const numberRex = /(?=.*[0-9])/;
const upperRex = /(?=.*[A-Z])/;
const lowerRex = /(?=.*[a-z])/;
const phoneRex = /^[0-9]{10}$/;
const lenRex = /^.{8,32}$/;
const OTPRex = /^[0-9]{6}$/;
//eslint-disable-next-line
const employeeIdRex = /^[ A-Za-z0-9_\-@/\\\|]*$/;
const socureTransactionIDRex =
  /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/;

const listSpecialALL = [
  "=",
  "+",
  "-",
  "^",
  "$",
  "*",
  ".",
  "[",
  "]",
  "{",
  "}",
  "(",
  ")",
  "?",
  '"',
  "!",
  "@",
  "#",
  "%",
  "&",
  "/",
  "\\",
  ",",
  ">",
  "<",
  "'",
  ":",
  ";",
  "|",
  "_",
  "~",
  "`",
];

function checkSpecial(inpt, listSpecial) {
  for (let i = 0; i < listSpecial.length; i++) {
    if (inpt.includes(listSpecial[i])) return true;
  }
  return false;
}

/** Field OTP validation */
export function validateOTP(OTP) {
  return OTPRex.test(OTP);
}
export function otpValidation() {
  return {
    validator(_, value) {
      if (OTPRex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(langs.messages.invalid_otp);
    },
  };
}

/** Field password validation */
export function validatePassword(password) {
  const passwordState = {
    upperCase: upperRex.test(password),
    lowerCase: lowerRex.test(password),
    noWhite: !whiteRex.test(password),
    number: numberRex.test(password),
    lenOK: lenRex.test(password),
    special: checkSpecial(password, listSpecialALL),
  };

  let allOK = true;
  let score = 0;

  for (let key in passwordState) {
    allOK = allOK && passwordState[key];
    if (key !== "noWhite" && passwordState[key]) score += 1;
  }

  passwordState.allOK = allOK;
  passwordState.score = score;

  let progressColor = score < 2 ? "danger" : score < 5 ? "yellow" : "success";

  passwordState.progressColor = progressColor;

  return passwordState;
}

/** Field confirm password validation */
export function validateConfPassword(password, confPassword) {
  return confPassword === password;
}

// export function validateAll(OTP, password, confPassword) {
//   const validate = {
//     OTPState: validateOTP(OTP),
//     passwordState: validatePassword(password),
//     confPasswordState: validateConfPassword(password, confPassword),
//   };

//   let ok = [
//     validate.OTPState,
//     validate.passwordState.allOK,
//     validate.confPasswordState,
//   ];

//   validate.allOK = ok.reduce((acc, curr) => acc && curr, true);

//   return validate;
// }

/** Field phone validation */
export function validatePhone() {
  return { pattern: phoneRex, message: "Please enter a valid phone number" };
}
export const validPhone = (rule, number, callback) => {
  const IndNum = /^[0]?[0-9]+$/;
  if (!IndNum.test(number)) {
    callback("Please enter valid phone number.");
  } else if (number.length > 12) {
    callback("Max length must be 12 digits.");
  } else if (number.length < 10) {
    callback("Min length must be 10 digits.");
  } else {
    callback();
  }
};
export const validPhoneWithRequired = (rule, number, callback) => {
  if (number !== "" && number) {
    const IndNum = /^[0]?[0-9]+$/;
    if (!IndNum.test(number)) {
      callback("Please enter valid phone number.");
    } else if (number.length > 12) {
      callback("Max length must be 12 digits.");
    } else if (number.length < 10) {
      callback("Min length must be 10 digits.");
    }
  } else {
    callback("Phone number is required.");
  }

  callback();
};

/** Field required validation */
export const required = (label) => {
  return {
    required: true,
    message: label ? `${label} is required.` : "This field is required.",
  };
};

/** Field email validation */
export const loginEmail = () => {
  return { type: "email", message: langs.messages.login_invalid_email };
};
export const forgotPasswordEmail = () => {
  return {
    type: "email",
    message: langs.messages.forgot_password_invalid_email,
  };
};
export const email = () => {
  return { type: "email", message: langs.validation_messages.invalid_email };
};

/** Field password validation */
export const password = () => {
  return { required: true, message: langs.validation_messages.password };
};

/** Field confirm password validation */
export const confirmPassword = () => {
  return { required: false, message: langs.validation_messages.cPassword };
};

/** Field new password validation */
export const newPassword = () => {
  return { required: true, message: langs.validation_messages.newPassword };
};

/** Field min length validation */
export const minLength = (number) => {
  return { min: number, message: `length must be ${number} characters.` };
};

/** Field max length validation */
export const maxLength = (number) => {
  return { max: number, message: `length must be ${number} characters.` };
};

export const zipCodeValidation = (value) => {
  return {
    validator(_, value) {
      const regex = /^[0-9]+$/;
      if (value) {
        if (!regex.test(value)) {
          return Promise.reject("Please enter valid zip code")
        }
      }
      return Promise.resolve();
    }
  }
}

/** SSN length validation */
export function SSNValidation() {
  return {
    validator(_, value) {
      const empIdRegex = /^[0-9]+$/;
      if (value) {
        if (!empIdRegex.test(value)) {
          return Promise.reject("Please enter valid ssn")
        }
        if (value?.length !== 9) {
          return Promise.reject("Length must be 9 digits");
        }
      }
      return Promise.resolve();
    },
  };
}

/** Field length validation */
export const maxLengthVal = (number) => {
  return {
    max: number,
    message: `length must be less than ${number} characters.`,
  };
};

/** Field max length validation */
export const minRangeLength = (number1, number2) => {
  return {
    max: number2,
    min: number1,
    message: `length must be ${number1} - ${number2} digits.`,
  };
};

/** Field required validation */
export const regExpVal = (regEx) => {
  return {
    required: true,
    type: "regexp",
    pattern: regEx,
    message: "Input format is wrong",
  };
};

/** Special validation */
export function specialValidation() {
  return {
    validator(_, value) {
      if (checkSpecial(value, listSpecialALL)) {
        return Promise.reject("Input format is wrong");
      }
      return Promise.resolve();
    },
  };
}

export function specialValidationEmployeeId() {
  return { pattern: employeeIdRex, message: "Input format is wrong" };
}

export function validationSocureTransactionID() {
  return { pattern: socureTransactionIDRex, message: "Input format is wrong" };
}
