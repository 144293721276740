export const DashboardIcon = ({ className = ""}) => (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon/pie-chart" clip-path="url(#clip0_7455_20930)">
            <path id="Vector" d="M14.1402 10.5914C13.7161 11.5944 13.0527 12.4782 12.2081 13.1656C11.3635 13.853 10.3634 14.3231 9.29515 14.5346C8.22692 14.7462 7.12314 14.6929 6.08029 14.3793C5.03743 14.0657 4.08727 13.5015 3.31287 12.7359C2.53846 11.9703 1.9634 11.0266 1.63794 9.9874C1.31249 8.9482 1.24656 7.84509 1.44592 6.77452C1.64528 5.70395 2.10385 4.69852 2.78155 3.84612C3.45925 2.99372 4.33544 2.32031 5.33352 1.88477" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_2" d="M14.6667 8.00065C14.6667 7.12517 14.4942 6.25826 14.1592 5.44943C13.8242 4.64059 13.3331 3.90566 12.714 3.28661C12.095 2.66755 11.3601 2.17649 10.5512 1.84145C9.74239 1.50642 8.87548 1.33398 8 1.33398V8.00065H14.6667Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_7455_20930">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>

)