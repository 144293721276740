import { Tag } from "antd";
import { useMemo } from "react";

export const StatusTag = ({ status }) => {
  const tagDetails = useMemo(() => {
    switch (status) {
      case "KYC Success":
      case "Success":
      case "success":
        return {
          color: "active",
          label: "Success",
        };
      case "active":
        return {
          color: "active",
          label: "Active",
        };
      case "Registered":
        return {
          color: "active",
          label: "Registered",
        };
      case "Activated":
        return {
          color: "active",
          label: "Activated",
        };
      case "Enrolled":
        return {
          color: "active",
          label: "Enrolled",
        };
      case "Separated":
        return {
          color: "separated",
          label: "Separated"
        }
      case "KYC Timed Out":
        return {
          color: "separated",
          label: "KYC Timed Out"
        }
      case "na":
      case "Na":
      case "NA":
        return {
          color: "fail",
          label: "NA",
        };
      case "fail":
      case "failed":
      default:
        return {
          color: "fail",
          label: "Failed",
        };
    }
  }, [status]);

  return (
    <Tag className={`status-employee ${tagDetails.color}`}>
      {tagDetails.label}
    </Tag>
  );
};
