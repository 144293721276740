import axios from "./axiosInterceptors";
import { STATUS_CODES } from "../utils/constants";

export const getContributionHistoryService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/contribution-run/summary",
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getContributionValidationReportService = async (
  contributionRunID,
  page,
  limit,
) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/contribution-run/${contributionRunID}`,
      params: { contribution_run_id: contributionRunID, page, limit },
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createContributionService = async (reqData, file) => {
  try {
    const formData = new FormData();
    Object.keys(reqData).forEach((key) => {
      if (typeof reqData[key] == "object") {
        formData.append(key, JSON.stringify(reqData[key]));
      } else {
        formData.append(key, reqData[key]);
      }
    });

    formData.append("contribution_run_file", file);
    const request = {
      method: "post",
      url: "/employer/payroll/contribution-run",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateContributionService = async (id, reqData, file) => {
  try {
    const formData = new FormData();
    Object.keys(reqData).forEach((key) => {
      if (typeof reqData[key] == "object") {
        formData.append(key, JSON.stringify(reqData[key]));
      } else {
        formData.append(key, reqData[key]);
      }
    });

    formData.append("contribution_run_file", file);

    const request = {
      method: "put",
      url: `/employer/payroll/contribution-run/${id}`,
      data: formData,
      setContentMultipart: true
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const initiateACHPullService = async (contributionRunID) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/contribution-run/pull/ach/${contributionRunID}`,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const initiateACHPullRetryService = async (contributionRunID) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/contribution-run/pull/ach/retry/${contributionRunID}`,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const executeContributionRunService = async (contributionRunID) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/contribution-run/execute/${contributionRunID}`,

      validateStatus: function (status) {
        return (
          status === STATUS_CODES.OK ||
          status === STATUS_CODES.REQUEST_TIMEOUT ||
          status === STATUS_CODES.GATEWAY_TIMEOUT
        );
      },
    };

    const response = await axios(request);

    return {
      error: false,
      status: response.status,
      data: response.data.data || null,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const executeContributionRunRetryService = async (
  contributionRunID,
  comments,
) => {
  try {
    const request = {
      method: "patch",
      url: `/employer/payroll/contribution-run/execute/retry/${contributionRunID}`,
      data: { comments },
    };

    const response = await axios(request);

    return {
      error: false,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const executingContributionStatusService = async (contributionRunID) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/contribution-run/execution-status/${contributionRunID}`,
    };

    const response = await axios(request);

    const statistic = {
      ...response.data.data,
      completion_percent: Math.round(
        ((response.data.data.successful_employees_count +
          response.data.data.errored_employees_count) /
          response.data.data.total_employees_count) *
        100,
      ),
    };

    return statistic;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const cancelContributionRunService = async (contributionRunID) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/contribution-run/cancel/${contributionRunID}`,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBenefitAccountTypeService = async () => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/employer/benefits-account-type`,
    };

    const response = await axios(request);
    return { error: false, data: response.data.body || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
