import { Col, Progress, Row } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '../../../../design-systems';
import { fixAmountFormat, planNameMap } from '../../../../utils';
import { DATE_FORMAT } from '../../../../utils/constants';
import { SpinLoader } from '../../../common/SpinLoader';

export const ReportChart = ({ data }) => {
    const { reportsData } = useSelector(state => state.reports);
    const getReportDateById = useCallback(() => {
        const id = "funding_summary"
        const reportDate = reportsData?.reports?.find(data => data?.type === id);
        if (reportDate?.report_date) {
            return moment(reportDate?.report_date).format(DATE_FORMAT.FOR_UI_FIRST_MM)
        }
        return ""
    }, [reportsData?.reports])
    
    return (
        <SpinLoader showContent={data} className="chart-spinner">
            <div>
                <Row gutter={[16, 16]}>
                    {
                        data.map(plan => (
                            <Col xs={24} md={12} lg={8}>

                                <div className='chart-card-wrapper'>
                                    <h2>{planNameMap[plan?.plan_name]}</h2>
                                    <div className='chart-card'>
                                        <div>
                                            <h4> As of {getReportDateById()}</h4>
                                            <Progress
                                                type="circle"
                                                percent={Math.floor((plan?.total_claims_paid/plan?.total_elections) * 100) ?? 0}
                                                format={(percent) => <div className='static-value'>{<span className='percent'>{percent}%</span>} <span className='label'>Paid out</span></div>}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                <Typography
                                                    className={"data-label"}
                                                    variant={"body-3"}
                                                    label={`Total elections`}
                                                />
                                                <Typography
                                                    className={"data-content amount-labels"}
                                                    variant={"body-2"}
                                                    label={fixAmountFormat(plan?.total_elections ?? 0)}
                                                />
                                            </div>

                                            <div>
                                                <Typography
                                                    className={"data-label"}
                                                    variant={"body-3"}
                                                    label={`Total paid YTD`}
                                                />
                                                <Typography
                                                    className={"data-content amount-labels"}
                                                    variant={"body-2"}
                                                    label={fixAmountFormat(plan?.total_claims_paid ?? 0)}
                                                />
                                            </div>
                                            <div>
                                                <Typography
                                                    className={"data-label"}
                                                    variant={"body-3"}
                                                    label={`Enrolled members`}
                                                />
                                                <Typography
                                                    className={"data-content amount-labels"}
                                                    variant={"body-2"}
                                                    label={plan?.enrolled_employees ?? 0}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </SpinLoader>
    );
};



