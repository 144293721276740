import React from "react";
import { Checkbox, Form } from "antd";
import { Typography } from "../index";

export const CheckBox = ({ label, status, disabled, name, onChange, defaultChecked }) => (
  <Form.Item name={name} valuePropName="checked" className="checkbox-wrapper">
    <Checkbox id={name} defaultChecked={defaultChecked} disabled={disabled} onChange={onChange}>
      {" "}
      <label htmlFor={name} onClick={(event) => event.stopPropagation()}>
        <Typography
          className={`${status && !disabled
              ? "checkbox-selected-label"
              : "checkbox-not-selected-label"
            } ${disabled ? (status ? "disabled-checked" : "disabled-unchecked") : ""
            }`}
          variant={"body-3"}
          label={label}
        />
      </label>
    </Checkbox>
  </Form.Item>
);

export const CheckBoxForGroup = ({
  label,
  status,
  disabled,
  value,
  onChange,
}) => (
  <Checkbox disabled={disabled} value={value} onChange={onChange}>
    {" "}
    <Typography
      className={`${status && !disabled
          ? "checkbox-selected-label"
          : "checkbox-not-selected-label"
        } ${disabled ? (status ? "disabled-checked" : "disabled-unchecked") : ""
        }`}
      variant={"body-3"}
      label={label}
    />
  </Checkbox>
);
