import { ApiErrors } from "../../utils";
import { CLAIMS_CONTANT } from "../../utils/constants";
import {
    disableDataLoader,
    enableDataLoader,
} from ".";
import axios from "axios";
import { getClaimActionReasons, getClaimDetailsByIdService, getClaimsActivityLogsService, getClaimsDocumentsService, getClaimsStatusService, getClaimsSummaryService, getNextClaimIdService, postClaimsDocumentsService, saveInternalNotesService, updateClaimActionService, updateVerifyUploadService } from "../../services/claimsService";

export function getClaimsSummary(data, callback) {
    const processName = "getClaimsSummary";
    return (dispatch) => {
        dispatch(enableDataLoader(processName));
        getClaimsSummaryService(data)
            .then((list) => {
                callback && callback(list);
                dispatch({
                    type: CLAIMS_CONTANT.SAVE_CLAIMS_SUMMARY,
                    payload: list.data,
                });
            })
            .catch((err) => {
                ApiErrors(err, dispatch);
            })
            .finally(() => {
                dispatch(disableDataLoader(processName));
            });
    };
}

export function getClaimsStatus(data, callback) {
    const processName = "getClaimsStatus";
    return (dispatch) => {
        dispatch(enableDataLoader(processName));
        getClaimsStatusService(data)
            .then((list) => {
                callback && callback(list);
                dispatch({
                    type: CLAIMS_CONTANT.SAVE_CLAIMS_STATUS,
                    payload: list.data.data,
                });
            })
            .catch((err) => {
                ApiErrors(err, dispatch);
            })
            .finally(() => {
                dispatch(disableDataLoader(processName));
            });
    };
}
export function getClaimDetailsById(data, callback) {
    const processName = "getClaimDetailsById"
    return (dispatch) => {
        dispatch(enableDataLoader(processName));
        getClaimDetailsByIdService(data)
            .then((list) => {
                callback && callback(list);
                dispatch({
                    type: CLAIMS_CONTANT.SAVE_CLAIM_DETAILS_BY_ID,
                    payload: list.data.data,
                });
            })
            .catch((err) => {
                ApiErrors(err, dispatch);
            })
            .finally(() => {
                dispatch(disableDataLoader(processName));
            });
    };
}

export function saveInternalNotes(data, callback) {
    const processName = "saveInternalNotes";
    return (dispatch) => {
        dispatch(enableDataLoader(processName));
        saveInternalNotesService(data)
            .then((list) => {
                callback && callback(list);
                dispatch({
                    type: CLAIMS_CONTANT.SAVE_INTERNAL_NOTES,
                    payload: list.data.data,
                });
            })
            .catch((err) => {
                ApiErrors(err, dispatch);
            })
            .finally(() => {
                dispatch(disableDataLoader(processName));
            });
    };
}

export function saveClaimsDocumentsPreDignedURL(id, data, callback) {
    return (dispatch) => {
        postClaimsDocumentsService(id, data)
            .then((res) => {
                callback && callback(res);
                dispatch({
                    type: CLAIMS_CONTANT.SAVE_CLAIMS_PRE_SIGNED_URL,
                    payload: res.data.data,
                })
            })
            .catch((err) => {
                ApiErrors(err, dispatch, true);
            })
            .finally(() => { });
    };
}

export function getClaimsDocumentsList(data, callback) {
    return (dispatch) => {
        getClaimsDocumentsService(data)
            .then((list) => {
                callback && callback(list);
                dispatch({
                    type: CLAIMS_CONTANT.SAVE_CLAIMS_DOCUMENTS,
                    payload: list.data.data,
                });
            })
            .catch((err) => {
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    };
}

export function saveClaimsActionReasons(data, callback) {
    return (dispatch) => {
        getClaimActionReasons(data)
            .then((list) => {
                callback && callback(list);
                dispatch({
                    type: CLAIMS_CONTANT.SAVE_CLAIM_ACTION_REASONS,
                    payload: list.data.data.data.map((item) => ({
                        value: item.id,
                        label: item.reason
                    })),
                });
            })
            .catch((err) => {
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    };
}

export function updateClaimsAction(reqData, callback) {
    const { claimId, action, data } = reqData
    return (dispatch) => {
        updateClaimActionService(claimId, action, data)
            .then((list) => {
                callback && callback(list);
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    };
}

export function updateVerifyUpload(claimId, signal, callback) {
    return (dispatch) => {
        updateVerifyUploadService(claimId, signal)
            .then((res) => {
                callback && callback(res);
            })
            .catch((err) => {
                if (!(axios.isCancel(err))) {
                    callback && callback(err);
                    ApiErrors(err, dispatch);
                }
            })
            .finally(() => {
            });
    }
}

export function getActivityLogs(claimId, callback) {
    return (dispatch) => {
        getClaimsActivityLogsService(claimId)
            .then((res) => {
                callback && callback(res);
                dispatch({
                    type: CLAIMS_CONTANT.SAVE_CLAIM_ACTIVITY_LOG,
                    payload: res?.data?.data?.data || [],
                });
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    }
}

export const saveCurrentClaimId = (reqData, callback) => {
    return (dispatch) => {
        dispatch({
            type: CLAIMS_CONTANT.SAVE_CURRENT_CLAIM_ID,
            payload: reqData
        })
    };
}

export const resetClaimDetails = () => {
    return dispatch => {
        dispatch({ type: CLAIMS_CONTANT.RESET_CLAIMS_DETAILS })
    }
}

export const saveClaimListAPIValues = (reqData, callback) => {
    return (dispatch) => {
        dispatch({
            type: CLAIMS_CONTANT.SAVE_CLAIM_LIST_API_DATA,
            payload: reqData
        })
    };
}

export const getNextClaimId = (data, callback) => {
    return dispatch => {
        getNextClaimIdService(data)
            .then((res) => {
                callback && callback(res);
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    }
}