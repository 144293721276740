import { Col, Form, Row } from "antd";
import { CheckBox, TextArea, TextField } from "../../../../../design-systems";
import { EditableTable } from "../../../../common/EditableTable";
import { mandatory } from "../../../../../utils/validations";

export const Divisions = ({ form }) => {
  const columns = [
    {
      title: "Division identifier",
      dataIndex: "division_identifier",
      width: "40%",
      editable: true,
      additionNode: ({ editingKey }) => (
        <TextField
          name="add-division_identifier"
          rules={[mandatory({ message: "Please enter division identifier" })]}
          disabled={!!editingKey}
          placeholder="Enter division identifier"
        />
      ),
      editingNode: () => (
        <TextField
          name="division_identifier"
          rules={[mandatory({ message: "Please enter division identifier" })]}
          placeholder="Enter division identifier"
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "40%",
      editable: true,
      additionNode: ({ editingKey }) => (
        <TextArea
          name="add-description"
          disabled={!!editingKey}
          placeholder="Enter description"
          maxLength={512}
          rules={
            form.getFieldValue("add_division") && [mandatory({ message: "Please enter division description" })]
          }
        />
      ),
      editingNode: ({ editingKey }) => (
        <TextArea name="description" placeholder="Enter description"
          maxLength={512}
          rules={
            form.getFieldValue("add_division") && [mandatory({ message: "Please enter division description" })]
          }
        />
      ),
    },
  ];
  const divisions = Form.useWatch("divisions", form);
  const customValidation = () => {
    const isDefaultDivision = !divisions?.length || (divisions?.length === 1 && divisions[0].identifier === "default");
    if (isDefaultDivision && form.getFieldValue("divisions").length < 2) {
      return Promise.reject(
        "You need to add at least one division or uncheck add division checkbox",
      );
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={16}>
      <Col xs={24} lg={8}>&nbsp;</Col>

      <Col xs={24} lg={16} >
        <div className="should-update-col division-layout">
          <Form.Item shouldUpdate className="should-update-form-item">
            {() =>
              form.getFieldValue("add_division") ? (
                <Form.Item
                  shouldUpdate
                  rules={[{ validator: customValidation }]}
                  name="divisions"
                >
                  <EditableTable
                    columns={columns}
                    data={form.getFieldValue("divisions")}
                    onDataChange={(divisions) =>
                      form.setFieldsValue({ divisions })
                    }
                  />
                </Form.Item>
              ) : (
                <div className="nothing-here"></div>
              )
            }
          </Form.Item>
        </div>
      </Col>
    </Row>
  );
};
