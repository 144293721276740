import { Modal,  } from "antd";
import { Typography, Button } from "../../../../../../../design-systems"
import { GreenCheck } from "../../../../../../../design-systems/Icons/GreenCheck";

export const KycSuccessModal = ({ open, onCancel }) => {
    return (
        <Modal
            visible={open}
            onCancel={() => {
                onCancel()
            }}
            title={""}
            width={700}
            footer=""
            className="manually-approve-kyc-success-modal success-employer-modal"
        >
            <div className="green-check-icon">
                <GreenCheck />
            </div>

            <Typography
                className="kyc-success-label"
                variant="subhead-2"
                label={<div className="typo-label">KYC updated successfully</div>}
            />
            <div className="close-btn-wrapper">
                <Button
                    label={"Close"}
                    variant="primary"
                    onclickCallback={() => onCancel()}
                >
                </Button>
            </div>
        </Modal>
    )
}