import { createExpenseService, deleteExpenseDraftDocumentsService, evaluateExpenseDraftService, getExpensesTypeService, saveExpensesAsDraftService, updateExpenseDraftService, uploadDraftExpenseDocumentService } from "../../services/expensesServices";
import { ApiErrors } from "../../utils";
import { EXPENSES_CONSTANT } from "../../utils/constants";

export function getExpensesTypes(id, callback) {
    return (dispatch) => {
        getExpensesTypeService(id)
            .then((res) => {
                callback && callback(res);
                dispatch({
                    type: EXPENSES_CONSTANT.GET_EXPENSES_TYPES,
                    payload: res.data.map((item) => ({
                        value: item.type,
                        label: item.type,
                        ...item
                    })),
                });
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    };
}

export function saveExpenseAsDraft(id, data, callback) {
    return (dispatch) => {
        saveExpensesAsDraftService(id, data)
            .then((res) => {
                callback && callback(res);
                dispatch({
                    type: EXPENSES_CONSTANT.SAVE_EXPENSES_AS_DRAFT,
                    payload: res.data.data
                })
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    }
}

export function updateExpenseDraft(id, expenseId, data, callback) {
    return (dispatch) => {
        updateExpenseDraftService(id, expenseId, data)
            .then((res) => {
                callback && callback(res);
                dispatch({
                    type: EXPENSES_CONSTANT.SAVE_EXPENSES_AS_DRAFT,
                    payload: res.data.data
                })
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    }
}

export const saveUploadedPreSignedUrl = (data) => {
    return (dispatch) => {
        dispatch({
            type: EXPENSES_CONSTANT.SAVE_UPLOADED_PRESIGNED_URL,
            payload: data
        })
    }
}

export function uploadDraftExpenseDocument(id, expenseId, data, callback) {
    return (dispatch) => {
        uploadDraftExpenseDocumentService(id, expenseId, data)
            .then((res) => {
                callback && callback(res);
                dispatch({
                    type: EXPENSES_CONSTANT.SAVE_UPLOADED_PRESIGNED_URL,
                    payload: res.data.presigned_url
                })
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    }
}

export function deleteDraftExpenseDocument(id, draftId, data, callback) {
    return (dispatch) => {
        deleteExpenseDraftDocumentsService(id, draftId, data)
            .then((res) => {
                callback && callback(res);
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    }
}

export function evaluateExpenseDraft(id, data, callback) {
    const processName = "evaluateExpenseDraft"
    return (dispatch) => {
        evaluateExpenseDraftService(id, data)
            .then((res) => {
                callback && callback(res);
                dispatch({
                    type: EXPENSES_CONSTANT.SAVE_EVALUATE_EXPENSE_DATA,
                    payload: res.data
                })
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    }
}

export function updateSavedPresignedUrl(fileName) {
    return dispatch => {
        dispatch({
            type: EXPENSES_CONSTANT.UPDATE_SAVED_PRESIGNED_URL,
            payload: fileName
        })
    }
}

export function createExpense(id, data, callback) {
    return (dispatch) => {
        createExpenseService(id, data)
            .then((res) => {
                callback && callback(res);
            })
            .catch((err) => {
                callback && callback(err);
                ApiErrors(err, dispatch);
            })
            .finally(() => {
            });
    }
}

export function resetExpenseData() {
    return dispatch => {
        dispatch({
            type: EXPENSES_CONSTANT.RESET_EXPENSE_DATA
        })
    }
}