export const CloseIcon = ({ width = 24, height = 24, onClick }) => (
    <svg width={width} height={height} onClick={onClick} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4738_1945)">
            <path d="M18 6L6 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 6L18 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_4738_1945">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
