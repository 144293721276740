import axios from "axios";
import { Auth as CAuth } from "aws-amplify";
import { getLocalStorage, setAuthorization } from "../utils";

const apiBaseURL = process.env.REACT_APP_PUBLIC_BIZ_API_BASE_URL;

const axiosInstance = axios.create({ baseURL: apiBaseURL });

axiosInstance.interceptors.request.use(
  async function (config) {
    const storage = getLocalStorage();

    const currentSession = await CAuth.currentSession();

    const accessToken = currentSession.accessToken.jwtToken;
    const idToken = currentSession.idToken.jwtToken;

    if (storage.authDetails?.accessToken !== accessToken) {
      setAuthorization({ accessToken, idToken });
    }

    if (storage.authDetails?.accessToken) {
      config.headers.Authorization = accessToken;
      config.headers.idToken = idToken;
      if (config.url === "/employer/payroll/contribution-run") {
        config.headers["Content-Type"] = "multipart/form-data";
      } else if (config?.setContentMultipart) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else {
        config.headers["Content-Type"] = "application/json";
      }
    }

    return config;
  },
  async function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default axiosInstance;
