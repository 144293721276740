import React from "react";
import { useSelector } from "react-redux";

import { Spin } from "antd";
import loaderLogo from "../../assets/img/incomm-loader.svg"

function SpinLoader({ fullscreen, showContent = true, children, className = "", customLoading }) {
  const globalLoading = useSelector((state) => state.loader.globalLoading);
  const dataLoading = useSelector((state) => state.loader.dataLoading);
  const loading = useSelector((state) => state.loader.loading);

  return fullscreen ? (
    <Spin
      spinning={globalLoading}
      tip={<img src={loaderLogo} alt="InComm Benefits-logo" className="loader-logo" />}
      className="page-loader-fullscreen"
      size="large"
    >
      {children}
    </Spin>
  ) : (
    <Spin
      spinning={loading || customLoading ||  Boolean(dataLoading?.length)}
      tip={<img src={loaderLogo} alt="InComm Benefits-logo" className="loader-logo" />}
      className={`page-loader  ${className}`}
      size="large"
    >
      {showContent ? children : null}
    </Spin>
  );
}

export { SpinLoader };
