import React from "react";

import { Modal } from "antd";

function WarningDeleteAdminModal({ showWarningModal, deleteAdmin, onCancel }) {
  return (
    <Modal
      visible={showWarningModal}
      closable={false}
      okText="Yes"
      cancelText="No"
      onOk={deleteAdmin}
      onCancel={onCancel}
      className="common-modal-style"
    >
      Are you sure you want to delete the user?
    </Modal>
  );
}

export { WarningDeleteAdminModal };
