import axios from "./axiosInterceptors";
import { formatDate } from "../utils";

export const getEmployerService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employers/summary",
    };

    const response = await axios(request);

    return { error: false, data: response.data.body || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const switchEmployerService = async (data) => {
  try {
    const request = {
      method: "put",
      url: "/employer/payroll/admin/switch-employer",
      data,
    };

    const response = await axios(request);

    return { error: false, data: response.data.body || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addEmployerService = async (data) => {
  try {
    data.payload[0].sent_at = formatDate();
    data.payload[0].employer_end_date = "3500-01-01";

    const request = {
      method: "post",
      url: "/employer/payroll/register",
      data,
    };

    const response = await axios(request);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCurrentEmployerService = async (data) => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employer/list",
    };

    const response = await axios(request);

    return { error: false, data: response.data.body.data || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEmployerLedgerService = async (data) => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employer/ledger",
      params: data,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEmployeeSummaryService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employer/employee-totals",
    };

    const response = await axios(request);

    return { error: false, data: response.data.data };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getDivisionsService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/divisions",
    };

    const response = await axios(request);

    return { error: false, data: response.data.data.divisions };
  } catch (error) {
    throw error;
  }
};

export const getAccountHolderDetailsService = async (emailID) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/support/account-holder-details",
      data: JSON.stringify({
        key: "email",
        value: emailID,
      }),
    };

    const response = await axios(request);

    return response.data.response;
  } catch (error) {
    throw error;
  }
};

export const getExpenseGroupDetailsService = async (data, pagination) => {
  const { zan, expense_group } = data;
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/support/account-holder-details/${zan}/${expense_group}`,
      params: pagination
    };

    const response = await axios(request);
    return response?.data?.expenses;
  } catch (error) {
    throw error;
  }
}
export const getAccountHolderDetailsServiceV2 = async (zan) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/support/account-holder-details",
      data: JSON.stringify({
        key: "zan",
        value: zan,
      }),
    };

    const response = await axios(request);

    return response.data.response;
  } catch (error) {
    throw error;
  }
};

export const addNewEmployerService = async (data) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/employer/onboarding",
      data
    }
    const response = await axios(request)
    return response.data;
  } catch (error) {
    throw error
  }
}

export const getEmployeeContributionIdentifierService = async () => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/benefits/contribution-employee-identifier`,
    };

    const response = await axios(request);
    return { error: false, data: response.data.body || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEmployeeRunoutOptionsService = async () => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/benefits/terminated-employee-runout-options`,
    };

    const response = await axios(request);
    return { error: false, data: response.data.body || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getCopaysCategoryService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/benefits/category",
    }

    const response = await axios(request);
    return { error: false, data: response.data.body || null }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const copyPlanService = async () => {
  try {
    const request = {
      method: 'post',
      url: `/employer/payroll/employer/add-plan`
    }
    const response = await axios(request);
    return { error: false, data: response.data || null }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const updatePlanService = async (data) => {
  try {
    const request = {
      method: 'put',
      url: `/employer/payroll/employer/add-plan`,
      data
    }
    const response = await axios(request);
    return { error: false, data: response.data || null }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getEmployerDetailsByEmployerId = async (employer_id) => {
  try {
    const request = {
      method: 'get',
      url: `/employer/payroll/employer/onboarding/${employer_id}`
    }
    const response = await axios(request);
    return { error: false, data: response.data.onboarding_data || null }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getEmployerDataByIdService = async (type) => {
  try {
    const request = {
      method: 'get',
      url: `/employer/payroll/employer/details/${type}`
    }
    const response = await axios(request);
    return { error: false, data: response.data.body || null }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const updateEmployerService = async (reqData) => {
  try {
    const request = {
      method: "patch",
      url: "/employer/payroll/admin/detail",
      data: {
        payload: {
          ...reqData,
          sent_at: formatDate(),
        },
      },
    };

    const response = await axios(request);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployerPlanDataService = async () => {
  try {
    const request = {
      method: 'get',
      url: `/employer/payroll/employer/plan/summary`
    }
    const response = await axios(request);
    return { error: false, data: response.data || null }
  } catch (error) {
    console.log(error);
    throw error;
  }
}