import { Col, Form, Modal, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Button,
  DatePicker,
  Select,
  Typography,
} from "../../../../../../../design-systems";
import DatePicker2 from "../../../../../../../design-systems/DatePicker2";
import { langs } from "../../../../../../../i18n";
import { getEmployeesData, showSuccessMessage, updateEmploymentInformation } from "../../../../../../../redux/actions";
import { comparisonValues, handleKeyDown } from "../../../../../../../utils";
import { useRouter } from "../../../../../../../utils/router";
import { mandatory } from "../../../../../../../utils/validations";

export const EmploymentInformationForm = ({ visible, onCancel, divisionList, form, getDivisonName }) => {
  const router = useRouter()
  const dispatch = useDispatch();
  // const [statuses, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [termDate, setTermDate] = useState(null);
  const [formChanged, setFormChanged] = useState(true);
  const { employmentStatuses, employeeDetails } = useSelector(state => state.employees);
  useEffect(() => {
    setTermDate(
      employeeDetails?.employmentTerminationDate?.value ?
        new Date(employeeDetails?.employmentTerminationDate?.value) :
        null
    )
  }, [employeeDetails])

  // useEffect(() => {
  //   if (Object.keys(employmentStatuses)?.length) {
  //     const updatedStatus = Object.entries(employmentStatuses).map(([key, value]) => ({
  //       label: key,
  //       value
  //     }))
  //     setStatus(updatedStatus)
  //   }
  // }, [employmentStatuses])

  const getEmployeeData = () => {
    const id = router.match.params.id;
    dispatch(getEmployeesData(id));
  };

  const initialValues = {
    status: employeeDetails?.employmentStatus?.value,
    employment_termination_date: employeeDetails?.employmentTerminationDate?.value,
    division: employeeDetails?.division_name?.value,
  };

  const clearedData = (object, employmentTermintionDateIsChanged) => {
    const modifiedObject = {};
    for (let key in object) {
      if ((key === "employment_termination_date" && employmentTermintionDateIsChanged)) {
        modifiedObject[key] = object[key] === null ? null : object[key];
      } else if (object[key] !== null && object[key] !== undefined) {
        modifiedObject[key] = object[key];
      }
    }
    return modifiedObject
  }

  const onFinish = (values) => {
    const statusIsChanged = initialValues.status !== values?.status;
    const employmentTermintionDateIsChanged = values?.employment_termination_date ? (moment(values?.employment_termination_date).isSame(initialValues?.employment_termination_date) ? false : true) : values?.employment_termination_date !== initialValues?.employment_termination_date;
    const divisionIsChanged = initialValues?.division !== values?.division
    const data = {
      "division_id": divisionIsChanged ? values.division : null,
      "employment_status_id": statusIsChanged ? values.status : null,
      "employment_termination_date": employmentTermintionDateIsChanged && values.employment_termination_date ? moment(values.employment_termination_date).format("YYYY-MM-DD") : null
    }
    dispatch(updateEmploymentInformation(employeeDetails?.employeeID?.value, clearedData(data, employmentTermintionDateIsChanged), (res) => {
      setLoading(false);
      if (!(res instanceof Error)) {
        setFormChanged(true)
        getEmployeeData()
        setLoading(false);
        onCancel()
        dispatch(showSuccessMessage(langs.messages.update_employee_success));
      }
    }))
  };

  const onFinishFailed = () => {
    setLoading(false)
  };

  const isDisable = (key) => {
    return employeeDetails?.[key]?.canEdit ? false : true;
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
        setFormChanged(true)
      }}
      title={<Typography variant="subhead-2" label="Edit employment details" />}
      width={700}
      footer=""
      className="employment-information-modal-wrapper"
    >
      <Form
        className="employment-information-form"
        layout="vertical"
        name="basic"
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onValuesChange={(values, allValues) => {
          setFormChanged(comparisonValues(initialValues, allValues));
        }}
        onFinishFailed={onFinishFailed}
      >
        <div className="form-wrapper">
          <Row gutter={[16, 0]}>
            {/* <Col sm={12}>
              <Select
                label="Status"
                disabled={isDisable("employmentStatus")}
                placeholder="Select status"
                name="status"
                options={statuses}
                rules={[mandatory({ message: "Please select a valid status" })]}
              />
            </Col> */}
            <Col sm={12}>
              <DatePicker2
                showImportant={false}
                isClearable={true}
                id="employment_termination_date"
                selected={termDate}
                name="employment_termination_date"
                label="Employment termination date"
                placeholder="MM/DD/YYYY"
                format={['MM/dd/yyyy', 'M/d/yyyy']}
                readOnly={false}
                onKeyDown={handleKeyDown}
                disabled={isDisable("employmentTerminationDate")}
                onChange={(date) => {
                  form.setFieldsValue({ 'employment_termination_date': date });
                  setFormChanged(false);
                  setTermDate(date)
                }}
              />
            </Col>
            {
              getDivisonName !== "default" &&
              <Col sm={12}>
                <Select
                  label="Division"
                  placeholder="Select division"
                  name="division"
                  disabled={isDisable("division_name")}
                  options={divisionList?.map(({ division_name, division_id }) => ({
                    label: division_name,
                    value: division_id,
                  }))}
                  rules={[
                    mandatory({ message: "Please select a valid division" }),
                  ]}
                />
              </Col>
            }
          </Row>
        </div>
        <div className="footer-wrapper">
          <Button
            variant="primary"
            onclickCallback={() => { setLoading(true) }}
            label="Save"
            htmlType="submit"
            disabled={formChanged}
            loading={loading}
          />
        </div>
      </Form>
    </Modal>
  );
};
