import { Auth as CAuth } from "aws-amplify";
import { setAuthorization } from "./localStorage";
import { EMAIL_DOMAIN_NAME, FEDERATED_ID_NAME } from "../utils/constants";
import {
  disableGlobalLoader,
  enableGlobalLoader,
  loginWithSSO,
  logout,
} from "../redux/actions";

export const getAWSCredentials = async (dispatch, history) => {
  dispatch(enableGlobalLoader());

  CAuth.currentSession()
    .then((session) => {
      setAuthorization({
        accessToken: session.accessToken.jwtToken,
        idToken: session.idToken.jwtToken,
      });
    })
    .then(async () => {
      const currentUser = await CAuth.currentAuthenticatedUser();
      const role = currentUser.attributes["custom:user_role"];
      const userData = { currentUser, role };
      return userData;
    })
    .then(async (userData) => {
      dispatch(
        loginWithSSO(userData, (res) => {
          dispatch(disableGlobalLoader());

          if (res.error && res.message) {
            dispatch(logout());

            history.push({
              pathname: "/",
              state: { ssoImproperAccess: res.message },
            });
            history.go(0);
          } else if (res.error && !res.message) {
            dispatch(logout());
          } else {
          }
        }),
      );
    })
    .catch(() => {
      dispatch(disableGlobalLoader());
    });
};

export const getProvider = (domain) => {
  switch (domain) {
    case EMAIL_DOMAIN_NAME.GMAIL_COM:
    case EMAIL_DOMAIN_NAME.GETZENDA_COM:
      return FEDERATED_ID_NAME.GOOGLE_SSO;
    case EMAIL_DOMAIN_NAME.OKTA_COM:
      return FEDERATED_ID_NAME.OKTA_SSO;
    case EMAIL_DOMAIN_NAME.ZENDA_BIZ_COM:
      return FEDERATED_ID_NAME.ZENDA_BIZ_SSO;
    default:
      return EMAIL_DOMAIN_NAME.INVALID_DOMAIN;
  }
};
