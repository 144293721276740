export const FillCheck = ({
  width = "18",
  height = "18",
  stroke = "#02880F",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.525 14.275L8.075 11.825C7.925 11.675 7.74167 11.6 7.525 11.6C7.30833 11.6 7.11667 11.6833 6.95 11.85C6.8 12 6.725 12.1833 6.725 12.4C6.725 12.6167 6.8 12.8 6.95 12.95L10 16.025C10.15 16.175 10.325 16.25 10.525 16.25C10.725 16.25 10.9 16.175 11.05 16.025L17.025 10.05C17.1917 9.88333 17.275 9.69167 17.275 9.475C17.275 9.25833 17.1917 9.06667 17.025 8.9C16.8583 8.75 16.6625 8.67917 16.4375 8.6875C16.2125 8.69583 16.025 8.775 15.875 8.925L10.525 14.275ZM12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3667 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.02917 5.825 4.9375 4.925C5.84583 4.025 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6333 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.975 18.1542 19.075 19.0625C18.175 19.9708 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
      fill={stroke}
    />
  </svg>
);
