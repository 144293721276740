import { Col, Form, Modal, Row } from "antd";
import {
  Button,
  Select,
  TextArea,
  TextField,
  Typography,
} from "../../../../../../../design-systems";
import { mandatory, validationSocureTransactionID } from "../../../../../../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { langs } from "../../../../../../../i18n";
import { useEffect, useState } from "react";
import { updateKYCStatus, updateSocureKYCStatus } from "../../../../../../../redux/actions";
import { clearFieldErrors, stripLetters } from "../../../../../../../utils";
import moment from "moment/moment";

export const ManuallyApproveKycForm = ({ getEmployeeData, visible, onCancel, form, setOpenSuccess }) => {
  const dispatch = useDispatch();
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const { userStatuses, employeeDetails } = useSelector((state) => state.employees);

  useEffect(() => {
    if (visible)
      form.setFieldsValue({ kycStatus: userStatuses["KYC Success"] });
  }, [visible])


  const onFinish = (values) => {
    if (
      (!!values.socureTransactionId && !!values.zendeskTicketId) ||
      (!values.socureTransactionId && !values.zendeskTicketId)
    ) {
      const fieldsWidthEmailError = [
        {
          name: "socureTransactionId",
          errors: [langs.messages.socure_id_must_be_passed],
        },
        {
          name: "zendeskTicketId",
          errors: [langs.messages.socure_id_must_be_passed],
        },
      ];

      form.setFields(fieldsWidthEmailError);

      return;
    }

    setButtonIsDisabled(true);

    const dataSocure = {
      payload: {
        user_status_id: values.kycStatus,
        employee_id: employeeDetails?.employeeID?.value,
        reference_id: values.socureTransactionId,
        notes: values.remark,
        sent_at: moment().format("YYYY-MM-DD")
      },
    };

    const dataKYC = {
      payload: {
        user_status_id: values.kycStatus,
        employee_id: employeeDetails?.employeeID?.value,
        work_email: employeeDetails.workEmail.value,
        zendesk_ticket_id: values.zendeskTicketId,
        notes: values.remark,
      },
    };

    if (!!values.socureTransactionId) {
      updateSocure(dataSocure);
    } else {
      updateKYC(dataKYC);
    }
  };

  const updateKYC = (data) => {
    dispatch(
      updateKYCStatus(data, (res) => {
        if (res instanceof Error) {
          setButtonIsDisabled(false);
        } else {
          onCancel()
          setButtonIsDisabled(false);
          setOpenSuccess(true);
          getEmployeeData();
          form.resetFields();
        }
      }),
    );
  };

  const updateSocure = (data) => {
    dispatch(
      updateSocureKYCStatus(data, (res) => {
        if (res instanceof Error) {
          setButtonIsDisabled(false);
        } else {
          onCancel();
          setButtonIsDisabled(false);
          setOpenSuccess(true);
          getEmployeeData();
          form.resetFields();
        }
      }),
    );
  };

  const onFinishFailed = (errorInfo) => {
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        title={<Typography variant="subhead-2" label="Manually approve KYC" />}
        width={700}
        footer=""
        className="manually-approve-kyc-modal-wrapper"
      >
        <Form
          className="manually-approve-kyc-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <div className="form-wrapper">
            <Row gutter={[16, 0]}>
              <Col sm={12}>
                <Select
                  label="KYC Status"
                  placeholder="Select kyc status"
                  name="kycStatus"
                  options={Object.keys(userStatuses).map((elem) => ({
                    label: elem,
                    value: userStatuses[elem],
                  }))}
                rules={[
                  mandatory({ message: "Please select a valid kyc status" }),
                ]}
                />
              </Col>
              <Col sm={12}>
                <TextField
                  label="Zendesk ticket ID"
                  placeholder="0000"
                  name="zendeskTicketId"
                  onChange={(e) => {
                    form.setFieldsValue({
                      zendeskTicketId: stripLetters(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col sm={24}>
                <TextField
                  label="Source transaction ID"
                  placeholder="00000000-0000-0000-0000-000000000000"
                  name="socureTransactionId"
                  rules={[validationSocureTransactionID()]}
                />
              </Col>
              <Col sm={24}>
                <TextArea
                  label="Remark"
                  placeholder="Reason for manual KYC approval"
                  name="remark"
                  maxLength={512}
                  rules={[mandatory({ message: "Please enter valid remark" })]}
                />
              </Col>
            </Row>
          </div>
          <div className="footer-wrapper">
            <Button
              variant="primary"
              onclickCallback={() => { }}
              label="Save"
              loading={buttonIsDisabled}
              htmlType="submit"
            />
          </div>
        </Form>
      </Modal>
      
    </>
  );
};
