import { Checkbox, Col, Form, Row } from "antd";
import { CheckBox, CheckBoxForGroup } from "../../../../../design-systems";
import { planOptions } from "../../../../../utils";

export const SelectPlan = ({ form, setPlansExt }) => {
  const customValidation = () => {
    if (
      !form.getFieldValue("plans") ||
      form.getFieldValue("plans")?.length < 1
    ) {
      return Promise.reject(
        "Please select at least one plan to continue.",
      );
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={16}>
      <Col xs={5} md={5} className="plan-cols">
        <CheckBox
          label="Select All"
          value=""
          name="select_all_plan"
          onChange={(e) => {
            const plans = e.target.checked
              ? planOptions.map(({ value }) => value)
              : [];
            setPlansExt(plans);
            form.setFieldsValue({ plans });
          }}
          className="plans-box"
        />
      </Col>
      <Col xs={19} md={19} className="plan-cols">
        <Form.Item name="plans" className="plans-box">
          <Checkbox.Group>
            <Row>
              {" "}
              {planOptions.map(({ label, value }) => (
                <Col span={5}>
                  <CheckBoxForGroup
                    label={label}
                    value={value}
                    onChange={(e) => {
                      const plans = form.getFieldValue("plans") || [];

                      if (e.target.checked) {
                        setPlansExt([...plans, e.target.value]);
                      } else {
                        setPlansExt([
                          ...plans.filter((plan) => plan !== e.target.value),
                        ]);
                      }

                      const plansLength = e.target.checked
                        ? (form.getFieldValue("plans")?.length || 0) + 1
                        : (form.getFieldValue("plans")?.length || 0) - 1;

                      form.setFieldsValue({
                        select_all_plan: plansLength === planOptions?.length,
                      });
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Col>
      <Col xs={24} md={24}>
        <Form.Item
          rules={[{ validator: customValidation }]}
          name="plans_err"
          className="dummy-error should-update-form-item"
        ></Form.Item>
      </Col>
    </Row>
  );
};
