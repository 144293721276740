import React from "react";
import { Card, Row, Col, Tag, Empty } from "antd";
import { accountStatusMap, fixAmountFormat } from "../../../../utils";
import { Typography } from "../../../../design-systems";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";

function InvestmentSection({ data }) {
  const { account_status, date_opened, investment_account_number, hsba_account_number, balances } = data ?? {};
  return (
    <Card
      bordered={false}
      className="account-holder-cards"
      style={{ height: 230 }}
    >
      {data && Object.keys(data)?.length > 0 ?
        <>
          <div className="d-flex justify-space-between">
            <Typography
              variant="subhead-3"
              label="Investment"
              className="account-holder-card-header mb-15"
            />
            {
              <Typography
                variant=""
                label={fixAmountFormat(balances)}
                className="account-holder-value font-size-18"
              />
            }
          </div>

          <div className="investment-header">
            <div className="left">
              <ul>
                <li>
                  <Typography
                    variant=""
                    label="Investment acc no."
                    className="account-holder-value "
                  />
                </li>
                <li>
                  <Typography
                    variant=""
                    label={investment_account_number}
                    className="investment-card-values"
                  />
                </li>
                <li>
                  <Typography
                    variant=""
                    label="HSBA Acc No."
                    className="account-holder-value "
                  />
                </li>
                <li>
                  <Typography
                    variant=""
                    label={hsba_account_number?.length ? hsba_account_number : "-"}
                    className="investment-card-values"
                  />
                </li>
              </ul>
            </div>
            <div className="right">
              <ul>
                <li>
                  <Typography
                    variant=""
                    label="Status"
                    className="account-holder-value "
                  />
                </li>
                <li>
                  {
                    accountStatusMap[account_status] ?
                      <Tag style={{ borderRadius: 10, padding: "2px 10px", background: "#E6E7E8", borderRadius: 20 }}>
                        {accountStatusMap[account_status]}
                      </Tag> :
                      <div>-</div>
                  }
                </li>
                <li>
                  <Typography
                    variant=""
                    label="Date Opened"
                    className="account-holder-value"
                  />
                </li>
                <li>
                  <Typography
                    variant=""
                    label={moment(date_opened).format(DATE_FORMAT.FOR_UI_FIRST_MM)}
                    className="investment-card-values"
                  />
                </li>
              </ul>
            </div>
          </div>
        </>
        :
        <div>
          <Typography
            variant="subhead-3"
            label="Investment"
            className="account-holder-card-header mb-15"
          />
          <Empty />
        </div>
      }
    </Card>
  );
}

export { InvestmentSection };
