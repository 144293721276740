import React, { useState } from "react"
import Chart from "react-apexcharts";
import { useRouter } from "../../../../utils/router";
import { employeeChartConfigOptions } from "../../../../utils/chart";
import { Empty, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { statusColors, statusDescriptions } from "../../../../utils";
import { Link } from "react-router-dom";

export const EmployeeSummaryChart = ({ data }) => {
    const router = useRouter();
    const allZero = data.every(item => item.employee_count === 0) || !data.length;
    const [series, setSeries] = useState(data.map(opt => opt.employee_count));
    const totalCount = data?.reduce((acc, opt) => acc + opt.employee_count, 0);
    const onLegendClick = (chartContext, seriesIndex, config) => {
        // commenting till we get api support
        const userStatus = config.config.labels[seriesIndex];
        router.push({
            pathname: "/admin/employees",
            state: { userStatus },
        });
    }
    const options = employeeChartConfigOptions(data, totalCount, onLegendClick)
    if (allZero) {
        return <div><Empty /></div>
    }

    const combinedData = data.map(item => ({
        account_holder_status: item.account_holder_status,
        employee_count: item.employee_count,
        description: statusDescriptions[item.account_holder_status],
        color: statusColors[item.account_holder_status]
    }));

    const LegendItem = ({ status, color, description }) => (
        <div className="legend-item-wrapper">
            <Link to={{
                pathname: "/admin/employees",
                state: {
                    userStatus: status
                }
            }} >
                <div className="color-tooltip-wrapper">
                    <div className="color" style={{ backgroundColor: color }} />
                    <div className="status">{status}</div>
                </div>
            </Link>
            <Tooltip placement="right" title={description}>
                <span style={{ marginLeft: "8px", cursor: "pointer" }}><InfoCircleOutlined /></span>
            </Tooltip>
        </div>
    );

    const legendItems = combinedData.map(({ account_holder_status, color, description }) => (
        <LegendItem key={account_holder_status} status={account_holder_status} color={color} description={description} />
    ));
    return (
        <div className="legend-chart-wrapper">
            <Chart
                options={options}
                series={series}
                type="donut"
                width={310}
            />
            <div>{legendItems}</div>
        </div>
    )
}