import { Col, Form, Modal, Row } from "antd";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Radio,
    TextField,
    Typography,
} from "../../../../design-systems";
import { getAccountHolderDetails, updateEmployeeLoginEmail, updateMFAAction } from "../../../../redux/actions";
import { comparisonValues } from "../../../../utils";
import { mfaActionsMap } from "../../../../utils/constants";
import {
    email,
    required,
} from "../../../../utils/validations";

export const ProfileInformationForm = ({ data, visible, setVisible, emailID, setDetails, setSuccess }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [formChanged, setFormChanged] = useState(true);

    useEffect(() => {
        if(data?.mfa_status) {
            form.setFieldsValue({ mfa: mfaActionsMap[data?.mfa_status?.toLowerCase()] })
        }
    }, [data, visible])

    const renderAddress = (address) => {
        const { address_line_1, address_line_2, city, state, postal_code } = address;
        let fullAddress = address_line_1 || '';
        if (address_line_2) {
            fullAddress += ', ' + address_line_2;
        }
        fullAddress += ', ' + city + ', ' + state + ', ' + postal_code;
        return fullAddress;
    };

    const initialValues = {
        zenda_account_num: data?.zenda_account_num,
        personal_email: data?.personal_email,
        work_email: data?.work_email.toLowerCase(),
        account_holder_status: data?.account_holder_status,
        user_address: renderAddress(data?.user_address || {}),
        first_name: data?.first_name,
        middle_name: data?.middle_name,
        last_name: data?.last_name,
        phone_number: data?.phone_number,
        employee_address: renderAddress(data?.employee_address || {}),
        braze_id: data?.braze_id,
        presignup_hash: data?.presignup_hash,
        mfa: mfaActionsMap[data?.mfa_status?.toLowerCase()]
    };

    const getEmployeeData = () => {
        dispatch(
            getAccountHolderDetails(emailID, (res) => {
                setDetails(res);
            }),
        );
    }

    const onFinish = async (values) => {
        const isEmailChanged = data.personal_email !== values.personal_email;
        const isMFAChanged = mfaActionsMap[data?.mfa_status?.toLowerCase()] !== values.mfa;
        const emailPayload = {
            "current_login_email": data?.personal_email ? data?.personal_email?.trim()?.toLowerCase() : null,
            "new_login_email": values.personal_email?.trim()?.toLowerCase()
        }
        const mfaPayload = {
            email: data?.personal_email?.trim()?.toLowerCase()
        }
        setLoading(true)
        const promises = []
        if (isEmailChanged) {
            promises.push(new Promise((resolve) => {
                dispatch(updateEmployeeLoginEmail(emailPayload, (res) => {
                    if (!(res instanceof Error)) {
                        resolve(1)
                    } else {
                        setLoading(false);
                        resolve(0)
                    }
                }))
            }))
        }
        if (isMFAChanged) {
            promises.push(new Promise((resolve) => {
                dispatch(updateMFAAction(mfaPayload, values?.mfa, (res) => {
                    if (!(res instanceof Error)) {
                        resolve(1)
                    } else {
                        setLoading(false);
                        resolve(0)
                    }
                }))
            }))
        }
        Promise.all(promises).then(resp => {
            if (resp[0] === 1) {
                getEmployeeData();
                setFormChanged(true);
                setVisible(false);
                setSuccess(true);
                setLoading(false);
            }
            if(resp[1] === 0) {
                setLoading(false);

            }
        })
    };

    const onFinishFailed = (errorInfo) => {
        setLoading(false)
    };


    return (
        <Modal
            visible={visible}
            onCancel={() => {
                form.resetFields()
                setVisible(false)
                setFormChanged(true)
                setLoading(false)
            }}
            title={<Typography variant="subhead-2" label="Edit account holder details" />}
            width={700}
            footer=""
            className="personal-information-modal-wrapper"
        >
            <Form
                className="personal-information-form"
                layout="vertical"
                name="basic"
                initialValues={initialValues}
                onValuesChange={(changedValues, allValues) => {
                    const isEmailChanged = changedValues.personal_email !== initialValues.personal_email;
                    const isMFAChanged =  changedValues?.mfa ? mfaActionsMap[changedValues?.mfa?.toLowerCase()] !== initialValues.mfa: mfaActionsMap[allValues?.mfa?.toLowerCase()] !== initialValues.mfa;
            
                    if (isEmailChanged || isMFAChanged) {
                        setFormChanged(false);
                    } else {
                        setFormChanged(true);
                    }
                }}
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div className="form-wrapper">
                    <Row gutter={[16, 0]}>
                        <Col sm={12}>
                            <Form.Item
                                name="personal_email"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    autoFocus={true}
                                    label="Login email"
                                    placeholder="Login email"
                                    name="personal_email"
                                    rules={[required("Email id"), email]}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="mfa"
                            >
                                <Radio
                                    value={data?.mfa_status?.toLowerCase()}
                                    label="2 factor authentication"
                                    data={[
                                        { label: "Off", value: "disable" },
                                    ]}
                                    name="mfa"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="work_email"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    disabled={true}
                                    label="Work email"
                                    placeholder="Work email"
                                    name="work_email"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="first_name"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    disabled={true}
                                    label="First name"
                                    placeholder="First name"
                                    name="first_name"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="middle_name"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    disabled={true}
                                    label="Middle name"
                                    placeholder="Middle name"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="last_name"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    disabled={true}
                                    label="Last Name"
                                    placeholder="Last name"
                                    name="last_name"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="zenda_account_num"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    disabled={true}
                                    label="InComm Benefits account number"
                                    placeholder="InComm Benefits account number"
                                    name="zenda_account_num"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="account_holder_status"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    disabled={true}
                                    label="Account status"
                                    placeholder="Account status"
                                    name="account_holder_status"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="user_address"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    disabled={true}
                                    label="User address"
                                    placeholder="User address"
                                    name="user_address"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                name="phone_number"
                                normalize={(value) => value.trimStart()}
                            >
                                <TextField
                                    disabled={true}
                                    label="Phone number"
                                    placeholder="Phone number"
                                    name="phone_number"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                            >
                                <TextField
                                    name="employee_address"
                                    label="Employee address"
                                    disabled={true}
                                    placeholder="Employee address"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div className="footer-wrapper">
                    <Button
                        variant="secondary"
                        onclickCallback={() => {
                            setVisible(false)
                            form.resetFields()
                            setFormChanged(true)
                            setLoading(false)
                        }}
                        label="Cancel"
                    />
                    <Button
                        variant="primary"
                        loading={loading}
                        onclickCallback={() => {
                        }}
                        label="Save"
                        htmlType="submit"
                        disabled={formChanged}
                    />
                </div>
            </Form>
        </Modal>
    );
};
