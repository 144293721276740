import React from 'react';
import 'antd/dist/antd.css';
import { fixAmountFormat, formatDateWithoutTime } from '../../../../utils';
import { Col, Row } from 'antd';

const AccountList = ({ data }) => {

  return (
    <div className="account-balance-container">
      {data && data?.map((d, index) => (
        d &&
        <>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <div className='account-balance-planName'>{d?.accountType}</div>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className='align-right account-balance-planName'>{fixAmountFormat(d?.availableBalance)}</div>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className='account-balance-dates '>
                {d?.year ? d.year : d.accountNumber}
              </div>
            </Col>
          </Row>
          {index === data?.length - 1 ?
            null :
            <hr />
          }
        </>
      ))}
    </div>
  );
};

export default AccountList;
