import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Select
} from "../../../../../design-systems";
import { required } from "../../../../../utils/validations";
import { getCoverages, getEmployerInfo } from '../../../../../redux/actions';
import { handleKeyDown } from '../../../../../utils';
import { useMemo } from 'react';
import DatePicker2 from '../../../../../design-systems/DatePicker2';
import moment from "moment";

export const HSAEmpPlanDetails = ({ form, formData, setFormData }) => {
  const dispatch = useDispatch()
  const coverages = useSelector((state) => state.employees.coverages);
  const { employerPlanInfo } = useSelector((state) => state.employer);
  const [planDateRange, setPlanDateRange] = useState([null, null])
  useEffect(() => {
    dispatch(getCoverages());
    dispatch(getEmployerInfo())
  }, [])

  const coverageForSelect = useMemo(() => {
    let coverage = Object.keys(coverages).map((cov) => ({
      label: cov,
      value: coverages[cov]
    }))
    return coverage
  }, [coverages])

  useMemo(() => {
    let minDate = moment().add(100, "year").toDate();
    let maxDate = moment().subtract(100, "year").toDate();
    employerPlanInfo?.forEach((plan) => {
      if (plan?.plans_enrolled?.includes("HSA")) {
        if (minDate > new Date(plan.plan_start_date)) {
          minDate = moment(plan.plan_start_date).toDate()
        }
        if (maxDate < new Date(plan.plan_end_date)) {
          maxDate = moment(plan.plan_end_date).toDate()
        }
      }
    })
    setPlanDateRange([minDate, maxDate])
  }, [employerPlanInfo]);

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <Select
          disabled={!coverageForSelect?.length}
          label="Select coverage type"
          placeholder="Select coverage type"
          name="hsa_coverage_type"
          options={coverageForSelect}
          rules={[
            required("Coverage type"),
          ]}
        />
      </Col>
      <Col xs={24} md={12}>
        <DatePicker2
          isClearable={true}
          id="hsa_plan_effective_date"
          selected={formData["hsa_plan_effective_date"]}
          name="hsa_plan_effective_date"
          rules={[required("Effective date")]}
          label="Effective date"
          placeholder="MM/DD/YYYY"
          format={['MM/dd/yyyy', 'M/d/yyyy']}
          readOnly={false}
          onKeyDown={handleKeyDown}
          minDate={planDateRange[0]}
          maxDate={planDateRange[1]}
          onChange={(date) => {
            form.setFieldsValue({ 'hsa_plan_effective_date': date });
            setFormData(((prevData) => ({
              ...prevData,
              'hsa_plan_effective_date': date
            })))
          }}
        />
      </Col>
      <Col xs={24} md={24}>
        <Form.Item
          name="plan_plan_effective_date"
          className="dummy-error should-update-form-item"
        ></Form.Item>
      </Col>
    </Row>
  )
};
