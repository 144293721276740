import React, { useEffect, useState } from "react";
import { useRouter } from "../../../utils/router/customRouterHook";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllDivisions,
    showSuccessMessage,
    updateEmployeeDetailsFrom,
} from "../../../redux/actions";
import {
    email,
    maxLength,
    minLength,
    required,
    specialValidationEmployeeId,
    SSNValidation,
    validateFirstNameRules,
    validateLastNameRules,
    validateMiddleNameRules,
    validatePhoneNumber,
} from "../../../utils/validations";
import {
    lowerObjectKeys,
} from "../../../utils";
import { STATE_NAMES, DATE_FORMAT, BENEFIT_ACCOUNT_TYPES } from "../../../utils/constants";
import { langs } from "../../../i18n";
import moment from "moment";

import { SpinLoader } from "../../../components/common/SpinLoader";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";

import { Button, Card, Col, Form, Row, } from "antd";
import { TextField, Select, DatePicker } from "../../../design-systems";

function EditEmployeeCensusUpload() {
    const dispatch = useDispatch();
    const router = useRouter();
    const [form] = Form.useForm();
    const [statuses, setStatus] = useState([]);

    const planValue = Form.useWatch('benefits_account_type', form);

    const divisions = useSelector((state) => state.employer.employerDivisions);
    const coverages = useSelector((state) => state.employees.coverages);
    const employmentStatuses = useSelector(
        (state) => state.employees.employmentStatuses,
    );

    const noDivisions = divisions.length === 0 || divisions.length === 1;
    const editableEmp = lowerObjectKeys(router?.location?.state?.employee || {});
    const fileID = router.query?.fileID;
    const empID = router.query?.empID;

    useEffect(() => {
        const initialValue = editableEmp?.benefits_account_type;
        if(planValue === "HSA") {
            if(initialValue !== planValue) {

                form.setFieldsValue({"coverage_tier": null})
            }
        }
    }, [planValue])

    useEffect(() => {
        if (Object.keys(employmentStatuses)?.length) {
            const updatedStatus = Object.entries(employmentStatuses).map(([key, value]) => ({
                label: key,
                value
            }))
            setStatus(updatedStatus)
        }
    }, [employmentStatuses])

    useEffect(() => {
        dispatch(getAllDivisions());

        setInitialFieldsValue();
        setInitialFieldsError();
        // eslint-disable-next-line
    }, []);

    const setInitialFieldsError = () => {
        const errors = editableEmp.errors;
        const initialFieldsError = Object.entries(errors || {}).map(([name, errors]) => ({
            name,
            errors,
        }));
        form.setFields(initialFieldsError);
    };

    const setInitialFieldsValue = () => {
        const initialFieldsValue = {
            ...editableEmp,
            user_status: "Active",
            dob: editableEmp.dob
                ? moment(editableEmp.dob).isValid() && moment(editableEmp.dob)
                : "",
            employment_start_date: editableEmp.employment_start_date
                ? moment(editableEmp.employment_start_date).isValid() &&
                moment(editableEmp.employment_start_date)
                : "",
            plan_effective_date: editableEmp.plan_effective_date
                ? moment(editableEmp.plan_effective_date).isValid() &&
                moment(editableEmp.plan_effective_date)
                : "",
            division:
                divisions.find((div) => div.division_name === editableEmp.division)
                    ?.division_id ?? editableEmp.division,
        };

        form.setFieldsValue(initialFieldsValue);
    };

    const onFinish = (values) => {
        const data = {
            id: empID,
            employee_id: values.employee_id,
            dob: moment(values.dob).format(DATE_FORMAT.FOR_SERVER),
            first_name: values.first_name?.trim(),
            middle_name: values.middle_name?.trim() || null,
            last_name: values.last_name?.trim(),
            employment_start_date: moment(values.employment_start_date).format(
                DATE_FORMAT.FOR_SERVER,
            ),
            work_email: values.work_email,
            employment_status: values.employment_status,
            ssn: values.ssn,
            mobile_phone_number: values.mobile_phone_number || null,
            home_address_1: values.home_address_1,
            home_address_2: values.home_address_2 || null,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code,
            plan_effective_date: moment(values.plan_effective_date).format(
                DATE_FORMAT.FOR_SERVER,
            ),
            employment_term_date: values.employment_term_date
                ? moment(values.employment_term_date).format(DATE_FORMAT.FOR_SERVER)
                : null,
            plan_term_date: values.plan_term_date
                ? moment(values.plan_term_date).format(DATE_FORMAT.FOR_SERVER)
                : null,
            coverage_tier: values.coverage_tier || null,
            division: values.division
                ? divisions.find((div) => div.division_id === values.division)
                    ?.division_name
                : "default",
            benefits_account_type: values.benefits_account_type || null,
            employee_amount: values?.employee_amount || null,
            employer_amount: values?.employer_amount || null,
            benefits_amount: values?.benefits_amount || null
        };

        if (!data.division) data.division = "default";

        dispatch(
            updateEmployeeDetailsFrom(fileID, empID, data, (res) => {
                dispatch(showSuccessMessage(langs.messages.update_employee_success));

                router.push(`/admin/employees/verification-census-file/${fileID}`);
            }),
        );
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onCancel = () => {
        router.push(`/admin/employees/verification-census-file/${fileID}`);
    };

    const isDisable = (key) => {
        if (key === "user_status") return true;
    };

    const customValidation = () => {
        const { benefits_amount, employee_amount, employer_amount } = form.getFieldsValue() || {};
        if ((Number(employee_amount) + Number(employer_amount)) !== Number(benefits_amount)) {
            return Promise.reject(`Benefit amount must be equal to sum of employee and employer amount`);
        }
        return Promise.resolve('valid');
    };

    return (
        <HeaderWithContent>
            <Card
                title="Employee details"
                bordered={false}
                className="data-grid edit-employee"
            >
                <SpinLoader>
                    <Form
                        name="basic"
                        form={form}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        autoComplete="off"
                        className="common-form-container edit-employee-form"
                    >
                        <Row gutter={(0, 30)}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="first_name"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("firstName")}
                                        label="First name"
                                        placeholder="Add first name"
                                        name="first_name"
                                        rules={
                                            [
                                                { validator: validateFirstNameRules, required: true },
                                            ]
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="middle_name"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("middle_name")}
                                        label="Middle name"
                                        placeholder="Add middle name"
                                        name="middle_name"
                                        rules={[{ validator: validateMiddleNameRules }]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="last_name"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("lastName")}
                                        label="Last name"
                                        rules={
                                            [
                                                { validator: validateLastNameRules, required: true },
                                            ]
                                        }
                                        placeholder="Add last name"
                                        name="last_name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={(0, 30)}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="home_address_1"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("home_address_1")}
                                        label="Street address 1"
                                        placeholder="Add street address 1"
                                        name="home_address_1"
                                        rules={[required("Street address 1"), maxLength(100)]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="home_address_2"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("home_address_2")}
                                        label="Street address 2"
                                        placeholder="Add street address 2"
                                        name="home_address_2"
                                        rules={[maxLength(100)]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={(0, 30)}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="city"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("city")}
                                        label="City"
                                        placeholder="Add city"
                                        name="city"
                                        rules={[required("City"), maxLength(60)]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="state"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <Select
                                        disabled={isDisable("state")}
                                        label="State"
                                        placeholder="Select state"
                                        name="state"
                                        options={STATE_NAMES.map(({ abbreviation }) => ({
                                            label: abbreviation,
                                            value: abbreviation,
                                        }))}
                                        rules={[required("State"), minLength(2), maxLength(2)]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="postal_code"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("postal_code")}
                                        label="Zip code"
                                        placeholder="Add zip code"
                                        name="postal_code"
                                        rules={[required("Zip code"), maxLength(5), minLength(5)]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={(0, 30)}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                >
                                    <DatePicker
                                        name="dob"
                                        rules={[required("Date of birth")]}
                                        label="Date of birth"
                                        disabled={isDisable("dob")}
                                        format={DATE_FORMAT.FOR_UI_FIRST_MM}
                                        disabledDate={(currentDate) => {
                                            const today = moment().startOf('day');
                                            return (
                                                currentDate.isAfter(today)
                                                    ? currentDate.isAfter(today)
                                                    : false
                                            )
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="work_email"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("workEmail")}
                                        label="Work email"
                                        placeholder="Add work email"
                                        name="work_email"
                                        rules={[required("Email id"), email]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={(0, 30)}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                    name="mobile_phone_number"
                                    normalize={(value) => value.trimStart()}
                                >
                                    <TextField
                                        disabled={isDisable("mobile_phone_number")}
                                        label="Mobile phone number"
                                        placeholder="Add mobile phone number"
                                        name="mobile_phone_number"
                                        rules={[{
                                            validator: validatePhoneNumber
                                        }]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                >
                                    <TextField
                                        name="ssn"
                                        label="SSN"
                                        rules={[required("SSN"), SSNValidation]}
                                        hasFeedback
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        disabled={isDisable("SSN")}
                                        placeholder="SSN"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={(0, 30)}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <div className="custom-icon">
                                    <Form.Item>
                                        <TextField
                                            name="employee_id"
                                            label="Employee ID"
                                            rules={[
                                                required("Employee ID"),
                                                maxLength(30),
                                                specialValidationEmployeeId(),
                                            ]}
                                            hasFeedback
                                            disabled={isDisable("employee_id")}
                                            placeholder="Employee ID"
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                >
                                    <DatePicker
                                        label="Employment start date"
                                        name="employment_start_date"
                                        rules={[required("Employment start sate")]}
                                        disabled={isDisable("employment_start_date")}
                                        placeholder="Date"
                                        disabledDate={(currentDate) => {
                                            const today = moment().startOf('day');
                                            return (
                                                currentDate && currentDate.isAfter(today)
                                            )
                                        }
                                        }
                                        format={DATE_FORMAT.FOR_UI_FIRST_MM}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={(0, 30)}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item
                                >
                                    <Select
                                        rules={[required("Employment status")]}
                                        label="Employment status"
                                        name="employment_status"
                                        disabled={isDisable("employment_status")}
                                        placeholder="Select employment status"
                                        options={statuses}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                <Form.Item>
                                    <DatePicker
                                        disabled={isDisable("employmentTerminationDate")}
                                        name="employment_termination_date"
                                        label="Employment termination date"
                                        format="MM/DD/YYYY"
                                        disabledDate={(currentDate) => {
                                            const today = moment().startOf('day');
                                            return (
                                                currentDate && currentDate.isAfter(today)
                                            )
                                        }
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={(0, 30)}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                                {
                                    !noDivisions &&
                                    <Select
                                        label="Division"
                                        placeholder="Select division"
                                        name="division"
                                        disabled={isDisable("division")}
                                        options={divisions?.map(({ division_name, division_id }) => ({
                                            label: division_name,
                                            value: division_id,
                                        }))}
                                        rules={[required("Division")]}
                                    />
                                }
                            </Col>
                        </Row>

                        <Row gutter={(0, 30)}>
                            {(planValue === "HSA") ?
                                <>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item
                                            rules={[required("Coverage type")]}
                                        >
                                            <Select
                                                label="Plan"
                                                name="benefits_account_type"
                                                rules={[required("Plan")]}
                                                disabled={isDisable("benefits_account_type")}
                                                placeholder="Plan"
                                                options={Object.entries(BENEFIT_ACCOUNT_TYPES).map(([key, value]) => ({
                                                    label: key,
                                                    value: key
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item
                                            rules={[required("Coverage tier")]}
                                        >
                                            <Select
                                                label="Coverage tier"
                                                name="coverage_tier"
                                                rules={[required("Coverage tier")]}
                                                disabled={isDisable("coverage_tier")}
                                                placeholder="Coverage tier"
                                                options={Object.entries(coverages).map(([key, value]) => {
                                                    return ({
                                                        label: key,
                                                        value: key
                                                    })
                                                })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <DatePicker
                                                label="Coverage Effective Date"
                                                rules={[required("Coverage Effective Date")]}
                                                name="plan_effective_date"
                                                disabled={isDisable("plan_effective_date")}
                                                placeholder="Date"
                                                format={DATE_FORMAT.FOR_UI_FIRST_MM}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                                :
                                <>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item
                                            rules={[required("Plan")]}
                                        >
                                            <Select
                                                label="Plan"
                                                name="benefits_account_type"
                                                rules={[required("Plan")]}
                                                disabled={isDisable("benefits_account_type")}
                                                placeholder="Plan"
                                                options={Object.entries(BENEFIT_ACCOUNT_TYPES).map(([key, value]) => ({
                                                    label: key,
                                                    value: key
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <DatePicker
                                                label="Coverage Effective Date"
                                                rules={[required("Coverage Effective Date")]}
                                                name="plan_effective_date"
                                                disabled={isDisable("plan_effective_date")}
                                                placeholder="Date"
                                                format={DATE_FORMAT.FOR_UI_FIRST_MM}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <DatePicker
                                                label="Coverage End Date"
                                                // rules={[required("End Date")]}
                                                name="plan_term_date"
                                                disabled={isDisable("plan_term_date")}
                                                placeholder="Date"
                                                format={DATE_FORMAT.FOR_UI_FIRST_MM}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <TextField
                                                name="employee_amount"
                                                label="Election amount"
                                                rules={[required("Election amount")]}
                                                hasFeedback
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isDisable("employee_amount")}
                                                placeholder="Election amount"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <TextField
                                                name="employer_amount"
                                                label="Employer contribution"
                                                rules={[required("Employer contribution")]}
                                                hasFeedback
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isDisable("employer_amount")}
                                                placeholder="Employer contribution"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <TextField
                                                name="benefits_amount"
                                                label="Total amount"
                                                rules={[required("Total amount")]}
                                                hasFeedback
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isDisable("benefits_amount")}
                                                placeholder="Total amount"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item
                                            rules={[{ validator: customValidation }]}
                                            name="plans_err"
                                            className="dummy-error should-update-form-item"
                                        ></Form.Item>
                                    </Col>
                                </>
                            }
                            {/* {(editableEmp?.benefits_account_type !== "HSA" || planValue !== "HSA") &&
                                <>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item
                                            rules={[required("Plan")]}
                                        >
                                            <Select
                                                label="Plan"
                                                name="benefits_account_type"
                                                rules={[required("Plan")]}
                                                disabled={isDisable("benefits_account_type")}
                                                placeholder="Plan"
                                                options={Object.entries(BENEFIT_ACCOUNT_TYPES).map(([key, value]) => ({
                                                    label: key,
                                                    value: key
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <DatePicker
                                                label="Coverage Effective Date"
                                                rules={[required("Coverage Effective Date")]}
                                                name="plan_effective_date"
                                                disabled={isDisable("plan_effective_date")}
                                                placeholder="Date"
                                                format={DATE_FORMAT.FOR_UI_FIRST_MM}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <DatePicker
                                                label="Coverage End Date"
                                                // rules={[required("End Date")]}
                                                name="plan_term_date"
                                                disabled={isDisable("plan_term_date")}
                                                placeholder="Date"
                                                format={DATE_FORMAT.FOR_UI_FIRST_MM}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <TextField
                                                name="employee_amount"
                                                label="Election amount"
                                                rules={[required("Election amount")]}
                                                hasFeedback
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isDisable("employee_amount")}
                                                placeholder="Election amount"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <TextField
                                                name="employer_amount"
                                                label="Employer contribution"
                                                rules={[required("Employer contribution")]}
                                                hasFeedback
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isDisable("employer_amount")}
                                                placeholder="Employer contribution"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={8}>
                                        <Form.Item>
                                            <TextField
                                                name="benefits_amount"
                                                label="Total amount"
                                                rules={[required("Total amount")]}
                                                hasFeedback
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={isDisable("benefits_amount")}
                                                placeholder="Total amount"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item
                                            rules={[{ validator: customValidation }]}
                                            name="plans_err"
                                            className="dummy-error should-update-form-item"
                                        ></Form.Item>
                                    </Col>
                                </>
                            } */}
                        </Row>

                        <Row gutter={(0, 30)} justify="center">
                            <Col className="mt-40">
                                <Form.Item>
                                    <Button
                                        type="button"
                                        className="button-secondary mr-10"
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="button-primary"
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </SpinLoader>
            </Card>
        </HeaderWithContent >
    );
}

export { EditEmployeeCensusUpload };
