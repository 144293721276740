import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRouter } from "../../utils/router";
import { useDispatch } from "react-redux";
import { clearFieldErrors } from "../../utils";
import { LoginWithSSO } from "./LoginWithSSO";
import { loginEmail } from "../../utils/validations";
import { langs } from "../../i18n";
import { disableGlobalLoader, disableLoader, login } from "../../redux/actions";

import { Form, Input, Button, Divider } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";

function LoginPage() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = useForm();

  useEffect(() => {
    dispatch(disableGlobalLoader());
    dispatch(disableLoader());
  }, [dispatch]);

  const onFinish = async (values) => {
    dispatch(
      login(values, async (res) => {
        if (res instanceof Error) {
          const fieldsWidthAuthError = [
            { name: "email", errors: [""] },
            { name: "password", errors: [langs.messages.invalid_credentials] },
          ];

          form.setFields(fieldsWidthAuthError);

          return;
        }

        const loggedInUser = res.cognitoUser;
        console.log("loggedInUser: ", loggedInUser);

        if (res.cognitoUser.challengeName === "SMS_MFA") {
          console.log("SMS_MFA");
          router.push({
            pathname: "/auth/verifyid",
            state: {
              phone: res.cognitoUser.challengeParam.CODE_DELIVERY_DESTINATION,
              valid: true,
            },
          });
        } else if (res.cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          router.push({
            pathname: "/auth/account-setup",
            state: { email: values.email, password: values.password },
          });

          dispatch(disableGlobalLoader());
        } else if (res.cognitoUser.preferredMFA === "NOMFA") {
          console.log("NOMFA");
          router.push({
            pathname: "/auth/verifyphone",
            state: { username: res.cognitoUser.username },
          });
          dispatch(disableGlobalLoader());
        } else {
          console.log("Login ELSE", res);
          dispatch(disableGlobalLoader());
        }
      }),
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("user AUTH Failed:", errorInfo);
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  return (
    <div className="auth-body-content">
      <div className="form-container">
        <div className="form-body">
          <Form
            name="login"
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            layout="vertical"
            autoComplete="off"
            validateTrigger="onSubmit"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[loginEmail]}
              normalize={(value) => value.trim()}
            >
              <Input prefix={<i className="ni ni-email-83" />} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              normalize={(value) => value.trim()}
            >
              <Input.Password
                prefix={<i className="ni ni-lock-circle-open" />}
                iconRender={(visible) =>
                  visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                }
              />
            </Form.Item>

            <Form.Item shouldUpdate className="btn-block text-center">
              {({ getFieldsValue }) => {
                const { email, password } = getFieldsValue();
                const formIsComplete = !!email && !!password;
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button-primary w-100"
                    disabled={!formIsComplete}
                  >
                    Log In
                  </Button>
                );
              }}
            </Form.Item>

            <div className="text-right mb-15">
              <Link to="/auth/forgot-password" className="forgot-link">
                Forgot your password?
              </Link>
            </div>
          </Form>

          <Divider plain className="mb-4">
            <p className="corp-id-divider">OR</p>
          </Divider>

          <LoginWithSSO />
        </div>
      </div>
    </div>
  );
}

export { LoginPage };
