import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loader"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = compose(applyMiddleware(thunkMiddleware));

export const store = createStore(persistedReducer, middleware);
export const pStore = persistStore(store);
