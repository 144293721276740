export const HospitalIcon = ({ className = "" }) => (
<svg className={className} fill="#000000" height="16" width="16" version="1.1" id="Layer_1" viewBox="0 0 469.333 469.333">
<g>
	<g>
		<path d="M330.667,192h-64h-64h-64c-5.899,0-10.667,4.779-10.667,10.667v74.667c0,5.888,4.768,10.667,10.667,10.667h64h64h64
			c5.899,0,10.667-4.779,10.667-10.667v-74.667C341.333,196.779,336.565,192,330.667,192z M192,266.667h-42.667v-53.333H192V266.667
			z M256,266.667h-42.667v-53.333H256V266.667z M320,266.667h-42.667v-53.333H320V266.667z"/>
	</g>
</g>
<g>
	<g>
		<circle cx="53.333" cy="181.333" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<circle cx="53.333" cy="224" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<circle cx="53.333" cy="266.667" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<circle cx="53.333" cy="309.333" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<circle cx="53.333" cy="352" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<path d="M437.333,128H384V96c0-17.643-14.357-32-32-32h-34.805c-9.515-36.747-42.859-64-82.528-64s-73.013,27.253-82.528,64
			h-34.805c-17.643,0-32,14.357-32,32v32H32c-17.643,0-32,14.357-32,32v234.667v64c0,5.888,4.768,10.667,10.667,10.667h448
			c5.899,0,10.667-4.779,10.667-10.667v-64V160C469.333,142.357,454.976,128,437.333,128z M85.333,448h-64v-42.667h64V448z
			 M85.333,384h-64V160c0-5.877,4.779-10.667,10.667-10.667h53.333V384z M234.667,21.333c35.296,0,64,28.704,64,64
			c0,35.296-28.704,64-64,64s-64-28.704-64-64C170.667,50.037,199.371,21.333,234.667,21.333z M224,448h-64V330.667h64V448z
			 M309.333,448h-64V330.667h64V448z M362.667,138.667v256V448h-32V320c0-5.888-4.768-10.667-10.667-10.667H149.333
			c-5.899,0-10.667,4.779-10.667,10.667v128h-32v-53.333v-256V96c0-5.877,4.779-10.667,10.667-10.667h32
			c0,47.051,38.283,85.333,85.333,85.333S320,132.384,320,85.333h32c5.888,0,10.667,4.789,10.667,10.667V138.667z M448,448h-64
			v-42.667h64V448z M448,384h-64V149.333h53.333c5.888,0,10.667,4.789,10.667,10.667V384z"/>
	</g>
</g>
<g>
	<g>
		<circle cx="416" cy="181.333" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<circle cx="416" cy="224" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<circle cx="416" cy="266.667" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<circle cx="416" cy="309.333" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<circle cx="416" cy="352" r="10.667"/>
	</g>
</g>
<g>
	<g>
		<path d="M256,53.333c-5.899,0-10.667,4.779-10.667,10.667v10.667H224V64c0-5.888-4.768-10.667-10.667-10.667
			c-5.899,0-10.667,4.779-10.667,10.667v42.667c0,5.888,4.768,10.667,10.667,10.667c5.899,0,10.667-4.779,10.667-10.667V96h21.333
			v10.667c0,5.888,4.768,10.667,10.667,10.667c5.899,0,10.667-4.779,10.667-10.667V64C266.667,58.112,261.899,53.333,256,53.333z"/>
	</g>
</g>
</svg>
)