import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpinLoader } from "../../../common/SpinLoader";

import { getEmployerLedger } from "../../../../redux/actions";

import { PendingTransactionsTable } from "./PendingTransactionsTable";
import { CompletedTransactionsTable } from "./CompletedTransactionsTable";

function TransactionHistory({ fullView }) {
  const dispatch = useDispatch();

  const [ledgerData, setLedgerData] = useState(null);
  const currentEmployer = useSelector(
    (state) => state.employer.currentEmployer?.data.employer_name,
  );

  useEffect(() => {
    const requestDateRange = { from_date: "", to_date: "" };

    setLedgerData({});
    getLedgerData(requestDateRange);

    //eslint-disable-next-line
  }, [currentEmployer]);

  const getLedgerData = (bodyData) => {
    dispatch(
      getEmployerLedger(bodyData, (res) => {
        setLedgerData(res.data);
      }),
    );
  };

  return (
    <SpinLoader showContent={!!ledgerData}>
      <div className="transaction-history">
        <PendingTransactionsTable transactionList={ledgerData?.transactions} />
        <CompletedTransactionsTable
          fullView={fullView}
          ledgerData={ledgerData}
          getLedgerData={getLedgerData}
        />
      </div>
    </SpinLoader>
  );
}

export { TransactionHistory };
