import moment from "moment";
import { DATE_FORMAT } from "../utils/constants";

/* This function fixes this JS issue - 0.1 + 0.2 ===  0.30000000000000004 */
export function fixSumValue(sum) {
  return +sum.toFixed(12);
}

/* convert first letter in uppercase */
export const capitalizeFirstLetter = (string) => {
  if (string === "" || string === null || string === undefined) {
    return "";
  } else {
    return string
      .split(" ")
      .map((item) => item.substring(0, 1).toUpperCase() + item.substring(1))
      .join(" ");
  }
};

export const formatDate = (date) => {
  var d;
  if (date === null || date === undefined) {
    d = new Date();
  } else {
    d = new Date(date);
  }

  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export function formatTime(date) {
  var d;
  if (date === null || date === undefined) {
    d = new Date();
  } else {
    d = new Date(date);
  }

  var hours = "" + d.getHours(),
    minutes = "" + d.getMinutes(),
    seconds = "" + d.getSeconds();

  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;

  return [hours, minutes, seconds].join(":");
}

/*
  This function fixes amount to format: 
    12345678.9 => $12,345,678.90
    -123.4     => -$123.40
    0          => $0.00
    null       => $0.00
    "123"      => $123.00
    "-123.456" => -$123.46
*/
export function fixAmountFormat(amount = 0) {
  amount = Number(amount);

  const negative = amount < 0;

  const formattedAmount = Math.abs(amount)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${negative ? "-" : ""}$${formattedAmount}`;
}

export function hideBankingData(string) {
  if (!string) {
    return;
  }
  return string
    .split("")
    .map((number, index) => (index < string.length - 4 ? "*" : number))
    .join("");
}

/* 11/11/2021 DD/MM/YYYY */
export function dateFormate1(date) {
  return moment.utc(date).format(DATE_FORMAT.FOR_UI_FIRST_DD);
}

/* Compare two objects for identity */
export function comparisonValues(object1 = {}, object2 = {}) {
  const props1 = Object.getOwnPropertyNames(object1);
  const props2 = Object.getOwnPropertyNames(object2);

  if (props1.length !== props2.length) {
    return false;
  }

  for (let i = 0; i < props1.length; i += 1) {
    const prop = props1[i];
    const bothAreObjects =
      typeof object1[prop] === "object" && typeof object2[prop] === "object";

    if (bothAreObjects) {
      if (JSON.stringify(object1[prop]) !== JSON.stringify(object2[prop])) {
        return false;
      }
    } else if (!bothAreObjects && object1[prop] !== object2[prop]) {
      return false;
    }
  }

  return true;
}

/* Save text to the clipboard without styles */
export const copyWithoutStylesListener = () =>
  document.addEventListener("copy", () => {
    navigator.clipboard.readText().then((text) => {
      navigator.clipboard.writeText(text);
    });
  });

/* Allow only digits */
export function stripLetters(string, type) {
  const pattern = type === "decimal" ? /[0-9.]/g : /[0-9]/g;

  if (!string) return;

  return string
    .split("")
    .map((letter) => (letter.match(pattern) ? letter : ""))
    .join("");
}

/* Validate Email */
export function validateEmail(email) {
  const emailRex =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  return emailRex.test(email.toLowerCase());
}

/* Deletes empty fields from the object */
export function clearBodyObject(object) {
  for (let key in object) {
    if (object[key] === null || object[key] === undefined) {
      delete object[key];
    }
  }

  return object;
}

/* Clean up form errors */
export const clearFieldErrors = (form, allValues) => {
  const fieldNames = Object.keys(allValues);
  const clearedFields = fieldNames.map((field) => ({
    name: field,
    errors: [],
  }));

  form.setFields(clearedFields);
};

/* Get the local time zone */
export const getLocalTimezone = () => {
  const zoneName = moment.tz.guess();
  const timezoneName = moment.tz(zoneName).zoneAbbr();

  return timezoneName;
};

export const trimFormValues = (values) => {
  const trimmedValues = {};
  Object.keys(values)?.forEach((key) => {
    const value = values[key];

    if (Array.isArray(value)) {
      // Trim each element in the array
      trimmedValues[key] = value.map((item) => (item && item?.trim) ? item?.trim() : item);
    } else if (value && value instanceof moment) {
      // If it's a moment object, convert to string
      // trimmedValues[key] = value?.format();
      trimmedValues[key] = value;
    } else if (typeof value === 'string') {
      // Trim string values
      trimmedValues[key] = value?.trim();
    } else {
      // Leave other types unchanged
      trimmedValues[key] = value;
    }
  });
  return trimmedValues;
};

export const removeDuplicates = (array, key) => {
  if (!Array.isArray(array) || array.length === 0 || !key) return array;
  
  const uniqueArray = Object.values(array.reduce((acc, obj) => {
    acc[obj[key]] = obj;
    return acc;
  }, {}));
  return uniqueArray
}

export const modifiedTimestamp = (date) => {
  return moment(date)
  .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
  .format("YYYY-MM-DDTHH:mm:ss[Z]")
};

export const identifyEnvironment = (() => {
  let cachedEnvironment = null;

  return (url) => {
    if (cachedEnvironment) {
      return cachedEnvironment;
    }

    switch (true) {
      case url.includes("devint"):
        cachedEnvironment = "Devint";
        break;
      case url.includes("stg"):
        cachedEnvironment = "Staging";
        break;
      case url.includes("qa"):
        cachedEnvironment = "QA";
        break;
      default:
        cachedEnvironment = "";
    }

    return cachedEnvironment;
  };
})();

export const formatDateWithoutTime = (isoDate) => {
  if (!isoDate || typeof isoDate !== 'string') {
    return
  }

  const dateOnly = moment(isoDate.split("T")[0]).format('MM-DD-YYYY');
  return dateOnly;
}

export const generateDateArray = (startDate, endDate) => {
  const dates = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.clone().toDate());
      currentDate.add(1, 'days');
  }

  return dates;
};