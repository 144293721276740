import { Card, Upload, Tooltip, Empty, Spin, Progress } from "antd";
import { useRef, useState } from "react";
import { LoadingOutlined, PlusOutlined, PictureOutlined } from '@ant-design/icons';
import { ImageViewer } from "../../../../common/ImageViewer";
import { PDFViewer } from "../../../../common/PDFViewer";
import Pdf from "../../../../../assets/img/pdf-icon.png"
import { useEffect } from "react";
import { NoDocuments } from "../NoDocuments"
import { useDispatch, useSelector } from "react-redux";
import { saveClaimsDocumentsPreDignedURL, getClaimsDocumentsList, updateVerifyUpload, getActivityLogs, getClaimDetailsById } from "../../../../../redux/actions/claims.actions";
import { getFileUploadStatus, getFileUploadUrl, showErrorMessage, showSuccessMessage } from "../../../../../redux/actions";
import { langs } from "../../../../../i18n";
import imageCompression from 'browser-image-compression'
import { useMemo } from "react";
import { useCallback } from "react";

export const Documents = ({ claimId, status, expenseMode, allow_add_documents }) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [uploadStatus, setUploadStatus] = useState({});
  const [intervalIds, setIntervalIds] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileList, setFileList] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeRecord, setActiveRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(0)
  const [selectedFilesCount, setselectedFilesCount] = useState(0)
  const errorToastShownRef = useRef(false);

  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
  const { claimsDocs } = useSelector((state) => state.claims)

  const isCardExpenseType = expenseMode === "Card";
  const isStatusNeedsMoreInfo = status === "NEEDS_MORE_INFO";
  const isStatusDenied = status === "DENIED";
  const isStatusPartiallyApproved = status === "PARTIALLY_APPROVED";

  const handleDocumentClick = (doc, index) => {
    setActiveIndex(index);
    setActiveRecord(doc)
  };

  useEffect(() => {
    setFileTypeError("");
  }, [claimId])

  useEffect(() => {
    if (claimsDocs?.length) {
      const selectedDoc = claimsDocs[activeIndex]
      setActiveRecord(selectedDoc)
    }
  }, [claimsDocs])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (uploadedFiles > 0 && selectedFilesCount > 0) {
      if (uploadedFiles === selectedFilesCount && isFileUploaded) {
        const allFilesProcessed = Object.keys(uploadStatus).length === selectedFilesCount;
        const allFilesSuccessful = Object.values(uploadStatus).every(status => status === 'success');
        if (allFilesProcessed) {
          setFileList([]);
          setLoading(false);
          setTimeout(async function () {
            dispatch(getActivityLogs(claimId));
            dispatch(getClaimsDocumentsList(claimId));
            dispatch(getClaimDetailsById(claimId));
            setUploadStatus({})
            setIntervalIds({})
            setUploadProgress({})
            setUploadedFiles(0);
            setselectedFilesCount(0);
          }, 2500);
          dispatch(updateVerifyUpload(claimId, signal, (res) => {
            if (!(res instanceof Error)) {
              setIsFileUploaded(false)
              setFileList([])
              dispatch(
                showSuccessMessage(selectedFilesCount > 1 ? langs.messages.files_uploaded_successfully : langs.messages.file_uploaded_successfully),
              );
            }
          }))
        }
      }
    }
    return () => {
      controller.abort();
    }
  }, [uploadedFiles, selectedFilesCount, uploadStatus]);

  const handleS3Upload = async (file) => {
    try {
      setLoading(true);
      errorToastShownRef.current = false;
      if (!allowedFileTypes.includes(file.type)) {
        setLoading(false);
        setFileTypeError('Invalid file type. Only PDF files and images are allowed.');
        setFileList([]);
        setselectedFilesCount(0);
        return;
      } else {
        setFileTypeError("");
      }
      const fileNameToUpload = [file.name];
      let thumbnailName = file?.name?.split('.');
      thumbnailName = `${thumbnailName[0]}_thumbnail.${thumbnailName[thumbnailName.length - 1]}`;
      if (file.type !== "application/pdf") {
        compressImage(file);
      } else {
        const image = new Image();
        image.src = Pdf;
        const blob = await fetch(image.src).then((res) => res.blob());
        setThumbnailFile(blob);
      }
      const payload = {
        service: "admin-portal",
        module: "claim",
        payload: {
          claim_id: claimId
        },
        file_names: fileNameToUpload
      };
      dispatch(getFileUploadUrl(payload, (res) => {
        if (!(res instanceof Error)) {
          if (res?.data?.data?.presigned_url?.length) {
            res?.data?.data?.presigned_url?.forEach(data => {
              const presignedUrl = data.url;
              if (presignedUrl) {
                handleUploadToS3(presignedUrl, file, data.file_name, data.file_id);
              }
            });
          }
        }
      }));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUploadToS3 = async (uploadUrl, file, fileName, fileId) => {
    if (file) {
      try {
        setIsFileUploaded(true);
        const response = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            "Content-Type": file.type,
            "Content-Security-Policy":
              "connect-src 'self' https://zenda-payroll-non-prod.s3.us-east-2.amazonaws.com/",
          },
        });
        if (response.ok) {
          pollUploadStatus(fileId, fileName);
        } else {
          dispatch(showErrorMessage(langs.messages.file_upload_error));
        }
      } catch (error) {
        setLoading(false)
        console.error('File upload failed:', error);
      }
    }
  };

  const pollUploadStatus = (fileId, fileName) => {
    const intervalId = setInterval(() => {
      dispatch(getFileUploadStatus(fileId, (res) => {
        if (!(res instanceof Error)) {
          const { status = "", progress_percentage, message } = res?.data?.data?.data;
          setUploadProgress((prev) => ({
            ...prev,
            [fileName]: { status, progress_percentage },
          }));
          if (message?.length) {
            if (status !== "FILE_ACCEPTED") {
              dispatch(showErrorMessage(message));
            }
          }
          if (status === "ERRORED_OUT") {
            clearInterval(intervalId);
            setLoading(false);
            setFileList([])
          }
          if (status === "FILE_REJECTED") {
            clearInterval(intervalId);
            setLoading(false);
            setFileList([]);
            setUploadProgress({});
            setUploadStatus({});
            if (!errorToastShownRef.current) {
              dispatch(showErrorMessage(`${fileList?.length > 1 ? langs?.validation_messages?.file_reject_multiple: langs?.validation_messages?.file_reject}`));
              errorToastShownRef.current = true;
            }
          }
          if (progress_percentage === 100 || status === "FILE_ACCEPTED") {
            clearInterval(intervalId);
            setLoading(false)
            setUploadedFiles((prev) => prev + 1);
            setUploadStatus((prev) => ({
              ...prev,
              [fileName]: status === 'SUCCESS' ? 'success' : 'failed',
            }));
            setIntervalIds((prev) => {
              const { [fileName]: _, ...rest } = prev;
              return rest;
            });
          } else if (progress_percentage < 100) {
            setUploadStatus((prev) => ({
              ...prev,
              [fileName]: 'in-progress',
            }));
          }
        } else {
          clearInterval(intervalId);
          dispatch(showErrorMessage(langs.messages.file_upload_error));
          setUploadStatus((prev) => ({
            ...prev,
            [fileName]: 'failed',
          }));
          setIntervalIds((prev) => {
            const { [fileName]: _, ...rest } = prev;
            return rest;
          });
        }
      }));
    }, 2000);

    setIntervalIds((prev) => ({ ...prev, [fileName]: intervalId }));
  };


  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    const output = await imageCompression(file, options)
    setThumbnailFile(output)
  }

  const handleBeforeUpload = (file) => {
    if (!allowedFileTypes.includes(file.type)) {
      setFileTypeError('Invalid file type. Only PDF files and images are allowed.');
      setFileList([]);
      setselectedFilesCount(0);
      return false;
    } else {
      setFileTypeError('');
      return true;
    }
  }

  const handleUploadChange = (info) => {
    const validFileList = info.fileList.filter(file => {
      const isFileTypeValid = allowedFileTypes.includes(file.type);
      if (!isFileTypeValid) {
        setFileTypeError('Invalid file type. Only PDF files and images are allowed.');
      }
      return isFileTypeValid;
    });

    setFileList(validFileList);
    setselectedFilesCount(validFileList.length);
  };

  const customLoader = (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
      }
    >
    </Spin>
  )

  const customItemRender = () => {
    if (fileTypeError) return null;
    return (fileList && fileList.map(file => {
      const progress = uploadProgress[file.name] || 0;
      const percent = progress?.progress_percentage || 0
      const isLastItem = fileList.indexOf(file) === fileList.length - 1;
      return (
        <>
          <hr />
          <div style={{ padding: '10px 0' }} className="d-flex justify-space-between align-items-center">
            {file.name}
            {loading && <div style={{ display: 'flex', alignItems: 'center' }}>
              {percent < 100 ? customLoader : null}
              <Progress percent={progress?.progress_percentage} size="small" style={{ marginLeft: 8 }} />
            </div>}
          </div>
          {isLastItem && <hr />}
        </>
      );

    }))
  };

  const getMarginFromFileList = useMemo(() => {
    if (fileList?.length === 0) {
      return 40
    } else if (fileList?.length === 1) {
      return 100
    } else if (fileList?.length > 1) {
      return 120
    }
    return 40
  }, [fileList])

  return (
    <>
      <div className="claims-document-wrapper">
        <div className="claims-docs-card-wrapper">
          {
            claimsDocs.length > 0 && claimsDocs.map((doc, index) => {
              return (
                <Card id="test1" style={{ width: "9rem" }}
                  onClick={() => handleDocumentClick(doc, index)}
                >
                  <div className={`doc-wrapper ${activeIndex === index ? "active-doc" : ""}`}>
                    <div>
                      {
                        doc.file_type?.toLowerCase() === "pdf" ?
                          <img src={Pdf} alt="doc type" /> :
                          <PictureOutlined style={{ fontSize: "20px" }} />
                      }
                    </div>
                    <div>
                      <Tooltip
                        style={{ color: "#fff" }}
                        title={doc.file_name.length > 10 ? doc.file_name : ""}
                      >
                        <div
                          className="filename current-employer-name ant-menu-title-content">
                          {doc.file_name}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </Card>
              )
            })
          }

          {claimsDocs.length > 0 &&
            allow_add_documents &&
            (isStatusNeedsMoreInfo ||
              (isCardExpenseType && (isStatusPartiallyApproved || isStatusDenied))) && (
              <Card id="test" style={{ height: "60px", width: "9rem" }}>
                <Upload.Dragger
                  multiple
                  ref={uploadRef}
                  fileList={fileList}
                  customRequest={({ file }) => {
                    handleS3Upload(file);
                  }}
                  onDrop={(e) => handleBeforeUpload(e.dataTransfer.files)}
                  onChange={handleUploadChange}
                  disabled={loading}
                  name="avatar"
                  listType="picture-list"
                  className="document-uploader"
                  accept="image/jpeg, image/png, image/gif, application/pdf"
                  showUploadList={false}
                // itemRender={customItemRender}
                >
                  <div className="upload-btn">
                    {<PlusOutlined />}
                    <div>
                      Add
                    </div>
                  </div>
                </Upload.Dragger>
              </Card>
            )}
        </div>

        {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}


        {claimsDocs.length === 0 &&
          allow_add_documents
          && (isStatusNeedsMoreInfo || (isCardExpenseType && (isStatusPartiallyApproved || isStatusDenied))) &&
          <NoDocuments
            handleS3Upload={handleS3Upload}
            loading={loading}
            onChange={handleUploadChange}
            status={status}
            beforeUpload={handleBeforeUpload}
            uploadProgress={uploadProgress}
            fileList={fileList}
          />
        }

        <div className="mt-20">{customItemRender()}</div>

        {claimsDocs.length === 0 && !allow_add_documents && (isStatusNeedsMoreInfo || (isCardExpenseType && (isStatusPartiallyApproved || isStatusDenied))) && (
          <Empty className="no-claim-document-empty" />
        )}

        {claimsDocs.length === 0 && (!isStatusNeedsMoreInfo && !isStatusDenied && !isStatusPartiallyApproved) &&
          <Empty className="no-claim-document-empty" />
        }


      </div>

      {
        claimsDocs?.length > 0 &&
        <Card className="doc-preview-card" style={{ marginTop: 40 }}>
          {activeRecord?.file_type?.toLowerCase() === "pdf" ? (
            <PDFViewer pdfUrl={activeRecord?.presigned_url} />
          ) : (
            <div className="image-viewer">
              <ImageViewer imageUrl={activeRecord?.presigned_url} />
            </div>
          )}
        </Card>
      }

    </>
  );
};
