import { Alert, Spin, Form, Upload, Progress, Row, Col } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { UploadOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Typography } from "../../../../design-systems";
import Pdf from "../../../../assets/img/pdf.png"
import { required } from "../../../../utils/validations";
import { useSelector } from "react-redux";
import { deleteDraftExpenseDocument, updateSavedPresignedUrl } from "../../../../redux/actions/expenses.actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getFileUploadStatus, showErrorMessage } from "../../../../redux/actions";
import { langs } from "../../../../i18n";
import { useMemo } from "react";

export const UploadDocument = ({
    fileList,
    setFileList,
    form,
    setFileTypeError,
    fileTypeError,
    loading,
    setFileListCopy,
    fileListCopy,
    expense_draft_id,
    id,
    is_attachments_mandatory,
    setUploadedFiles,
    uploadProgress,
    setUploadProgress
}) => {
    const uploadRef = useRef();
    const dispatch = useDispatch();

    const [previewUrl, setPreviewUrl] = useState(null);
    const { presignedUrl } = useSelector((state) => state.expenses)
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    const handleBeforeUpload = (file) => {
        for (const key in file) {
            if (!allowedFileTypes.includes(file[key]?.type)) {
                setFileTypeError('Invalid file type. Only PDF files and images are allowed.');
                return false;
            } else {
                setFileTypeError('');
                return true;
            }
        }
    }

    const handleUploadChange = (info) => {
        const validFileList = info.fileList.filter(file => {
            const isFileTypeValid = allowedFileTypes.includes(file.type);
            if (!isFileTypeValid) {
                setFileTypeError('Invalid file type. Only PDF files and images are allowed.');
            } else {
                setFileTypeError("");
            }
            return isFileTypeValid;
        });

        const updatedFiles = validFileList.map((file, index) => {
            let { name } = file;
            const nameParts = name.split('.');
            let suffix = '';
            let occurrence = 1;

            // Check if the current file name exists in the fileList
            let existingFile = validFileList.find(existing => existing.name === name);

            // If the file name already exists, make it unique
            while (existingFile && existingFile !== file) {
                name = `${nameParts[0]}(${occurrence}).${nameParts[1]}`;
                occurrence++;
                suffix = `(${occurrence})`;
                // Check if the updated file name exists in the fileList
                existingFile = validFileList.find(existing => existing.name === name);
            }

            return { ...file, name };
        });

        const uploadedDocs = updatedFiles.filter(file => !havePreSignedUrl(file.name));
        setFileList(uploadedDocs);
        setFileListCopy(updatedFiles);
    };


    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            const preview = await readFile(file.originFileObj);
            file.preview = preview;
            setPreviewUrl(preview);
            return preview
        }
    }

    const expenseType = form.getFieldValue("expense_type")

    const havePreSignedUrl = (fileName) => {
        if (!presignedUrl.length) return false;
        const match = presignedUrl.find(url => url.file_name === fileName);
        return match !== undefined;
    }

    const files = Form.useWatch('files', form);

    useEffect(() => {
        if (files && !files?.fileList?.length && havePreSignedUrl(files?.file.name)) {
            form.setFieldValue(
                "files", undefined
            )
        }
    }, [files, presignedUrl])

    const customLoader = (
        <Spin
            indicator={
                <LoadingOutlined
                    style={{
                        fontSize: 24,
                    }}
                    spin
                />
            }
        >
        </Spin>
    )

    const getFileUploadStatus = useCallback((file) => {
        const fileData = uploadProgress[file?.name] || {};
        if (fileData && Object.keys(fileData)?.length > 0) {
            return fileData?.status
        }
        return ""
    }, [uploadProgress])

    const updateUploadProgressByFileName = (file) => {
        const progress = { ...uploadProgress }
        if (progress && Object.keys(progress)?.length && progress[file?.name]) {
            delete progress[file?.name]
            setUploadProgress(progress)
        }
    }
    const customItemRender = (originNode, file, fileList, action) => {
        const progress = uploadProgress[file.name] || {};
        const percent = progress?.progress_percentage || 0;
        return (
            <div className={`upload-document-item-wrapper ${(getFileUploadStatus(file) === "FILE_REJECTED" || getFileUploadStatus(file) === "ERRORED_OUT") ? "error-file-list" : ""}`}>
                <Row gutter={[0]} className="test">
                    <Col sm={4}>
                        {
                            file.type !== 'application/pdf' ?
                                <div style={{ fontSize: 40 }}><UploadOutlined /> </div> :
                                <img
                                    height={file.type !== 'application/pdf' && 80}
                                    src={file.type === 'application/pdf' ? Pdf : <UploadOutlined />}
                                    width={file.type === 'application/pdf' ? "50px" : "100px"}
                                />
                        }
                    </Col>
                    <Col sm={12}>
                        <span className="ml-10">{file.name}</span>
                    </Col>
                    <Col sm={"4"}>
                        {
                            loading && !(getFileUploadStatus(file) === "FILE_REJECTED" || getFileUploadStatus(file) === "ERRORED_OUT") &&
                            <div className="d-flex">
                                {percent < 100 ? customLoader : null}
                                <Progress percent={progress?.progress_percentage} size="small" style={{ marginLeft: 8 }} />
                            </div>
                        }
                    </Col>
                    <Col sm={4} >
                        {
                            !loading &&

                            <div className="delete-button-upload-docs mr-10" onClick={() => {
                                action.remove();
                                dispatch(updateSavedPresignedUrl(file.name))
                                updateUploadProgressByFileName(file);
                                if (!(getFileUploadStatus(file) === "FILE_REJECTED" || getFileUploadStatus(file) === "ERRORED_OUT") && !loading && havePreSignedUrl(file.name)) {
                                    dispatch(deleteDraftExpenseDocument(id, expense_draft_id, {
                                        delete_file_list: [file.name]
                                    }, (res) => {
                                        if (!(res instanceof Error)) {
                                            dispatch(updateSavedPresignedUrl(file.name))
                                            setUploadedFiles(0)
                                        }
                                    }))
                                }
                            }}>
                                <DeleteOutlined />
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div>
            <Typography
                label="Upload your expense documents"
                variant="subhead-2"
                className="employee-listing-title "
            />
            <Typography
                label="Attach proof documents to your expense as part of your submission."
                variant="subhead-4"
                className="employee-listing-title "
            />
            <Alert className="upload-doc-warning" message={`Documentation is required for ${expenseType}`} type="warning" showIcon closable={false} />
            <div className="section-gap"></div>
            <div>
                Please ensure your document(s) has the following:
                <ul className="doc-upload-list">
                    <li>Date of service</li>
                    <li>Provider or merchant name</li>
                    <li>Details of the service or product received and the amount charged.</li>
                </ul>
                Itemized receipts, bills or an explanation of benefits (EOB) document are all sufficient proof documents.
            </div>
            <div>
                <hr className="my-24" />
                <Typography
                    label="Documents"
                    variant="subhead-1"
                    className="employee-listing-title "
                />
                <hr className="my-24" />
                <div style={{ marginBottom: 20 }}>
                    <Form.Item
                        name="files"
                        rules={is_attachments_mandatory ? [required("Documents")] : []}
                    >
                        <Upload.Dragger
                            ref={uploadRef}
                            itemRender={customItemRender}
                            fileList={fileListCopy}
                            beforeUpload={handlePreview}
                            onDrop={(e) => handleBeforeUpload(e.dataTransfer.files)}
                            onChange={handleUploadChange}
                            disabled={loading}
                            name="avatar"
                            className="document-uploader"
                            accept="image/jpeg, image/png, image/gif, application/pdf"
                            listType="picture"
                            iconRender={(file) => {
                                if (file.type === 'application/pdf') {
                                    return <img src={Pdf} />;
                                }
                                return <UploadOutlined />;
                            }}
                        // onRemove={(file) => {
                        //     if (havePreSignedUrl(file.name)) {
                        //         dispatch(deleteDraftExpenseDocument(id, expense_draft_id, {
                        //             delete_file_list: [file.name]
                        //         }, (res) => {
                        //             if (!(res instanceof Error)) {
                        //                 dispatch(updateSavedPresignedUrl(file.name))
                        //                 setUploadedFiles(0)
                        //             }
                        //         }))
                        //     }
                        // }}
                        >
                            <div className="upload-btn">
                                <Button iconLeft={<UploadOutlined />} label="Upload" />
                            </div>
                        </Upload.Dragger>
                    </Form.Item>
                </div>
                {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
            </div>
        </div>
    )
}