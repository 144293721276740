import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Form } from 'antd';
import { Card, Button, Col, Input, List, Row, Tag } from "antd";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import {
  ActivityLog,
  Documents,
  Footer,
} from "../../../components/admin/claims";
import EmployeeDetails from "../../../components/admin/claims/View/EmployeeDetails";
import ExpenseDetails from "../../../components/admin/claims/View/ExpenseDetails";
import { Tabs } from "../../../design-systems";
import { useRouter } from "../../../utils/router";
import { useDispatch } from "react-redux";
import { getClaimDetailsById, resetClaimDetails, getClaimsDocumentsList, saveInternalNotes, getActivityLogs, getNextClaimId } from "../../../redux/actions/claims.actions";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter, claimStatus } from "../../../utils";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { getCurrentEmployer, setShowSecondSection, switchEmployerTypeData } from "../../../redux/actions";
import moment from "moment";
import StatusActionInfo from "../../../components/admin/claims/View/StatusActionInfo";
import TravelTransactionDetails from "../../../components/admin/claims/View/TravelTransactionDetails";


export const View = ({ page }) => {
  const [form] = Form.useForm();
  const router = useRouter()
  const dispatch = useDispatch()

  const [note, setNote] = useState("")
  const [claimsData, setClaimsData] = useState({})
  const [nextClaimId, setNextClaimId] = useState(null)

  const { claimsDetails, internalNotes } = useSelector((state) => state.claims)
  const currentEmployer = useSelector(
    (state) => state.employer.currentEmployer?.data
  );
  const apiData = JSON.parse(localStorage.getItem("claimListApiData")) || {};
  const updateApiData = (data) => {
    if (Object?.keys(data)?.length) {
      for (const key in data) {
        if (key === "page" || key === "limit" || !data[key]) {
          delete data[key]
        }
      }
    }
    return data
  }

  const currentClaimId = router?.match?.params?.id;
  const currentEmployeeId = router?.query?.eid ? decodeURIComponent(router?.query?.eid) : "";



  const getClaimsDetails = async () => {
    setNextClaimId(null);
    const data = updateApiData(apiData)
    const promises = [
      dispatch(getClaimsDocumentsList(currentClaimId)),
      dispatch(getClaimDetailsById(currentClaimId)),
      dispatch(getActivityLogs(currentClaimId)),
      dispatch(getNextClaimId({ ...data, claim_id: currentClaimId, employee_id: currentEmployeeId }, (res) => {
        if (!(res instanceof Error)) {
          setNextClaimId(res?.data?.data?.next_claim_id ?? null)
        }
       
      }))
    ]
    await Promise.all(promises)
  }

  const propValue = window.propValue;

  useEffect(() => {
    if (propValue) {
      dispatch(setShowSecondSection(false))
    }
  }, [propValue])

  useEffect(() => {
    if (!currentClaimId) {
      router.push("/admin/claims")
    }
    if (currentClaimId) {
      getClaimsDetails()
    }
    return () => {
      dispatch(resetClaimDetails())
    }
  }, [])

  useEffect(() => {
    getClaimsDetails()
  }, [currentClaimId])

  useEffect(() => {
    setClaimsData(claimsDetails)
    if (claimsDetails?.employer_id && (claimsDetails?.employer_id !== currentEmployer?.employer_id)) {
      dispatch(
        switchEmployerTypeData({ employer_id: claimsDetails?.employer_id }, (res) => {
          dispatch(getCurrentEmployer());
        }),
      );
    }
  }, [claimsDetails])

  const getEmployeeFullName = useMemo(() => {
    let fullName = `${claimsData?.first_name} ${claimsData.middle_name ?? ""} ${claimsData?.last_name}`
    return capitalizeFirstLetter(fullName)
  }, [claimsData])

  const onFinish = async (values) => {
    if (currentClaimId) {
      dispatch(saveInternalNotes({
        note: values?.note,
        claim_id: currentClaimId
      }))
      setNote("")
      form.resetFields();
    }
  };

  const getStatusTagClassName = useCallback((status) => {
    let className = ""
    switch (status) {
      case "NEEDS_MORE_INFO":
        className = "need_more_info";
        break;
      case "PARTIALLY_APPROVED":
        className = "approved_partial";
        break;
      case "APPROVED":
        className = "approved";
        break;
      case "DENIED":
        className = "denied"
        break;
      default:
        className = "awaiting_review";
        break;
    }
    return className
  }, [claimsData])

  return (
    <HeaderWithContent customFooter={<Footer page={page} nextClaimId={nextClaimId} />}>
      <Row gutter={0}>
        <Col span={24}>
          <div className="wrapper">
            <Card title="" bordered={false} className="claims-view-wrapper">
              <SpinLoader showContent={!!claimsDetails}>
                <EmployeeDetails
                  empName={getEmployeeFullName}
                  employee_id={claimsData?.employee_id}
                  claim_id={claimsData.claim_id}
                  employer_name={claimsData.employer_name}
                  last_updated_on={claimsData.last_updated_on}
                />
                <Row gutter={10}>
                  <Col span={7}>
                    <Card
                      title="Claim Details"
                      bordered={true}
                      className="data-grid claims-details"
                    >
                      <div className="claim-status">
                        <div className="claim-status-typo">Claim Status</div>
                        <Tag className={`status-claims ${getStatusTagClassName(claimsData.claim_status)}`}>{claimStatus[claimsData.claim_status]}</Tag> <br />
                      </div>
                      <StatusActionInfo
                        claimsData={claimsData}
                      />
                      <ExpenseDetails
                        claimsData={claimsData}
                      />
                    </Card>
                    {
                      claimsData?.associated_claim &&
                      <Card
                        title=""
                        bordered={true}
                        className="data-grid travel-card"
                      >
                        <TravelTransactionDetails
                          claimsData={claimsData}
                          page={page}
                          emp_id={currentEmployeeId}
                        />
                      </Card>
                    }
                    <Card
                      title=""
                      bordered={true}
                      className="data-grid internal-note-card"
                    >
                      <div className="internal-note-header">Internal note</div>
                      <Form
                        layout="vertical"
                        className="innerForm"
                        initialValues={{}}
                        form={form}
                        onFinish={onFinish}>
                        <div className="note-textarea-label">Add note</div>
                        <Form.Item
                          name="note"
                        >
                          <Input.TextArea
                            className="note-textarea"
                            rows={4}
                            onChange={(e) => {
                              setNote(e.target.value)
                            }}
                            maxLength={512}
                            placeholder="Enter descriptive text here"
                          />
                        </Form.Item>
                        <div className="button-right-layout">
                          <Button
                            disabled={note === ""}
                            className="add-note-button"
                            variant="secondary"
                            htmlType="submit"
                          >
                            Add Note
                          </Button>
                        </div>
                      </Form>
                      <List
                        itemLayout="vertical"
                        className="note-list-wrapper"
                        size="large"
                        dataSource={internalNotes}
                        renderItem={(item) => (
                          <List.Item key={item?.note}>
                            <div className="list-notes">
                              <span className="note-user">{capitalizeFirstLetter(item?.note_created_by)}</span>
                              <span className="note-date">{moment(item?.created_timestamp).format("MM/DD/YYYY")}</span>
                            </div>
                            {item.note}
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                  <Col span={17}>
                    <Card bordered={true} className="data-grid claims-details">
                      <Tabs
                        className="document-activity-log-tabs"
                        tabs={[
                          {
                            key: "1",
                            title: "Documents",
                            content: <Documents
                              claimId={currentClaimId}
                              status={claimsDetails?.claim_status}
                              expenseMode={claimsDetails?.expense_mode}
                              allow_add_documents={claimsDetails?.allow_add_documents}
                            />,
                          },
                          {
                            key: "2",
                            title: "Activity log",
                            content: <ActivityLog />,
                          },
                        ]}
                      />
                    </Card>
                  </Col>
                </Row>
              </SpinLoader>
            </Card>
          </div>
        </Col>
      </Row>
    </HeaderWithContent >
  );
};
