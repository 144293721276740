import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRouter } from "../../utils/router";
import { useDispatch, useSelector } from "react-redux";
import { validateOTP, otpValidation } from "../../utils/validations";
import { clearFieldErrors, stripLetters } from "../../utils";
import { loginByOTP } from "../../redux/actions";
import { langs } from "../../i18n";
import { useForm } from "antd/lib/form/Form";

import { Form, Input, Button } from "antd";
import { disableGlobalLoader } from "../../redux/actions";
import { ROLE_CONSTANT } from "../../utils/constants";

function VerifyOtp() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = useForm();

  const user = useSelector((state) => state.userDetails.user);

  useEffect(() => {
    dispatch(disableGlobalLoader());
  }, [dispatch]);

  const onFinish = (values) => {
    const { OTP } = values;

    if (validateOTP(OTP)) {
      dispatch(
        loginByOTP({ OTP, user }, (res) => {
          if (res instanceof Error) {
            const fieldsWidthOTPError = [
              { name: "OTP", errors: [langs.messages.invalid_otp] },
            ];

            form.setFields(fieldsWidthOTPError);
          } else {
            router.push({
              pathname: res.role === ROLE_CONSTANT.ZENDA_ADMIN ? "/admin/employers" : "/admin/dashboard",
            });
          }
        }),
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const renderCodeDeliveryDestination = () => {
    if (!user) return;

    const phone_number =
      user.cognitoUser.challengeParam?.CODE_DELIVERY_DESTINATION;

    return phone_number ? phone_number.slice(-10) : "";
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  return (
    <div className="auth-body-content resend-otp">
      <div className="forgot-form-container reset-form-container">
        <div className="form-container">
          <h1 className="text-center">Two-factor authentication</h1>
          <div className="form-body">
            <div className="text-muted mb-4">
              <small>
                We have sent a 6 digit code to the phone number{" "}
                {renderCodeDeliveryDestination()}.
              </small>
            </div>
            <Form
              name="login"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              layout="vertical"
              autoComplete="off"
              validateTrigger="onSubmit"
            >
              <Form.Item
                label="Verification Code"
                name="OTP"
                type="number"
                rules={[otpValidation()]}
                onChange={(e) =>
                  form.setFieldsValue({
                    OTP: stripLetters(e.target.value),
                  })
                }
              >
                <Input
                  placeholder="Enter the code"
                  prefix={<i className="ni ni-key-25" />}
                  maxLength={6}
                  autoFocus
                />
              </Form.Item>

              <Form.Item shouldUpdate className="btn-block text-center">
                {({ getFieldsValue }) => {
                  const { OTP } = getFieldsValue();
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button-primary"
                      disabled={!OTP || OTP.length !== 6}
                    >
                      Continue
                    </Button>
                  );
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="text-left text-muted back-to-login">
          <Link to="/auth/login">
            <small>Return to Log In</small>
          </Link>
        </div>
      </div>
    </div>
  );
}

export { VerifyOtp };
