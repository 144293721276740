import { Col, Form, Row } from "antd";
import { DatePicker } from "../../../../../design-systems";
import { mandatory } from "../../../../../utils/validations";
import moment from "moment";
import { useMemo } from "react";
import { handleKeyDown } from "../../../../../utils";
import DatePicker2 from "../../../../../design-systems/DatePicker2";

export const PlanYear = ({ form, formData, setFormData }) => {
  // keeping the code till we discuss the hsa logic with backend
  const plans = form.getFieldValue("plans") || []
  const isFsaIncluded = useMemo(() => {
    if (plans.length === 1 && plans.includes('HSA')) {
      form.setFieldsValue({ end_date: "" });
    }
    return (plans.includes("FSA") || plans.includes("DCFSA") || plans.includes("LFSA"))
  }, [plans])

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <Form.Item shouldUpdate className="should-update-form-item">
          {() => (
            <DatePicker2
              isClearable={true}
              id="start_date"
              selected={!formData["start_date"] ? formData["start_date"] : formData["start_date"] instanceof Date ? new Date(formData["start_date"]) : moment(formData["start_date"]).toDate()}
              name="start_date"
              label="Start Date"
              rules={[mandatory({ message: "Please choose valid start date" })]}
              placeholder="MM/DD/YYYY"
              format={['MM/dd/yyyy', 'M/d/yyyy']}
              filterDate={(date) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const elevenMonthsAgo = new Date();
                elevenMonthsAgo.setHours(0, 0, 0, 0);
                elevenMonthsAgo.setMonth(today.getMonth() - 11);
                return (date >= elevenMonthsAgo && date <= today || date >= today);
              }}
              readOnly={false}
              onKeyDown={handleKeyDown}
              onChange={(date) => {
                form.setFieldsValue({ 'start_date': date });
                if (form.getFieldValue("end_date1")) {
                  form.setFieldsValue({ end_date1: "" });
                  formData['end_date1'] = "";
                }
                setFormData(((prevData) => ({
                  ...prevData,
                  'start_date': date
                })))
              }}
            />
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item shouldUpdate className="should-update-form-item">
          {() => (
            <DatePicker2
              isClearable={true}
              id="end_date1"
              selected={!formData["end_date1"] ? formData["end_date1"] : formData["end_date1"] instanceof Date ? new Date(formData["end_date1"]) : moment(formData["end_date1"]).toDate()}
              name="end_date1"
              label="End Date"
              rules={[mandatory({ message: "Please choose valid end date" })]}
              placeholder="MM/DD/YYYY"
              format={['MM/dd/yyyy', 'M/d/yyyy']}
              filterDate={(currentDate) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const startDate = formData["start_date"];
                if (startDate) {
                  const startDateMoment = moment(startDate).isAfter(today) ? moment(startDate).add(1, 'M').add(1,"days") : moment().add(1, 'M');
                  return !startDateMoment.isAfter(moment(currentDate));
                } else {
                  const currentDateMoment = moment(currentDate);
                  return !currentDateMoment.add(1, 'M');
                }
              }}
              readOnly={false}
              onKeyDown={handleKeyDown}
              onChange={(date) => {
                form.setFieldsValue({ 'end_date1': date });
                setFormData(((prevData) => ({
                  ...prevData,
                  'end_date1': date
                })))
              }}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  )
};
