import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getEmployerTypeData,
  addEmployer,
  showSuccessMessage,
  showErrorMessage,
} from "../../../../redux/actions";
import {
  PAYROLL_FREQUENCY,
  PAYROLL_PROVIDER,
  EMPLOYER_STATUS,
} from "../../../../utils/constants";
import { langs } from "../../../../i18n";
import { Form, Button, Input, Row, Col } from "antd";

function AddEmployer() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    dispatch(getEmployerTypeData());

    const fieldVal = {
      payload: [
        {
          name: "Microsoft",
          tin_num: 1276578,
          address_1: "addr_1",
          address_2: "addr_2",
          city: "udp",
          state: "kar",
          postal_code: "555",
          country_code: 840,
          employer_status_id: 2,
          employer_contact_email_id: "contact@microsoft.com",
          employer_domain_name: "microsoft.com",
          payroll_frequency_id: 6,
          payroll_provider_id: 3,
          finch_has_access: 0,
          employer_start_date: "2021-02-16",
          automatic_payroll_link_established: 0,
          contribution_key_column: 1,
          division: [
            { division_name: "Zomato div-1" },
            { division_name: "Zomato div-2" },
          ],
        },
      ],
    };

    form.setFieldsValue({ empjson: JSON.stringify(fieldVal, undefined, 2) });

    // eslint-disable-next-line
  }, []);

  const onFinish = (values) => {
    try {
      dispatch(
        addEmployer(JSON.parse(values.empjson), (res) => {
          dispatch(showSuccessMessage(langs.messages.insert_employee_success));
        }),
      );
    } catch (error) {
      dispatch(showErrorMessage(langs.messages.add_employer_error));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const renderHintTable = (title, dataArray) => {
    return (
      <div>
        <span className="title">{title}</span>
        <table>
          <tr>
            <th>Id</th>
            <th>Value</th>
          </tr>
          {dataArray.map((ele) => (
            <tr key={ele.id}>
              <td>{ele.id}</td>
              <td>{ele.value}</td>
            </tr>
          ))}
        </table>
      </div>
    );
  };

  return (
    <div className="add-employer-block">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={(0, 30)}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name="empjson"
              label=""
              rules={[{ required: true, message: "Please add payload." }]}
            >
              <TextArea value={addEmployer} rows="15" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={(0, 30)}>
          <Col xs={24} sm={24} md={24} lg={24} className="text-right">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="button-primary"
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="hint-block"></div>
      <h2>Hint:</h2>
      <Row gutter={(0, 30)}>
        <Col xs={24} sm={24} md={6} lg={6}>
          {renderHintTable("payroll_frequency_id", PAYROLL_FREQUENCY)}
        </Col>
        <Col xs={24} sm={24} md={6} lg={6}>
          {renderHintTable("payroll_provider_id", PAYROLL_PROVIDER)}
        </Col>
        <Col xs={24} sm={24} md={6} lg={6}>
          {renderHintTable("employer_status_id", EMPLOYER_STATUS)}
        </Col>
      </Row>
    </div>
  );
}

export { AddEmployer };
