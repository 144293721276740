import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "../../utils/router";
import { Link } from "react-router-dom";
import { clearFieldErrors } from "../../utils";
import {
  validateFirstNameRules,
  validateLastNameRules,
  confirmPassword,
  validatePassword,
} from "../../utils/validations";
import { completeSetup, setNewPassword } from "../../redux/actions";
import { useForm } from "antd/lib/form/Form";
import { langs } from "../../i18n";

import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Form, Input, Button, Progress, Row, Col } from "antd";

function AccountSetupPage(props) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = useForm();

  const [validateState, setValidateState] = useState({
    upperCase: false,
    lowerCase: false,
    number: false,
    special: false,
    lenOK: false,
    noWhite: false,
    progressColor: "danger",
    score: 0,
  });

  const onFinish = async (values) => {
    const { firstName, lastName, confirm_password: newPassword } = values;
    const { email, password } = router.location.state;

    if (!email || !password) return;

    if (validateState.allOK) {
      dispatch(
        setNewPassword(
          { email, password, firstName, lastName, newPassword },
          (updatedUser) => {
            dispatch(
              completeSetup({ firstName, lastName, updatedUser }, () => {
                router.push("/admin/dashboard");
              }),
            );
          },
        ),
      );
    } else {
      const fieldsWidthPasswordFormatError = [
        {
          name: "password",
          errors: [langs.messages.password_requirements_are_not_met],
        },
      ];
      form.setFields(fieldsWidthPasswordFormatError);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const renderValidationIcon = (type) => {
    return validateState[type] ? <CheckOutlined /> : <CloseOutlined />;
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  return (
    <div className="auth-body-content reset-auth-body-content">
      <div className="forgot-form-container  reset-form-container">
        <div className="form-container">
          <h1 className="text-center">Account Setup</h1>
          <div className="form-body">
            <Form
              name="login"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              layout="vertical"
              autoComplete="off"
              validateTrigger="onSubmit"
            >
              <Row gutter={(0, 30)}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    normalize={(value) => value.trimStart()}
                    rules={[{ validator: validateFirstNameRules }]}
                  >
                    <Input
                      autoComplete="off"
                      prefix={<i className="ni ni-circle-08" />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    normalize={(value) => value.trimStart()}
                    rules={[{ validator: validateLastNameRules }]}
                  >
                    <Input
                      autoComplete="off"
                      prefix={<i className="ni ni-circle-08" />}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="New Password"
                name="password"
                onChange={(e) => {
                  setValidateState(validatePassword(e.target.value));
                }}
                normalize={(value) => value.trim()}
                className="reset-new-password"
              >
                <Input.Password
                  autoComplete="new-password"
                  prefix={<i className="ni ni-lock-circle-open" />}
                  iconRender={(visible) =>
                    visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                  }
                />
              </Form.Item>
              <Progress
                strokeColor={
                  validateState.progressColor === "danger"
                    ? "#f5365c"
                    : validateState.progressColor === "yellow"
                    ? "#ffd600"
                    : "#2dce89"
                }
                step={6}
                percent={validateState.score * 2 * 10}
              />
              <div className="password-check">
                <div className="password-check-detail">
                  <div>{renderValidationIcon("lenOK")} 8 ≤ Length ≤ 32</div>
                  <div>{renderValidationIcon("number")} Number</div>
                  <div>{renderValidationIcon("noWhite")} No Whitespaces</div>
                </div>
                <div className="password-check-detail">
                  <div>{renderValidationIcon("upperCase")} Upper Case</div>
                  <div>{renderValidationIcon("lowerCase")} Lower Case</div>
                  <div>{renderValidationIcon("special")} Symbol</div>
                </div>
              </div>

              <Form.Item
                label="Confirm New Password"
                name="confirm_password"
                rules={[
                  confirmPassword,
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        langs.validation_messages.newConfirmPassword,
                      );
                    },
                  }),
                ]}
                normalize={(value) => value.trim()}
              >
                <Input.Password
                  autoComplete="new-password"
                  prefix={<i className="ni ni-lock-circle-open" />}
                  iconRender={(visible) =>
                    visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                  }
                />
              </Form.Item>

              <Form.Item shouldUpdate className="btn-block text-center">
                {({ getFieldsValue }) => {
                  const { firstName, lastName, password, confirm_password } =
                    getFieldsValue();
                  const formIsComplete =
                    !!firstName &&
                    !!lastName &&
                    !!password &&
                    !!confirm_password;
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button-primary"
                      disabled={!formIsComplete}
                    >
                      Continue
                    </Button>
                  );
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="text-left text-muted back-to-login">
          <Link to="/login">
            <small>Go to login instead?</small>
          </Link>
        </div>
      </div>
    </div>
  );
}

export { AccountSetupPage };
