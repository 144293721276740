import React from "react";
import { AccountSetupPage } from "../../pages/auth/AccountSetup";
import { ForgotPasswordPage } from "../../pages/auth/ForgotPassword";
import { LoginPage } from "../../pages/auth/Login";
import { VerifyOtp } from "../../pages/auth/OtpVerification";
import { VerifyPhone } from "../../pages/auth/PhoneVerification";
import { ResetPasswordPage } from "../../pages/auth/ResetPassword";

export const unauthorizedRoutes = [
  {
    path: "/",
    component: () => <LoginPage />,
    exact: true,
  },
  {
    path: "/auth/login",
    component: () => <LoginPage />,
  },
  {
    path: "/auth/forgot-password",
    component: () => <ForgotPasswordPage />,
  },
  {
    path: "/auth/reset-password",
    component: () => <ResetPasswordPage />,
  },
  {
    path: "/auth/account-setup",
    component: () => <AccountSetupPage />,
  },
  {
    path: "/auth/verifyid",
    component: () => <VerifyOtp />,
  },
  {
    path: "/auth/verifyphone",
    component: () => <VerifyPhone />,
  },
];
