import React from 'react';
import { Button, Progress, Spin, Typography, Upload } from 'antd';
import { NoClaimIcon } from '../../../../design-systems';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

export const NoDocuments = ({ handleS3Upload, loading, onChange, beforeUpload, uploadProgress, fileList }) => {
    const props = {
        name: 'file',
        multiple: true,
        customRequest({ file }) {
            handleS3Upload(file)
        },
        onChange,
        className: "document-dragger",
        showUploadList: true,
        disabled: loading,
        showUploadList: false,
    };

    const customLoader = (
        <Spin
            indicator={
                <LoadingOutlined
                    style={{
                        fontSize: 24,
                    }}
                    spin
                />
            }
        >
        </Spin>
    )

    const customItemRender = (originNode, file) => {
        const progress = uploadProgress[file.name] || 0;
        const isLastItem = fileList.indexOf(file) === fileList.length - 1;
        return (
            <>
                <hr />
                <div style={{ padding: '10px 0' }} className="d-flex justify-space-between align-items-center">
                    {file.name}
                    {loading && <div style={{ display: 'flex', alignItems: 'center' }}>
                        {progress?.progress_percentage < 100 ? customLoader : null}
                        <Progress percent={progress?.progress_percentage} size="small" style={{ marginLeft: 8 }} />
                    </div>}
                </div>
                {isLastItem && <hr />}
            </>
        );
    };

    return (
        <div className='no-document-claim-wrapper'>
            <Dragger
                listType="picture"
                itemRender={customItemRender}
                accept="image/jpeg, image/png, image/gif, application/pdf"
                onDrop={(e) => beforeUpload(e.dataTransfer.files)}
                {...props}>
                {
                    !loading &&
                    <p className="ant-upload-drag-icon">
                        <NoClaimIcon />
                    </p>
                }
                <Typography className="no-claims-subhead" variant="subhead-2" label={`Drop file here or use "Add" button`} />
                <Button disabled={loading} className="plusIcon-nodata" icon={<PlusOutlined />}>Add</Button>
            </Dragger >
        </div>
    )
};