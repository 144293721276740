import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAccountHolderDetails, getCurrentEmployerDetailsByTab, getEmployeePlanData, getEmployeesData, getEmployerInfo, getUserStatuses, resetEmployeeData } from "../../../redux/actions";
import { useRouter } from "../../../utils/router/customRouterHook";
import { getClaimsStatus, getClaimsSummary, saveClaimListAPIValues, saveCurrentClaimId } from "../../../redux/actions/claims.actions";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";

import { Card, Col, Empty, Row, Tabs } from "antd";
import {
  EmployeeDetailHeader,
  EmployeeDetails as EmployeeDetailsTabContent,
} from "../../../components/admin/employees/EmployeeDetails/v2";
import { useSelector } from "react-redux";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { AccountHolder, KYCStatus } from "../../../components/admin/employees";
import { CLAIM_STATUS, ROLE_CONSTANT, STATUS_CODES } from "../../../utils/constants";
import { Button, Typography } from "../../../design-systems";
import { CreateExpense } from "./CreateExpense";
import useShowSecondSection from "../../../hooks/useShowSection";
import { getExpensesTypes, resetExpenseData } from "../../../redux/actions/expenses.actions";
import { useMemo } from "react";
import { planInformationEmpKeys } from "../../../utils";
import debounce from "lodash/debounce";
import { getEmployeeClaimListingColumns } from "../../../utils/claims";
import { Table } from "../../../design-systems";
import { NoClaims } from "../../../components/admin/claims/NoClaims";

export const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const record = router?.location?.state ?? {};
  const { dataLoading, loading } = useSelector((state) => state.loader);
  const { employeeDetails, employeePlans, currentWorkEmail } = useSelector((state) => state.employees);
  const role = useSelector((state) => state?.userDetails?.roleType);
  const [open, setOpen] = useState(false)
  const [details, setDetails] = useState(null);
  const [showData, setShowData] = useState(null)
  const [formHasChanged] = useState(false);
  const [employee, setEmployeeData] = useState(null);
  const [search, setSearch] = useState("")
  const id = router.match.params.id;
  const [showCreateExpense, setShowCreateExpense] = useState(false);

  const [plans, setPlans] = useState(null);

  const [data, setData] = useState([])
  const [selectedStatusValues, setSelectedStatusValues] = useState(Object.keys(CLAIM_STATUS));
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit] = useState(10);
  const [selectedTab, setSelectedTab] = useState()
  const [sortFields, setSortFields] = useState({
    sort: "claim_id",
    order: "desc"
  })

  const { claimsSummary } = useSelector((state) => state.claims)
  useEffect(() => {
    if (router?.history?.location?.state?.showClaimsTab) {
      setSelectedTab("3");
    } else {
      if (role && role === ROLE_CONSTANT.ZENDA_ADMIN) {
        setSelectedTab("1");
      } else {
        setSelectedTab("2");
      }
    }
    // if (router.query.id) {
    //   if(role && role === ROLE_CONSTANT.ZENDA_ADMIN) {
        // disabling api call for perf env
        // dispatch(getExpensesTypes(router.query.id, (data) => {
        //   if (data && data.isAxiosError) {
        //     setShowCreateExpense(false);
        //   } else if (data && data.data && data.data.length > 0) {
        //     setShowCreateExpense(true);
        //   }
        // }))
    //   }
    // }

  }, [router.location, router.history, router.query]);

  useEffect(() => {
    setEmployeeData(employeeDetails);
  }, [employeeDetails])

  useShowSecondSection()

  useEffect(() => {
    if(role && role === ROLE_CONSTANT.ZENDA_ADMIN) {
      dispatch(getClaimsStatus())
      dispatch(getUserStatuses(true));
    }
    dispatch(getCurrentEmployerDetailsByTab(planInformationEmpKeys?.PLAN_INFO, (res) => {
      const plans = res.data.current_plans[0]?.plan_information?.selected_plans || {};
      setPlans(plans)
    }))
    getEmployeeData();
    return () => {
      dispatch(resetEmployeeData())
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTotalCount(claimsSummary?.total_records ?? 0)
    setData(claimsSummary?.data ?? [])
  }, [claimsSummary])

  useEffect(() => {
    setCurrentPage(1)
  }, [search, sortFields, selectedStatusValues])

  const onCreateExpenseClick = () => {
    setOpen(true)
    dispatch(resetExpenseData())
  }

  const hasFsaAccounts = useMemo(() => employeePlans?.FSA?.length > 0, [employeePlans])

  const getEmployeeData = async (shouldFetchPlan = true) => {
    const promises = [
      dispatch(getEmployeesData(id, (res) => {
        if (res instanceof Error) {
          if (res?.response?.status === STATUS_CODES.NOT_FOUND) {
            setShowData(false)
          }
        } else {
          if (role && role === ROLE_CONSTANT.ZENDA_ADMIN) {
            if (res?.employee.workEmail) {
              dispatch(getAccountHolderDetails(res?.employee.workEmail?.value, (res) => {
                if (!(res instanceof Error)) {
                  setDetails(res)
                }
              }))
            }
          }
          setShowData(true)
        }
      })),
      dispatch(getEmployerInfo())
    ];
    if (shouldFetchPlan) {
      promises.push(
        dispatch(getEmployeePlanData(id, (res) => {
          if (!(res instanceof Error)) {
            setShowData(true)
          }
        })),

      )
    }
    await Promise.all(promises);
  };


  const getClaimsSummaryData = async () => {
    const payload = {
      search: search,
      page: currentPage,
      limit: limit,
      sort: sortFields.sort,
      order: sortFields.order,
      claim_status: Array.isArray(selectedStatusValues) ? selectedStatusValues?.join(',') : [selectedStatusValues]?.join(','),
      employee_id: id
    }
    localStorage.setItem("claimListApiData", JSON.stringify(payload))
    dispatch(saveClaimListAPIValues(payload))
    if(role && role === ROLE_CONSTANT.ZENDA_ADMIN) {
      await dispatch(getClaimsSummary(payload))
    }
  }

  useEffect(() => {
    if (!open) {
      getClaimsSummaryData();
    }
  }, [open]);

  const onFetchDataDebounced = debounce(getClaimsSummaryData, 0);
  useEffect(() => {
    onFetchDataDebounced();
    return () => {
      onFetchDataDebounced.cancel();
    };
  }, [currentPage, limit, sortFields, selectedStatusValues, selectedStatusValues?.length, search]);

  const handleTableChange = (pagination, filters, sorter, { _, action }) => {
    switch (action) {
      case 'sort': {
        if (!sorter?.column) {
          setSortFields({
            sort: "claim_status",
            order: "desc"
          });
          return;
        }
        setSortFields({
          sort: sorter.field,
          order: sorter.order === "ascend" ? "asc" : "desc"
        })
        break;
      }
      case 'filter': {
        setSelectedStatusValues(filters.claim_status)
        break;
      }
      default:
        break;
    }
  }

  const columns = useMemo(() => getEmployeeClaimListingColumns(selectedStatusValues), [selectedStatusValues]);


  return (
    <HeaderWithContent showWarning={formHasChanged}>
      {
        showCreateExpense ?
          <CreateExpense
            getEmployeeData={getEmployeeData}
            open={open}
            setOpen={setOpen}
          />
          : null
      }
      <Row gutter={0}>
        <Col span={24}>
          <Card
            title=""
            bordered={false}
            className="employee-details-wrapper data-grid"
          >
            <Typography
              label="Employee details"
              variant="subhead-1"
              className="employee-listing-title "
            />
            <EmployeeDetailHeader employee={employee} showData={showData} />
            <Tabs tabBarExtraContent={
              <div>
                {
                  showCreateExpense ?
                    <Button
                      className="create-expense-button"
                      variant="primary"
                      onclickCallback={onCreateExpenseClick}
                      label="Create expense"
                      htmlType="submit"
                      disabled={!showData}
                    /> :
                    null
                }
              </div>
            }
              defaultActiveKey="1"
              className="tabs-wrapper"
              activeKey={selectedTab}
              onChange={(activeKey) => {
                setSelectedTab(activeKey);
              }}
            >


              {
                role && role === ROLE_CONSTANT.ZENDA_ADMIN &&
                <Tabs.TabPane tab="Account holder" key="1" disabled={!showData}>
                  <AccountHolder
                    employee={employee}
                    emailID={employee?.workEmail?.value}
                    setDetails={setDetails}
                    details={details}
                  />
                </Tabs.TabPane>
              }
              <Tabs.TabPane tab="Employee details" key="2" className="tab-pane">
                {
                  (showData || (dataLoading.length || loading)) ?
                    <SpinLoader>
                      <>
                        <EmployeeDetailsTabContent planInfo={plans} record={record} getEmployeeData={getEmployeeData} employee={employee} />
                      </>
                    </SpinLoader>
                    : <Empty />
                }
              </Tabs.TabPane>
              {
                role && role === ROLE_CONSTANT.ZENDA_ADMIN && hasFsaAccounts &&
                <Tabs.TabPane tab="Claims" key="3" className="claims-wrapper">
                  <SpinLoader showContent={!!data}>
                    <Table
                      className="claims-list-table"
                      columns={columns}
                      dataSource={data}
                      onChange={handleTableChange}
                      rowClassName={"pointer"}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            router.push({
                              pathname: `/admin/employee-claims/${encodeURIComponent(record?.claim_id)}`,
                              search: `?eid=${id}`
                            })
                            dispatch(saveCurrentClaimId(record?.claim_id))
                          }
                        };
                      }}
                      pagination={{
                        onChange: (page) => setCurrentPage(page),
                        total: totalCount,
                        current: currentPage,
                        defaultCurrentPage: 1,
                        defaultPageSize: limit,
                      }}
                      scroll={data?.length ? { x: 1200 } : undefined}
                    />
                  </SpinLoader>
                  {
                    (dataLoading.length || loading || data.length) ? null : <NoClaims />
                  }
                </Tabs.TabPane>
              }

            </Tabs>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
};
