import { Col, Row, Layout } from "antd";
import { Button } from "../../../../design-systems";
import { ArrowLeft, ArrowRight } from "../../../../design-systems/Icons";

const { Footer: AntFooter } = Layout;

export const Footer = ({
  onCancel,
  onNext,
  onPrevious,
  isFirstStep,
  isLastStep,
  page = ""
}) => (
  <AntFooter className="custom-add-employer-footer">
    <Row gutter={0} align="middle" justify="space-between">
      <Col xs={24} sm={24} md={12} lg={12} className="text-left">
        <Button
          className="button-wrapper-child btn-cancel-footer"
          variant="ghost"
          onclickCallback={onCancel}
          label="Cancel"
        />
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        className="text-right next-previous-btns"
      >
        {!isFirstStep && (
          <Button
            className="button-wrapper-child"
            variant="secondary"
            onclickCallback={onPrevious}
            label="Previous"
            IconLeft={<ArrowLeft />}
          />
        )}
        <Button
          className="button-wrapper-child"
          variant="primary"
          onclickCallback={onNext}
          label={isLastStep ? page === "addEmployee" ? "Add employee" : "Finish" : "Next"}
          IconRight={isLastStep ? "" : ArrowRight}
        />
      </Col>
    </Row>
  </AntFooter>
);
