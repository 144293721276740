import { Col, Row } from "antd";
import { Select, TextField } from "../../../../../design-systems";
import { required, maxLength, validateZipCode, whiteSpace } from "../../../../../utils/validations";
import { STATE_NAMES } from "../../../../../utils/constants";

export const ResidentialAddress = () => {

  const validateAddress = (_, value) => {
    const regex = /^(?:(?:a\.?p\.?o\.?|p\.?o\.?|a\.?p\.?o\.?b\.?|p\.?o\.?b\.?|post\s*office|call\s*box|gpo\s*box)(?:\s*\d+\S*)?\b|\b(?![a-zA-Z]+\s*\d+\b)(?!\d+\b)(?:[a-zA-Z]+\s*\d+))(?!\s+\w)/i;
    const trimmedValue = value?.trim();
    
    if (trimmedValue?.length < 4) {
      return Promise.reject("Address should be minimum 4 characters.");
    }
    
    if (regex.test(trimmedValue)) {
      return Promise.reject('The address cannot be a P.O. box.');
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Row gutter={16}>
      <Col xs={24} lg={8}>&nbsp;</Col>
      <Col xs={24} lg={16}>
        <div className="should-update-col division-layout">
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <TextField
                label="Street address 1"
                placeholder="Enter street address 1"
                name="mailing_address_1"
                maxLength={40}
                rules={[
                  required("Street address 1"),
                  { validator: validateAddress },
                  whiteSpace({ message: "Street address 1 should not contain only spaces." })
                ]}
              />
            </Col>
            <Col xs={24} md={12}>
              <TextField
                label="Street address 2"
                placeholder="Enter street address 2"
                name="mailing_address_2"
                maxLength={30}
                rules={[ whiteSpace({ message: "Street address 2 should not contain only spaces." })]}
              />

            </Col>
            <Col xs={24} md={12}>
              <TextField
                label="City"
                placeholder="Enter city"
                name="city"
                onKeyPress={(e) => {
                  if (!/^[A-Za-z\s-]+$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                rules={[required("City"), whiteSpace({ message: "City should not contain only spaces." })]}
              />
            </Col>
            <Col xs={24} md={12}>
              <Select
                label="State"
                placeholder="Select state"
                name="state"
                options={STATE_NAMES.map(({ abbreviation }) => ({
                  label: abbreviation,
                  value: abbreviation,
                }))}
                rules={[required("State")]}
              />
            </Col>
            <Col xs={24} md={12}>
              <TextField
                label="Zip code"
                placeholder="Enter zip code"
                name="zip_code"
                maxLength={5}
                onKeyPress={(event) => {
                  if (!/[0-9-]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={(event) => {
                  const clipboardData = event.clipboardData || window.clipboardData;
                  const pastedText = clipboardData.getData('Text');
              
                  if (!/^\d+$/.test(pastedText)) {
                    event.preventDefault();
                  }
                }}
                rules={[required("Zip code"), whiteSpace({ message: "Zip code should not contain only spaces." }), validateZipCode()]}
              />
            </Col>

          </Row>
        </div>
      </Col>

    </Row>
  )
};
