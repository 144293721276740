import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  maxLength,
  minLength,
  required,
  minRangeLength,
} from "../../../../utils/validations";
import {
  getAccountNumberFieldFeedback,
  getRoutingNumberFieldFeedback,
} from "../../../../utils";
import {
  updateBankDetails,
  showSuccessMessage,
} from "../../../../redux/actions";
import {
  SafetyCertificateOutlined,
  CheckCircleOutlined,
  SettingOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Form, Input, Button, Select } from "antd";
import { WarningInfo } from "./WarningInfo";
import BankDataInput from "./BankDataInput";

function BankAccountForm({
  setFormHasChanged,
  getBankInfo,
  divisionList,
  bankAccountData,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;

  const [selectedDivision, setSelectedDivision] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [routingNumberVisible, setRoutingNumberVisible] = useState(false);
  const [accountNumberVisible, setAccountNumberVisible] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    setBankAccountData();

    // eslint-disable-next-line
  }, [divisionList, bankAccountData]);

  const setBankAccountData = () => {
    const divisionId = divisionList?.filter(
      (div) => div.division_name === bankAccountData?.division_name,
    )[0]?.division_id;

    setIsDefault(
      !!divisionList?.length &&
        divisionList[0].division_name === "default" &&
        divisionList.length === 1
        ? true
        : false,
    );

    setVerificationStatus(bankAccountData?.verification_status);

    const fieldVal = {
      account_number: bankAccountData?.account_number,
      division_name: bankAccountData?.division_name,
      name_on_account: bankAccountData?.name_on_account,
      routing_number: bankAccountData?.routing_number,
    };

    if (
      !fieldVal.account_number &&
      !fieldVal.name_on_account &&
      !fieldVal.routing_number
    ) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }

    setSelectedDivision(divisionId);
    setInitialValues(fieldVal);
    form.setFieldsValue(fieldVal);
  };

  const onFinish = (values) => {
    const reqData = {
      account_number: values.account_number,
      routing_number: values.routing_number,
      name_on_account: values.name_on_account,
      division_id: selectedDivision,
    };

    dispatch(
      updateBankDetails(reqData, (res) => {
        if (!res.error) {
          setIsEditable(false);
          setFormHasChanged(false);
          getBankInfo(selectedDivision);
          dispatch(showSuccessMessage(res.data.message));
        } else {
          getBankInfo(selectedDivision);
        }
      }),
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = (id) => {
    setIsModalVisible(true);
    setSelectedId(id);
  };

  const onValuesChange = (value, allValues) => {
    const isEdited =
      allValues.account_number !== initialValues.account_number ||
      allValues.routing_number !== initialValues.routing_number ||
      allValues.name_on_account !== initialValues.name_on_account;

    setFormHasChanged(isEdited);
  };

  const handleChange = (id) => {
    if (isEditable) {
      form.setFieldsValue({ division_name: selectedDivision });
      showModal(id);
    } else {
      getBankInfo(id);
    }
  };

  const renderOptions = () => {
    return divisionList?.map((el) => (
      <Option key={el.division_id} value={el.division_id}>
        {el.division_name}
      </Option>
    ));
  };

  const renderIcon = () => {
    if (verificationStatus === null) return;

    if (verificationStatus === "processing") {
      return (
        <span className="processing">
          <SettingOutlined /> Processing
        </span>
      );
    } else if (verificationStatus === "ready") {
      return (
        <span className="ready">
          <CheckCircleOutlined /> Ready
        </span>
      );
    } else if (verificationStatus === "verified") {
      return (
        <span className="verified">
          <SafetyCertificateOutlined /> Verified
        </span>
      );
    }
  };

  const toggleRoutingNumberVisible = () => {
    setRoutingNumberVisible(!routingNumberVisible);
  };

  const toggleAccountNumberVisible = () => {
    setAccountNumberVisible(!accountNumberVisible);
  };

  return (
    <Form
      form={form}
      name="bankaccount"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      layout="vertical"
      autoComplete="off"
      className="bankaccount"
    >
      <div className="heading-block">
        <h2>
          Bank Account Info
          {renderIcon()}
        </h2>
        <div className="subtitle mb-15">
          Provide the routing and account numbers InComm Benefits users to initiate an ACH
          transfer
        </div>
      </div>
      {!isDefault ? (
        <Form.Item
          label="Division"
          name="division_name"
          rules={[required("Division")]}
        >
          <Select onSelect={handleChange}>{renderOptions()}</Select>
        </Form.Item>
      ) : null}
      <div className="banking-input">
        <Form.Item
          label="Your Bank Routing Number"
          name="routing_number"
          className="bnk-num"
          rules={[required("Bank routing number"), minLength(9), maxLength(9)]}
          normalize={(value) => value.trim()}
          validateStatus={
            isEditable && getRoutingNumberFieldFeedback(form.getFieldsValue())
          }
          hasFeedback
        >
          <BankDataInput
            valueVisible={routingNumberVisible}
            maxLength={9}
            disabled={!isEditable}
          />
        </Form.Item>
        <div
          onClick={toggleRoutingNumberVisible}
          className="banking-input-eye-icon"
        >
          {routingNumberVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      </div>
      <div className="banking-input">
        <Form.Item
          label="Your Bank Account Number"
          name="account_number"
          className="bnk-num"
          rules={[required("Bank account number"), minRangeLength(8, 12)]}
          normalize={(value) => value.trim()}
          validateStatus={
            isEditable && getAccountNumberFieldFeedback(form.getFieldsValue())
          }
          hasFeedback
        >
          <BankDataInput
            valueVisible={accountNumberVisible}
            maxLength={12}
            disabled={!isEditable}
          />
        </Form.Item>
        <div
          onClick={toggleAccountNumberVisible}
          className="banking-input-eye-icon"
        >
          {accountNumberVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </div>
      </div>
      <Form.Item label="Name on Account" name="name_on_account">
        <Input maxLength={40} disabled={!isEditable} />
      </Form.Item>
      {verificationStatus && verificationStatus !== "processing" ? (
        <div className="text-center">
          {isEditable ? (
            <div className="w-100">
              <Button
                type="primary"
                htmlType="button"
                className="button-secondary mr-10"
                onClick={() => {
                  if (divisionList?.length) {
                    setIsEditable(false);
                    getBankInfo(divisionList[0].division_id);
                  }
                }}
              >
                Discard Changes
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="button-primary"
              >
                Save and Validate
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              htmlType="button"
              className="button-primary"
              onClick={() => {
                setIsEditable(true);
              }}
              disabled
            >
              Change Account Info
            </Button>
          )}
        </div>
      ) : null}

      <WarningInfo
        isModalVisible={isModalVisible}
        handleCancel={() => {
          setIsModalVisible(false);
        }}
        handleOk={() => {
          getBankInfo(selectedId);
          setIsModalVisible(false);
        }}
      />
    </Form>
  );
}

export { BankAccountForm };
