import React from "react";
import { Card } from "antd";
import { Table } from "../../../../design-systems";
import { getClaimsActivityColumns } from "../../../../utils";
import { useSelector } from "react-redux";

export const ActivityLog = () => {
  const { activityLogs } = useSelector(state => state.claims)

  return <>
    <Card
      bordered
      className="emp-detail-card plan-information-wrapper"
    >
      <Table
        className={"activity-logs-table"}
        columns={getClaimsActivityColumns()}
        dataSource={activityLogs}
        pagination={false}
      />
    </Card>
  </>;
};
