import { useState } from "react";
import { Dropdown, Input, Menu } from "antd";
import {
  Typography,
} from "../../../design-systems";
import { Checkbox } from "antd"
import { CLAIM_STATUS } from "../../../utils/constants";
import { DownOutlined } from "@ant-design/icons";
import { ChevronDown } from "../../../design-systems";
import { SearchInput } from "../../../pages/admin/employee/SearchInput";

export const ClaimsTitle = ({ search, setSearch, setSelectedValues, selectedValues, claimsStatus, getSelectStatusValues }) => {
  const [claimStatusDropdown, setClaimStatusDropdown] = useState(false);

  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((v) => v !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };
  const menu = (
    <Menu className="claim-status-menu">
      {claimsStatus.length > 0 ? claimsStatus.map((value, index) => (
        <Menu.Item key={index} className="claim-status-menu-item">
          <Checkbox
            className="checkbox-wrapper"
            onChange={() => handleCheckboxChange(value)}
            checked={selectedValues.includes(value)}
          >
            {CLAIM_STATUS[value]}
          </Checkbox>
        </Menu.Item>
      )) : (
        <Menu.Item className="claim-status-menu-item">
          No records available.
        </Menu.Item>
      )}
    </Menu>
  );

  const handleSearchChange = (searchValue) => {
    setSearch(searchValue?.trim());
    setSelectedValues(searchValue.trim() ? [] : ["AWAITING_REVIEW"]);
  };

  return (
    <div className="claims-title-wrapper">
      <div className="search-wrapper">
        <Typography variant="subhead-1" label="Claims" className="label" />
        <SearchInput
          placeholder={"Search by employee, email or claim number"}
          onChange={(e) => {
            if (!e.target.value) {
              setSearch("")
              setSelectedValues(["AWAITING_REVIEW"]);
            }
          }}
          onSearch={(e) => handleSearchChange(e)}
        />
      </div>
      <Dropdown
        overlay={menu}
        prefix={<ChevronDown />}
        trigger={["click"]}
        menu={menu}
        placement="bottomRight"
        visible={claimStatusDropdown}
        onVisibleChange={setClaimStatusDropdown}
      >
        <div className="claim-status-dropdown">
          Claim status
          <DownOutlined style={{ fontSize: "16px", fontWeight: "bold" }} />
        </div>
      </Dropdown>
    </div>
  );
};
