export const Close = ({ width = 22, height = 22, onClick }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.1479 4.85247C17.5058 5.21045 17.5058 5.79085 17.1479 6.14883L6.14786 17.1488C5.78988 17.5068 5.20947 17.5068 4.85149 17.1488C4.49351 16.7909 4.49351 16.2105 4.85149 15.8525L15.8515 4.85247C16.2095 4.49449 16.7899 4.49449 17.1479 4.85247Z"
      fill="#989FAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.85149 4.85247C5.20947 4.49449 5.78988 4.49449 6.14786 4.85247L17.1479 15.8525C17.5058 16.2105 17.5058 16.7909 17.1479 17.1488C16.7899 17.5068 16.2095 17.5068 15.8515 17.1488L4.85149 6.14883C4.49351 5.79085 4.49351 5.21045 4.85149 4.85247Z"
      fill="#989FAE"
    />
  </svg>
);
