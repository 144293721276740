export function getRoutingNumberFieldFeedback(fieldsValue) {
  if (!fieldsValue.routing_number || fieldsValue.routing_number.length < 9) {
    return "error";
  }
  return "success";
}

export function getAccountNumberFieldFeedback(fieldsValue) {
  if (!fieldsValue.account_number) {
    return "error";
  }
  if (fieldsValue.account_number.length < 8) {
    return "error";
  }
  if (fieldsValue.account_number.length > 12) {
    return "error";
  }
  return "success";
}
