export const EditIcon = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4503_8793)">
      <path
        d="M11.3333 1.99955C11.5084 1.82445 11.7163 1.68556 11.9451 1.5908C12.1738 1.49604 12.419 1.44727 12.6666 1.44727C12.9143 1.44727 13.1595 1.49604 13.3882 1.5908C13.617 1.68556 13.8249 1.82445 14 1.99955C14.1751 2.17465 14.314 2.38252 14.4087 2.61129C14.5035 2.84006 14.5523 3.08526 14.5523 3.33288C14.5523 3.58051 14.5035 3.8257 14.4087 4.05448C14.314 4.28325 14.1751 4.49112 14 4.66622L4.99998 13.6662L1.33331 14.6662L2.33331 10.9995L11.3333 1.99955Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4503_8793">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
EditIcon.defaultProps = {
  color: "#989FAE",
};
