import { toast } from "react-toastify";
import { logout } from "../redux/actions";
import { langs } from "../i18n";
import { STATUS_CODES } from "./constants";

export const ApiErrors = (error, dispatch) => {
  if (error.response && error.response.status === STATUS_CODES.UNAUTHORIZED) {
    toast.error(langs.messages.session_expire_error);
    return dispatch(logout());
  } else if (
    error.response &&
    error.response.status === STATUS_CODES.SESSION_EXPIRED
  ) {
    toast.error(langs.messages.session_expire_error);
    return dispatch(logout());
  } else if (
    error.response &&
    error.response.status === STATUS_CODES.INTERNAL_SERVER_ERROR
  ) {
    if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message !== undefined
    ) {
      return toast.error(error.response.data.error.message);
    }

    toast.error(langs.messages.default_error);
    return dispatch(logout());
  } else if (
    error.response &&
    error.response.status === STATUS_CODES.UNKNOWN_IMPLEMENTED
  ) {
    if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message !== undefined
    ) {
      return toast.error(error.response.data.error.message);
    }
    return toast.error(langs.messages.default_error);
  } else if (
    error.response &&
    error.response.status === STATUS_CODES.BAD_REQUEST
  ) {
    if (
      error.response.data &&
      error.response.data.error &&
      Array.isArray(error.response.data.error.message) &&
      error.response.data.error.message.length
    ) {
      return toast.error(error.response.data.error.message[0].email_validation);
    } else if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message !== undefined
    ) {
      return toast.error(error.response.data.error.message);
    } else if (error.response.data && error.response.data.message) {
      return toast.error(error.response.data.message);
    }
  } else if (
    error.response &&
    error.response.status === STATUS_CODES.FORBIDDEN
  ) {
    if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message !== undefined
    ) {
      return toast.error(error.response.data.error.message);
    }
  } else if (
    error.response &&
    error.response.status === STATUS_CODES.UNPROCESSABLE_ENTITY
  ) {
    if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message !== undefined
    ) {
      return toast.error(error.response.data.error.message);
    } else {
      return toast.error(langs.messages.error_in_input_data)
    }
  } else if (
    error.response &&
    error.response.status === STATUS_CODES.NOT_FOUND
  ) {
    if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message !== undefined
    ) {
      return toast.error(error.response.data.error.message);
    }
  }

  if (error.code === "NotAuthorizedException") {
    toast.error(error.message);
    return dispatch(logout());
  } else if (error.code === "NetworkError") {
    return toast.error(error.message);
  } else if (error.code !== "") {
    return toast.error(error.message);
  } else {
    return toast.error(langs.messages.default_error);
  }
};
