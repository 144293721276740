import React, { useEffect, useState } from "react";
import { useRouter } from "../../utils/router";
import { useDispatch, useSelector } from "react-redux";
import { clearFieldErrors, stripLetters } from "../../utils";
import { otpValidation, validatePhone } from "../../utils/validations";
import {
  disableGlobalLoader,
  setPhoneNumber,
  loginWithPhoneNumberVerification,
  loginWithoutOTP,
} from "../../redux/actions";
import { langs } from "../../i18n";
import { useForm } from "antd/lib/form/Form";

import { Auth as CAuth } from "aws-amplify";

import { Form, Input, Button, Select, Row, Col } from "antd";
import { GlobalOutlined, PhoneOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { ROLE_CONSTANT } from "../../utils/constants";

function VerifyPhone() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = useForm();
  const { Option } = Select;

  const { roleType } = useSelector((state) => state.userDetails)
  console.log({ roleType });

  const [phoneVerification, setPhoneVerification] = useState(false);

  useEffect(() => {
    dispatch(disableGlobalLoader());
  }, [dispatch]);

  const onFinish = async (values) => {
    const { countryname, phonenumber, OTP } = values;

    const user = await CAuth.currentAuthenticatedUser();
    if (!phoneVerification) {
      const formattedPhone = (countryname ? countryname : "+1") + phonenumber;

      dispatch(
        setPhoneNumber({ user, formattedPhone }, (res) => {
          if (res instanceof Error) {
            console.log("setPhoneNumber error", res);
            toast.error(res.message || langs.messages.default_error);
          } else {
            setPhoneVerification(true);
          }
        }),
      );
    } else {
      dispatch(
        loginWithPhoneNumberVerification({ user, OTP }, (res) => {
          if (res instanceof Error) {
            const fieldsWidthAuthError = [
              { name: "OTP", errors: [langs.messages.invalid_otp] },
            ];

            form.setFields(fieldsWidthAuthError);
          } else {
            // router.push("/admin/dashboard");
          }
        }),
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  const skipPhoneRegistration = async () => {
    const user = await CAuth.currentAuthenticatedUser();
    const loggedInUser = user;
    dispatch(
      loginWithoutOTP(loggedInUser, (res) => {
        if (res.role === ROLE_CONSTANT.ZENDA_ADMIN)
          router.push({
            pathname: res.role === ROLE_CONSTANT.ZENDA_ADMIN ? "/admin/employers" : "/admin/dashboard",
          });
      }),
    );
  };

  return (
    <div className="auth-body-content resend-otp">
      <div className="forgot-form-container  reset-form-container">
        <div className="form-container">
          <h1 className="text-center">Enable Two-factor authentication</h1>
          <div className="form-body">
            <div className="text-muted mb-4">
              <medium></medium>
              <small>
                Register a phone number to enable Two-factor authentication for
                an extra layer of security.
              </small>
            </div>
            <Form
              name="login"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              layout="vertical"
              autoComplete="off"
              validateTrigger="onSubmit"
            >
              <Row gutter={(0, 30)}>
                <Col xs={24} sm={24} md={10} lg={10}>
                  <div className="custom-select-icon acc-set-country">
                    <div className="globe-icon">
                      <GlobalOutlined />
                    </div>
                    <Form.Item
                      label="Country"
                      name="countryname"
                      className="custom-leftpad-space"
                    >
                      <Select
                        defaultValue="+1"
                        defaultActiveFirstOption
                        onChange={() => {
                          setPhoneVerification(false);
                          form.setFieldsValue({
                            OTP: "",
                          });
                        }}
                      >
                        <Option value="+1">US (+1)</Option>
                        <Option value="+91">IND (+91)</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14}>
                  <div className="custom-select-icon acc-set-phnum">
                    <div className="phone-icon">
                      <PhoneOutlined />
                    </div>
                    <Form.Item
                      label="Phone Number"
                      name="phonenumber"
                      rules={[validatePhone()]}
                      onChange={(e) => {
                        setPhoneVerification(false);
                        form.setFieldsValue({
                          phonenumber: stripLetters(e.target.value),
                          OTP: "",
                        });
                      }}
                    >
                      <Input autoFocus prefix={<i className="" />} maxLength={10} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              {phoneVerification && (
                <Form.Item
                  label="Verification Code"
                  name="OTP"
                  rules={[otpValidation()]}
                  onChange={(e) =>
                    form.setFieldsValue({
                      OTP: stripLetters(e.target.value),
                    })
                  }
                >
                  <Input
                    placeholder="Enter the code"
                    prefix={<i className="ni ni-key-25" />}
                    maxLength={6}
                    autoFocus
                  />
                </Form.Item>
              )}

              <Form.Item shouldUpdate className="btn-block text-center">
                {({ getFieldsValue }) => {
                  const { phonenumber, OTP } = getFieldsValue();
                  return (
                    <div>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="button-primary"
                        disabled={
                          phoneVerification
                            ? !OTP || OTP.length !== 6
                            : !phonenumber
                        }
                      >
                        {phoneVerification ? "Verify" : "Send OTP"}
                      </Button>
                      &nbsp;
                      <Button
                        type="primary"
                        className="button-primary"
                        onClick={skipPhoneRegistration}
                      >
                        Remind Me Later
                      </Button>
                    </div>
                  );
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { VerifyPhone };
