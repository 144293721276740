export const NoClaimIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="178" height="170" viewBox="0 0 178 170" fill="none">
  <path d="M87.4321 156.523C123.941 156.523 153.538 126.854 153.538 90.2555C153.538 53.6571 123.941 23.9883 87.4321 23.9883C50.9228 23.9883 21.3262 53.6571 21.3262 90.2555C21.3262 126.854 50.9228 156.523 87.4321 156.523Z" fill="#EAEEF9"/>
  <path d="M38.7501 47.3242H132.788C136.512 47.3242 139.439 50.2576 139.439 53.9909V113.591C139.439 117.325 136.512 120.258 132.788 120.258H38.7501C35.0258 120.258 32.0996 117.325 32.0996 113.591V53.9909C32.0996 50.2576 35.1588 47.3242 38.7501 47.3242Z" fill="#DAE2EB"/>
  <path d="M164.269 73.7656C166.33 71.8779 166.474 68.673 164.591 66.6074C162.708 64.5417 159.511 64.3974 157.45 66.2851C155.389 68.1728 155.245 71.3777 157.128 73.4434C159.012 75.509 162.209 75.6533 164.269 73.7656Z" fill="#EAEEF9"/>
  <path d="M49.2317 30.6222C50.9781 30.0532 51.9338 28.1726 51.3661 26.4218C50.7985 24.6711 48.9225 23.7131 47.176 24.2822C45.4295 24.8512 44.4738 26.7318 45.0415 28.4825C45.6092 30.2333 47.4852 31.1913 49.2317 30.6222Z" fill="#EAEEF9"/>
  <g filter="url(#filter0_d_5232_107256)">
    <path d="M55.7729 19.8498C57.0217 19.8498 58.0341 18.8349 58.0341 17.5831C58.0341 16.3312 57.0217 15.3164 55.7729 15.3164C54.5241 15.3164 53.5117 16.3312 53.5117 17.5831C53.5117 18.8349 54.5241 19.8498 55.7729 19.8498Z" fill="url(#paint0_linear_5232_107256)"/>
  </g>
  <g filter="url(#filter1_d_5232_107256)">
    <path d="M163.249 51.5909C164.425 51.5909 165.377 50.6358 165.377 49.4576C165.377 48.2794 164.425 47.3242 163.249 47.3242C162.074 47.3242 161.121 48.2794 161.121 49.4576C161.121 50.6358 162.074 51.5909 163.249 51.5909Z" fill="url(#paint1_linear_5232_107256)"/>
  </g>
  <path d="M111.054 19.965C111.651 19.4186 111.692 18.4909 111.147 17.8929C110.602 17.295 109.677 17.2532 109.08 17.7996C108.484 18.3461 108.442 19.2738 108.987 19.8718C109.532 20.4697 110.458 20.5115 111.054 19.965Z" fill="#EAEEF9"/>
  <path d="M141.318 85.5645V114.898C141.318 118.631 138.259 121.832 134.402 121.832H37.7036C33.9793 121.832 30.7871 118.765 30.7871 114.898V83.0312" stroke="#1676EE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <g filter="url(#filter2_d_5232_107256)">
    <path d="M132.974 69.1328H102.563C99.6031 69.1328 96.7458 70.1539 94.5007 71.9913L86.5407 78.5243C84.2956 80.3618 81.4383 81.3824 78.4789 81.3824H43.8837C40.414 81.3824 37.5566 84.2408 37.5566 87.7115C37.5566 88.0177 37.5567 88.3239 37.6588 88.6301L43.9859 122.316C44.4961 125.481 47.1494 127.829 50.3129 127.829H123.993C127.157 127.829 129.81 125.583 130.32 122.419L139.199 76.3806C139.811 72.9099 137.464 69.7453 133.994 69.1328C133.688 69.1328 133.28 69.1328 132.974 69.1328Z" fill="white"/>
  </g>
  <g filter="url(#filter3_d_5232_107256)">
    <path d="M146.771 60.0938H107.134C103.276 60.0938 99.5521 61.4275 96.6259 63.8275L86.251 72.3608C83.3248 74.7608 79.6007 76.0939 75.7434 76.0939H30.6528C26.1305 76.0939 22.4062 79.8275 22.4062 84.3609C22.4062 84.7609 22.4064 85.1608 22.5394 85.5608L30.7859 129.561C31.451 133.695 34.9092 136.761 39.0325 136.761H135.066C139.189 136.761 142.647 133.828 143.312 129.695L154.884 69.5606C155.682 65.0273 152.623 60.8938 148.101 60.0938C147.702 60.0938 147.17 60.0938 146.771 60.0938Z" fill="white"/>
  </g>
  <path d="M70.0219 105.69C72.3726 105.69 74.2781 103.779 74.2781 101.423C74.2781 99.0661 72.3726 97.1562 70.0219 97.1562C67.6712 97.1562 65.7656 99.0661 65.7656 101.423C65.7656 103.779 67.6712 105.69 70.0219 105.69Z" fill="#1C3754"/>
  <path d="M103.543 105.557C105.894 105.557 107.8 103.647 107.8 101.29C107.8 98.934 105.894 97.0234 103.543 97.0234C101.193 97.0234 99.2871 98.934 99.2871 101.29C99.2871 103.647 101.193 105.557 103.543 105.557Z" fill="#1C3754"/>
  <g filter="url(#filter4_d_5232_107256)">
    <path d="M153.177 133.707C152.645 135.974 151.847 138.241 150.916 140.241C148.389 145.041 144.532 148.908 139.743 151.308C134.822 153.841 128.97 154.774 122.984 153.574C109.151 150.641 100.239 137.174 103.166 123.307C106.092 109.441 119.526 100.507 133.359 103.44C138.28 104.507 142.67 106.907 146.261 110.374C152.379 116.507 154.907 125.307 153.177 133.707Z" fill="url(#paint2_linear_5232_107256)"/>
  </g>
  <path d="M150.898 140.229C148.371 145.029 144.514 148.896 139.725 151.296L118.178 129.695L122.168 128.095L129.484 127.695H138.395L150.898 140.229Z" fill="url(#paint3_linear_5232_107256)"/>
  <path d="M136.268 126.231H130.415V120.365C130.415 119.165 129.484 118.098 128.154 118.098C126.957 118.098 125.893 119.031 125.893 120.365V126.231H120.04C118.843 126.231 117.779 127.165 117.779 128.498C117.779 129.831 118.71 130.764 120.04 130.764H125.893V136.632C125.893 137.832 126.824 138.898 128.154 138.898C129.351 138.898 130.415 137.965 130.415 136.632V130.764H136.268C137.465 130.764 138.529 129.831 138.529 128.498C138.529 127.165 137.465 126.231 136.268 126.231Z" fill="url(#paint4_linear_5232_107256)"/>
  <path d="M81.5762 74.7908C76.9209 65.7241 75.8568 54.6573 78.916 44.7906C81.9753 34.9238 89.0248 26.3904 97.9365 21.5904C100.73 20.1237 103.789 18.9237 106.981 18.7903C110.173 18.657 113.499 19.7237 115.627 22.257C117.755 24.6571 118.154 28.5238 116.425 31.1904C114.563 33.7238 110.971 34.6571 107.912 33.9905C103.124 33.0571 99.1336 29.3238 97.9365 24.7904C96.7394 20.257 98.3355 14.9236 101.927 11.8569C104.321 9.7236 107.513 8.65692 110.572 7.72358C125.204 3.45688 141.032 2.92354 155.929 6.12357" stroke="#C9D4E2" stroke-miterlimit="10" stroke-dasharray="4 4"/>
  <path d="M90.887 110.258H82.7734V112.258H90.887V110.258Z" fill="#1C3754"/>
  <path d="M163.78 2.65396C163.647 4.65398 163.514 6.38733 162.051 6.78733C160.587 7.18734 159.922 5.85399 158.991 3.98731C158.06 2.25396 158.592 0.520614 160.188 0.120611C161.785 -0.279393 164.046 0.253945 163.78 2.65396Z" fill="#DAE2EB"/>
  <path d="M162.319 11.9859C162.718 9.58588 163.117 8.38587 161.787 7.7192C160.324 7.05253 159.393 8.25254 157.93 9.85255C156.6 11.3192 157.398 13.3192 158.728 13.9859C160.191 14.7859 161.92 13.9859 162.319 11.9859Z" fill="#DAE2EB"/>
  <path d="M164.053 7.71672C163.92 8.65006 163.255 9.31673 162.324 9.45007C161.925 9.45007 161.526 9.45007 160.994 9.45007C159.131 9.1834 157.668 7.98339 157.801 6.78338C157.934 5.58337 159.664 4.9167 161.659 5.18337C162.058 5.18337 162.457 5.3167 162.723 5.45004C163.654 5.7167 164.186 6.65005 164.053 7.71672C164.053 7.71672 164.053 7.58339 164.053 7.71672Z" fill="#1C3754"/>
  <path d="M18.5332 44.38C18.5332 42.1133 18.5332 39.9799 20.1293 39.3133C21.8585 38.6466 22.7895 40.2466 23.9866 42.3799C25.1837 44.38 24.6517 46.38 22.9225 47.0467C21.4594 47.7133 18.5332 47.3133 18.5332 44.38Z" fill="#DAE2EB"/>
  <path d="M19.4611 33.4522C19.1951 36.2522 18.7961 37.7189 20.5252 38.3856C22.2543 39.0522 23.1854 37.5856 24.7815 35.4522C26.1116 33.5855 25.1805 31.3188 23.4514 30.6522C21.7223 29.9855 19.7271 31.1855 19.4611 33.4522Z" fill="#DAE2EB"/>
  <path d="M18 38.6508C18 37.5841 18.7981 36.7841 19.7291 36.6508C20.1282 36.5174 20.6602 36.5174 21.1922 36.6508C23.3204 36.7841 25.0495 37.9841 24.9165 39.3175C24.7835 40.6508 23.0544 41.5842 20.9262 41.3175C20.5272 41.3175 20.1282 41.1842 19.7291 41.0508C18.665 40.7841 18 39.7175 18 38.6508Z" fill="#1C3754"/>
  <path d="M26.9102 39.0586C40.6102 39.0586 65.8821 47.0587 66.2811 76.2589" stroke="#C9D4E2" stroke-miterlimit="10" stroke-dasharray="4 4"/>
  <defs>
    <filter id="filter0_d_5232_107256" x="45.5117" y="12.3164" width="20.5225" height="20.5332" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="5"/>
      <feGaussianBlur stdDeviation="4"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.1125 0 0 0 0 0.295916 0 0 0 0 1 0 0 0 0.22 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5232_107256"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5232_107256" result="shape"/>
    </filter>
    <filter id="filter1_d_5232_107256" x="153.121" y="44.3242" width="20.2563" height="20.2666" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="5"/>
      <feGaussianBlur stdDeviation="4"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.1125 0 0 0 0 0.295916 0 0 0 0 1 0 0 0 0.22 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5232_107256"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5232_107256" result="shape"/>
    </filter>
    <filter id="filter2_d_5232_107256" x="27.5566" y="50.1328" width="121.739" height="78.6953" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="-9"/>
      <feGaussianBlur stdDeviation="5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.13 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5232_107256"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5232_107256" result="shape"/>
    </filter>
    <filter id="filter3_d_5232_107256" x="0.40625" y="49.0938" width="176.604" height="120.667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="11"/>
      <feGaussianBlur stdDeviation="11"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5232_107256"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5232_107256" result="shape"/>
    </filter>
    <filter id="filter4_d_5232_107256" x="94.6094" y="99.8828" width="67.1006" height="67.21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="5"/>
      <feGaussianBlur stdDeviation="4"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.1125 0 0 0 0 0.295916 0 0 0 0 1 0 0 0 0.22 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5232_107256"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5232_107256" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_5232_107256" x1="53.513" y1="17.5853" x2="58.0363" y2="17.5853" gradientUnits="userSpaceOnUse">
      <stop stop-color="#83A6FF"/>
      <stop offset="1" stop-color="#5A78FF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_5232_107256" x1="161.122" y1="49.4596" x2="165.379" y2="49.4596" gradientUnits="userSpaceOnUse">
      <stop stop-color="#83A6FF"/>
      <stop offset="1" stop-color="#5A78FF"/>
    </linearGradient>
    <linearGradient id="paint2_linear_5232_107256" x1="102.624" y1="128.512" x2="153.735" y2="128.512" gradientUnits="userSpaceOnUse">
      <stop stop-color="#83A6FF"/>
      <stop offset="1" stop-color="#5A78FF"/>
    </linearGradient>
    <linearGradient id="paint3_linear_5232_107256" x1="128.884" y1="129.319" x2="142.765" y2="143.166" gradientUnits="userSpaceOnUse">
      <stop stop-color="#4F6AFF"/>
      <stop offset="1" stop-color="#657EFF" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint4_linear_5232_107256" x1="134.591" y1="134.945" x2="123.622" y2="124.002" gradientUnits="userSpaceOnUse">
      <stop offset="0.0113208" stop-color="#C2D1FF"/>
      <stop offset="1" stop-color="white"/>
    </linearGradient>
  </defs>
</svg>
)