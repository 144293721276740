import React from "react";
import { fixAmountFormat } from "../../../../utils";
import { LEDGER_TRANSACTION_STATUSES } from "../../../../utils/constants";

import { Table } from "antd";

function PendingTransactionsTableHeader() {
  return (
    <div className="custom-table-header">
      <div className="custom-table-header-title">Pending Transactions</div>
    </div>
  );
}

function PendingTransactionsTable({ transactionList = [] }) {
  const pendingTransactionList = transactionList?.filter(
    (t) => t.status === LEDGER_TRANSACTION_STATUSES.PROCESSING,
  );
  const isVisible = Boolean(pendingTransactionList?.length);

  const pendingTableColumns = [
    {
      title: "DESCRIPTION",
      dataIndex: "description",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      render: (amount) => fixAmountFormat(amount),
    },
  ];

  return (
    isVisible && (
      <Table
        bordered
        title={() => <PendingTransactionsTableHeader />}
        columns={pendingTableColumns}
        dataSource={pendingTransactionList}
        pagination={false}
        className="mb-15 compact-table"
      />
    )
  );
}

export { PendingTransactionsTable };
