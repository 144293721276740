import React from "react";
import { Typography } from "../../../../design-systems";
import { EditIcon } from "../../../../design-systems/Icons";

export const SectionHeader = ({ name, edit }) => {
  return (
    <div className="section-header-wrapper">
      <Typography
        className={"section-title"}
        variant={"subtitle-large"}
        label={name}
      />
      {edit && (
        <span className="edit-section">
          <EditIcon />
          <Typography
            className={"edit-title"}
            variant={"section-title-2"}
            label={"Edit"}
          />
        </span>
      )}
    </div>
  );
};
