import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../../utils/router/customRouterHook";
import { langs } from "../../../../i18n";
import Papa from "papaparse";
import {
  getFileUploadStatus,
  getFileUploadUrl,
  showErrorMessage,
  showSuccessMessage,
  uploadFile,
  uploadFileS3,
} from "../../../../redux/actions";
import { LoadingOutlined } from '@ant-design/icons';
import { getMD5hash, validateCSVHeaders } from "../../../../utils";

import { DownloadFileTemplateButton } from "../../../common/DownloadFileTemplateButton";
import { Button, Col, Modal, Progress, Row, Spin, Tooltip, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function UploadEmployeesModal({
  isModalVisible,
  setIsModalVisible,
  bulkUploadName,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const [intervalId, setIntervalId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [file, setFile] = useState(null);


  useEffect(() => {
    setFileUploadError(null);
    setUploadProgress({});
    setFile(null);
  }, []);

  useEffect(() => {
    if (!isModalVisible) {
      setFile(null);
      setFileList([])
    }
  }, [isModalVisible])

  const onFileUpload = async () => {
    setButtonIsDisabled(true);

    const hash = await getMD5hash(file);

    const reqData = {
      checksum: hash,
      file_name: file.name,
      bulk_upload_name: bulkUploadName,
    };

    const payload = {
      service: "admin-portal",
      module: "census",
      payload: reqData,
      file_names: [file.name]
    };
    setLoading(true)
    dispatch(getFileUploadUrl(payload, (res) => {
      if (!(res instanceof Error)) {
        if (res?.data?.data?.presigned_url?.length) {
          res?.data?.data?.presigned_url?.forEach(data => {
            const presignedUrl = data.url;
            if (presignedUrl) {
              dispatch(
                uploadFileS3(presignedUrl, res?.xRayTraceId, file, (res) => {
                  if (res instanceof Error) {
                    dispatch(showErrorMessage(langs.messages.file_upload_error));
                  } else {
                    pollUploadStatus(data?.file_id, data?.file_name);
                  }
                }),
              );
            }
          });
        }
      }
    }));
  };


  useEffect(() => {
    if (uploadStatus[file?.name] === "success") {
      dispatch(
        showSuccessMessage(langs.messages.file_uploaded_successfully),
      );

      router.push({
        pathname: "/admin/employees",
        state: { showCensusTab: true },
      });
    }
  }, [uploadStatus])

  const pollUploadStatus = (fileId, fileName) => {
    const intervalId = setInterval(() => {
      dispatch(getFileUploadStatus(fileId, (res) => {
        if (!(res instanceof Error)) {
          const { status = "", progress_percentage, message } = res?.data?.data?.data;
          setUploadProgress((prev) => ({
            ...prev,
            [fileName]: { status, progress_percentage },
          }));
          if (message?.length) {
            if (status !== "FILE_ACCEPTED") {
              dispatch(showErrorMessage(message));
            }
          }
          if (status === "ERRORED_OUT") {
            clearInterval(intervalId);
            setLoading(false);
            // setFileList([])
          }
          if (status === "FILE_REJECTED") {
            clearInterval(intervalId);
            setLoading(false);
            // setFileList([]);
            dispatch(showErrorMessage("File is rejected. Please use another file."));
          }
          if (progress_percentage === 100 || status === "FILE_ACCEPTED") {
            clearInterval(intervalId);
            setLoading(false)
            setUploadStatus((prev) => ({
              ...prev,
              [fileName]: (status === 'SUCCESS' || status === 'FILE_ACCEPTED') ? 'success' : 'failed',
            }));
            setIntervalId((prev) => {
              const { [fileName]: _, ...rest } = prev;
              return rest;
            });
          }
          // else if (progress_percentage < 100) {
          //   setUploadStatus((prev) => ({
          //     ...prev,
          //     [fileName]: 'in-progress',
          //   }));
          // }
        } else {
          clearInterval(intervalId);
          dispatch(showErrorMessage(langs.messages.file_upload_error));
          setUploadStatus((prev) => ({
            ...prev,
            [fileName]: 'failed',
          }));
          setIntervalId((prev) => {
            const { [fileName]: _, ...rest } = prev;
            return rest;
          });
        }
      }));
    }, 2000);

    setIntervalId((prev) => ({ ...prev, [fileName]: intervalId }));
  };

  const onCancelFileUpload = () => {
    setIsModalVisible(false);
    setFileUploadError(null);
    setFile(null);
  };

  const fileCheck = async (file) => {
    setFile(null);
    setFileUploadError(null);

    const fileState = await validateFile(file);

    if (fileState) {
      setFile(file);
      setFileList([file]);
    }
  };

  const validateFile = async (file) => {
    if (!file) {
      setFileUploadError(langs.messages.CSV_file_is_required);
      return;
    }

    const fileSplit = file.name.split(".");

    if (fileSplit[fileSplit.length - 1] !== "csv") {
      setFileUploadError(langs.messages.please_upload_valid_CSV_file);
      return;
    }

    const papaPromise = (importFile) =>
      new Promise((resolve, reject) => {
        Papa.parse(importFile, {
          header: false,
          preview: 1,
          skipEmptyLines: true,
          error: function (err, file, inputElem, reason) {
            reject(err);
          },
          step: function (results, parser) {
            resolve(results);
          },
          complete: function (results) { },
        });
      });

    const results = await papaPromise(file);

    if (results.errors.length !== 0) return;

    const validationResult = validateCSVHeaders(results.data);

    if (
      validationResult.incorrect.length !== 0 ||
      validationResult.missing.length !== 0
    ) {
      setFileUploadError(
        `Fields do not comply with the rules: ${validationResult.incorrect.join(
          ", ",
        )}.
         Expected: ${validationResult.missing.join(
          ", ",
        )}. Fix the headers and try again.`,
      );
      return;
    } else {
      return true;
    }
  };

  const customLoader = (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
      }
    >
    </Spin>
  )

  const customItemRender = (originNode, file) => {
    const progress = uploadProgress[file.name] || 0;
    const percent = progress?.progress_percentage || 0;
    return (
      <Row gutter={[20]} >
        <Col sm="16">
          {file.name}
        </Col>
        <Col sm="4">
          {
            loading &&
            <div className="d-flex">
              {percent < 100 ? customLoader : null}
              <Progress percent={progress?.progress_percentage} size="small" style={{ marginLeft: 8 }} />
            </div>
          }
        </Col>
        <Col sm="2">
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title="Upload CSV Employee File"
      visible={isModalVisible}
      okText="Upload"
      cancelText="Cancel"
      onOk={onFileUpload}
      okButtonProps={{
        disabled: !file || fileUploadError || buttonIsDisabled,
        loading: file && buttonIsDisabled ? true : false,
      }}
      cancelButtonProps={{
        disabled: loading
      }}
      onCancel={onCancelFileUpload}
      className="common-modal-style upload-csv"
    >
      <div className="text-muted sub-title">
        Select the CSV and click upload.
      </div>
      <div className="upload-block d-flex">
        <Upload
          fileList={fileList}
          accept=".txt, .csv"
          showUploadList={file ? true : false}
          listType="picture-list"
          itemRender={customItemRender}
          beforeUpload={(file, fileList) => fileCheck(file)}
        >
          <Button
            icon={<UploadOutlined />}
            type="button"
            className="button-secondary-s mr-10"
          >
            Click to Upload
          </Button>
        </Upload>
        <DownloadFileTemplateButton employee compact />

        {/* {file && <p className="file-info">{file.name}</p>} */}
      </div>
      {fileUploadError && (
        <p className="invalid-feedback">{fileUploadError}</p>
      )}
    </Modal>
  );
}

export { UploadEmployeesModal };
