import axios from "./axiosInterceptors";

export const getClaimsSummaryService = async (data) => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/claims/summary",
      params: data
    };

    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getClaimsStatusService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/claims/status",
    };

    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getClaimDetailsByIdService = async (id) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/claims/${id}`
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const saveInternalNotesService = async (data) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/claims/internal-notes`,
      data
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getClaimsDocumentsService = async (id) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/claims/${id}/documents`,
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const postClaimsDocumentsService = async (id, data) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/claims/${id}/documents`,
      data
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getClaimActionReasons = async (reason) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/claims/${reason}/reasons`,
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}
export const updateVerifyUploadService = async (claimId, signal) => {
  try {
    const request = {
      method: "patch",
      url: `employer/payroll/claims/${claimId}/documents/verify-upload`,
      signal
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const updateClaimActionService = async (claim_id, action, data) => {
  try {
    const request = {
      method: "patch",
      url: `/employer/payroll/claims/${claim_id}/${action}`,
      data
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getClaimsActivityLogsService = async (claim_id) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/claims/${claim_id}/activity-logs`
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getNextClaimIdService = async (data) => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/claims/summary/next-claim-id",
      params: data
    }
    const response = await axios(request);

    return { error: false, data: response || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
}