import { Collapse as AntCollapse, } from "antd";
import {
  employmentInformationStep,
  employmentInformationKeys
} from "../../../../../utils";
import { PanelHeader } from "../../../employer/AddEmployer/PanelHeader";
import { useEffect, useState } from "react";
import { Collapse } from "../../../../../design-systems";
import { useSelector } from "react-redux";
import { useRouter } from "../../../../../utils/router";
import useShowSecondSection from "../../../../../hooks/useShowSection";

const { Panel } = AntCollapse;

export const EmploymentInformation = ({ form, formData, setFormData }) => {
  const router = useRouter()
  useShowSecondSection();
  const { employee_draft_id } = useSelector((state) => state.employees)
  useEffect(() => {
    if (!employee_draft_id) {
      router.push('/admin/employee/add/1')
    }
  }, [employee_draft_id])
  // const [sameAsCorporate, setSameAsCorporate] = useState(true)
  const [expandedPanels, setExpandedPanels] = useState(
    employmentInformationStep.map(({ key }) => key),
  );

  const onChange = async (updatedPanels) => {
    if (updatedPanels?.length < expandedPanels.length) {
      try {
        const recentKey = expandedPanels.find(
          (key) => !updatedPanels.includes(key),
        );

        if (
          recentKey === employmentInformationKeys.MAILING_ADDRESS &&
          !!form?.getFieldValue("same_as_corporate")
        ) {
          setExpandedPanels(updatedPanels);
        }
        // else {
        //   const values = await form?.validateFields(validateFields[recentKey]);
        //   if (Object.keys(values).length) {
        //   }
        // }
        setExpandedPanels(updatedPanels);
      } catch (err) { }
    } else {
      setExpandedPanels(updatedPanels);
    }
  };

  return (
    <Collapse
      className="horizontal-collapse test"
      activeKey={expandedPanels}
      onChange={onChange}
      panels={employmentInformationStep.map(({ content, title, key }) => (
        <Panel header={<div className="panel-head"><PanelHeader label={title} form={form} expandedPanels={expandedPanels} setExpandedPanels={setExpandedPanels} /></div>} key={key}>
          {content({ form, formData, setFormData })}
        </Panel>
      ))}
    />
  );
};
