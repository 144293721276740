// import {  } from "antd";
import { useSelector } from "react-redux";
import { Button, Edit, Typography } from "../../../../../../design-systems";
import { ROLE_CONSTANT } from "../../../../../../utils/constants";
import { Tag } from "antd";

export const CardTitle = ({ title, onEdit, accountHolderStatus = "", page = "", addPlan, onAddPlanClick, plansData, tagText }) => {
  const role = useSelector((state) =>
    state.userDetails && state.userDetails.roleType
      ? state.userDetails.roleType
      : "ADMIN",
  );
  const tagColor = tagText === "ACTIVE" ? "green" : tagText === "INACTIVE" ? "red" : "yellow";
  const showEditIcon = onEdit && role === ROLE_CONSTANT.ZENDA_ADMIN && !(page === "personalInfo" && (accountHolderStatus === "Activated" || accountHolderStatus === "Closed"));

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 20
      }}
    >
      <div className="d-flex justify-space-between align-items-center gap-10" 
      >
        <Typography variant="body-1" label={title} />
        {tagText &&
          <Tag className={`plan-information-tag ${tagColor}`}>{tagText}</Tag>
        }
      </div>
      {
        addPlan && role === ROLE_CONSTANT.ZENDA_ADMIN && (
          <Button
            variant="primary"
            onclickCallback={onAddPlanClick}
            label="Add plan"
            htmlType="submit"
            disabled={!plansData?.length}
          // loading={loading}
          />
        )
      }

      {showEditIcon && (
        <Edit stroke="#296DB2" onClick={onEdit} className="edit-emp-card" />
      )}
    </div>
  )
};
