import { Card, Col, Row } from "antd";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { getClaimListingColumns } from "../../../utils";
import { ClaimsTitle } from "../../../components/admin/claims";
import { Table } from "../../../design-systems";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getClaimsStatus, getClaimsSummary, saveClaimListAPIValues, saveCurrentClaimId } from "../../../redux/actions/claims.actions";
import { useSelector } from "react-redux";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { NoClaims } from "../../../components/admin/claims/NoClaims";
import debounce from "lodash/debounce";
import { useRouter } from "../../../utils/router";

export const List = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const storedFilters = JSON.parse(localStorage.getItem("claimListApiData")) || {};
  const [data, setData] = useState([])
  const [selectedValues, setSelectedValues] = useState(storedFilters?.claim_status ? storedFilters?.claim_status?.toString()?.split(",") : ["AWAITING_REVIEW"]);
  const [search, setSearch] = useState("")
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit] = useState(10)
  const [sortFields, setSortFields] = useState({
    sort: "claim_id",
    order: "desc"
  })
  const { dataLoading, loading } = useSelector((state) => state.loader);
  const { claimsSummary, claimsStatus } = useSelector((state) => state.claims)

  useEffect(() => {
    dispatch(getClaimsStatus())
  }, [])

  useEffect(() => {
    setTotalCount(claimsSummary?.total_records ?? 0)
    setData(claimsSummary?.data ?? [])
  }, [claimsSummary])

  useEffect(() => {
    setCurrentPage(1)
  }, [search, sortFields, selectedValues])

  const getClaimsSummaryData = async () => {
    const data = {
      search: search,
      page: currentPage,
      limit: limit,
      sort: sortFields.sort,
      order: sortFields.order,
      claim_status: Array.isArray(selectedValues) ? selectedValues?.join(',') : [selectedValues]?.join(',')
    }
    localStorage.setItem("claimListApiData", JSON.stringify(data))
    dispatch(saveClaimListAPIValues(data))
    await dispatch(getClaimsSummary(data))
  }

  const onFetchDataDebounced = debounce(getClaimsSummaryData, 0);
  useEffect(() => {
    onFetchDataDebounced();
    return () => {
      onFetchDataDebounced.cancel();
    };
  }, [currentPage, limit, sortFields, selectedValues, selectedValues.length, search]);

  const handleTableChange = (pagination, filters, sorter, { _, action }) => {
    switch (action) {
      case 'sort': {
        if (!sorter?.column) {
          setSortFields({
            sort: "claim_status",
            order: "desc"
          });
          return;
        }
        setSortFields({
          sort: sorter.field,
          order: sorter.order === "ascend" ? "asc" : "desc"
        })
        break;
      }
      default:
        break;
    }
  }

  const columns = useMemo(() => getClaimListingColumns(), []);

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card title="" bordered={false} className="claims-wrapper">
            <div className="title-wrapper">
              <ClaimsTitle
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                claimsStatus={claimsStatus}
                search={search}
                setSearch={setSearch}
              />
            </div>
            {
              dataLoading.length || loading || data.length ?
                <SpinLoader showContent={!!data}>
                  <Table
                    className="claims-list-table"
                    columns={columns}
                    dataSource={data}
                    onChange={handleTableChange}
                    rowClassName={"pointer"}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          router.push({
                            pathname: `/admin/claims/details/${record?.claim_id}`,
                          })
                          dispatch(saveCurrentClaimId(record?.claim_id))
                        }
                      };
                    }}
                    pagination={{
                      onChange: (page) => setCurrentPage(page),
                      total: totalCount,
                      current: currentPage,
                      defaultCurrentPage: 1,
                      defaultPageSize: limit,
                    }}
                    scroll={data?.length ? { x: 1200 } : undefined}
                  />
                </SpinLoader> :
                <NoClaims />
            }
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  )
};
