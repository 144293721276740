import React, { useEffect } from "react";

import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { GlobalSearch, SearchResults } from "../../../components/admin/search";
import { SpinLoader } from "../../../components/common/SpinLoader";

import { Card, Col, Row } from "antd";
import { Typography } from "../../../design-systems";

function Search() {
  useEffect(() => {
    localStorage.removeItem("claimListApiData")
  }, []);

  return (
    <HeaderWithContent>
      <Card title="" bordered={false} className="search-page-wrapper">
        <div className="title-wrapper">
          <div className="claims-title-wrapper">
            <div className="search-wrapper">
              <Typography variant="subhead-1" label="Search" className="label" />
              <GlobalSearch />
            </div>
          </div>
        </div>
        <SpinLoader>
          <SearchResults />
        </SpinLoader>
      </Card>
    </HeaderWithContent>
  );
}

export { Search };
