import { Select as AntSelect, Form } from "antd";
import { ChevronDown, Close, Search } from "../Icons";

export const Select = ({
  name,
  value,
  label,
  rules,
  supportiveText,
  placeholder,
  options,
  disabled,
  showSearch,
  optionFilterProp,
  largeSearch,
  className,
  mode,
  onClear,
  onChange,
  onDeselect,
  defaultValue,
  popupClassName,
  allowClear = true,
  style
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      help={supportiveText}
      className={`select-field-wrapper ${largeSearch && "large-search"
        } ${className}`}
    >
      <AntSelect
        style={style}
        autoComplete="off"
        mode={mode}
        allowClear={allowClear}
        value={value}
        defaultValue={defaultValue}
        onDeselect={onDeselect}
        disabled={disabled}
        placeholder={
          placeholder ? (
            placeholder
          ) : showSearch ? (
            <div className="search-placeholder">
              {" "}
              <Search /> Search
            </div>
          ) : (
            ""
          )
        }
        suffixIcon={<ChevronDown />}
        options={options}
        popupClassName={`select-option-wrapper ${popupClassName}`}
        showSearch={showSearch}
        optionFilterProp={optionFilterProp}
        clearIcon={<Close />}
        onClear={onClear}
        onChange={onChange}
      />
    </Form.Item>
  );
};
