import axios from "./axiosInterceptors";
import { formatDate } from "../utils";

function isValidEmployeesPayload(employees) {
  var payload = { employee: [] };
  for (let i = 0; i < employees.length; i++) {
    const { workEmail, employeeID, SSN } = employees[i];
    payload["employee"].push({
      work_email: workEmail.trim(),
      employee_id: employeeID.trim(),
      ssn: SSN,
    });
  }
  if (payload["employee"].length === 0) {
    payload["employee"].push({
      work_email: "someone@something.com",
      employee_id: "AAAAAAAAAAAAAAAA",
      ssn: 123456789,
    });
  }
  return payload;
}

export const getCountriesService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/country",
    };

    const response = await axios(request);

    const returnData = { error: false, countries: {} };
    for (let i = 0; i < response.data.body.data.length; i++) {
      const { code_numeric, name } = response.data.body.data[i];
      returnData.countries[name] = code_numeric;
    }
    return returnData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEmploymentStatusesService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employee/employmentstatus/all",
    };

    const response = await axios(request);

    const returnData = { error: false, employmentStatuses: {} };
    for (let i = 0; i < response.data.body.data.length; i++) {
      const { id, code } = response.data.body.data[i];
      returnData.employmentStatuses[code] = id;
    }
    return returnData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUserStatusesService = async (filterStatusForKyc) => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employee/status/all",
      params: filterStatusForKyc ? { filterStatusForKyc: true } : "",
    };

    const response = await axios(request);

    const returnData = { error: false, userStatuses: {} };
    for (let i = 0; i < response.data.body.data.length; i++) {
      const { id, code } = response.data.body.data[i];
      returnData.userStatuses[code] = id;
    }
    return returnData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCoveragesService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/coveragetype",
    };

    const response = await axios(request);

    const returnData = { error: false, coverages: {} };
    for (let i = 0; i < response.data.body.data.length; i++) {
      const { id, code } = response.data.body.data[i];
      returnData.coverages[code] = id;
    }
    return returnData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

function addEmployeesPayload(employees, runkyc) {
  const payload = {
    upload_type: "payload",
    file_for: "employee",
    run_kyc: Number(runkyc),
    payload: [],
  };
  for (let i = 0; i < employees.length; i++) {
    const {
      employeeID,
      employeeDateOfJoining,
      firstName,
      lastName,
      middleName,
      DOB,
      SSN,
      mobileNum,
      addr1,
      addr2,
      city,
      st,
      postal,
      workEmail,
      HSAPlanEligibilityDate,
      HSAPlanStartDate,
      employmentStatusID,
      countryID,
      userStatusID,
      coverageID,
      division_id,
      coverage_effective_date,
      coverage_termination_date,
      employment_termination_date,
    } = employees[i];

    let entry = {
      employee_id: employeeID.trim(),
      employee_date_of_joining: employeeDateOfJoining,
      employment_status_id: employmentStatusID,
      first_name: firstName.trim(),
      middle_name: middleName ? middleName.trim() : "",
      last_name: lastName.trim(),
      date_of_birth: DOB,
      ssn: SSN,
      mobile_phone_num: mobileNum,
      address_line_1: addr1.trim(),
      address_line_2: addr2 === undefined ? "" : addr2,
      city: city.trim(),
      state: st,
      postal_code: postal,
      country_code: countryID,
      work_email: workEmail.trim(),
      coverage_id: coverageID,
      hsa_plan_eligibility_dt: HSAPlanEligibilityDate,
      hsa_start_dt: HSAPlanStartDate,
      account_type_id: 1,
      division_id,
      coverage_effective_date,
      coverage_termination_date,
      employment_termination_date,
      sent_at: formatDate(),
    };

    if (userStatusID) entry["user_status_id"] = userStatusID;
    payload["payload"].push(entry);
  }

  let emPayload = { employee_payload: payload };
  const formData = new FormData();
  Object.keys(emPayload).forEach((key) => {
    if (typeof emPayload[key] == "object") {
      formData.append(key, JSON.stringify(emPayload[key]));
    } else {
      formData.append(key, payload[key]);
    }
  });

  return formData;
}

const prepareValidateResponse = (returnData, source) => {
  const data = returnData;
  for (let i = 0; i < source?.length; i++) {
    const {
      employee_id,
      employee_id_validation,
      work_email,
      work_email_validation,
      ssn,
      ssn_validation,
    } = source[i];
    const employee = {
      employeeID: employee_id,
      workEmail: work_email,
      employeeIDState: employee_id_validation,
      workEmailState: work_email_validation,
      ssn: ssn,
      SSNState: ssn_validation,
    };

    if (employee_id_validation) {
      const splitEmployeeID = employee_id_validation.split(" : ");
      if (splitEmployeeID[splitEmployeeID.length - 1] === "Pass") {
        employee.employeeIDState = "valid";
      } else if (
        splitEmployeeID[splitEmployeeID.length - 1] === "Duplicate Record"
      ) {
        employee.employeeIDState = "Duplicate Employee ID.";
      } else if (splitEmployeeID[splitEmployeeID.length - 1]) {
        employee.employeeIDState = splitEmployeeID[splitEmployeeID.length - 1];
      }
    }
    if (work_email_validation) {
      const splitWorkEmail = work_email_validation.split(" : ");
      if (splitWorkEmail[splitWorkEmail.length - 1] === "Pass") {
        employee.workEmailState = "valid";
      } else if (
        splitWorkEmail[splitWorkEmail.length - 1] === "Duplicate Record"
      ) {
        employee.workEmailState = "Duplicate work email.";
      } else if (
        splitWorkEmail[splitWorkEmail.length - 1] === "Domain name mismatch"
      ) {
        employee.workEmailState = "Email domain name is mismatched.";
      } else if (splitWorkEmail[splitWorkEmail.length - 1]) {
        employee.workEmailState = splitWorkEmail[splitWorkEmail.length - 1];
      }
    }
    if (ssn_validation) {
      const splitSSN = ssn_validation.split(" : ");
      if (splitSSN[splitSSN.length - 1] === "Pass") {
        employee.SSNState = "valid";
      } else if (splitSSN[splitSSN.length - 1] === "Duplicate Record") {
        employee.SSNState = "Duplicate SSN.";
      } else if (splitSSN[splitSSN.length - 1]) {
        employee.SSNState = splitSSN[splitSSN.length - 1];
      }
    } else {
      console.log("Error in validate employee");
      data.error = true;
    }

    data.validate.push(employee);
  }
  return data;
};

export const validateEmployeesService = async (employees) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/is_valid/employees",
      data: isValidEmployeesPayload(employees),
    };

    const response = await axios(request);

    const returnData = { error: false, validate: [] };
    return prepareValidateResponse(returnData, response.data?.body);
  } catch (error) {
    const returnData = { error: true, validate: [] };
    return prepareValidateResponse(
      returnData,
      error.response?.data?.error?.message,
    );
  }
};

export const addEmployeeService = async (reqData) => {
  let employees = reqData;
  let runkyc = employees.length ? employees[0].runkyc : 1;

  try {
    const request = {
      method: "post",
      url: "/employer/payroll/register/employees",
      data: addEmployeesPayload(employees, runkyc, false, "NA"),
    };

    const response = await axios(request);

    const returnData = {
      error: false,
      success: {
        inputs: response.data.body["Total Input Records"],
        added: response.data.body["New Employees Added"],
      },
    };
    return returnData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAccountStatusesService = async () => {
  return { error: false, acountStatuses: null };
};

export const fileUploadService = async (data) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/upload/files",
      data
    }
    const response = await axios(request);
    return { error: false, data: response || [] };
  } catch (error) {
    throw error
  }
}

export const getfileUploadStatusService = async (file_id) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/upload/files/${file_id}/status`
    }
    const response = await axios(request);
    return { error: false, data: response || [] };
  } catch (error) {
    throw error
  }
}