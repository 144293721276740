import React from "react";
import moment from "moment-timezone";
import { DATE_FORMAT } from "../../../../utils/constants";
import { fixAmountFormat } from "../../../../utils";

function CumulativeContributions({ contributionYTDTotal }) {
  const pstTime = moment().tz("America/Los_Angeles");

  return (
    <div className="balance">
      <h4>Cumulative YTD HSA Contributions</h4>
      <small className="text-muted">
        as of {pstTime.format("hh:mm A")} PST on{" "}
        {moment().format(DATE_FORMAT.FOR_UI_FIRST_MM)}
      </small>
      <span>{fixAmountFormat(contributionYTDTotal)}</span>
    </div>
  );
}

export { CumulativeContributions };
