import React, { useState } from "react";
import { langs } from "../../../i18n";

import { Button, Modal, Spin, Upload, Row, Col, Progress } from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { DownloadFileTemplateButton } from "../../common/DownloadFileTemplateButton";

const UploadContributionModal = ({
  handleCancel,
  submitUploadedFile,
  isModalVisible,
  setUploadedFile,
  uploadedFile,
  uploadProgress,
  loading,
  fileUploaderError,
  setFileUploaderError,
  setFileList,
  fileList
}) => {
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);


  const onOk = () => {
    setButtonIsDisabled(true);

    if (!uploadedFile) {
      setFileUploaderError({
        error: true,
        msg: langs.messages.CSV_file_is_required,
      });
    } else {
      submitUploadedFile();
    }
  };

  const handleUpload = (file) => {
    const fileSplit = file.name.split(".");

    if (fileSplit[fileSplit.length - 1] !== "csv") {
      setFileUploaderError({
        error: true,
        msg: langs.messages.please_upload_valid_CSV_file,
      });
      return;
    }

    if (fileSplit.slice(0, -1).join("").length > 150) {
      setFileUploaderError({
        error: true,
        msg: langs.messages.contribution_run_file_name_is_too_long,
      });
      return;
    }

    setUploadedFile(file);
    setFileList([file]);
    setButtonIsDisabled(false);
    setFileUploaderError({ error: false, msg: "" });

    return false;
  };


  const customLoader = (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
      }
    >
    </Spin>
  )

  const customItemRender = (originNode, file) => {
    const progress = uploadProgress && uploadProgress[file.name] || {};
    const percent = progress?.progress_percentage || 0;
    return (
      <Row gutter={[20]}>
        <Col sm="16">
          {file.name}
        </Col>
        <Col sm="4">
          {
            loading &&
            <div className="d-flex">
              {percent < 100 ? customLoader : null}
              <Progress percent={progress?.progress_percentage} size="small" style={{ marginLeft: 8 }} />
            </div>
          }
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title="Upload CSV Contribution File"
      visible={isModalVisible}
      okText="Upload"
      cancelText="Cancel"
      onOk={onOk}
      okButtonProps={{
        disabled: !uploadedFile || buttonIsDisabled || fileUploaderError.error,
        loading: uploadedFile && buttonIsDisabled ? true : false,
      }}
      cancelButtonProps={{
        disabled: loading
      }}
      onCancel={handleCancel}
      className="common-modal-style upload-csv"
    >
      <div className="text-muted sub-title">
        Select the CSV and click upload.
      </div>
      <div className="upload-block d-flex">
        <Upload
          accept=".csv"
          fileList={fileList}
          showUploadList={uploadedFile ? true : false}
          listType="picture-list"
          itemRender={customItemRender}
          beforeUpload={(file, fileList) => handleUpload(file)}
        >
          <Button
            icon={<UploadOutlined />}
            type="button"
            className="button-secondary-s mr-10"
          >
            Click to Upload
          </Button>
        </Upload>
        <DownloadFileTemplateButton contribution compact />

        {/* {uploadedFile && <p className="file-info">{uploadedFile.name}</p>} */}
      </div>
      {fileUploaderError.error && (
        <p className="invalid-feedback">{fileUploaderError.msg}</p>
      )}
    </Modal>
  );
};

export { UploadContributionModal };
