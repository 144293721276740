export const Warning = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.47012 20.5036H19.5301C21.0701 20.5036 22.0301 18.8336 21.2601 17.5036L13.7301 4.49359C12.9601 3.16359 11.0401 3.16359 10.2701 4.49359L2.74012 17.5036C1.97012 18.8336 2.93012 20.5036 4.47012 20.5036ZM12 13.25C11.45 13.25 11 12.8 11 12.25V10.25C11 9.7 11.45 9.25 12 9.25C12.55 9.25 13 9.7 13 10.25V12.25C13 12.8 12.55 13.25 12 13.25ZM12 17.25C12.5523 17.25 13 16.8023 13 16.25C13 15.6977 12.5523 15.25 12 15.25C11.4477 15.25 11 15.6977 11 16.25C11 16.8023 11.4477 17.25 12 17.25Z"
      fill="#945605"
    />
  </svg>
);
