export const validateMinAmounts = (form) => {
    return {
        validator(_, value) {
            const { amount_requested_end: maxAmount } = form.getFieldsValue();
            if ((Number(maxAmount) || 0) && (Number(value) || 0) >= (Number(maxAmount) || 0)) {
                return Promise.reject('Min amount must be less than the max amount.');
            }
            return Promise.resolve();
        },
    };
};

export const validateMaxAmounts = (form) => {
    return {
        validator(_, value) {
            const { amount_requested_start: minAmount } = form.getFieldsValue();
            if ((Number(value) || 0) && (Number(value) || 0) < (Number(minAmount) || 0)) {
                return Promise.reject('Max amount cannot be less than the min amount.');
            }
            return Promise.resolve();
        },
    };
};