export const Employee = ({
  width = 16,
  height = 16,
  className,
  color = "white",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clip-path="url(#clip0_5365_129436)">
      <path
        d="M15.3333 13.9993V12.6659C15.3328 12.0751 15.1362 11.5011 14.7742 11.0341C14.4122 10.5672 13.9053 10.2336 13.3333 10.0859"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3334 14V12.6667C11.3334 11.9594 11.0525 11.2811 10.5524 10.781C10.0523 10.281 9.37399 10 8.66675 10H3.33341C2.62617 10 1.94789 10.281 1.4478 10.781C0.9477 11.2811 0.666748 11.9594 0.666748 12.6667V14"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6667 2.08594C11.2404 2.2328 11.7488 2.5664 12.1118 3.03414C12.4749 3.50188 12.672 4.07716 12.672 4.66927C12.672 5.26138 12.4749 5.83666 12.1118 6.3044C11.7488 6.77214 11.2404 7.10574 10.6667 7.2526"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99992 7.33333C7.47268 7.33333 8.66659 6.13943 8.66659 4.66667C8.66659 3.19391 7.47268 2 5.99992 2C4.52716 2 3.33325 3.19391 3.33325 4.66667C3.33325 6.13943 4.52716 7.33333 5.99992 7.33333Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5365_129436">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
