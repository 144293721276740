import React, { useEffect } from 'react';
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Select, TextField } from "../../../../../design-systems";
import { mandatory, validateEmployerTaxID, whiteSpace } from "../../../../../utils/validations";
import { getEmployeeContributionIdentifier } from '../../../../../redux/actions';

export const BusinessDetails = () => {
  const dispatch = useDispatch()
  const contributionIdentifiers = useSelector((state) => state.employer?.contributionIdentifiers)

  useEffect(() => {
    dispatch(getEmployeeContributionIdentifier())
  }, [dispatch])

  return (
    <Row gutter={16}>
      <Col xs={24} lg={8}>&nbsp;</Col>
      <Col xs={24} lg={16}>
        <div className="should-update-col division-layout">
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <TextField
                label="Business name"
                placeholder="Enter business name"
                name="business_name"
                rules={[mandatory({ message: "Please enter a valid business name" }), whiteSpace({ message: "Business name should not contain only spaces." })]}
              />
            </Col>
            <Col xs={24} md={12}>
              <TextField
                label="Employer tax ID"
                placeholder="Enter employer tax ID"
                name="employer_tax_id"
                maxLength={9}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  { required: true, message: "Employer tax id is required" },
                  {
                    validator: validateEmployerTaxID,
                  },
                ]}
                onPaste={(event) => {
                  // Prevent pasting alphanumeric characters
                  const clipboardData = event.clipboardData || window.clipboardData;
                  const pastedText = clipboardData.getData('Text');

                  if (!/^[0-9]+$/.test(pastedText)) {
                      event.preventDefault();
                  }
              }}
              />
            </Col>
            <Col xs={24} md={12}>
              <Select
                disabled={!contributionIdentifiers?.length}
                label="Primary employee identifier"
                placeholder="Select primary employee identifier"
                name="primary_employee_identifier"
                options={contributionIdentifiers}
                rules={[mandatory({ message: "Please select a valid option" })]}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
};
