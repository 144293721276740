import React, { useEffect, useState } from "react";
import { useRouter } from "../../../../utils/router/customRouterHook";
import { capitalizeFirstLetter, fixAmountFormat, handleKeyDown } from "../../../../utils";
import { renderLedgerTransactionStatusTag } from "../../../../utils";
import {
  LEDGER_TRANSACTION_STATUSES,
  DATE_FORMAT,
} from "../../../../utils/constants";
import moment from "moment";

import { Table, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DateRangePicker } from "./DateRangePicker";
import { SearchInput } from "../../../../pages/admin/employee/SearchInput";
import DatePicker2 from "../../../../design-systems/DatePicker2";

function CompletedTransactionsTableHeader({
  fullView,
  ledgerData,
  applySearchFilter,
  dateRange,
  setDateRange,
  search,
  setSearch
}) {

  const [dateRanges, setDateRanges] = useState([null, null]);
  const [startRangeDate, endRangeDate] = dateRanges;

  const isEveryValueNull = (arr) => {
    return Array.isArray(arr) && arr.every(date => date === null)
  }

  const disableFutureDates = date => {
    const today = moment().startOf('day');
    return moment(date).isAfter(today) ? false : true
  };
  return fullView ? (
    <div className="custom-table-header">
      <div className="custom-table-header-title mb-15">Transaction History</div>
      <div className="info-grid-data">
        <div className="left-block">
          <span>{`Current Balance: ${fixAmountFormat(
            ledgerData?.account_balance,
          )}`}</span>
        </div>
        <div className="right-block">
          <SearchInput
            onChange={(e) => {
              if (!e.target.value) {
                setSearch("")
              }
            }}
            onSearch={(e) => applySearchFilter(e)}
            placeholder="Search transaction"
          />
          <div style={{ height: 40 }} className="account-activity-datepicker-wrapper">
            <div style={{ marginLeft: 5, marginTop: -4, maxWidth: "100%", width: "450px", }}>
              <DatePicker2
                maxDate={new Date()}
                customClassName="transactions-datepicker"
                className="transactions-datepicker"
                readOnly={false}
                selectsRange={true}
                showImportant={false}
                placeholder="Start date - End date"
                startDate={startRangeDate}
                endDate={endRangeDate}
                format={['MM/dd/yyyy', 'M/d/yyyy']}
                onChange={(dates) => {
                  setDateRanges(dates);
                  setDateRange(dates)
                }}
                filterDate={disableFutureDates}
                onKeyDown={handleKeyDown}
                isClearable={true}
              />
              <input type="date" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom-table-header">
      <div className="custom-table-header-title">Transaction History</div>
      <div>Current Balance: {fixAmountFormat(ledgerData?.account_balance)}</div>
    </div>
  );
}

function CompletedTransactionsTable({ fullView, ledgerData, getLedgerData }) {
  const router = useRouter();

  const [dateRange, setDateRange] = useState([]);
  const [searchRes, setSearchRes] = useState([]);
  const [search, setSearch] = useState("")

  useEffect(() => {
    setSearchRes(ledgerData?.transactions);
  }, [ledgerData]);

  const isAnyValueNull = (arr) => {
    return Array.isArray(arr) && arr.some(date => date === null)
  }

  const isEveryValueNull = (arr) => {
    return Array.isArray(arr) && arr.every(date => date === null)
  }

  useEffect(() => {
    const requestDateRange = {
      from_date: dateRange[0]
        ? moment(dateRange[0]).format(DATE_FORMAT.FOR_SERVER_REVERT)
        : "",
      to_date: dateRange[1]
        ? moment(dateRange[1]).format(DATE_FORMAT.FOR_SERVER_REVERT)
        : "",
    };

    if (!isAnyValueNull(dateRange) || isEveryValueNull(dateRange)) {
      getLedgerData(requestDateRange);
    }

    //eslint-disable-next-line
  }, [dateRange]);

  const applySearchFilter = (e) => {
    let term = e;
    if (term !== "") {
      let value = term.toLowerCase();
      let matches = ledgerData?.transactions?.filter((v) => {
        if (
          v.description.toLowerCase().includes(value) ||
          v.status.toLowerCase().includes(value) ||
          v.type.toLowerCase().includes(value)
        ) {
          return v;
        }

        return false;
      });
      setSearchRes(matches);
    } else {
      setSearchRes(ledgerData?.transactions);
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {

    router.history.push({
      pathname: router.pathname,
      search: `?page=${pagination.current}`,
    });
  };

  const demoTableColumns = [
    {
      title: "DATE",
      dataIndex: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
  ];

  const fullTableColumns = [
    {
      title: "DATE",
      dataIndex: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
    },
    {
      title: "TYPE",
      dataIndex: "type",
      filters: [
        {
          text: "Deposit",
          value: "deposit",
        },
        {
          text: "Contribution",
          value: "contribution",
        },
        {
          text: "Withdrawal",
          value: "withdrawal",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (type) => capitalizeFirstLetter(type),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      filters: [
        {
          text: "Posted",
          value: LEDGER_TRANSACTION_STATUSES.POSTED,
        },
        {
          text: "Failed",
          value: LEDGER_TRANSACTION_STATUSES.FAILED,
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (status) => renderLedgerTransactionStatusTag(status),
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "BALANCE",
      dataIndex: "available_balance",
      render: (balance) => fixAmountFormat(balance),
      align: "right"
    },
  ];

  const columns = fullView ? fullTableColumns : demoTableColumns;
  const currentTransactionList = fullView ? searchRes : searchRes?.slice(0, 5);
  const completedTransactionList = currentTransactionList?.filter(
    (t) => t.date !== "Pending",
  );

  return (
    <Table
      title={() => (
        <CompletedTransactionsTableHeader
          fullView={fullView}
          ledgerData={ledgerData}
          applySearchFilter={applySearchFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
          search={search}
          setSearch={setSearch}
        />
      )}
      columns={columns}
      dataSource={completedTransactionList}
      onChange={onChange}
      bordered
      scroll={
        completedTransactionList?.length ? fullView && { x: 1200 } : undefined
      }
      pagination={
        !!fullView && {
          current: router.query.page ? Number(router.query.page) : 1,
        }
      }
      rowClassName={(record) => record.link_id && "pointer"}
      className="mb-15"
      onRow={(record) => ({
        onClick: () => {
          if (record.link_id) {
            router.push(`/admin/contribution-progress/${record.link_id}`);
          }
        },
      })}
    />
  );
}

export { CompletedTransactionsTable };
