import React, { useEffect, useMemo, useState } from "react";
import { fixAmountFormat } from "../../../../utils";

import { Card, Select } from "antd";
import { Typography, Table, } from "../../../../design-systems";

function ContributionsSection({ data }) {
  const { Option } = Select
  const [selectedYear, setSelectedYear] = useState(undefined);
  const [filteredData, setFilteredData] = useState(data)
  const contributionYears = useMemo(() => {
    return data ? data?.map(contri => {
      return ({
        label: contri?.contribution_year,
        value: contri?.contribution_year
      })
    }): []
  }, [data]);

  useEffect(() => {
    if (selectedYear) {
      setFilteredData(data?.filter(item => item.contribution_year === selectedYear));
    } else {
      setFilteredData(data);
    }
  }, [selectedYear, data]);

  useEffect(() => {
    if (contributionYears?.length === 1) {
      setSelectedYear(contributionYears[0]?.value);
    }else {
      setSelectedYear(new Date().getFullYear())
    }
  }, [contributionYears]);

  const YTDColumns = [
    {
      title: "Total contribution",
      dataIndex: "contribution_amount",
      render: (amount) => fixAmountFormat(amount),
      align: "left",
      width: 120
    },
    {
      title: "Contribution limit",
      dataIndex: "max_contribution",
      render: (amount) => fixAmountFormat(amount),
      align: "left",
      width: 100
    },
  ];

  return (
    <Card bordered={false} className="account-holder-cards">
      <div className="d-flex justify-space-between align-item-center">
        <Typography
          variant="subhead-3"
          label="HSA Contributions"
          className="account-holder-card-header"
        />
        {contributionYears?.length > 0 ?<Select
          name="year"
          className="contribution-year-select"
          allowClear={false}
          value={selectedYear}
          placeholder="Select year"
          options={contributionYears}
          onChange={(e) => setSelectedYear(e)}
          disabled={contributionYears?.length === 1 || !contributionYears?.length || !contributionYears}
        >
          {contributionYears.map(year => (
            <Option>{year.value}</Option>
          ))}
        </Select> : null}
      </div>
      <div className="mt-30">
        <Table
          columns={YTDColumns}
          dataSource={filteredData}
          pagination={false}
          scrollX={120}
          className="compact-table activity-logs-table"
        />
      </div>
    </Card>
  );
}

export { ContributionsSection };
