import React, { useEffect, useState } from "react";

import { Card, Col, Empty, Row } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";
import { Typography, Table, ExpandIcon } from "../../../../design-systems";
import { ExpandedModalView } from "./ExpandedViewModal";

function KYCSection({ data }) {
  const [open, setOpen] = useState(false);

  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit] = useState(10);

  const renderSocureResp = (resp) => {
    // if (!resp) {
    //   return ("")
    // }
    // return (
    //   <pre className="socure-resp custom-scroll">
    //     {resp ? JSON.stringify(resp, null, 2) : ""}
    //   </pre>
    // )
    if (resp?.kyc?.kyc_reason_code?.length > 0) {
      return resp?.kyc?.kyc_reason_code?.join(",")
    }
  }
  const KYCColumns = [
    {
      title: "Date",
      dataIndex: "created_timestamp",
      width: 200,
      render: (date) => moment(date).format(DATE_FORMAT.FOR_UI_DATE_TIME)
    },
    {
      title: "Decision",
      dataIndex: "decision",
    },
    {
      title: "Name",
      dataIndex: "",
      width: 200,
      render: (record) =>
        `${record?.first_name && record.first_name} ${record?.last_name && record.last_name
        }`,
    },
    {
      title: "Reference id",
      dataIndex: "reference_id",
    },
    {
      title: "KYC type",
      dataIndex: "kyc_type",
      width: 200
    },
  ];

  useEffect(() => {
    if (open) {
      KYCColumns.push({
        title: "KYC response",
        dataIndex: "socure_response",
        render: (socureResponse) => renderSocureResp(socureResponse),
      })
    }
  }, [open]);

  const renderKYCDetails = (
    open,
    setOpen,
    KYCColumns,
    data
  ) => {
    return (
      <Card bordered={false} className={!open ? "account-holder-cards" : ""}>
        <div className="d-flex justify-space-between">
          {!open &&
            <>
              <Typography
                variant="subhead-3"
                label="KYC"
                className="account-holder-card-header"
              />
              <div onClick={() => setOpen(true)}>
                <ExpandIcon />
              </div>
            </>
          }
        </div>
        <div className={!open ? "mt-30" : ""}>
          {data?.length ?
            <Table
              bordered
              columns={KYCColumns}
              dataSource={data}
              pagination={false}
              scroll={data?.length ? { x: 1200 } : undefined}
              className="compact-table activity-logs-table"
            /> :
            <Empty />
          }
        </div>
      </Card>
    )
  }
  return (
    <>
      {renderKYCDetails(
        false,
        setOpen,
        KYCColumns,
        data,

      )}
      <ExpandedModalView
        open={open}
        setOpen={setOpen}
        title={"KYC"}
        renderJSX={() => renderKYCDetails(
          open,
          setOpen,
          KYCColumns,
          data,
        )}
      />
    </>
  );
}

export { KYCSection };
