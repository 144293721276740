export const AlertFilled = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.35259 15.3767H14.6476C15.8026 15.3767 16.5226 14.1242 15.9451 13.1267L10.2976 3.36922C9.72009 2.37172 8.28009 2.37172 7.70259 3.36922L2.05509 13.1267C1.47759 14.1242 2.19759 15.3767 3.35259 15.3767ZM9 9.93652C8.5875 9.93652 8.25 9.59902 8.25 9.18652V7.68652C8.25 7.27402 8.5875 6.93652 9 6.93652C9.4125 6.93652 9.75 7.27402 9.75 7.68652V9.18652C9.75 9.59902 9.4125 9.93652 9 9.93652ZM9 12.9365C9.41421 12.9365 9.75 12.6007 9.75 12.1865C9.75 11.7723 9.41421 11.4365 9 11.4365C8.58579 11.4365 8.25 11.7723 8.25 12.1865C8.25 12.6007 8.58579 12.9365 9 12.9365Z"
      fill="#945605"
    />
  </svg>
);
