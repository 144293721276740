import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeSummary } from "../../../../redux/actions";

import { Divider } from "antd";
import { StaffListTable } from "./StaffListTable";
import { CumulativeContributions } from "./CumulativeContributions";
import { SpinLoader } from "../../../common/SpinLoader";
import { ReportChart } from "./Chart";
import { EmployeeSummaryChart } from "./EmployeeSummaryChart";

function EmployeeSummary({ hasHSAPlan }) {
  const dispatch = useDispatch();

  const [employeeSummary, setEmployeeSummary] = useState(null);
  const currentEmployer = useSelector(
    (state) => state.employer.currentEmployer?.data.employer_name,
  );

  useEffect(() => {
    setEmployeeSummary(null);

    dispatch(
      getEmployeeSummary((res) => {
        setEmployeeSummary(res.data);
      }),
    );
  }, [dispatch, currentEmployer]);

  return (
    <SpinLoader showContent={!!employeeSummary}>
      <div className="employer-summary">
        {hasHSAPlan &&
          <>
            <CumulativeContributions
              contributionYTDTotal={employeeSummary?.ytd_contribution_total}
            />
            <Divider />
          </>
        }
        <EmployeeSummaryChart data={employeeSummary?.employee_summary || []} />
      </div>
    </SpinLoader>
  );
}

export { EmployeeSummary };
