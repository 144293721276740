import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from "antd";
import {
  DatePicker,
  Radio,
  Select,
  TextField,
} from "../../../../../design-systems";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeRunoutOptions } from '../../../../../redux/actions';
import { mandatory, validateMinLength, validateMaxLength, validateMinContributionDCFSA, validateMaxContributionDCFSA } from "../../../../../utils/validations";
import moment from 'moment';
import { handleKeyDown, irsValueToYearMap } from '../../../../../utils';
import DatePicker2 from '../../../../../design-systems/DatePicker2';

export const DCFSAPlanDetails = ({ form, formData, setFormData }) => {
  const dispatch = useDispatch()
  const runOutOptions = useSelector((state) => state.employer?.runOutOptions);
  const [disableRadio, setDisableRadio] = useState(false);
  useEffect(() => {
    if (!runOutOptions.length) {
      dispatch(getEmployeeRunoutOptions())
    }
  }, [runOutOptions])

  const employeeRunOut = Form.useWatch("dcfsa_terminated_employees_run_out", form);
  useEffect(() => {
    let value;
    const selectedOptionValue = runOutOptions?.find(opt => opt.value === employeeRunOut);
    if (selectedOptionValue !== undefined) {
      value = selectedOptionValue?.label
    }
    if (employeeRunOut && value === "Days after the last day they were active") {
      setDisableRadio(true)
      form.setFieldsValue({
        "dcfsa_allow_claims": "no"
      })
    } 
    else {
      setDisableRadio(false)
    }
  }, [employeeRunOut])

  const customValidation = () => {
    let limit = 5000, startYear;
    const startDate = form.getFieldValue("start_date");
    if (startDate) {
      startYear = moment(startDate).year();
      limit = irsValueToYearMap[startYear]["DCFSA"]
    }
    const { dcfsa_employee_maximum_contribution } = form.getFieldsValue();
    if ((Number(dcfsa_employee_maximum_contribution)) > Number(limit)) {
      return Promise.reject(`Maximum IRS contribution exceeded. Employee contribution amount must be below or equal to ${limit}`);
    }
    return Promise.resolve('valid');
  };
  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <TextField
          label="Employee minimum contribution"
          placeholder="Enter minimum contribution"
          name="dcfsa_employee_minimum_contribution"
          rules={[
            mandatory({
              message: "Please enter a valid minimum contribution",
            }),
            validateMinContributionDCFSA(form)
          ]}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onInput={(e) => {
            const value = e.target.value;
            if (value === "") return;
            if (!/^\d*\.?\d{0,2}$/.test(value)) {
              e.target.value = parseFloat(value).toFixed(2);
            }
          }}
          step="1"
          min={0}
          prefix="$"
          type="number"
        />
      </Col>
      <Col xs={24} md={12}>
        <TextField
          label="Employee maximum contribution"
          placeholder="Enter maximum contribution"
          name="dcfsa_employee_maximum_contribution"
          rules={[
            mandatory({
              message: "Please enter a valid maximum contribution",
            }),
            validateMaxContributionDCFSA(form)
          ]}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onInput={(e) => {
            const value = e.target.value;
            if (value === "") return;
            if (!/^\d*\.?\d{0,2}$/.test(value)) {
              e.target.value = parseFloat(value).toFixed(2);
            }
          }}
          step="1"
          min={0}
          prefix="$"
          type="number"
        />
      </Col>
      {/* <Col xs={24} md={12}>
        <TextField
          label="Employer contribution amount"
          placeholder="Enter contribution amount"
          name="dcfsa_employee_contribution_amount"
          prefix="$"
          type="number"
          min={0}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onInput={(e) => {
            const value = e.target.value;
            if (value === "") return;
            if (!/^\d*\.?\d{0,2}$/.test(value)) {
              e.target.value = parseFloat(value).toFixed(2);
            }
          }}
          step="1"
        />
      </Col> */}
      {/* <Col xs={24} md={12}></Col> */}
      <Col xs={24} md={12}>
        <Select
          disabled={!runOutOptions?.length}
          label="Terminated employees run out"
          placeholder="Select an option"
          name="dcfsa_terminated_employees_run_out"
          options={runOutOptions}
          rules={[
            mandatory({
              message: "Please select a valid terminated employees run out",
            }),
          ]}
        />
      </Col>
      <Col xs={24} md={12}>
        <TextField
          label="Run out period"
          placeholder="Enter run out period"
          name="dcfsa_run_out_period"
          rules={[
            mandatory({
              message: "Please enter a valid run out period",
            }),
            validateMinLength(),
            validateMaxLength(),
          ]}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          suffix="Days"
        />
      </Col>
      {/* <Col xs={24} md={12}></Col> */}
      
      <Col xs={24} md={12}></Col>
      <Col xs={24} md={24}>
        <Radio
          label="Allow claim to be filed with a date of service after termination"
          rules={[
            mandatory({
              message: "Please select a valid option",
            }),
          ]}
          disabled={disableRadio}
          data={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          name="dcfsa_allow_claims"
        />
      </Col>
      <Col xs={24} md={12}>
        <Radio
          label="Grace period"
          rules={[
            mandatory({
              message: "Please select a valid option",
            }),
          ]}
          data={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          name="dcfsa_grace_period"
        />
      </Col>
      <Col xs={24} md={12} className="should-update-col">
        <Form.Item shouldUpdate className="should-update-form-item">
          {() =>
            form.getFieldValue("dcfsa_grace_period") === "yes" ? (
              <DatePicker2
                isClearable={true}
                id="dcfsa_grace_period_end_date"
                selected={formData["dcfsa_grace_period_end_date"]}
                name="dcfsa_grace_period_end_date"
                label="Date for ending grace period"
                rules={[
                  mandatory({ message: "Please choose valid grace period" }),
                ]}
                placeholder="MM/DD/YYYY"
                format={['MM/dd/yyyy', 'M/d/yyyy']}
                filterDate={(date) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  return date >= today;
                }}
                readOnly={false}
                onKeyDown={handleKeyDown}
                onChange={(date) => {
                  form.setFieldsValue({ 'dcfsa_grace_period_end_date': date });
                  setFormData(((prevData) => ({
                    ...prevData,
                    'dcfsa_grace_period_end_date': date
                  })))
                }}
              />
            ) : (
              <div className="nothing-here"></div>
            )
          }
        </Form.Item>
      </Col>
      <Col xs={24} md={24}>
        <Form.Item
          name="dcfsa"
          className="dummy-error should-update-form-item"
        ></Form.Item>
      </Col>
    </Row>
  )
};
