import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { langs } from "../../i18n";
import { useRouter } from "../../utils/router";
import { loginEmail } from "../../utils/validations";
import { getProvider } from "../../utils";
import { Auth as CAuth } from "aws-amplify";
import { clearFieldErrors } from "../../utils";
import { EMAIL_DOMAIN_NAME } from "../../utils/constants";
import {
  disableGlobalLoader,
  enableGlobalLoader,
  showErrorMessage,
} from "../../redux/actions";

import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";

function LoginWithSSO() {
  const dispatch = useDispatch();
  const history = useHistory();
  const router = useRouter();
  const [form] = useForm();

  useEffect(() => {
    if (router.location.state?.ssoImproperAccess) {
      const fieldsWidthAuthError = [
        { name: "email", errors: [router.location.state.ssoImproperAccess] },
      ];

      form.setFields(fieldsWidthAuthError);
      history.replace({ ...history.location, state: undefined });
    }
  }, [router.location.state?.ssoImproperAccess, history, form]);

  const federatedSignIn = async (provider) => {
    dispatch(enableGlobalLoader());
    await CAuth.federatedSignIn({ provider })
      .then(() => {
      })
      .catch((err) => {
        dispatch(disableGlobalLoader());
        console.log(err);
      });
  };

  const onFinish = ({ email }) => {
    const domain = email.split("@").pop();
    const provider = getProvider(domain);

    if (provider === EMAIL_DOMAIN_NAME.INVALID_DOMAIN) {
      dispatch(showErrorMessage(langs.messages.invalid_sso_domain));
    } else {
      federatedSignIn(provider);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("SSO AUTH Failed:", errorInfo);
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  return (
    <Form
      name="loginSSO"
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      layout="vertical"
      autoComplete="off"
      validateTrigger="onSubmit"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[loginEmail]}
        normalize={(value) => value.trim()}
      >
        <Input prefix={<i className="ni ni-email-83" />} />
      </Form.Item>

      <Form.Item shouldUpdate className="btn-block text-center">
        {({ getFieldsValue }) => {
          const { email } = getFieldsValue();
          const formIsComplete = !!email;
          return (
            <Button
              type="primary"
              htmlType="submit"
              className="button-primary w-100"
              disabled={!formIsComplete}
              style={{ textTransform: "none" }}
            >
              Continue with SSO
            </Button>
          );
        }}
      </Form.Item>
    </Form>
  );
}

export { LoginWithSSO };
