import React, { useState } from "react";
import { fixAmountFormat, getLocalTimezone } from "../../../../utils";

import { Card, Empty } from "antd";
import moment from "moment";
import { FullscreenOutlined } from "@ant-design/icons"
import { DATE_FORMAT } from "../../../../utils/constants";
import { ExpandIcon, Table, Typography } from "../../../../design-systems";
import { ExpandedModalView } from "./ExpandedViewModal";

function PendingACHSection({ data }) {
  const [open, setOpen] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit] = useState(10)


  const achColumns = [
    {
      title: "Txn timestamp",
      dataIndex: "create_timestamp",
      render: (date) => date ?
        `${moment
          .utc(date)
          .local()
          .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Description",
      dataIndex: "indicator",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
    },
    {
      title: "Transaction ID",
      dataIndex: "ach_txn_id",
    },
  ];

  const renderPendingACHDetails = (
    data,
    achColumns,
    isModalOpen
  ) => {
    return (
      <Card bordered={false} className={!isModalOpen ? "account-holder-cards" : ""}>
        <div className="d-flex justify-space-between">

          {!isModalOpen &&
            <>
              <Typography
                variant="subhead-3"
                label="Pending ACH"
                className="account-holder-card-header"
              />
              <div onClick={() => setOpen(true)}>
                <ExpandIcon />
              </div>
            </>
          }
        </div>
        <div className={!isModalOpen ? "mt-30": ""}>
          {
            data?.length ?
            <Table
              bordered
              scrollX={900}
              columns={achColumns}
              dataSource={data}
              pagination={isModalOpen ? {
                onChange: (page) => setCurrentPage(page),
                total: totalCount,
                current: currentPage,
                defaultCurrentPage: 1,
                defaultPageSize: limit,
              } : false}
              scroll={data?.length ? { x: 1200 } : undefined}
              tableLayout="auto"
              className="compact-table mb-15 activity-logs-table"
            /> : 
            <Empty />
          }
        </div>
      </Card>
    )
  }

  return (
    <>
      {renderPendingACHDetails(
        data,
        achColumns,
        false
      )}
      <ExpandedModalView
        open={open}
        setOpen={setOpen}
        title={"Pending ACH"}
        renderJSX={() => renderPendingACHDetails(
          data,
          achColumns,
          true
        )}
      />
    </>
  )
}

export { PendingACHSection };
