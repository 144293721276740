import React from "react";
import { Typography } from "../index";
import { Link as ReactLink } from "react-router-dom";

export const Link = ({ label, size, variant, disabled, url }) => {
  function getVariant(size) {
    if (size === "large") return "body-2";
    else if (size === "small") return "body-4";
  }
  function getClassName(variant, disabled) {
    if (disabled) return `link-disabled`;
    else return `link-${variant}`;
  }
  return (
    <>
      {disabled ? (
        <Typography
          className={getClassName(variant, disabled)}
          variant={getVariant(size)}
          label={label}
        />
      ) : (
        <ReactLink to={url}>
          <Typography
            className={getClassName(variant, disabled)}
            variant={getVariant(size)}
            label={label}
          />
        </ReactLink>
      )}
    </>
  );
};
