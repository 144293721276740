import { ApiErrors } from "../../utils";
import {
  disableDataLoader,
  disableLoader,
  enableDataLoader,
  enableLoader,
} from ".";
import {
  getBankByDivisionService,
  updateBankDivisionService,
} from "../../services";

export function getBankDetailsByDivisionId(id, callback) {
  const processName = "getBankDetailsByDivisionId";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getBankByDivisionService(id)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function updateBankDetails(reqData, callback) {
  return (dispatch) => {
    dispatch(enableLoader());
    updateBankDivisionService(reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableLoader());
      });
  };
}
