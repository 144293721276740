import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRouter } from "../../utils/router";
import { useDispatch } from "react-redux";
import {
  resetPassword,
  showSuccessMessage,
  forgotPassword,
} from "../../redux/actions";
import { otpValidation, validatePassword } from "../../utils/validations";
import { clearFieldErrors, stripLetters } from "../../utils";
import { langs } from "../../i18n";
import { useForm } from "antd/lib/form/Form";

import { Form, Input, Button, Progress } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

function ResetPasswordPage() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = useForm();

  const { username } = router.location.state;

  const [isDisabledResend, setIsDisabledResend] = useState(false);
  const [validateState, setValidateState] = useState({
    upperCase: false,
    lowerCase: false,
    number: false,
    special: false,
    lenOK: false,
    noWhite: false,
    progressColor: "danger",
    score: 0,
  });

  const onFinish = (values) => {
    values.username = username;

    if (validateState.allOK) {
      dispatch(
        resetPassword(values, (res) => {
          if (res instanceof Error) {
            const fieldsWidthOTPError = [
              { name: "OTP", errors: [langs.messages.invalid_otp] },
            ];
            form.setFields(fieldsWidthOTPError);
          } else {
            dispatch(showSuccessMessage(langs.messages.reset_password_success));
            router.push("/auth/login");
          }
        }),
      );
    } else {
      const fieldsWidthPasswordFormatError = [
        {
          name: "password",
          errors: [langs.messages.password_requirements_are_not_met],
        },
      ];
      form.setFields(fieldsWidthPasswordFormatError);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const resendVerificationCode = (e) => {
    e.preventDefault();
    if (isDisabledResend) return;

    setIsDisabledResend(true);
    dispatch(forgotPassword({ email: username }));
    setTimeout(function () {
      setIsDisabledResend(false);
    }, 180000);
  };

  const renderValidationIcon = (type) => {
    return validateState[type] ? <CheckOutlined /> : <CloseOutlined />;
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  return (
    <div className="auth-body-content reset-auth-body-content">
      <div className="forgot-form-container  reset-form-container">
        <div className="form-container">
          <h1 className="text-center">Reset Password</h1>
          <div className="form-body">
            <div className="text-muted mb-4">
              <small>
                We have sent a verification code to {username}
                <br /> Please enter the code below and proceed with setting a
                new password.
              </small>
            </div>
            <Form
              name="login"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              layout="vertical"
              autoComplete="off"
              validateTrigger="onSubmit"
            >
              <Form.Item
                label="Verification Code"
                name="OTP"
                type="number"
                rules={[otpValidation()]}
                onChange={(e) =>
                  form.setFieldsValue({
                    OTP: stripLetters(e.target.value),
                  })
                }
                normalize={(value) => value.trim()}
              >
                <Input
                  placeholder="Enter the code"
                  autoComplete="off"
                  prefix={<i className="ni ni-key-25" />}
                  maxLength={6}
                  autoFocus
                />
              </Form.Item>

              <Form.Item
                label="New Password"
                name="password"
                onChange={(e) => {
                  setValidateState(validatePassword(e.target.value));
                }}
                normalize={(value) => value.trim()}
                className="reset-new-password"
              >
                <Input.Password
                  autoComplete="new-password"
                  prefix={<i className="ni ni-lock-circle-open" />}
                  iconRender={(visible) =>
                    visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                  }
                />
              </Form.Item>
              <Progress
                strokeColor={
                  validateState.progressColor === "danger"
                    ? "#f5365c"
                    : validateState.progressColor === "yellow"
                    ? "#ffd600"
                    : "#2dce89"
                }
                step={6}
                percent={validateState.score * 2 * 10}
              />
              <div className="password-check">
                <div className="password-check-detail">
                  <div>{renderValidationIcon("lenOK")} 8 ≤ Length ≤ 32</div>
                  <div>{renderValidationIcon("number")} Number</div>
                  <div>{renderValidationIcon("noWhite")} No Whitespaces</div>
                </div>
                <div className="password-check-detail">
                  <div>{renderValidationIcon("upperCase")} Upper Case</div>
                  <div>{renderValidationIcon("lowerCase")} Lower Case</div>
                  <div>{renderValidationIcon("special")} Symbol</div>
                </div>
              </div>

              <Form.Item
                label="Confirm New Password"
                name="confirm_password"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        langs.messages.invalid_confirm_password,
                      );
                    },
                  }),
                ]}
                normalize={(value) => value.trim()}
              >
                <Input.Password
                  autoComplete="new-password"
                  prefix={<i className="ni ni-lock-circle-open" />}
                  iconRender={(visible) =>
                    visible ? <EyeInvisibleOutlined /> : <EyeOutlined />
                  }
                />
              </Form.Item>

              <Form.Item shouldUpdate className="btn-block text-center">
                {({ getFieldsValue }) => {
                  const { OTP, password, confirm_password } = getFieldsValue();
                  const formIsComplete =
                    !!OTP &&
                    OTP.length === 6 &&
                    !!password &&
                    !!confirm_password;
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button-primary"
                      disabled={!formIsComplete}
                    >
                      Reset Password
                    </Button>
                  );
                }}
              </Form.Item>

              <small className="resend-code">
                Didn't receive the code?{" "}
                <Link
                  to=""
                  onClick={resendVerificationCode}
                  disabled={isDisabledResend}
                >
                  Resend
                </Link>
              </small>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ResetPasswordPage };
