import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { TextField, DatePicker, Select } from "../../../../../design-systems";
import { required, specialValidationEmployeeId, whiteSpace } from "../../../../../utils/validations";
import { DATE_FORMAT } from '../../../../../utils/constants';
import moment from 'moment';
import { getAllDivisions, saveSelectedDivisionId } from '../../../../../redux/actions';
import { handleKeyDown } from '../../../../../utils';
import DatePicker2 from '../../../../../design-systems/DatePicker2';

export const EmploymentDetails = ({ form, formData, setFormData }) => {
    const dispatch = useDispatch();
    const [isDefault, setIsDefault] = useState(false)
    const [divisionList, setDivisionList] = useState([])
    useEffect(() => {
        dispatch(
            getAllDivisions((res) => {
                if (!(res instanceof Error)) {
                    if (res?.data[0]?.division_name === "default" && res?.data?.length === 1) {
                        setIsDefault(true);
                        form.setFieldValue("division", res?.data[0]?.division_id)
                        dispatch(saveSelectedDivisionId(res?.data[0]?.division_id));
                    }
                    setDivisionList(res.data);
                }
            }),
        );
    }, [dispatch, isDefault]);



    return (
        <Row gutter={16}>
            <Col xs={24} lg={8}>&nbsp;</Col>
            <Col xs={24} lg={16}>
                <div className="should-update-col division-layout">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <TextField
                                normalize={(value) => value.trimStart()}
                                label="Employee ID"
                                placeholder="Enter employee id"
                                name="employee_id"
                                maxLength={30}
                                rules={[required("Employee id"),
                                specialValidationEmployeeId(),
                                whiteSpace({ message: "Employee id should not contain only spaces." })
                                ]}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <DatePicker2
                                isClearable={true}
                                id="employement_start_Date"
                                selected={formData["employement_start_Date"]}
                                name="employement_start_Date"
                                rules={[required("Employement start date")]}
                                label="Employement start date"
                                placeholder="MM/DD/YYYY"
                                format={['MM/dd/yyyy', 'M/d/yyyy']}
                                readOnly={false}
                                onKeyDown={handleKeyDown}
                                onChange={(date) => {
                                    form.setFieldsValue({ 'employement_start_Date': date });
                                    setFormData(((prevData) =>({
                                        ...prevData,
                                        'employement_start_Date': date
                                    })))
                                }}
                            />
                        </Col>
                        {
                            !isDefault &&
                                Array.isArray(divisionList) &&
                                divisionList.length ?
                                <Col xs={24} md={12}>
                                    <Select
                                        label="Division"
                                        placeholder="Select division"
                                        name="division"
                                        options={divisionList?.map(({ division_name, division_id }) => ({
                                            label: division_name,
                                            value: division_id,
                                        }))}
                                        rules={[
                                            required("Division"),
                                        ]}
                                    />
                                </Col> : null
                        }
                    </Row>
                </div>
            </Col>
        </Row>
    )
};
