import axios from "axios";
import moment from "moment";
import { renderTitle, Typography } from "../design-systems";
import { langs } from "../i18n";
import { showErrorMessage } from "../redux/actions";
import { renderClaimStatus } from "./claims";
import { capitalizeFirstLetter, fixAmountFormat } from "./common";
import { DATE_FORMAT, ROLE_CONSTANT } from "./constants";

const formatDate = (date) => {
  return date ? moment(date).format(DATE_FORMAT.FOR_UI_FIRST_MM) : null
}

export const downloadReport = (dispatch, reportURL) => {
  axios
    .get(reportURL, { responseType: "blob" })
    .then((res) => {
      const a = window.document.createElement("a");

      a.href = reportURL;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch((err) => {
      dispatch(showErrorMessage(langs.messages.report_not_available));
    });
};

export const getExtraCreditReportColumns = () => {
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Plan", "plan_name"),
      key: "plan_name",
      dataIndex: "plan_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Total elections", "total_elections"),
      key: "total_elections",
      dataIndex: "total_elections",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Total paid YTD", "total_paid_ytd"),
      key: "total_paid_ytd",
      dataIndex: "total_paid_ytd",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Enrolled members", "enrolled_members"),
      key: "enrolled_members",
      dataIndex: "enrolled_members",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Percent paid out", "percent_paid_out"),
      key: "percent_paid_out",
      dataIndex: "percent_paid_out",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Graph", "graph"),
      key: "graph",
      dataIndex: "graph",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
  ]
}

export const getClaimsPaymentReportColumns = (data) => {
  // const shouldHideDivisionColumn = data.every(item => item.division === 'default');
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Claim ID", "claim_id"),
      key: "claim_id",
      dataIndex: "claim_id",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employee ID", "emp_id"),
      key: "emp_id",
      dataIndex: "emp_id",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "First name", "first_name"),
      key: "first_name",
      dataIndex: "first_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Last name", "last_name"),
      key: "last_name",
      dataIndex: "last_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    // {
    //   title: (titleProps) =>
    //     renderTitle(titleProps, "Division", "division"),
    //   key: "division",
    //   className: shouldHideDivisionColumn ? "d-none" : "",
    //   dataIndex: "division",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: false
    // },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Plan", "plan_name"),
      key: "plan_name",
      dataIndex: "plan_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Date processed", "processed_date"),
      key: "processed_date",
      dataIndex: "processed_date",
      render: (date) => formatDate(date),
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Date of service", "service_date"),
      key: "service_date",
      dataIndex: "service_date",
      render: (date) => formatDate(date),
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Claim status", "claim_status"),
      key: "claim_status",
      dataIndex: "claim_status",
      sortDirections: ["descend", "ascend"],
      render: (status) => status ? renderClaimStatus({ status }) : null,
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Payment type", "payment_type"),
      key: "payment_type",
      dataIndex: "payment_type",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      render: (title) => capitalizeFirstLetter(title)
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Patient name", "patient_name"),
      key: "patient_name",
      dataIndex: "patient_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Amount requested", "amount_requested"),
      key: "amount_requested",
      dataIndex: "amount_requested",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Amount approved", "approved_amount"),
      key: "approved_amount",
      dataIndex: "approved_amount",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Amount denied", "amount_denied"),
      key: "amount_denied",
      dataIndex: "amount_denied",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
    }
  ]
}

export const getFundingLedgerColumns = () => {
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Account", "plan_name"),
      key: "plan_name",
      dataIndex: "plan_name",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      render: (data) => {
        switch (data) {
          case "EOA_FSA":
            return "FSA";
          case "EOA_LFSA":
            return "LFSA";
          case "EOA_DCFSA":
            return "DCFSA";
          case "EOA_XFSA":
            return "ALL";
          default:
            return data;
        }
      }
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Description", "description"),
      key: "description",
      dataIndex: "description",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Transaction amount", "transaction_amount"),
      key: "transaction_amount",
      dataIndex: "transaction_amount",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Date processed", "date_processed"),
      key: "date_processed",
      dataIndex: "date_processed",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      render: (date) => formatDate(date)
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Reference", "reference"),
      key: "reference",
      dataIndex: "reference",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
  ]
}

export const planNameMap = {
  EOA_FSA: "FSA",
  EOA_HSA: "HSA",
  EOA_LFSA: "LFSA",
  EOA_DCFSA: "DCFSA"
}

export const getFundingSummaryReportColumns = () => {
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Plan", "plan_name"),
      key: "plan_name",
      dataIndex: "plan_name",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      render: (plan) => planNameMap[plan]
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Total elections", "total_elections"),
      key: "total_elections",
      dataIndex: "total_elections",
      sortDirections: ["descend", "ascend"],
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      sorter: false,
      align: "right",
      className: "align-amount-fields",
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Total claims paid", "total_claims_paid"),
      key: "total_claims_paid",
      dataIndex: "total_claims_paid",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "To be spent ", "to_be_spent"),
      key: "to_be_spent",
      dataIndex: "to_be_spent",
      sortDirections: ["descend", "ascend"],
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      sorter: false,
      align: "right",
      className: "align-amount-fields",
    },
  ]
}

export const getClaimsPaybackReport = (data) => {
  const shouldHideDivisionColumn = data.every(item => item.division === 'default');
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Claim ID", "claim_id"),
      key: "claim_id",
      dataIndex: "claim_id",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employee ID", "emp_id"),
      key: "emp_id",
      dataIndex: "emp_id",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "First name", "first_name"),
      key: "first_name",
      dataIndex: "first_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Last name", "last_name"),
      key: "last_name",
      dataIndex: "last_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    // {
    //   title: (titleProps) =>
    //     renderTitle(titleProps, "Division", "division"),
    //   key: "division",
    //   className: shouldHideDivisionColumn ? "d-none" : "",
    //   dataIndex: "division",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: false
    // },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Plan", "plan_name"),
      key: "plan_name",
      dataIndex: "plan_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Date processed", "processed_date"),
      key: "processed_date",
      dataIndex: "processed_date",
      render: (date) => formatDate(date),
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Days outstanding", "days_outstanding"),
      key: "days_outstanding",
      dataIndex: "days_outstanding",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Payback status", "payback_status"),
      key: "payback_status",
      dataIndex: "payback_status",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Amount requested", "amount_requested"),
      key: "amount_requested",
      dataIndex: "amount_requested",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Amount denied", "amount_denied"),
      key: "amount_denied",
      dataIndex: "amount_denied",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
    },
  ]
}

export const getPlanStatusSummaryReportColumns = (data) => {
  // const shouldHideDivisionColumn = data.every(item => item.division === 'default');
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employee ID", "emp_id"),
      key: "emp_id",
      dataIndex: "emp_id",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "First name", "first_name"),
      key: "first_name",
      dataIndex: "first_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Last name", "last_name"),
      key: "last_name",
      dataIndex: "last_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    // {
    //   title: (titleProps) =>
    //     renderTitle(titleProps, "Division", "division"),
    //   key: "division",
    //   className: shouldHideDivisionColumn ? "d-none" : "",
    //   dataIndex: "division",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: false
    // },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Plan", "plan_name"),
      key: "plan_name",
      dataIndex: "plan_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Total elected", "total_elected"),
      key: "total_elected",
      dataIndex: "total_elected",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      align: "right",
      className: "align-amount-fields",
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Total paid", "total_paid"),
      key: "total_paid",
      dataIndex: "total_paid",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      align: "right",
      className: "align-amount-fields",
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Claims pending", "claims_pending"),
      key: "claims_pending",
      dataIndex: "claims_pending",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      align: "right",
      className: "align-amount-fields",
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Available balance", "available_balance"),
      key: "available_balance",
      dataIndex: "available_balance",
      sortDirections: ["descend", "ascend"],
      sorter: false,
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      align: "right",
      className: "align-amount-fields",
    },
  ]
}

export const getEnrollmentReportColumns = () => {
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employee ID", "employee_id"),
      key: "employee_id",
      dataIndex: "employee_id",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "First name", "first_name"),
      key: "first_name",
      dataIndex: "first_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Last name", "last_name"),
      key: "last_name",
      dataIndex: "last_name",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employment status", "employment_status"),
      key: "employment_status",
      dataIndex: "employment_status",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Account Holder status", "account_holder_status"),
      key: "account_holder_status",
      dataIndex: "account_holder_status",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Plan", "plan"),
      key: "plan",
      dataIndex: "plan",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Effective date", "effective_date"),
      key: "effective_date",
      dataIndex: "effective_date",
      render: (date) => formatDate(date),
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "End date", "end_date"),
      key: "end_date",
      dataIndex: "end_date",
      render: (date) => formatDate(date),
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Account status", "account_status"),
      key: "account_status",
      dataIndex: "account_status",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "KYC status", "kyc_status"),
      key: "kyc_status",
      dataIndex: "kyc_status",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Coverage level", "coverage_level"),
      key: "coverage_level",
      dataIndex: "coverage_level",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Division", "division"),
      key: "division",
      dataIndex: "division",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Email", "email_address"),
      key: "email_address",
      dataIndex: "email_address",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Street address 1", "street_address_1"),
      key: "street_address_1",
      dataIndex: "street_address_1",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Street Address 2", "street_address_2"),
      key: "street_address_2",
      dataIndex: "street_address_2",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "City", "city"),
      key: "city",
      dataIndex: "city",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "State", "state"),
      key: "state",
      dataIndex: "state",
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Zip code", "zip_code"),
      key: "zip_code",
      dataIndex: "zip_code",
      sortDirections: ["descend", "ascend"],
      sorter: false
    }
  ]
}




export const getHSAHeldReportColumns = () => {
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employee ID", "employee_id"),
      key: "employee_id",
      dataIndex: "employee_id",
      sortDirections: ["descend", "ascend"],
      sorter: false,
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "First name", "first_name"),
      key: "first_name",
      dataIndex: "first_name",
      sortDirections: ["descend", "ascend"],
      sorter: false,
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Last name", "last_name"),
      key: "last_name",
      dataIndex: "last_name",
      sortDirections: ["descend", "ascend"],
      sorter: false,
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Payroll date", "payroll_date"),
      key: "payroll_date",
      dataIndex: "payroll_date",
      render: (date) => formatDate(date),
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employee payroll contributions", "employee_payroll_contribution"),
      key: "employee_payroll_contribution",
      dataIndex: "employee_payroll_contribution",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employer contributions", "employer_payroll_contribution"),
      key: "employer_payroll_contribution",
      dataIndex: "employer_payroll_contribution",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
    }, {
      title: (titleProps) =>
        renderTitle(titleProps, "Total contributions", "total_payroll_contribution"),
      key: "total_payroll_contribution",
      dataIndex: "total_payroll_contribution",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Reason", "reason"),
      key: "reason",
      dataIndex: "reason",
      sortDirections: ["descend", "ascend"],
      sorter: false,
    }

  ]
}

export const getHSAPersonalContributionDetailReportColumns = () => {
  return [
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employee ID", "employee_id"),
      key: "employee_id",
      dataIndex: "employee_id",
      sortDirections: ["descend", "ascend"],
      sorter: false,
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "First name", "first_name"),
      key: "first_name",
      dataIndex: "first_name",
      sortDirections: ["descend", "ascend"],
      sorter: false,
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Last name", "last_name"),
      key: "last_name",
      dataIndex: "last_name",
      sortDirections: ["descend", "ascend"],
      sorter: false,
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Contribution date", "contribution_date"),
      key: "contribution_date",
      dataIndex: "contribution_date",
      render: (date) => formatDate(date),
      sortDirections: ["descend", "ascend"],
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Contribution amount", "contribution_amount"),
      key: "contribution_amount",
      dataIndex: "contribution_amount",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
    }
  ];
}

export const getHSAContributionDetailReportColumns = () => {
  const cols = getHSAHeldReportColumns();
  cols.pop();
  return cols;
}


export const getHSAContributionSummaryReportColumns = (role) => {
  const cols = getHSAContributionDetailReportColumns();
  cols.splice(3, 1);
  if (role === ROLE_CONSTANT.ZENDA_ADMIN) {
    cols.push({
      title: (titleProps) =>
        renderTitle(titleProps, "Total personal contributions", "total_personal_contribution"),
      key: "total_personal_contribution",
      dataIndex: "total_personal_contribution",
      render: (amount) => (
        <Typography
          variant=""
          className="amount-cell"
          label={fixAmountFormat(amount)}
        />
      ),
      sortDirections: ["descend", "ascend"],
      sorter: false,
      align: "right",
      className: "align-amount-fields",
    })
  }
  cols.forEach(col => {
    if (col.key === 'total_payroll_contribution') {
      col.title = (titleProps) =>
        renderTitle(titleProps, "Total payroll and employer contributions", "total_payroll_contribution");
    }
  });
  return cols;
}


export const getReportName = {
  "enrollment": "Enrollment",
  "contributions": "HSA contributions",
  "claims_payments": "Claim payments",
  "claims_payback": "Claim payback",
  "funding_ledger": "FSA employer account detail",
  "employee_ledger": "FSA employer account detail",
  "plan_summary": "FSA employee plan status",
  "funding_summary": "FSA employer plan summary",
  "hsa/contributions": "Contribution monthly",
  "hsa/contribution_summary": "Contribution Summary",
  "hsa/contribution_details": "Contribution Details",
  "hsa/contributions_held": "Contribution Held",
  "hsa/personal_contribution_details": "Personal Contribution Details",
  "fsa/claims_payments": "Claim payments",
  "fsa/claims_payback": "Claim payback",
  "fsa/funding_ledger": "Employer account detail",
  "fsa/plan_summary": "Employee plan status",
  "fsa/funding_summary": "Employer plan summary"
}

export const getReportDescription = {
  "claims_payments": "This report shows high-level details of each claim paid, including card transactions that have yet to be fully substantiated.",
  "claims_payback": "This report shows the status of all debit card transactions that either must be repaid or was already repaid.",
  "funding_ledger": "This report is a detailed statement of all transactions in the FSA employer account.",
  "plan_summary": "This report shows the current status of each employee’s plans, including election amount, claims paid, claims pending and available balance.",
  "funding_summary": "This report has one row for each plan in each plan year comparing the total elections to the total claims paid to date."
}

export const getReportsAPILink = {
  "hsa/contribution_summary": "hsa/contribution-summary",
  "hsa/contribution_details": "hsa/contribution-details",
  "hsa/contributions_held": "hsa/contributions-held",
  "hsa/personal_contribution_details": "hsa/personal-contribution-details",
  "fsa/claims_payments": "claims_payments",
  "fsa/claims_payback": "claims_payback",
  "fsa/funding_ledger": "funding_ledger",
  "fsa/plan_summary": "plan_summary",
  "fsa/funding_summary": "funding_summary",
  "enrollment": "enrollment/employee"
}

export const getReportsDownloadAPILink = {
  "hsa/contribution_summary": "contribution_summary",
  "hsa/contribution_details": "contribution_details",
  "hsa/contributions_held": "contributions_held",
  "hsa/personal_contribution_details": "personal_contribution_details",
  "fsa/claims_payments": "claims_payments",
  "fsa/claims_payback": "claims_payback",
  "fsa/funding_ledger": "funding_ledger",
  "fsa/plan_summary": "plan_summary",
  "fsa/funding_summary": "funding_summary",
  "enrollment": "employee_enrollments"
}