import { Col, Row } from "antd";
import moment from "moment";
import { PersonalInformation } from "./PersonalInformation";
import { EmploymentInformation } from "./EmploymentInformation";
import { PlanInformation } from "./PlanInformation";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const EmployeeDetails = ({ planInfo, record, getEmployeeData, employee }) => {

  const { currentEmployerDetails, employerPlanInfo } = useSelector(
    (state) => state.employer,
  );
  const { current_plans, upcoming_plans, previous_plans } = currentEmployerDetails['plan-info'] || {};
  const { employeePlans } = useSelector((state) => state.employees);

  const employeeFSAPlans = useMemo(() => {

    const plans = [];

    const findPlan = (plans) => {
      if (plans?.length) {
        const current_plan = plans[0];
        const employerFSAPlans = current_plan.plan_information.selected_plans.filter(plan => plan !== 'HSA');
        if (employerFSAPlans.length < 1  || (current_plan.admin_action?.length > 0 && current_plan.admin_action[0].action === "EDIT_PLAN")) {
          return null;
        }
        const plan_year = moment(current_plan.plan_information.plan_year.plan_start_date).format('YYYY');
        const planConfigId = current_plan.plan_information.plan_id;
        let planFound = false;
        let notEnrolledPlans = [];
        employeePlans?.FSA?.forEach(plan => {
          if (plan_year === plan.plan_year && plan.plan_config_status !== "INACTIVE") {
            const employeeFSAPlans = plan.plan_data.map(data => data.plan_type);
            notEnrolledPlans = employerFSAPlans.filter(plan => !employeeFSAPlans.includes(plan));
            planFound = true;
          }
        });
        if (!planFound) {
          notEnrolledPlans = employerFSAPlans;
        }
        return {
          plan_year,
          notEnrolledPlans,
          planFound,
          employerPlanInfo: current_plan,
          configId: planConfigId
        }
      }
    }
    const current_plan = findPlan(current_plans);
    if(current_plan) {
      current_plan.status = "ACTIVE"
      plans.push(current_plan);
    }
    const upcoming_plan = findPlan(upcoming_plans);
    if(upcoming_plan) {
      upcoming_plan.status = "UPCOMING"
      plans.push(upcoming_plan)
    }
    employeePlans?.FSA?.forEach(plan => {
      if (plan.plan_config_status === "INACTIVE") {
        plans.push({
          plan_year: plan.plan_year,
          notEnrolledPlans: [],
          planFound: true,
          configId: plan.plan_config_id,
          status: "INACTIVE"
        })
      }
    });
    return plans;
  }, [currentEmployerDetails, employeePlans]);

  const isHSAInEmployerPlans = useMemo(() => {

    let minDate = moment().add(100, "year").toDate();
    let maxDate = moment().subtract(100, "year").toDate();
    employerPlanInfo?.forEach((plan) => {
      if (plan?.plans_enrolled?.includes("HSA")) {
        if (minDate > new Date(plan.plan_start_date)) {
          minDate = moment(plan.plan_start_date).toDate()
        }
        if (maxDate < new Date(plan.plan_end_date)) {
          maxDate = moment(plan.plan_end_date).toDate()
        }
      }
    })

    const checkPlan = (plans) => plans?.find(plan => plan.plan_information.selected_plans.includes('HSA'));
    let plan = checkPlan(current_plans) || checkPlan(upcoming_plans) || checkPlan(previous_plans)
    plan = plan || null
    plan = JSON.parse(JSON.stringify(plan))
    if(plan && plan.plan_information && plan.plan_information.plan_year) {
      plan.plan_information.plan_year.plan_start_date = minDate;
      plan.plan_information.plan_year.plan_end_date = maxDate;
    }
    return plan;
  }, [current_plans, upcoming_plans, previous_plans]);
  const isHSAInEmployeePlans = Object.keys(employeePlans).some(plan => plan === 'HSA' && employeePlans[plan] !== null);

  const showAddHSAButton = isHSAInEmployerPlans && !isHSAInEmployeePlans;

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={12} lg={12}>
        <PersonalInformation getEmployeeData={getEmployeeData} />
      </Col>
      <Col xs={24} md={12} lg={12}>
        <EmploymentInformation getEmployeeData={getEmployeeData} />
      </Col>
      {
        employeePlans && employeePlans.HSA && Object.keys(employeePlans.HSA).length &&
        <Col xs={24}>
          <PlanInformation planName={"HSA"} planYear={null} planInfo={planInfo} record={record} getEmployeeData={getEmployeeData} employee={employee} employerPlan={isHSAInEmployerPlans} />
        </Col>
      }
      {showAddHSAButton && (
        <Col xs={24}>
          <PlanInformation hideTable={true} planName={"HSA"} planYear={null} planInfo={planInfo} record={record} getEmployeeData={getEmployeeData} employee={employee} />
        </Col>
      )}
      {
        (employeeFSAPlans && employeeFSAPlans.length) ?
          employeeFSAPlans?.map((plan, index) => (
            <Col xs={24} key={index}>
              <PlanInformation planName={"FSA"} hideTable={!plan?.planFound} notEnrolledPlans={plan?.notEnrolledPlans} planYear={plan?.plan_year} planInfo={planInfo} record={record} getEmployeeData={getEmployeeData} employee={employee} employerPlan={plan?.employerPlanInfo} planConfigId={plan?.configId} planStatus={plan?.status} />
            </Col>
          ))
          : null
      }
    </Row>
  );
};
