import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  getContributionReportLink,
  getContributionReportsList,
  showErrorMessage,
} from "../../../redux/actions";
import { langs } from "../../../i18n";
import { downloadReport } from "../../../utils";

import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { Card, Col, Row, Table } from "antd";
import useShowSecondSection from "../../../hooks/useShowSection";
import { Typography, renderTitle } from "../../../design-systems";

function Reports() {
  const dispatch = useDispatch();

  const [reports, setReports] = useState(null);

  useEffect(() => {
    dispatch(
      getContributionReportsList((res) => {
        setReports(res.monthly);
      }),
    );

    // eslint-disable-next-line
  }, []);

  useShowSecondSection();

  const getReportLink = (reportID) => {
    dispatch(
      getContributionReportLink(reportID, (res) => {
        const reportURL = res?.url;

        if (reportURL) {
          downloadReport(dispatch, reportURL);
        } else {
          dispatch(
            showErrorMessage(langs.messages.error_loading_the_report_link),
          );
        }
      }),
    );
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: (titleProps) => renderTitle(titleProps, "Reports", "date"),
      dataIndex: "",
      key: "date",
      render: (record) => moment(record.end_date).format("MMMM, YYYY"),
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.end_date) - new Date(b.end_date),
    },
  ];

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card
            title=""
            bordered={false}
            className="data-grid employee-details-wrapper"
          >
            <Typography
              label="Month-end contribution reports"
              variant="subhead-1"
              className="employee-listing-title "
            />
            <SpinLoader showContent={!!reports}>
              <Table
                columns={columns}
                dataSource={reports}
                onChange={onChange}
                bordered
                rowClassName={"pointer"}
                className="compact-table reports-table"
                pagination={{ hideOnSinglePage: true }}
                onRow={(record) => ({
                  onClick: () => getReportLink(record.report_id),
                })}
              />
            </SpinLoader>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { Reports };
