import React, { useEffect, useCallback } from "react";
import { Row, Col, Form, Alert, Radio, Spin } from "antd"
import { Select, Typography } from "../../../../design-systems";
import { required } from "../../../../utils/validations";
import { fixAmountFormat } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { evaluateExpenseDraft } from "../../../../redux/actions/expenses.actions";
import { useState } from "react";

export const ReimbursmentSummary = ({ form, spinLoader, setSpinLoader, displayHSA, expense_draft_id, id }) => {
    const dispatch = useDispatch();
    const { evaluateData } = useSelector((state) => state.expenses);
    const {
        amount_to_reimburse,
        health_expense_details,
        primary_default_account_type,
        primary_from,
        reimburse_to_options,
        display_hsa,
        secondary_from,
        travel_expense_details,
        HSA
    } = evaluateData || {};

    useEffect(() => {
        if (primary_default_account_type) {
            form.setFieldsValue({ "from": primary_default_account_type })
        }
        if (display_hsa) {
            form.setFieldsValue({ "hsa_from": "HSA" })
        }

    }, [primary_default_account_type, display_hsa])

    useEffect(() => {
        if (!secondary_from?.length) return;
        if (secondary_from?.length === 1) {
            form.setFieldsValue({ secondary_from: secondaryFromOptions()[0]?.value })
        }
    }, [secondary_from])

    const toOptions = useCallback(() => {
        const updatedOptions = reimburse_to_options?.map(opt => {
            return { value: opt.key, label: opt.text }
        })
        return updatedOptions
    }, [reimburse_to_options])

    useEffect(() => {
        if (!toOptions()?.length || !reimburse_to_options.length) return;

        if (toOptions()?.length === 1) {
            form.setFieldsValue({ to: toOptions()[0]?.value })
        } else if (toOptions()?.length > 1) {
            const indexExternal = toOptions()?.findIndex(opt => opt.value === "EXTERNAL");
            const indexCheck = toOptions()?.findIndex(opt => opt.value === "CHECK");
            if (indexExternal !== -1 && indexCheck !== -1) {
                form.setFieldsValue({ to: toOptions()[indexExternal]?.value });
            } else if (indexExternal !== -1) {
                form.setFieldsValue({ to: toOptions()[indexExternal]?.value });
            } else if (indexCheck !== -1) {
                form.setFieldsValue({ to: toOptions()[indexCheck]?.value });
            }
        }

    }, [reimburse_to_options, toOptions])

    const {
        mileage,
        cost_per_mile,
        other_travel_expense_amount
    } = travel_expense_details || {}

    const getArrayFromObj = (obj) => {
        const dataArray = obj && Object.keys(obj).map(key => {
            const account = obj[key];
            return {
                value: key,
                label: <div className="d-flex justify-space-between">
                    <div>{account?.account}</div>
                    <div>{`Balance ${fixAmountFormat(account?.current_balance)}`}</div>
                </div>
            };
        });
        return dataArray;
    }

    const fromAcc = Form.useWatch('from', form);
    const hsaFrom = Form.useWatch('hsa_from', form);
    const secondaryFromAcc = Form.useWatch('secondary_from', form);

    const getSelectAccValues = (key, selectedValue) => {
        if(!display_hsa) {
            if ((!fromAcc || !primary_from || !primary_from[selectedValue])) {
                return "";
            }
        }
        const selectedAccData = selectedValue?.toLowerCase() === "hsa" ? HSA : primary_from[selectedValue];

        if (!selectedAccData.hasOwnProperty(key)) {
            return "";
        }

        return selectedAccData[key];
    };

    const getHSAFromValues = () => {
        const data = HSA ?? {};
        if (!Object.keys(data).length) {
            return []
        }
        return [{
            value: data.account,
            label: <div className="d-flex justify-space-between">
                <div>{data.account}</div>
                <div>{`Balance ${fixAmountFormat(data.current_balance)}`}</div>
            </div>
        }]
    }

    const getRemainingHSAAmount = () => {
        const selectedFrom = (primary_from && primary_from[fromAcc]) ?? {};
        return fixAmountFormat((amount_to_reimburse - selectedFrom?.current_balance) ?? 0)
    }

    const evaluateApi = () => {
        setSpinLoader(true)
        const apiData = {
            expense_draft_id,
            evaluation_type: displayHSA === "yes" ? "DEFAULT" : "OPT_OUT_HSA"
        };
        dispatch(evaluateExpenseDraft(id, apiData, (res) => {
            if (!(res instanceof Error)) {
                setSpinLoader(false)
                form.setFieldsValue({ display_hsa_radio: "yes" })
            } else {
                setSpinLoader(false)
            }
        }));
    }

    useEffect(() => {
        if (displayHSA === 'no') {
            evaluateApi()
        }
    }, [displayHSA])

    const secondaryFromOptions = useCallback(() => {
        if (!secondary_from?.length) return [];
        const result = secondary_from.map(opt => ({
            value: opt.account,
            label: <div className="d-flex justify-space-between">
                <div>{opt.account}</div>
                <div>{`Balance ${fixAmountFormat(opt.current_balance)}`}</div>
            </div>
        }))
        return result;
    }, [secondary_from])

    const RenderAccountInfo = ({ field, selectedValue, data }) => {
        if (!selectedValue || !field || !data) {
            return null;
        }

        let valueToDisplay;
        if (Array.isArray(data)) {
            const index = data?.length && data.findIndex(opt => (opt.account === selectedValue || opt.value === selectedValue));
            if (index !== -1) {
                valueToDisplay = field === "balance" ? data[index]?.balance_post_debit : data[index]?.comment;
            }
        } else if (typeof data === 'object') {
            valueToDisplay = field === "balance" ? getSelectAccValues('balance_post_debit', selectedValue) : getSelectAccValues('comment', selectedValue);
        }
        if (valueToDisplay !== null && valueToDisplay !== undefined) {
            if (field === "balance") {
                return (
                    <div className={valueToDisplay === 0 ? "balance_zero" : ""}>
                        {`Remaining balance after submission: ${fixAmountFormat(valueToDisplay)}`}
                    </div>
                );
            } else if (field === 'alert' && valueToDisplay) {
                return <Alert className="upload-doc-warning h-auto" message={<div className="text-link-small">{valueToDisplay}</div>} type="warning" showIcon closable={false} />;
            }
        }

        return null;
    };

    return (
        <>
            <Typography
                label="Reimbursement Breakdown"
                variant="subhead-2"
                className="employee-listing-title mb-20"
            />
            {
                spinLoader ?
                    <div className="d-flex justify-content-center align-items-center m-auto">
                        <Spin
                            className={`page-loader`}
                            style={{ height: 400, display: "flex", margin: "auto", alignItems: "center" }}
                            size="large"
                            spinning={spinLoader}
                        >

                        </Spin>
                    </div>
                    :

                    <>
                        <Row>
                            <Col md={12}>
                                <div className="d-flex justify-space-between ">
                                    <p>{health_expense_details?.expense_type} expenses</p>
                                    <p>{fixAmountFormat(health_expense_details?.amount)}</p>
                                </div>
                                {
                                    health_expense_details?.expense_type !== "Dependent Care" &&
                                    <>
                                        <div className="d-flex justify-space-between">
                                            <p>Travel – {mileage} miles @ {fixAmountFormat(cost_per_mile)}</p>
                                            <p>{fixAmountFormat(mileage * cost_per_mile)}</p>
                                        </div>
                                        <div className="d-flex justify-space-between">
                                            <p>Other Travel Expenses</p>
                                            <p>{fixAmountFormat(other_travel_expense_amount)}</p>
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                        <hr className="mb-15" />
                        <Row>
                            <Col md={12}>
                                <div className="d-flex justify-space-between">
                                    <p className="text-cta">Amount to Reimburse</p>
                                    <p className="text-cta">{fixAmountFormat(amount_to_reimburse)}</p>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <div className="mt-40"></div>
                        <Row gutter={[16]}>
                            <Col xs={24} md={12}>
                                <div className="from-expense-account-wrapper">
                                    <Select
                                        className="expense-type"
                                        label="From"
                                        allowClear={false}
                                        defaultValue={primary_default_account_type}
                                        placeholder="Select expense type"
                                        name="from"
                                        disabled={getArrayFromObj(primary_from)?.length === 1}
                                        options={getArrayFromObj(primary_from)}
                                        rules={[
                                            required("Field"),
                                        ]}
                                    />
                                </div>
                                <RenderAccountInfo field={"balance"} selectedValue={fromAcc} data={primary_from} />
                                <RenderAccountInfo field={"alert"} selectedValue={fromAcc} data={primary_from} />
                                {
                                    display_hsa &&
                                    <>
                                        <Form.Item name="display_hsa_radio" label={<div className="text-link-small">{`Would you like the remaining ${getRemainingHSAAmount()} to get reimbursed from your HSA?`}</div>}>
                                            <Radio.Group defaultValue={"yes"}>
                                                <Radio value="yes">Yes</Radio>
                                                <Radio value="no">No</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <div className="from-expense-account-wrapper">
                                            <Select
                                                className="expense-type"
                                                disabled={getHSAFromValues()?.length === 1}
                                                placeholder="Select expense type"
                                                name="hsa_from"
                                                options={getHSAFromValues()}
                                                allowClear={false}
                                                rules={[
                                                    required("Field"),
                                                ]}
                                            />
                                        </div>
                                        <RenderAccountInfo field={"balance"} selectedValue={hsaFrom} data={primary_from} />
                                        {/* <RenderAccountInfo field={"alert"} selectedValue={hsaFrom} data={primary_from} /> */}
                                    </>
                                }
                                {
                                    secondary_from?.length > 0 ?
                                        <>
                                            <div className="from-expense-account-wrapper">
                                                <Select
                                                    className="expense-type"
                                                    defaultValue={secondaryFromOptions()?.length === 1 ? secondaryFromOptions()[0].value : ""}
                                                    disabled={secondaryFromOptions()?.length === 1}
                                                    placeholder="From"
                                                    name="secondary_from"
                                                    options={secondaryFromOptions()}
                                                    allowClear={false}
                                                    rules={[
                                                        required("Field")
                                                    ]}
                                                />
                                            </div>
                                            <RenderAccountInfo field={"balance"} selectedValue={secondaryFromAcc} data={secondary_from} />
                                            <RenderAccountInfo field={"alert"} selectedValue={secondaryFromAcc} data={secondary_from} />
                                        </> :
                                        null
                                }
                            </Col>
                            <div className="section-gap"></div>
                            <Col xs={24} md={12}>
                                <Select
                                    className="expense-type"
                                    // disabled={isPlanYearNull}
                                    label="To"
                                    placeholder="Select expense type"
                                    name="to"
                                    allowClear={false}
                                    disabled={toOptions()?.length === 1}
                                    options={toOptions()}
                                    rules={[
                                        required("Field"),
                                    ]}
                                />
                            </Col>
                        </Row>
                    </>
            }

        </>
    )
} 