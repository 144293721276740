import { Tag } from "antd";
import { CLAIM_STATUS, DATE_FORMAT } from "./constants";
import { Typography, renderTitle } from "../design-systems";
import moment from "moment";
import { fixAmountFormat, getLocalTimezone } from "./common";

export const renderClaimStatus = ({ status, record }) => {
  let className = "";

  switch (status) {
    case "AUTO_SUBSTANTIATED":
      className = "awaiting_review";
      break;
    case "NEEDS_MORE_INFO":
      className = "need_more_info";
      break;
    case "PARTIALLY_APPROVED":
      className = "approved_partial";
      break;
    case "APPROVED":
      className = "approved";
      break;
    case "DENIED":
      className = "denied"
      break;
    default:
      className = "awaiting_review";
      break;
  }
  // className = record ? "awaiting_review" : className;
  let paybackStatusColor = null;
  const showPaybackStatus = record && ["DENIED", "PARTIALLY_APPROVED"].indexOf(status) >= 0 && record.payback_status;
  if(showPaybackStatus) {
    switch (record.payback_status) {
      case "Paid back":
        paybackStatusColor = "success";
        break;
      case "Payback due":
        paybackStatusColor = "error";
        break;
      case "Payback in progress":
        paybackStatusColor = "processing";
    }
  }

  return (

    <><Tag className={`status-claims typo-label ${className}`}>
      {CLAIM_STATUS[status] || CLAIM_STATUS.awaiting_review}
    </Tag><>

        {
          showPaybackStatus ? <Tag color={paybackStatusColor}>{record.payback_status}</Tag> : null
        }

      </></>
  );
};

const renderClaimLastUpdatedDate = ({ last_updated_on }) => {
  return moment(last_updated_on).format("MM/DD/YYYY")
}

const renderClaimStartDate = ({ start_date, row }) => {
  const { end_date } = row
  if (!start_date) return ""
  if (end_date) {
    return <div>
      <div>{moment(start_date).format("MM/DD/YYYY")}</div>
      <div>{moment(end_date).format("MM/DD/YYYY")}</div>
    </div>
  } else {
    return moment(start_date).format("MM/DD/YYYY")
  }
}

export const claimStatus = {
  "NEEDS_MORE_INFO": "Need more info",
  "PARTIALLY_APPROVED": "Approved partial",
  "CANCELLED": "Cancelled",
  "APPROVED": "Approved",
  "DENIED": "Denied",
  "AWAITING_REVIEW": "Awaiting review"
}
export const expenseType = {
  "MEDICAL": "Medical",
  "DENTAL": "Dental",
  "VISION": "Vision",
  "DEPENDENT_CARE": "Dependent Care",
  "PHARMACY": "Pharmacy",
  "TRAVEL": "Travel"
}

export const claimStatusMap = {
  "Need more information": "NEEDS_MORE_INFO",
  "Partially approve": "PARTIALLY_APPROVED",
  "Deny": "DENIED",
  "Approve claim": "APPROVED",
  "Cancel claim": "CANCELLED"
}

export const getClaimsActivityColumns = () => [
  {
    title: (titleProps) =>
      renderTitle(titleProps, "Timestamp", "timestamp"),
    key: "timestamp",
    dataIndex: "timestamp",
    sorter: false,
    render: (date) => date ?
      `${moment
        .utc(date)
        .local()
        .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
  },
  {
    title: (titleProps) => renderTitle(titleProps, "Action", "action"),
    key: "action",
    dataIndex: "action",
    sorter: false,
  },
  {
    title: (titleProps) => renderTitle(titleProps, "UserID", "user_id"),
    key: "user_id",
    dataIndex: "user_id",
    sorter: false,
  },
  {
    title: (titleProps) => renderTitle(titleProps, "Details", "details"),
    key: "details",
    dataIndex: "details",
    sorter: false,
  },
]

export const getClaimListingColumns = () => [
  {
    title: (titleProps) =>
      renderTitle(titleProps, "Employee", "employee_name"),
    key: "employee_name",
    dataIndex: "employee_name",
    sortDirections: ["descend", "ascend"],
    sorter: false
  },
  {
    title: (titleProps) => renderTitle(titleProps, "Claim #", "claim_id"),
    key: "claim_id",
    dataIndex: "claim_id",
    sorter: false,
  },
  {
    title: (titleProps) => renderTitle(titleProps, "Status", "claim_status"),
    key: "claim_status",
    dataIndex: "claim_status",
    render: (status) => renderClaimStatus({ status }),
    sorter: true
  },
  {
    title: (titleProps) => renderTitle(titleProps, "Updated On", "last_updated_on"),
    key: "last_updated_on",
    dataIndex: "last_updated_on",
    render: (last_updated_on) => renderClaimLastUpdatedDate({ last_updated_on }),
    sorter: true,
  },
  {
    title: <Typography variant="body-4" label="Amount" />,
    key: "amount",
    dataIndex: "amount",
    render: (amount) => (
      <Typography
        variant="body-2"
        className="amount-cell"
        label={`${fixAmountFormat(amount.toFixed(2))}`}
      />
    ),
    align: "right"
  },
  {
    title: (titleProps) =>
      renderTitle(titleProps, "Transaction date", "start_date"),
    key: "start_date",
    dataIndex: "start_date",
    render: (start_date, row) => renderClaimStartDate({ start_date, row }),
    sorter: true,
  },
  {
    title: (titleProps) => renderTitle(titleProps, "Merchant", "merchant"),
    key: "merchant",
    dataIndex: "merchant",
    sorter: false,
  },
  {
    title: <Typography variant="body-4" label="Claim Type" />,
    key: "claim_type",
    dataIndex: "claim_type",
  },
];

export const getEmployeeClaimListingColumns = (filteredInfo) => {
  const cols = getClaimListingColumns().filter((col) => col.key !== "employee_name");
  const statusCol = cols.find((col) => col.key === "claim_status");
  statusCol.filteredValue = filteredInfo || [];
  statusCol.filters = Object.keys(CLAIM_STATUS).map((status) => ({
    text: CLAIM_STATUS[status],
    value: status,
  }));
  statusCol.render = (status, record) => renderClaimStatus({ status, record });
  return cols;
}