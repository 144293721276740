import { Tag } from "antd";
import { ADMIN_STATUSES } from "../utils/constants";

export function renderAdminStatusTag(tag) {
  let color;

  switch (tag) {
    case ADMIN_STATUSES.ENABLE:
      color = "green";
      break;
    case ADMIN_STATUSES.INVITED:
      color = "orange";
      break;
    case ADMIN_STATUSES.DISABLE:
      color = "volcano";
      break;
    default:
      color = "volcano";
      break;
  }

  return <Tag color={color}>{renderAdminStatusLabel(tag).toUpperCase()}</Tag>;
}

export function renderAdminStatusLabel(tag) {
  let label;

  switch (tag) {
    case ADMIN_STATUSES.ENABLE:
      label = "Active";
      break;
    case ADMIN_STATUSES.DISABLE:
      label = "Disabled";
      break;
    default:
      label = tag;
      break;
  }

  return label;
}
