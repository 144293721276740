export const Search = ({className = ""}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.1665 3.33268C5.94484 3.33268 3.33317 5.94435 3.33317 9.16601C3.33317 12.3877 5.94484 14.9993 9.1665 14.9993C12.3882 14.9993 14.9998 12.3877 14.9998 9.16601C14.9998 5.94435 12.3882 3.33268 9.1665 3.33268ZM1.6665 9.16601C1.6665 5.02388 5.02437 1.66602 9.1665 1.66602C13.3086 1.66602 16.6665 5.02388 16.6665 9.16601C16.6665 13.3082 13.3086 16.666 9.1665 16.666C5.02437 16.666 1.6665 13.3082 1.6665 9.16601Z"
      fill="#989FAE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2846 13.2851C13.61 12.9597 14.1377 12.9597 14.4631 13.2851L18.0881 16.9101C18.4136 17.2355 18.4136 17.7632 18.0881 18.0886C17.7627 18.414 17.235 18.414 16.9096 18.0886L13.2846 14.4636C12.9592 14.1382 12.9592 13.6105 13.2846 13.2851Z"
      fill="#989FAE"
    />
  </svg>
);
