import React from "react";
import { Card, Col, Row, Tabs } from "antd";
import { AddEmployer } from "../../../components/admin/settings/employer";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { SpinLoader } from "../../../components/common/SpinLoader";

function Employer() {
  const { TabPane } = Tabs;

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card title="Employer Details" bordered={false}>
            <SpinLoader showContent={true}>
              <Tabs
                defaultActiveKey="1"
                type="card"
                className="employee-setting-tab"
              >
                <TabPane tab="Add Employer" key="1">
                  <AddEmployer />
                </TabPane>
              </Tabs>
            </SpinLoader>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { Employer };
