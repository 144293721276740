import { Button, Col, Form, Modal, Row, Typography } from "antd";
import React, { useRef, useState } from "react";
import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Close, Select, TextArea, TextField } from "../../../../design-systems";
import { getActivityLogs, getClaimDetailsById, getClaimsDocumentsList, updateClaimsAction } from "../../../../redux/actions/claims.actions";
import { claimStatusMap } from "../../../../utils";
import { useRouter } from "../../../../utils/router";
import { mandatory, validateApprovedAmount } from "../../../../utils/validations";
import { SuccessModal } from "../../employer";

export const ClaimActionDialog = ({ visible, title, onCancel, checkLastRecord, onNext, page, emp_id }) => {
    const [form] = Form.useForm();
    const router = useRouter();
    const dispatch = useDispatch();
    const formRef = useRef();
    const reasonValue = Form.useWatch('reason_type_id', form);
    const approvedAmountValue = Form.useWatch("approved_amount", form)
    const { claimActionReasons, claimsDetails } = useSelector((state) => state.claims)
    const [selectedReasonLabel, setSelecedReasonLabel] = useState("")
    const [successModalOpened, setSuccessModalOpened] = useState(false);
    const [loading, setLoading] = useState(false)

    const isEmployeeClaims = page === "employee-claims"
    const showReasonsField = useMemo(() => {
        return (title === "Need more information" || (title === "Deny" || title === "Partially approve") || title === "Partially approve")
    }, [title])

    useEffect(() => {
        const label = claimActionReasons.find(d => d?.value === reasonValue)?.label;
        setSelecedReasonLabel(label)
    }, [reasonValue])

    const handleFormSubmit = async (values) => {
        setLoading(true)
        let data = {}
        if (showReasonsField) {
            if (title === "Partially approve") {
                data.approved_amount = values.approved_amount
            }
            data.reason_type_id = values.reason_type_id ?? null
            data.additional_note = selectedReasonLabel === "Other" ? values.additional_note : null
        }
        dispatch(updateClaimsAction({ claimId: claimsDetails?.claim_id, action: claimStatusMap[title], data }, (res) => {
            if (!(res instanceof Error)) {
                setLoading(false)
                form.resetFields();
                setSuccessModalOpened(true)
                onCancel()
                setTimeout(() => {
                    dispatch(getClaimsDocumentsList(claimsDetails?.claim_id))
                    dispatch(getClaimDetailsById(claimsDetails?.claim_id))
                    dispatch(getActivityLogs(claimsDetails?.claim_id))
                }, 1000)
            } else {
                setLoading(false)
            }
        }))
    }

    const CurrencyFormatter = () => {
        const value = approvedAmountValue ? claimsDetails?.total_expense_amount?.toFixed(2) - approvedAmountValue : Number(0).toFixed(2)
        const isNegative = value < 0;
        const formattedValue = Math.abs(value)
        const displayValue = isNegative ? `-$${formattedValue.toFixed(2)}` : `$${formattedValue.toFixed(2)}`;
        return <span className="total_claim_amount">{displayValue}</span>;
    };

    const onClaimList = () => {
        if (isEmployeeClaims) {
            router.push(`/admin/employee-detail/${emp_id}`, { showClaimsTab: true })
        }else {
            router.push("/admin/claims")
        }
    }

    return (
        <>
            <Modal
                title={
                    <div className="cancel-modal-header">
                        <Typography >{title}</Typography>
                        <Close height={16} width={16} onClick={() => {
                            form.resetFields();
                            onCancel()
                        }} />
                    </div>
                }
                centered={true}
                visible={visible}
                closable={true}
                validateTrigger="onSubmit"
                okText="Submit"
                maskClosable={false}
                ref={formRef}
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        htmlType="submit"
                        disabled={loading}
                        onClick={async () => {
                            setLoading(false)
                            const isValid = await form.validateFields();
                            if (!isValid?.errorFields?.length) {
                                handleFormSubmit(isValid)
                            }
                        }}
                    >
                        {loading && <LoadingOutlined />}
                        {showReasonsField ? "Submit" : "Confirm"}
                    </Button>
                ]}
                className="common-modal-style claim-action-modal"
            >
                <Form
                    layout="vertical"
                    className="innerForm"
                    initialValues={{}}
                    form={form}
                    onFinish={(values) => handleFormSubmit(values)}>
                    <Row>
                        {
                            title === "Partially approve" &&
                            <>
                                <Col xs={24}>
                                    <div className="partially-approve-wrapper">
                                        <div>Total amount</div>
                                        <div className="total_claim_amount">${claimsDetails?.total_expense_amount?.toFixed(2)}</div>
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <TextField
                                        label="Approved amount"
                                        placeholder="Enter approve amount"
                                        name="approved_amount"
                                        rules={[
                                            mandatory({
                                                message: "Please enter approved amount",
                                            }),
                                            validateApprovedAmount(claimsDetails?.total_expense_amount?.toFixed(2))
                                        ]}
                                        onChange={(e) => {
                                            if (Number(e.target.value) > Number(approvedAmountValue)) {
                                                e.preventDefault();
                                                return
                                            }
                                        }}
                                        onKeyPress={(event) => {
                                            if (Number(event.target.value) > Number(approvedAmountValue)) {
                                                event.preventDefault();
                                                return
                                            }
                                            if (!/[0-9.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            if (value === "") return;
                                            if (!/^\d*\.?\d{0,2}$/.test(value)) {
                                                e.target.value = parseFloat(value).toFixed(2);
                                            }
                                        }}
                                        step="1"
                                        min="0"
                                        type={"number"}
                                        prefix="$"
                                    />
                                </Col>
                                <Col xs={24}>
                                    <div className="partially-approve-wrapper">
                                        <div>Denied amount</div>
                                        <div className="total_claim_amount"><CurrencyFormatter /></div>
                                    </div>
                                </Col>
                            </>
                        }
                        {
                            showReasonsField ?
                                <>
                                    <Col xs={24}>
                                        <Select
                                            label={(title === "Deny" || title === "Partially approve") ? "Select denial reason" : "Select reason"}
                                            rules={[
                                                mandatory({
                                                    message: "Please select a valid reason",
                                                }),
                                            ]}
                                            popupClassName="claim_reason_select"
                                            name="reason_type_id"
                                            disabled={!claimActionReasons?.length}
                                            placeholder={(title === "Deny" || title === "Partially approve") ? "Select a denial reason" : "Select a reason"}
                                            options={claimActionReasons}
                                            className="w-100"

                                        />
                                    </Col>
                                    {
                                        selectedReasonLabel === "Other" &&
                                        <Col xs={24} md={24} className="should-update-col">
                                            <TextArea
                                                label="Description"
                                                name="additional_note"
                                                rules={[
                                                    mandatory({
                                                        message: "Please enter additional description",
                                                    }),
                                                ]}
                                                rows={4}
                                                maxLength={512}
                                                placeholder="Enter descriptive text here"
                                            />
                                        </Col>
                                    }
                                </> :
                                <Col xs={24}>
                                    <div>By clicking on confirm you will be {title === "Approve claim" ? "approving" : "cancelling"} the claim of <b>${claimsDetails?.total_expense_amount?.toFixed(2)}</b>. Are you sure you want to <b>{title === "Approve claim" ? "approve" : "cancel"} this claim?</b></div>
                                </Col>
                        }
                    </Row>
                </Form>
            </Modal>
            <SuccessModal
                title={title}
                page="claim-action"
                onCancel={() => setSuccessModalOpened(false)}
                open={successModalOpened}
                onSecondaryActionClick={onClaimList}
                onPrimaryActionClick={onNext}
                primaryLabel="Next claim"
                secondaryLabel="Go to claim listing"
                checkLastRecord={checkLastRecord}
                onNext={onNext}
            />
        </>
    )
} 