import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../utils/router/customRouterHook";
import { langs } from "../../../i18n";
import {
  DATE_FORMAT,
  CONTRIBUTION_STATUSES,
  BENEFIT_ACCOUNT_TYPES,
} from "../../../utils/constants";
import moment from "moment";
import {
  showInfoMessage,
  getContributionHistory,
  getContributionHistoryWithoutLoader,
  getBenefitAccountType,
  enableLoader,
  disableLoader,
} from "../../../redux/actions";
import {
  fixAmountFormat,
  getLocalTimezone,
  getReportURL,
  renderContrStatusTag,
  renderAccountTypeMultiYearColumn,
  downloadReport,
  queryStringGenerator
} from "../../../utils";

import { ChevronDown } from "../../../design-systems";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { Card, Col, Row, Table, Button, Dropdown, Menu, Checkbox } from "antd";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { DownloadFileTemplateButton } from "../../../components/common/DownloadFileTemplateButton";
import { Typography } from "../../../design-systems";
import { SearchInput } from "../employee/SearchInput";
import useShowSecondSection from "../../../hooks/useShowSection"

const displayReports =
  process.env.REACT_APP_PUBLIC_DISPLAY_REPORTS === "true" ? true : false;

function ContributionRunHistory() {
  const dispatch = useDispatch();
  const router = useRouter();

  const [contributionsList, setContributionsList] = useState(null);
  const [searchRes, setSearchRes] = useState(null);
  const [searchValue, setSearchValue] = useState(router.query.search || "");
  const [accountTypeDropdown, setAccountTypeDropdown] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);



  let checkContributionsForValidationStatusIntervalId;

  const benefitAccountTypesCallback = (res) => {
    const accounts = [];
    if (res && !res.error) {
      let hasHSAPlan = false;
      res?.data?.plans?.map((item) => {
        if (item.plans.length > 0 && item.name === "DCFSA") {
          item.plans.map((plan) => {
            accounts.push({
              name: item.name + " " + moment(plan.plan_start_date).format("YYYY"),
              planYear: new Date(plan.plan_start_date).getTime()
            });
          });

        } else if (item.name === "HSA") {
          hasHSAPlan = true;
        }
      });

      accounts.sort((a, b) => b.planYear - a.planYear);
      if (hasHSAPlan) {
        accounts.unshift({
          name: "HSA",
          planYear: null
        });
      }
      setAccountTypes(accounts);
      if (router.query.filter && router.query.filter.length > 0) {
        const filters = router.query.filter.split(",");
        setSelectedValues(filters.filter((item) => accounts.map((item) => item.name).includes(item)));
      } else {
        setSelectedValues(accounts.map((item) => item.name));
      }
    }
  };
  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((v) => v !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const menu = (
    <Menu className="claim-status-menu">
      {accountTypes.length > 0 ? accountTypes.map((value, index) => (
        <Menu.Item key={index} className="claim-status-menu-item">
          <Checkbox
            className="checkbox-wrapper"
            onChange={() => handleCheckboxChange(value.name)}
            checked={selectedValues.includes(value.name)}
          >
            {value.name}
          </Checkbox>
        </Menu.Item>
      )) : (
        <Menu.Item className="claim-status-menu-item">
          No records available.
        </Menu.Item>
      )}
    </Menu>
  );


  const applySearchFilter = (term) => {
    const filteredList = contributionsList?.filter((item) => selectedValues.includes(item.benefits_account_type === BENEFIT_ACCOUNT_TYPES.HSA ?
      BENEFIT_ACCOUNT_TYPES.HSA : item.benefits_account_type + " " + (item.plan_start_date ? moment(item.plan_start_date).format("YYYY") : "")));
    const params = router.query;
    if (term === null  && router.query.search) {
      term = router.query.search;
    }
    if (term !== "") {
      const value = term.toLowerCase();
      const matches = filteredList?.filter((v) =>
        v.contribution_name.toLowerCase().includes(value),
      );
      params.search = term;
      setSearchRes(matches);
    } else {
      setSearchRes(filteredList);
    }
    if (selectedValues.length > 0) {
      params.filter = selectedValues;
    }
    router.history.push({ pathname: router.pathname, search: queryStringGenerator(params) });
  };

  useShowSecondSection()
  useEffect(() => {
    getContributions();
    dispatch(getBenefitAccountType(benefitAccountTypesCallback));

    return () => {
      clearInterval(checkContributionsForValidationStatusIntervalId);
    };
  }, []);

  useMemo(() => {
    applySearchFilter(searchValue);
  }, [searchValue, contributionsList, selectedValues]);

  const getContributions = () => {
    dispatch(
      getContributionHistory((res) => {
        setSearchRes(res.data.contribution_run_list);
        setContributionsList(res.data.contribution_run_list);

        checkContributionsForValidationStatus(res.data.contribution_run_list);
      }),
    );
  };

  const getContributionsWithoutLoader = () => {
    dispatch(
      getContributionHistoryWithoutLoader((res) => {
        setSearchRes(res.data.contribution_run_list);
        setContributionsList(res.data.contribution_run_list);

        checkContributionsForValidationStatus(res.data.contribution_run_list);
      }),
    );
  };

  const checkContributionsForValidationStatus = (contributions) => {
    const needCheck = contributions.some(
      (contr) =>
        contr.contribution_status ===
        CONTRIBUTION_STATUSES.VALIDATION_IN_PROGRESS,
    );

    if (needCheck && !checkContributionsForValidationStatusIntervalId) {
      checkContributionsForValidationStatusIntervalId = setInterval(
        getContributionsWithoutLoader,
        15000,
      );
    }

    if (!needCheck) {
      clearInterval(checkContributionsForValidationStatusIntervalId);
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    const params = router.query;
    params.page = pagination.current;
    params.size = pagination.pageSize;
    router.history.push({
      pathname: router.pathname,
      search: queryStringGenerator(params),
    });
  };

  const onDownloadReport = async (e, contributionRunID) => {
    e.stopPropagation();

    dispatch(enableLoader());
    const currentReportURL = await getReportURL(contributionRunID);
    downloadReport(dispatch, currentReportURL);
    dispatch(disableLoader());
  };

  const onTableRowClick = (record) => {
    if (
      record.contribution_status ===
      CONTRIBUTION_STATUSES.VALIDATION_IN_PROGRESS
    ) {
      dispatch(showInfoMessage(langs.messages.contr_validation_in_progress));
    } else if (
      record.contribution_status === CONTRIBUTION_STATUSES.VALIDATION_ERROR
    ) {
      router.push(
        `/admin/contribution-validation-report/${record.contribution_run_id}`,
      );
    } else {
      router.push(`/admin/contribution-progress/${record.contribution_run_id}`);
    }
  };

  const columns = [
    {
      title: "Contribution Run",
      key: "contribution_run_id",
      dataIndex: "contribution_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.contribution_name.localeCompare(b.contribution_name),
      render: (contributionRun) => (
        <Typography className="contributionRun" label={contributionRun}>
          {contributionRun}
        </Typography>
      ),
    },
    {
      title: "Contribution Status",
      key: "tags",
      dataIndex: "contribution_status",
      render: (status) => renderContrStatusTag(status),
    },
    {
      title: "# Employees",
      key: "contribution_run_id",
      dataIndex: "employee_count",
      sorter: (a, b) => a.employee_count - b.employee_count,
      render: (count) => String(count),
      align: "right"
    },
    {
      title: "Total $",
      key: "contribution_run_id",
      dataIndex: "total_contribution_amt",
      sorter: (a, b) => a.total_contribution_amt - b.total_contribution_amt,
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Contribution Time",
      key: "contribution_run_id",
      dataIndex: "exec_end_time",
      sorter: (a, b) => new Date(a.exec_end_time) - new Date(b.exec_end_time),
      render: (date) => {
        const localTime = moment
          .utc(date)
          .local()
          .format(DATE_FORMAT.FOR_UI_DATE_TIME);

        return date && `${localTime} ${getLocalTimezone()}`;
      },
    },
    {
      title: "Payroll Date",
      key: "contribution_run_id",
      dataIndex: "contribution_date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.contribution_date) - new Date(b.contribution_date),
      render: (date) => moment(date).format(DATE_FORMAT.FOR_UI_FIRST_MM),
    },
    {
      title: "Benefits Account Type",
      key: "benefits_account_type",
      dataIndex: "benefits_account_type",
      render: (account, obj) => renderAccountTypeMultiYearColumn(account, obj),
    },
    {
      title: "Report",
      key: "contribution_run_id",
      dataIndex: "contribution_status",
      render: (status, item) => {
        const isVisible =
          item.benefits_account_type !== BENEFIT_ACCOUNT_TYPES.DCFSA &&
          (status === CONTRIBUTION_STATUSES.ERRORED ||
            status === CONTRIBUTION_STATUSES.SUCCESSFUL);

        return isVisible ? (
          <div
            className="download-report-table-icon"
            onClick={(e) => onDownloadReport(e, item.contribution_run_id)}
          >
            <DownloadOutlined />
          </div>
        ) : null;
      },
      hidden: !displayReports,
    },
  ].filter((item) => !item.hidden);

  const handleSearchChange = (e) => setSearchValue(e);

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card
            title=""
            bordered={false}
            className="data-grid employee-details-wrapper"
          >
            <Typography
              label="Contribution runs"
              variant="subhead-1"
              className="employee-listing-title "
            />
            <SpinLoader showContent={!!contributionsList}>
              <div className="contribution-history-title">
                <Row>
                  <Col sm={8}>
                    <SearchInput
                      onChange={(e) => {
                        if (!e.target.value) {
                          setSearchValue("")
                        }
                      }}
                      onSearch={
                        (e) => {
                          handleSearchChange(e)
                        }
                      }
                      placeholder={"Search contributions"}
                      defaultValue={router.query.search}
                    />
                  </Col>
                  <Col sm={16}>
                    <div className="d-flex gap-15 justify-flex-end">
                      <DownloadFileTemplateButton contribution compact />
                      <Button
                        onClick={() => router.push("/admin/create-contribution")}
                        type="default"
                        className="button-primary-s ml-10"
                      >
                        New Contribution Run
                      </Button>
                      <div className="contributions-account-type-wrapper">
                        <Dropdown
                          overlay={menu}
                          prefix={<ChevronDown />}
                          trigger={["click"]}
                          menu={menu}
                          placement="bottomRight"
                          visible={accountTypeDropdown}
                          onVisibleChange={setAccountTypeDropdown}
                        >
                          <div className="account-type-dropdown">
                            Account type
                            <DownOutlined className="account-type-dropdown-btn" />
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* <div className="search-grid-data-contribution">
                <div className="left-block">
                  <SearchInput
                    onChange={(e) => {
                      if (!e.target.value) {
                        setSearchValue("")
                      }
                    }}
                    onSearch={
                      (e) => {
                         handleSearchChange(e) 
                        }
                    }
                    placeholder={"Search contributions"}
                  />
                </div> */}
              {/* <div className="claims-wrapper">
                <Dropdown
                    overlay={menu}
                    prefix={<ChevronDown />}
                    trigger={["click"]}
                    menu={menu}
                    placement="bottomRight"
                    visible={accountTypeDropdown}
                    onVisibleChange={setAccountTypeDropdown}
                  >
                    <div className="claim-status-dropdown">
                      Account type
                      <DownOutlined style={{ fontSize: "16px", fontWeight: "bold" }} />
                    </div>
                  </Dropdown> 
                </div>         */}
              {/* <div className="right-block">
                  <DownloadFileTemplateButton contribution compact />
                  <Button
                    onClick={() => router.push("/admin/create-contribution")}
                    type="default"
                    className="button-primary-s ml-10"
                  >
                    New Contribution Run
                  </Button>
                  <div className="claims-wrapper"style={{ height: 50 }}>
                  <Dropdown
                      overlay={menu}
                      prefix={<ChevronDown />}
                      trigger={["click"]}
                      menu={menu}
                      placement="bottomRight"
                      visible={accountTypeDropdown}
                      onVisibleChange={setAccountTypeDropdown}
                    >
                      <div className="claim-status-dropdown">
                        Account type
                        <DownOutlined style={{ fontSize: "16px", fontWeight: "bold" }} />
                      </div>
                    </Dropdown> 
                </div> 
                </div> */}
              {/* </div> */}
              <Table
                columns={columns}
                dataSource={searchRes}
                onChange={onChange}
                bordered
                scroll={searchRes?.length ? { x: 1200 } : undefined}
                rowClassName={"pointer"}
                pagination={{
                  current: router.query.page ? Number(router.query.page) : 1,
                  pageSize: router.query.size ? Number(router.query.size) : 10,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => onTableRowClick(record),
                  };
                }}
              />
            </SpinLoader>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { ContributionRunHistory };
