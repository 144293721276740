import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import FooterLogo from "../../assets/img/incomm-loader.svg";
import { IncommLoaderIcon } from "../../design-systems";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <Row gutter={0} align="center">
          <Col xs={24} sm={24} md={24} lg={24} >
            <div className="footer-left-block">
              <Link to="/">
                <div className="footer-logo"><IncommLoaderIcon color="white" /></div>
              </Link>
              <div className="copyright">
                <b>Copyright © {moment().year()} InComm Payments, Inc. All Rights Reserved.</b>
              </div>
            </div>
          </Col>
          {/* <Col xs={24} sm={24} md={6} lg={6} className="text-right">
            <ul className="nav-footer">
              <li>
                <Link
                  to={{ pathname: "https://getzenda.com/about/" }}
                  target="_blank"
                >
                  About Us
                </Link>
              </li>
              <li> &nbsp; &nbsp;|&nbsp; &nbsp;</li>
              <li className="nav-item">
                <Link
                  to={{ pathname: "https://getzenda.com/careers/" }}
                  target="_blank"
                >
                  Careers
                </Link>
              </li>
            </ul>
          </Col> */}
        </Row>
      </div>
    </div>
  );
}
export { Footer };
