import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../utils/router/customRouterHook";
import moment from "moment";
import { langs } from "../../../i18n";
import {
  ROLE_CONSTANT,
  STATE_NAMES,
  DATE_FORMAT,
} from "../../../utils/constants";
import {
  formatDate,
  stripLetters,
  renderOptions,
  renderStates,
} from "../../../utils";

import {
  getCountries,
  getCoverages,
  getEmploymentStatuses,
  getAccountStatuses,
  getUserStatuses,
  showErrorMessage,
  showSuccessMessage,
  addEmployee,
  getAllDivisions,
} from "../../../redux/actions";
import {
  validateAddr1,
  validateCity,
  validateDOB,
  validateEmployeeDateOfJoining,
  validateEmployeeID,
  validateFirstName,
  validateFirstNameRules,
  validateHSAPlanEligibilityDate,
  validateHSAPlanStartDate,
  validateLastName,
  validateLastNameRules,
  validateMiddleName,
  validateMiddleNameRules,
  validateMobileNum,
  validatePostal,
  validateSSN,
  validateSt,
  validateWorkEmail,
  required,
  email,
  minLength,
  maxLength,
  specialValidationEmployeeId,
} from "../../../utils/validations";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";

import { Card, Col, Row, Form, Input, Button, Select, DatePicker } from "antd";
import {
  IdcardFilled,
  MobileFilled,
  HomeFilled,
  GlobalOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import hashicon from "../../../assets/img/hash-icon.png";

function AddEmployee() {
  const dispatch = useDispatch();
  const router = useRouter();
  const forRef = useRef(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  const countries = useSelector((state) => state.employees.countries);
  const coverages = useSelector((state) => state.employees.coverages);
  const userStatuses = useSelector((state) => state.employees.userStatuses);
  const employmentStatuses = useSelector(
    (state) => state.employees.employmentStatuses,
  );

  const [divisionList, setDivisionList] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCoverages());
    dispatch(getAccountStatuses());
    dispatch(getEmploymentStatuses());
    dispatch(getUserStatuses());
    getDivisions();

    // eslint-disable-next-line
  }, []);

  const getEmployeeData = () => {
    let fieldVal = {
      country:
        Object.keys(countries)[0] in countries ? Object.keys(countries)[0] : "",
      employmentstatus:
        Object.keys(employmentStatuses)[0] in employmentStatuses
          ? Object.keys(employmentStatuses)[0]
          : "",
      userstatus:
        Object.keys(userStatuses)[0] in userStatuses
          ? Object.keys(userStatuses)[0]
          : "",
      coveragetype:
        Object.keys(coverages)[0] in coverages ? Object.keys(coverages)[0] : "",
    };

    form.setFieldsValue({ ...fieldVal });
    // dispatch(disableGlobalLoader());
  };

  const getDivisions = () => {
    dispatch(
      getAllDivisions((res1) => {
        if (!res1.error) {
          if (
            res1.data[0].division_name === "default" &&
            res1.data.length === 1
          ) {
            setIsDefault(true);
            setSelectedDivision(res1.data[0].division_id);
          }
          setDivisionList(res1.data);
          getEmployeeData(res1.data);
        } else {
          getEmployeeData(divisionList);
        }
      }),
    );
  };

  const validateAll = (payload) => {
    const {
      employeeID,
      employeeDateOfJoining,
      firstName,
      lastName,
      middleName,
      DOB,
      SSN,
      mobileNum,
      addr1,
      city,
      st,
      postal,
      workEmail,
      HSAPlanEligibilityDate,
      HSAPlanStartDate,
    } = payload;

    const validate = {
      employeeDateOfJoiningState: validateEmployeeDateOfJoining(
        employeeDateOfJoining,
      ),
      firstNameState: validateFirstName(firstName),
      middleName: validateMiddleName(middleName),
      lastNameState: validateLastName(lastName),
      DOBState: validateDOB(DOB),
      SSNState: validateSSN(SSN),
      mobileNumState: validateMobileNum(mobileNum),
      addr1State: validateAddr1(addr1),
      // addr2State: validateAddr2(addr2),
      cityState: validateCity(city),
      stState: validateSt(st),
      postalState: validatePostal(postal),
      HSAPlanEligibilityDateState: validateHSAPlanEligibilityDate(
        HSAPlanEligibilityDate,
      ),
      HSAPlanStartDateState: validateHSAPlanStartDate(HSAPlanStartDate),
      employeeID: validateEmployeeID(employeeID),
      workEmail: validateWorkEmail(workEmail),
    };

    for (let status in validate) {
      if (validate[status] !== "valid") {
        dispatch(showErrorMessage(validate[status]));
        return false;
      }
    }

    return true;
  };

  const createEmployee = (values, runkyc) => {
    const validAllPayload = {
      employeeID: values.employeeID,
      employeeDateOfJoining: values.employeeDateOfJoining,
      firstName: values.firstName.trim(),
      middleName: values.middle_name?.trim(),
      lastName: values.lastName.trim(),
      DOB: values.dateofbirth,
      SSN: values.SSN,
      mobileNum: values.mobileNum,
      addr1: values.streetaddress1,
      addr2: values.streetaddress2,
      city: values.city,
      st: values.state,
      postal: values.postalcode,
      workEmail: values.workEmail,
      HSAPlanEligibilityDate: values.coverage_effective_date,
      HSAPlanStartDate: values.coverage_effective_date,
      coverage_effective_date: values.coverage_effective_date,
    };

    const allOK = validateAll(validAllPayload);

    if (!allOK) {
      return true;
    } else {
      let reqData = {
        ...validAllPayload,
        employmentStatus: values.employmentstatus,
        country: values.country,
        userStatus: values.userstatus,
        coverage: values.coveragetype,
        mobile_phone_num: values.mobileNum ? values.mobileNum : "",
        employmentStatusID: employmentStatuses[values.employmentstatus],
        countryID: countries[values.country],
        coverageID: coverages[values.coveragetype],
        division_id: selectedDivision,
        middleName: values.middle_name ? values.middle_name.trim() : "",
        DOB: moment(values.dateofbirth, DATE_FORMAT.FOR_UI_FIRST_MM).format(
          DATE_FORMAT.FOR_SERVER,
        ),
        socure_reference_id: ROLE_CONSTANT.ADMIN
          ? ""
          : values.socureReferenceID,
        employeeDateOfJoining: moment(
          values.employeeDateOfJoining,
          DATE_FORMAT.FOR_UI_FIRST_MM,
        ).format(DATE_FORMAT.FOR_SERVER),
        HSAPlanEligibilityDate: moment(
          values.coverage_effective_date,
          DATE_FORMAT.FOR_UI_FIRST_MM,
        ).format(DATE_FORMAT.FOR_SERVER),
        HSAPlanStartDate: moment(
          values.coverage_effective_date,
          DATE_FORMAT.FOR_UI_FIRST_MM,
        ).format(DATE_FORMAT.FOR_SERVER),
        runkyc,
        coverage_effective_date: values.coverage_effective_date
          ? moment(
              values.coverage_effective_date,
              DATE_FORMAT.FOR_UI_FIRST_MM,
            ).format(DATE_FORMAT.FOR_SERVER)
          : null,
        employment_termination_date: null,
        coverage_termination_date: null,
        sent_at: formatDate(),
      };

      let temp = [];
      temp.push(reqData);

      dispatch(
        addEmployee(temp, (res) => {
          if (!res.error) {
            dispatch(showSuccessMessage(langs.messages.add_employee_success));
            router.push("/admin/employees/employee-list");
          }
        }),
      );
    }
  };

  const onFinish = async (runkyc) => {
    form
      .validateFields()
      .then((values) => {
        createEmployee(values, runkyc);
      })
      .catch((errorInfo) => {
        console.log(
          errorInfo.errorFields.some((item) => item.errors.length > 0),
        );
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (value) => {
    setSelectedDivision(value);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const isDisable = (key) => {
    if (key === "employmentStatus" || key === "userStatus") return true;
  };

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card
            title="Employee Details"
            bordered={false}
            className="add-employee"
            extra={
              <Button
                onClick={() =>
                  router.push("/admin/employees/upload-census-file")
                }
                className="button-primary-s"
              >
                New census Upload
              </Button>
            }
          >
            <SpinLoader showContent={!!divisionList}>
              <Form
                name="basic"
                form={form}
                labelCol={{ span: 24 }}
                ref={forRef}
                wrapperCol={{ span: 24 }}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                autoComplete="off"
                className="common-form-container"
              >
                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Form.Item
                      label="First Name"
                      name="firstName"
                      normalize={(value) => value.trimStart()}
                      rules={[
                        { validator: validateFirstNameRules, required: true },
                      ]}
                    >
                      <Input
                        disabled={isDisable("firstName")}
                        placeholder=""
                        prefix={<i className="ni ni-circle-08" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Form.Item
                      label="Middle Name"
                      name="middle_name"
                      normalize={(value) => value.trimStart()}
                      rules={[{ validator: validateMiddleNameRules }]}
                    >
                      <Input
                        disabled={isDisable("middleName")}
                        placeholder=""
                        prefix={<i className="ni ni-circle-08" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      normalize={(value) => value.trimStart()}
                      rules={[
                        { validator: validateLastNameRules, required: true },
                      ]}
                    >
                      <Input
                        disabled={isDisable("lastName")}
                        placeholder=""
                        prefix={<i className="ni ni-circle-08" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Form.Item
                      label="Work Email"
                      name="workEmail"
                      rules={[required("Email id"), email]}
                    >
                      <Input
                        disabled={isDisable("workEmail")}
                        placeholder=""
                        prefix={<i className="ni ni-email-83" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div className="custom-select-icon">
                      <div className="info-ico">
                        <InfoCircleFilled />
                      </div>
                      <Form.Item
                        label="Employment Status"
                        name="employmentstatus"
                        className="custom-leftpad-space"
                        rules={[required("Employment status")]}
                      >
                        <Select
                          disabled={isDisable("employmentStatus")}
                          placeholder=""
                        >
                          {renderOptions(employmentStatuses)}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div className="custom-select-icon">
                      <div className="info-ico">
                        <InfoCircleFilled />
                      </div>
                      <Form.Item
                        label="Account Status"
                        name="userstatus"
                        className="custom-leftpad-space"
                        rules={[required("Account Status")]}
                      >
                        <Select
                          disabled={isDisable("userStatus")}
                          placeholder=""
                        >
                          {renderOptions(userStatuses)}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div className="custom-icon">
                      <Form.Item
                        label="SSN"
                        name="SSN"
                        rules={[required("SSN"), maxLength(9), minLength(9)]}
                      >
                        <Input
                          placeholder=""
                          disabled={isDisable("SSN")}
                          prefix={<IdcardFilled />}
                          maxLength={9}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div className="custom-icon">
                      <Form.Item
                        label="Employee ID"
                        name="employeeID"
                        rules={[
                          required("Employee ID"),
                          maxLength(30),
                          specialValidationEmployeeId(),
                        ]}
                      >
                        <Input
                          placeholder=""
                          disabled={isDisable("employeeID")}
                          prefix={
                            <img src={hashicon} alt="info" className="" />
                          }
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div className="custom-icon common-input-num">
                      <Form.Item
                        label="Mobile Phone Number"
                        name="mobileNum"
                        onChange={(e) => {
                          form.setFieldsValue({
                            mobileNum: stripLetters(e.target.value),
                          });
                        }}
                      >
                        <Input
                          placeholder=""
                          disabled={isDisable("mobileNum")}
                          maxLength={10}
                          prefix={<MobileFilled />}
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Form.Item
                      label="Home Address 1"
                      name="streetaddress1"
                      rules={[required("Street address 1"), maxLength(100)]}
                    >
                      <Input
                        placeholder=""
                        disabled={isDisable("addr1")}
                        prefix={<HomeFilled />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Form.Item
                      label="Home Address 2"
                      name="streetaddress2"
                      rules={[maxLength(100)]}
                    >
                      <Input
                        placeholder=""
                        disabled={isDisable("addr2")}
                        prefix={<HomeFilled />}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[required("City"), maxLength(60)]}
                    >
                      <Input
                        placeholder=""
                        disabled={isDisable("city")}
                        prefix={<HomeFilled />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div className="custom-select-icon">
                      <div className="info-ico">
                        <HomeFilled />
                      </div>
                      <Form.Item
                        label="State"
                        name="state"
                        rules={[required("State"), minLength(2), maxLength(2)]}
                        className="custom-leftpad-space"
                      >
                        <Select disabled={isDisable("st")} placeholder="">
                          {renderStates(STATE_NAMES)}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6}>
                    <Form.Item
                      label="Zip Code"
                      name="postalcode"
                      rules={[required("Zip code"), maxLength(5), minLength(5)]}
                      onChange={(e) => {
                        form.setFieldsValue({
                          postalcode: stripLetters(e.target.value),
                        });
                      }}
                    >
                      <Input
                        placeholder=""
                        disabled={isDisable("postal")}
                        prefix={<HomeFilled />}
                        maxLength={5}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div className="custom-select-icon">
                      <div className="globe-icon">{<GlobalOutlined />}</div>
                      <Form.Item
                        label="Country"
                        name="country"
                        rules={[required("Country")]}
                        className="custom-leftpad-space"
                      >
                        <Select disabled={isDisable("country")} placeholder="">
                          {renderOptions(countries)}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div className="date-picker">
                      <Form.Item
                        label="Date of Birth"
                        name="dateofbirth"
                        rules={[required("Date of birth")]}
                      >
                        <DatePicker
                          disabled={isDisable("DOB")}
                          onChange={onChange}
                          placeholder=""
                          format={DATE_FORMAT.FOR_UI_FIRST_MM}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div className="date-picker">
                      <Form.Item
                        label="Employment Start Date"
                        name="employeeDateOfJoining"
                        rules={[required("Date of joining")]}
                      >
                        <DatePicker
                          disabled={isDisable("employeeDateOfJoining")}
                          onChange={onChange}
                          placeholder=""
                          format={DATE_FORMAT.FOR_UI_FIRST_MM}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div className="date-picker">
                      <Form.Item
                        label="Coverage Effective Date"
                        name="coverage_effective_date"
                        rules={[required("Coverage Effective Date")]}
                      >
                        <DatePicker
                          placeholder=""
                          format={DATE_FORMAT.FOR_UI_FIRST_MM}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <div className="custom-select-icon">
                      <div className="info-ico">
                        <InfoCircleFilled />
                      </div>

                      <Form.Item
                        label="Coverage Type"
                        name="coveragetype"
                        className="custom-leftpad-space"
                        rules={[required("Coverage type")]}
                      >
                        <Select disabled={isDisable("coverage")} placeholder="">
                          {renderOptions(coverages)}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  {!isDefault && (
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <div className="custom-select-icon">
                        {!isDefault &&
                        Array.isArray(divisionList) &&
                        divisionList.length ? (
                          <div className="info-ico">
                            <InfoCircleFilled />
                          </div>
                        ) : null}

                        {!isDefault &&
                        Array.isArray(divisionList) &&
                        divisionList.length ? (
                          <Form.Item
                            label="Division"
                            name="division_id"
                            className="custom-leftpad-space"
                            rules={[required("Division")]}
                          >
                            <Select onChange={handleChange} placeholder="">
                              {divisionList?.map((el) => (
                                <Option
                                  key={el.division_id}
                                  value={el.division_id}
                                >
                                  {el.division_name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null}
                      </div>
                    </Col>
                  )}
                </Row>
                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="btn-block">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="button"
                          className="button-secondary mr-10"
                          onClick={() => window.location.reload()}
                        >
                          Discard Changes
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-primary mr-10"
                          onClick={() => onFinish(0)}
                        >
                          Save as Draft
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-primary"
                          onClick={() => onFinish(1)}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </SpinLoader>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { AddEmployee };
