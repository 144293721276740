import React, { useState } from "react";

import {
  Typography,
  Button,
  TextField,
  TextArea,
  Select,
  Link,
  CheckBox,
  Radio,
  Tabs,
  DatePicker,
  Alert,
} from "../design-systems";
import { Badge, Divider, Form, notification } from "antd";
import dummyImage from "../assets/img/dummy-image.png";
import { PlusOutlined } from "@ant-design/icons";
import {
  Home,
  Info,
  Warning,
  ErrorNotification,
  SuccessNotication,
} from "../design-systems/Icons";

const FormLayout = ({ children }) => (
  <Form labelWrap labelAlign="right" layout="vertical">
    {children}
  </Form>
);

const dummyOptions = [
  {
    label: "Option 1",
    value: "Option 1",
  },
  {
    label: "Option 2",
    value: "Option 2",
  },
  {
    label: "Option 3",
    value: "Option 3",
  },
  {
    label: "Option 4",
    value: "Option 4",
  },
];

const dummyOptionsWithJsx = [
  {
    label: (
      <div className="dummy-option">
        <img src={dummyImage} alt="dummy 001" />
        Option 1
      </div>
    ),
    title: "Option 1",
    value: "option-01",
  },
  {
    label: (
      <div className="dummy-option">
        <img src={dummyImage} alt="dummy 002" />
        Option 2
      </div>
    ),
    title: "Option 2",
    value: "option-02",
  },
  {
    label: (
      <div className="dummy-option">
        <img src={dummyImage} alt="dummy 003" />
        Option 3
      </div>
    ),
    title: "Option 3",
    value: "option-03",
  },
  {
    label: (
      <div className="dummy-option">
        <img src={dummyImage} alt="dummy 004" />
        Option 4
      </div>
    ),
    title: "Option 4",
    value: "option-04",
  },
];

const DesignSystems = () => {
  //notification
  const [api, contextHolder] = notification.useNotification();
  const openNotificationInfoWithTitle = () => {
    api.open({
      duration: 5,
      message: (
        <>
          {" "}
          <span>
            <Info />
          </span>
          <Typography
            className={"notification-title"}
            label="Label"
            variant={"body-2"}
          />
        </>
      ),
      description: (
        <Typography
          className={`notification-desc-withTitle`}
          label="Info message here"
          variant={"body-3"}
        />
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationSuccessWithTitle = () => {
    api.open({
      duration: 5,
      message: (
        <>
          {" "}
          <span>
            <SuccessNotication />
          </span>
          <Typography
            className={"notification-title"}
            label="Label"
            variant={"body-2"}
          />
        </>
      ),
      description: (
        <Typography
          className={`notification-desc-withTitle`}
          label="Success message here"
          variant={"body-3"}
        />
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationErrorWithTitle = () => {
    api.open({
      duration: 5,
      message: (
        <>
          {" "}
          <span>
            <ErrorNotification />
          </span>
          <Typography
            className={"notification-title"}
            label="Label"
            variant={"body-2"}
          />
        </>
      ),
      description: (
        <Typography
          className={`notification-desc-withTitle`}
          label="Error message here"
          variant={"body-3"}
        />
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
      // placement: 'bottomRight'
    });
  };
  const openNotificationWarningWithTitle = () => {
    api.open({
      duration: 5,
      message: (
        <>
          {" "}
          <span>
            <Warning />
          </span>
          <Typography
            className={"notification-title"}
            label="Label"
            variant={"body-2"}
          />
        </>
      ),
      description: (
        <Typography
          className={`notification-desc-withTitle`}
          label="Warning message here"
          variant={"body-3"}
        />
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationSuccessLabelOnly = () => {
    api.open({
      duration: 5,
      // message: <> <span><Success/></span><Typography className={'notification-title'} label='Label' variant={'body-2'}/></>,
      description: (
        <div className="label-only-wrapper">
          <span>
            <SuccessNotication />
          </span>
          <Typography
            className={`notification-desc-label-only`}
            label="Info message here"
            variant={"body-3"}
          />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationInfoLabelOnly = () => {
    api.open({
      duration: 5,
      // message: <> <span><Success/></span><Typography className={'notification-title'} label='Label' variant={'body-2'}/></>,
      description: (
        <div className="label-only-wrapper">
          <span>
            <Info />
          </span>
          <Typography
            className={`notification-desc-label-only`}
            label="Info message here"
            variant={"body-3"}
          />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationErrorLabelOnly = () => {
    api.open({
      duration: 5,
      // message: <> <span><Success/></span><Typography className={'notification-title'} label='Label' variant={'body-2'}/></>,
      description: (
        <div className="label-only-wrapper">
          <span>
            <ErrorNotification />
          </span>
          <Typography
            className={`notification-desc-label-only`}
            label="Info message here"
            variant={"body-3"}
          />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWarningLabelOnly = () => {
    api.open({
      duration: 5,
      // message: <> <span><Success/></span><Typography className={'notification-title'} label='Label' variant={'body-2'}/></>,
      description: (
        <div className="label-only-wrapper">
          <span>
            <Warning />
          </span>
          <Typography
            className={`notification-desc-label-only`}
            label="Info message here"
            variant={"body-3"}
          />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWithActionInfo = () => {
    api.open({
      duration: 5,
      // message: <> <span><Success/></span><Typography className={'notification-title'} label='Label' variant={'body-2'}/></>,
      description: (
        <div className="action-wrapper">
          <span>
            <Info />
          </span>
          <Typography
            className={`notification-desc-with-action`}
            label="Info message here"
            variant={"body-3"}
          />

          <Button buttonVariant={"body-2"} variant={"ghost"} label={"create"} />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWithActionSuccess = () => {
    api.open({
      duration: 5,
      // message: <> <span><Success/></span><Typography className={'notification-title'} label='Label' variant={'body-2'}/></>,
      description: (
        <div className="action-wrapper">
          <span>
            <SuccessNotication />
          </span>
          <Typography
            className={`notification-desc-with-action`}
            label="Info message here"
            variant={"body-3"}
          />

          <Button buttonVariant={"body-2"} variant={"ghost"} label={"create"} />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWithActionWarning = () => {
    api.open({
      duration: 5,
      // message: <> <span><Success/></span><Typography className={'notification-title'} label='Label' variant={'body-2'}/></>,
      description: (
        <div className="action-wrapper">
          <span>
            <Warning />
          </span>
          <Typography
            className={`notification-desc-with-action`}
            label="Info message here"
            variant={"body-3"}
          />

          <Button buttonVariant={"body-2"} variant={"ghost"} label={"create"} />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWithActionError = () => {
    api.open({
      duration: 5,
      // message: <> <span><Success/></span><Typography className={'notification-title'} label='Label' variant={'body-2'}/></>,
      description: (
        <div className="action-wrapper">
          <span>
            <ErrorNotification />
          </span>
          <Typography
            className={`notification-desc-with-action`}
            label="Info message here"
            variant={"body-3"}
          />

          <Button buttonVariant={"body-2"} variant={"ghost"} label={"create"} />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWithActionandTitleInfo = () => {
    api.open({
      duration: 5,
      message: (
        <>
          {" "}
          <span>
            <Info />
          </span>
          <Typography
            className={"notification-title"}
            label="Label"
            variant={"body-2"}
          />
        </>
      ),
      description: (
        <div className="action-title-wrapper">
          <Typography
            className={`notification-desc-withTitle `}
            label="Info message here"
            variant={"body-3"}
          />
          <Button buttonVariant={"body-2"} variant={"ghost"} label={"create"} />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWithActionandTitleSuccess = () => {
    api.open({
      duration: 5,
      message: (
        <>
          {" "}
          <span>
            <SuccessNotication />
          </span>
          <Typography
            className={"notification-title"}
            label="Label"
            variant={"body-2"}
          />
        </>
      ),
      description: (
        <div className="action-title-wrapper">
          <Typography
            className={`notification-desc-withTitle `}
            label="Success message here"
            variant={"body-3"}
          />
          <Button buttonVariant={"body-2"} variant={"ghost"} label={"create"} />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWithActionandTitleWarning = () => {
    api.open({
      duration: 5,
      message: (
        <>
          {" "}
          <span>
            <Warning />
          </span>
          <Typography
            className={"notification-title"}
            label="Label"
            variant={"body-2"}
          />
        </>
      ),
      description: (
        <div className="action-title-wrapper">
          <Typography
            className={`notification-desc-withTitle `}
            label="Warning message here"
            variant={"body-3"}
          />
          <Button buttonVariant={"body-2"} variant={"ghost"} label={"create"} />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  const openNotificationWithActionandTitleError = () => {
    api.open({
      duration: 5,
      message: (
        <>
          {" "}
          <span>
            <ErrorNotification />
          </span>
          <Typography
            className={"notification-title"}
            label="Label"
            variant={"body-2"}
          />
        </>
      ),
      description: (
        <div className="action-title-wrapper">
          <Typography
            className={`notification-desc-withTitle `}
            label="Error message here"
            variant={"body-3"}
          />
          <Button buttonVariant={"body-2"} variant={"ghost"} label={"create"} />
        </div>
      ),
      className: "custom-class",
      style: {
        width: 360,
      },
    });
  };
  //button props and handlers
  // const isDisabled = false;
  // const isLoading = false;
  function onClickHandler() {
    console.log("clicked button");
  }

  //checkbox handler
  const [checkboxStatus, setcheckboxStatus] = useState(false);
  const [checkboxStatus1, setcheckboxStatus1] = useState(true);

  const onChangeCheckBox = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setcheckboxStatus(e.target.checked);
  };
  const onChangeCheckBox1 = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setcheckboxStatus1(e.target.checked);
  };
  //radio
  const [value, setValue] = useState(1);
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  //date picker
  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <div className="design-system-container">
      <h1>Typography:</h1>
      <Divider />
      <div className="typography-wrapper">
        <Typography variant={"heading-1"} label="What's new" />
        <Typography variant={"heading-2"} label="Welcome!" />
        <Typography variant={"heading-3"} label="Tell us what you're into" />
        <Typography variant={"heading-4"} label="Tell us what you're into" />
        <Typography variant={"subhead-1"} label="Tell us what you're into" />
        <Typography variant={"subhead-2"} label="Tell us what you're into" />
        <Typography
          variant={"subtitle-Large"}
          label="Tell us what you're into"
        />
        <Typography variant={"section-title-1"} label="This is your label" />
        <Typography
          variant={"section-title-2"}
          label="Tell us what you're into"
        />
        <Typography variant={"button"} label="Tell us what you're into" />
        <Typography
          variant={"section-content"}
          label="Upgrade to pro to unlock advanced features."
        />
        <Typography
          variant={"body-1"}
          label="We will send you marketing promotions, special offers, inspiration, and policy updates via email."
        />
        <Typography
          variant={"body-2"}
          label="We will send you marketing promotions, special offers, inspiration, and policy updates via email."
        />
        <Typography
          variant={"body-3"}
          label="We will send you marketing promotions, special offers, inspiration, and policy updates via email."
        />
        <Typography
          variant={"body-4"}
          label="We will send you marketing promotions, special offers, inspiration, and policy updates via email."
        />
        <Typography
          variant={"body-5"}
          label="We will send you marketing promotions, special offers, inspiration, and policy updates via email."
        />
        <Typography variant={"label"} label="Your full name" />
        <Typography variant={"graphic-label"} label="Graph Label" />
        <Typography variant={"calendar-label"} label="January" />
      </div>

      <h1>Buttons:</h1>
      <Divider />
      <div className="button-wrapper">
        <Button
          className="button-wrapper-child"
          variant="primary"
          // disabled={isDisabled}
          //loading={isLoading}
          onclickCallback={onClickHandler}
          // IconRight={() => <PlusOutlined />}
          // IconLeft={<PlusOutlined />}
          label="Primary"
        />
        <Button
          className="button-wrapper-child"
          variant="primary"
          // disabled={isDisabled}
          //loading={isLoading}
          onclickCallback={onClickHandler}
          // IconRight={() => <PlusOutlined />}
          IconLeft={<PlusOutlined />}
          label="Primary Icon"
        />
        <Button
          className="button-wrapper-child"
          variant="primary"
          disabled={true}
          //loading={isLoading}
          onclickCallback={onClickHandler}
          // IconRight={() => <PlusOutlined />}
          // IconLeft={<PlusOutlined />}
          label="Primary Disabled"
        />

        <Button
          className="button-wrapper-child"
          variant="primary"
          disabled={false}
          loading={true}
          onclickCallback={onClickHandler}
          // IconRight={() => <PlusOutlined />}
          // IconLeft={<PlusOutlined />}
          label="Primary Loading"
        />
      </div>
      <div className="button-wrapper">
        <Button
          className="button-wrapper-child"
          variant="secondary"
          onclickCallback={onClickHandler}
          label="Secondary"
          // IconRight={() => <PlusOutlined />}
        />
        <Button
          className="button-wrapper-child"
          variant="secondary"
          onclickCallback={onClickHandler}
          label="Secondary Icon"
          IconLeft={<PlusOutlined />}
          // IconRight={() => <PlusOutlined />}
        />
        <Button
          className="button-wrapper-child"
          variant="secondary"
          onclickCallback={onClickHandler}
          label="Secondary Disabled"
          disabled={true}
          // IconRight={() => <PlusOutlined />}
        />
        <Button
          className="button-wrapper-child"
          variant="secondary"
          onclickCallback={onClickHandler}
          label="Secondary loading"
          loading={true}
          // IconRight={() => <PlusOutlined />}
        />
      </div>
      <div className="button-wrapper">
        <Button
          className="button-wrapper-child"
          variant="ghost"
          onclickCallback={onClickHandler}
          label="Ghost"
          // IconLeft={<PlusOutlined />}
        />
        <Button
          className="button-wrapper-child"
          variant="ghost"
          onclickCallback={onClickHandler}
          label="Ghost Icon"
          IconLeft={<PlusOutlined />}
        />
        <Button
          className="button-wrapper-child"
          variant="ghost"
          onclickCallback={onClickHandler}
          label="Ghost Disabled"
          disabled={true}
          // IconLeft={<PlusOutlined />}
        />
        <Button
          className="button-wrapper-child"
          variant="ghost"
          onclickCallback={onClickHandler}
          label="Ghost Loading"
          loading={true}
          // IconLeft={<PlusOutlined />}
        />
      </div>
      <h1>Text Fields:</h1>
      <Divider />
      <div className="textfield-wrapper">
        <div>
          <FormLayout>
            <TextField
              label="Label will appear here"
              supportiveText="This is supportive text"
              placeholder="Add Text"
              name="text_001"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            />
          </FormLayout>
        </div>
        <div>
          <FormLayout>
            <TextField
              label="Label will appear here"
              supportiveText="This is supportive text"
              placeholder="Add Text"
              name="text_002"
              prefix="$"
            />
          </FormLayout>
        </div>
        <div>
          <FormLayout>
            <TextField
              label="Label will appear here"
              supportiveText="This is supportive text"
              placeholder="Add Text"
              name="text_001"
              suffix="px"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            />
          </FormLayout>
        </div>
      </div>

      <h1>Text Area:</h1>
      <Divider />
      <div className="textfield-wrapper">
        <div>
          <FormLayout>
            <TextArea
              label="Label will appear here"
              supportiveText="This is supportive text"
              placeholder="Add Text"
              name="textarea_001"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            />
          </FormLayout>
        </div>
        <div>
          <FormLayout>
            <TextArea
              label="Label will appear here"
              supportiveText="This is supportive text"
              placeholder="Add Text"
              name="textarea_002"
              disabled
            />
          </FormLayout>
        </div>
        <div>
          <FormLayout>
            <TextArea
              label="Label will appear here"
              placeholder="Add Text"
              name="textarea_003"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            />
          </FormLayout>
        </div>
      </div>
      <h1>Link:</h1>
      <Divider />
      <div className="button-wrapper">
        <Link
          label={"create primary large"}
          disabled={false}
          size="large"
          variant="primary"
          url={"/"}
        />
        <Link
          label={"disabled primary large"}
          disabled={true}
          size="large"
          variant="primary"
          url={"/"}
        />

        <Link
          label={"create primary small"}
          size="small"
          variant="primary"
          url={"/"}
        />
        <Link
          label={"disabled primary small"}
          disabled={true}
          size="small"
          variant="primary"
          url={"/"}
        />
      </div>
      <div className="button-wrapper">
        <Link
          label={"create secondary large"}
          disabled={false}
          size="large"
          variant="secondary"
          url={"/"}
        />
        <Link
          label={"disabled secondary large"}
          disabled={true}
          size="large"
          variant="secondary"
          url={"/"}
        />

        <Link
          label={"create secondary small"}
          size="small"
          variant="secondary"
          url={"/"}
        />
        <Link
          label={"disabled secondary small"}
          disabled={true}
          size="small"
          variant="secondary"
          url={"/"}
        />
      </div>

      <h1>Select:</h1>
      <Divider />
      <div className="select-wrapper">
        <div>
          <FormLayout>
            <Select
              label="Label will appear here"
              supportiveText="This is supportive text"
              placeholder="Select an option"
              name="select_001"
              options={dummyOptions}
            />
          </FormLayout>
        </div>

        <div>
          <FormLayout>
            <Select
              label="Label will appear here"
              supportiveText="This is supportive text"
              placeholder="Select an option"
              disabled
              name="select_002"
              options={dummyOptions}
            />
          </FormLayout>
        </div>

        <div>
          <FormLayout>
            <Select
              label="Label will appear here"
              supportiveText="This is supportive text"
              placeholder="Select an option"
              name="select_003"
              options={dummyOptionsWithJsx}
            />
          </FormLayout>
        </div>

        <div>
          <FormLayout>
            <Select
              label="Label will appear here"
              supportiveText="This is supportive text"
              name="select_004"
              options={dummyOptionsWithJsx}
              showSearch
              optionFilterProp="title"
            />
          </FormLayout>
        </div>
      </div>

      <h1>Search Bar (Large):</h1>
      <Divider />
      <div className="select-wrapper">
        <div>
          <FormLayout>
            <Select
              label="Label will appear here"
              supportiveText="This is supportive text"
              name="select_005"
              options={dummyOptionsWithJsx}
              showSearch
              largeSearch
              optionFilterProp="title"
            />
          </FormLayout>
        </div>

        <div>
          <FormLayout>
            <Select
              label="Label will appear here"
              supportiveText="This is supportive text"
              name="select_005"
              options={dummyOptions}
              showSearch
              largeSearch
              optionFilterProp="title"
            />
          </FormLayout>
        </div>
        <div>
          <FormLayout>
            <Select
              label="Label will appear here"
              supportiveText="This is supportive text"
              name="select_005"
              options={dummyOptions}
              showSearch
              largeSearch
              optionFilterProp="title"
              disabled
            />
          </FormLayout>
        </div>
      </div>
      <h1>CheckBox:</h1>
      <Divider />
      <div className="checkbox-wrapper">
        <CheckBox
          label={""}
          status={checkboxStatus1}
          callback={onChangeCheckBox1}
        />
        <CheckBox
          label={"label1"}
          status={checkboxStatus}
          callback={onChangeCheckBox}
        />
        <CheckBox
          disabled={true}
          label={"label3 "}
          status={true}
          callback={onChangeCheckBox}
        />
      </div>
      <h1>Radio:</h1>
      <Divider />
      <div>
        <Radio
          value={value}
          data={[
            { value: 1, label: "Label 1", disabled: false },
            { value: 2, label: "Label 2", disabled: false },
            { value: 3, label: "Label 3", disabled: true },
          ]}
          callback={onChangeRadio}
        />
      </div>

      <h1>Tabs:</h1>
      <Divider />
      <div>
        <Tabs
          tabs={[
            {
              key: "1",
              title: "Tab 1",
              content: "Content for tab 1",
              prefix: <Home />,
            },
            {
              key: "2",
              title: "Tab 2",
              content: "Content for tab 2",
              suffix: <Badge count="20" className="counter" />,
            },
            {
              key: "3",
              title: "Tab 3",
              content:
                "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
              prefix: <Home />,
              suffix: <Badge count="20" className="counter" />,
            },
          ]}
        />
      </div>
      <h1>Date picker:</h1>
      <Divider />
      <DatePicker format="DD-MM-YYYY" callback={onChangeDate} />

      <h1>Alerts:</h1>
      <Divider />
      <div className="alert-view-wrapper">
        <Alert
          message="Success Tips"
          description="Detailed description and advice about successful copywriting."
          type="success"
          showIcon
        />
        <Alert
          description="Detailed description and advice about successful copywriting."
          type="success"
          showIcon
          closable={false}
        />

        <Alert
          message="Error Tips"
          description="Detailed description and advice about successful copywriting."
          type="error"
          showIcon
        />
        <Alert
          description="Detailed description and advice about successful copywriting."
          type="error"
          showIcon
          closable={false}
        />

        <Alert
          message="Info Tips"
          description="Detailed description and advice about successful copywriting."
          type="info"
          showIcon
        />
        <Alert
          description="Detailed description and advice about successful copywriting."
          type="info"
          showIcon
          closable={false}
        />

        <Alert
          message="Warning Tips"
          description="Detailed description and advice about successful copywriting."
          type="warning"
          showIcon
        />
        <Alert
          description="Detailed description and advice about successful copywriting."
          type="warning"
          showIcon
          closable={false}
        />
      </div>
      <h1>Notification Toast:</h1>
      <Divider />
      <p>Text with Title </p>
      <div className="button-wrapper">
        {contextHolder}
        <Button
          variant="primary"
          onclickCallback={openNotificationInfoWithTitle}
          label={"info"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationSuccessWithTitle}
          label={"success"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationWarningWithTitle}
          label={"warning"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationErrorWithTitle}
          label={"error"}
        />
      </div>
      <p>Text Only</p>
      <div className="button-wrapper">
        <Button
          variant="primary"
          onclickCallback={openNotificationInfoLabelOnly}
          label={"info"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationSuccessLabelOnly}
          label={"success"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationWarningLabelOnly}
          label={"warning"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationErrorLabelOnly}
          label={"error"}
        />
      </div>
      <p>Text With Action</p>
      <div className="button-wrapper">
        <Button
          variant="primary"
          onclickCallback={openNotificationWithActionInfo}
          label={"info"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationWithActionSuccess}
          label={"success"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationWithActionWarning}
          label={"warning"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationWithActionError}
          label={"error"}
        />
      </div>
      <p>Text with Title and Action</p>
      <div className="button-wrapper">
        <Button
          variant="primary"
          onclickCallback={openNotificationWithActionandTitleInfo}
          label={"info"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationWithActionandTitleSuccess}
          label={"success"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationWithActionandTitleWarning}
          label={"warning"}
        />
        <Button
          variant="primary"
          onclickCallback={openNotificationWithActionandTitleError}
          label={"error"}
        />
      </div>
    </div>
  );
};

export default DesignSystems;
