import React from "react";
import { Button as AntdButton } from "antd";
import { Typography } from "../index";
import Icon from "@ant-design/icons";

const LoaderSvg = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4498_66530)">
      <path
        d="M8 12V14.6667"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8254 10.8242L12.7121 12.7109"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.28638 12.7109L5.17304 10.8242"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8H14.6667"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.33325 8H3.99992"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8254 5.17182L12.7121 3.28516"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.28662 3.28516L5.17329 5.17182"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 1.33203V3.9987"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4498_66530">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);

export const Button = ({
  variant,
  disabled,
  loading,
  onclickCallback,
  IconLeft,
  IconRight,
  label,
  className,
  buttonVariant,
  htmlType,
}) => {
  return (
    <AntdButton
      className={`${variant} ${className ?? ""}`}
      type={variant}
      htmlType={htmlType}
      disabled={disabled}
      loading={loading}
      icon={IconLeft}
      onClick={onclickCallback}
    >
      {loading && (
        <Icon spin className="customLoaderIcon" component={LoaderSvg} />
      )}
      <Typography
        variant={`${buttonVariant ? buttonVariant : "button"}`}
        label={label}
      />
      {IconRight && IconRight()}
    </AntdButton>
  );
};
