export const ArrowUp = ({
  width = "16",
  height = "16",
  className,
  onClick,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M8 12.6663V3.33301"
      stroke="#989FAE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.33301 7.99967L7.99967 3.33301L12.6663 7.99967"
      stroke="#989FAE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
