import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRouter } from "../../utils/router/customRouterHook";
import { useDispatch, useSelector } from "react-redux";
import { IncommLoaderIcon } from "../../design-systems";
import { Divider, Layout, Menu, Tooltip } from "antd";
// import { AlignRightOutlined, MenuOutlined } from "@ant-design/icons";
// import Logo from "../../AlignRightOutlined/img/admin-logo.png";
import Logo from "../../assets/img/incomm_logo.svg";
import { AlignRightOutlined, MenuOutlined, BankFilled, BankTwoTone } from "@ant-design/icons";
// import Logo from "../../assets/img/admin-logo.png";
import { OrganizationIcon } from "../../design-systems/Icons/OrganizationIcon";
import { ROLE_CONSTANT } from "../../utils/constants";
import { deleteEmployerProgressData, setShowSecondSection } from "../../redux/actions";
import { getReportName } from "../../utils";
import MenuOutlinedIcon from "../../design-systems/Icons/MenuOutlinedIcon";
import { DentalIcon, HospitalIcon, EnrollmentIcon } from "../../design-systems";

const displayReports =
  process.env.REACT_APP_PUBLIC_DISPLAY_REPORTS === "true" ? true : false;

function SideBar({ myRoutes }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const router = useRouter();
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const [selectedMenu, setSelectedMenu] = useState("/dashboard")
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [dynamicReports, setDynamicReports] = useState([]);

  const { reportsData } = useSelector(state => state.reports)
  useEffect(() => {
    setDynamicReports(reportsData?.reports)
    if (reportsData?.reports?.length > 0) {
      let reports = [...reportsData?.reports];
      reports.forEach(item => {
        if (item.type === "contributions") {
          item.customPath = true;
        }
      });
      setDynamicReports(reports)
    }
  }, [reportsData?.reports])

  useEffect(() => {
    // Update selected menu based on route changes
    setSelectedMenu(router.pathname);
  }, [router.pathname]);

  const renderDynamicReports = () => {
    let enrollmentReport = null;
    const hsaReports = [];
    const fsaReports = [];
    dynamicReports.forEach((report) => {
      const reportKey = `${report.report_type?.toLowerCase()}/${report.type}`
      const menuItem = (<Menu.Item
        key={reportKey}
        onClick={() => {
          const path = `/admin/reports/${reportKey}`;
          router.push(path)
          setSelectedMenu(`/admin/reports/${reportKey}`);
        }}
        className={router.pathname === `/admin/reports/${reportKey}` ? "sidebar-submenu-selected-background" : "sidebar-sub-menu"}
      >
        <Tooltip
          placement="rightBottom"
          style={{ color: "#fff" }}
          title={getReportName[reportKey]}
        >
        {getReportName[reportKey]}
          
        </Tooltip>

      </Menu.Item>);

      if (report.report_type === "FSA") {
        fsaReports.push(menuItem)
      } else if (report.report_type === "HSA") {
        hsaReports.push(menuItem)
      } else {
        enrollmentReport = menuItem;
      }
    });
    return (
      <>
      {
        process.env.REACT_APP_PUBLIC_DISPLAY_ENROLLMENT_REPORT === "true" && enrollmentReport && 
        <>{
          <Menu.Item
            key="enrollment"
            icon={<EnrollmentIcon/>}
            onClick={() => {
              const path = `/admin/reports/enrollment`;
              router.push(path)
              setSelectedMenu(`/admin/reports/enrollment`);
            }}
            id="report-enrollment-menu"
            className={router.pathname === `/admin/reports/enrollment` ? "sidebar-submenu-selected-background" : "sidebar-sub-menu"}
            popupClassName="report-submenu-popup"
            style={{paddingLeft: "12px"}}
          >
            <Tooltip
              placement="rightBottom"
              style={{ color: "#fff" }}
              title={"Enrollment Report"}
            >
              <span>Enrollment Report</span>
            </Tooltip>
          </Menu.Item>
        }</>
      },
        {
          hsaReports.length > 0 &&
          <SubMenu
            key="HSAReports"
            icon={<HospitalIcon />}
            title="HSA Reports"
            //onTitleClick={() => handleSubMenuClick("HSAReports")}
            // openKeys={openKeys}
            mode="inline"
            className="report-submenu"
            popupClassName="report-submenu-popup"
          >
            {hsaReports.map((element) => element)}
          </SubMenu>
        }
        {
          fsaReports.length > 0 &&
          <SubMenu
            key="FSAReports"
            icon={<DentalIcon />}
            title="FSA Reports"
            //onTitleClick={() => handleSubMenuClick("FSAReports")}
            // openKeys={openKeys}
            mode="inline"
            className="report-submenu"
            popupClassName="report-submenu-popup"
          >
            {fsaReports.map((report) => report)}
          </SubMenu>
        }
      </>
    );
  };

  const { currentEmployer, showSection2 } = useSelector((state) => state.employer);

  let loc = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
  const role = useSelector((state) => state?.userDetails?.roleType);

  useEffect(() => {
    if (role) {
      if (role === ROLE_CONSTANT.ZENDA_ADMIN) {
        dispatch(setShowSecondSection(showSection2))
      }
      if (role !== ROLE_CONSTANT.ZENDA_ADMIN) {
        dispatch(setShowSecondSection(true))
      }
    }
  }, [role])

  const matchRoutes = (arr) => {
    let navIndexArr = [];
    arr.filter((el) => {
      let i = el.views.findIndex(
        (v) =>
          router.pathname === `${v.layout}${v.path}` ||
          v.childRoutes.includes(loc) ||
          v.childRoutes.includes(location.pathname),
      );
      if (i >= 0) {
        navIndexArr.push(el.views[i]);
        // setOpenKeys([`${el.index}`])
        return el.views[i];
      } else {
        return [];
      }
    });
    return navIndexArr;
  };

  const calCulateSelected = () => {
    let navIndexArr = myRoutes.filter((el) => {
      if (
        router.pathname === `${el.layout}${el.path}` ||
        el.childRoutes.includes(loc) ||
        el.childRoutes.includes(location.pathname)
      ) {
        return el;
      }

      return false;
    });

    if (navIndexArr.length === 0) {
      navIndexArr = matchRoutes(
        myRoutes.filter((el) => {
          if (el.collapse === true) return el.views;

          return false;
        }),
      );
    }

    let index = navIndexArr.length ? [navIndexArr[0].index] : ["0"];
    // setSelectedKeys([index])
    return index;
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const renderSidebarChildOptions = (val) => {
    return (
      <SubMenu
        key={val.index}
        icon={<i className="ni ni-settings-gear-65" />}
        title={val.name}
        // onTitleClick={() => {
        //   if (openKeys.includes(val.index)) {
        //     setOpenKeys([]);
        //   } else {
        //     setOpenKeys([val.index]);
        //   }
        // }}
      >
        {val.views.map((el, i) => {
          let showMenu = el.roles.includes(role);
          if (!showMenu) return true;
          return (
            <Menu.Item
              onClick={() => router.push(`${el.layout}${el.path}`)}
              key={el.index}
            >
              {el.name}
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  };
  const section2Routes = ["Dashboard", "Employees", "Contributions", "Reports", "Current Employer"];
  const routesWithoutReports = myRoutes.filter(
    (route) => route.path !== "/reports",
  );

  const renderSection2Menu = () => {
    let routes = displayReports ? myRoutes : routesWithoutReports;
    routes = routes.filter(el => section2Routes.includes(el.name));
    return routes.map((el, i) => {
      let showMenu = el?.roles?.includes(role);
      if (el.name === "Current Employer") {
        return <Menu.Item
          onClick={() => {
            setSelectedMenu(el.path)
            router.push("/admin/employer-detail");
          }}
          className={"employer-name-sidebar employer-name-margin "}
          icon={<div className={collapsed ? "employer-icon-sidebar-collapsed" : "employer-icon-sidebar"}><OrganizationIcon className="sidebar-icon" /></div>}
          key="9"
        >
          {!collapsed ?
            <Tooltip
              style={{ color: "#fff" }}
              title={currentEmployer?.data?.employer_name?.length > 25 ? currentEmployer?.data?.employer_name : ""}
            >
              <div
                className="current-employer-name ant-menu-title-content employer-name-color">
                {currentEmployer?.data.employer_name}
              </div>
            </Tooltip> :
            <div
              className=" ant-menu-title-content">
              {currentEmployer?.data.employer_name}
            </div>
          }

        </Menu.Item>
      }
      if (el.name === "Reports") {
        return renderReportsSubMenu()
      }
      if (!showMenu) return true;
      return el.collapse === true ? (
        renderSidebarChildOptions(el)
      ) : (
        <Menu.Item
          onClick={() => {
            setSelectedMenu(el.path)
            router.push(`${el.layout}${el.path}`);
          }}
          key={el.index}
          icon={el.getIcon ? el.getIcon() : <i className={el.icon} />}
        >
          {el.name}
        </Menu.Item>
      );
    })
  }

  const renderSidebarParentOptions = () => {
    let routes = displayReports ? myRoutes : routesWithoutReports;
    routes = routes.filter(el => !section2Routes.includes(el.name))
    const searchMenu = routes.find((el) => el.name === "Search");
    return routes.map((el, i) => {
      if (role === ROLE_CONSTANT.ZENDA_ADMIN) {
        if (el.name === "Employers" || el.name === "Claims") {
          return <Menu.Item
            onClick={() => {
              setSelectedMenu(el.path)
              dispatch(deleteEmployerProgressData());
              dispatch(setShowSecondSection(false))
              localStorage.removeItem("claimListApiData")
              sessionStorage.removeItem("isSwitchEmpClicked")
              router.push(`${el.layout}${el.path}`);
            }}
            key={el.index}
            icon={el.getIcon ? el.getIcon() : <i className={el.icon} />}
          >
            {el.name}
          </Menu.Item>
        }

        if (el.name === "Search") {
          return <>
            <Menu.Item
              onClick={() => {
                setSelectedMenu(el.path);
                dispatch(setShowSecondSection(false))
                sessionStorage.removeItem("isSwitchEmpClicked")
                router.push(`${el.layout}${el.path}`);
              }}
              key={el.index}
              icon={el.getIcon ? el.getIcon() : <i className={el.icon} />}
            >
              {el.name}
            </Menu.Item>
            {searchMenu && showSection2 ? <Divider className="sidebar-divider" /> : null}
          </>
        }
      }



      if (el.name === "Employers" || el.name === "Claims" || el.name === "Current Employer") {
        return null;
      }
      let showMenu = el?.roles?.includes(role);

      if (!showMenu) return true;
      return el.collapse === true ? (
        renderSidebarChildOptions(el)
      ) : (
        <Menu.Item
          onClick={() => {
            setSelectedMenu(el.path)
            router.push(`${el.layout}${el.path}`);
          }}
          className="test1111"
          key={el.index}
          icon={el.getIcon ? el.getIcon() : <i className={el.icon} />}
        >
          {el.name}
        </Menu.Item>
      );
    })
  };

  const handleSubMenuClick = (key) => {
    // Set the opened keys when the SubMenu is clicked
    setOpenKeys((prevOpenKeys) => {
      if (prevOpenKeys.includes(key)) {
        return prevOpenKeys.filter((k) => k !== key);
      } else {
        return [...prevOpenKeys, key];
      }
    });
  };

  const renderReportsSubMenu = () => {
    const reportsSubMenu = myRoutes?.find((el) => el.name === "Reports");
    if (reportsSubMenu && reportsSubMenu?.dynamicChildRoutes && dynamicReports?.length) {
      return (
        <SubMenu
          key={reportsSubMenu.index}
          icon={reportsSubMenu.getIcon ? reportsSubMenu.getIcon() : <i className={reportsSubMenu.icon} />}
          title={reportsSubMenu.name}
          //onTitleClick={() => handleSubMenuClick(reportsSubMenu.index)}
          // openKeys={openKeys}
          id="report-menu"
          popupClassName="report-submenu-popup"
        >
          {
            renderDynamicReports()
          }
        </SubMenu>
      );
    }
    return null;
  };

  return (
    <Sider className="custom-sider" collapsible collapsed={collapsed} width="280" trigger={null}>
      <div className="logo">
        <Link to="/">
          {collapsed ? <div className="collapse-sidebar-icon"><IncommLoaderIcon color="white" /></div> : <img src={Logo} alt="Zenda-logo" />}
        </Link>
        <div onClick={() => toggle()} className="collapse-sidebar-icon" style={{ cursor: "pointer" }}>
          {collapsed ? <MenuOutlinedIcon color="#fff" /> : <AlignRightOutlined />}
        </div>
      </div>
      <Menu
        className="sidebar-menu"
        //openKeys={openKeys}
        selectedKeys={calCulateSelected()}
        inlineCollapsed={collapsed}
        mode="inline"
        style={{
          height: '100%',
          borderRight: "2px solid $bg-color-incomm",
        }}
      >
        {renderSidebarParentOptions()}
        {showSection2 && renderSection2Menu()}
      </Menu>
    </Sider>
  );
}

export { SideBar };
