export const PAYROLL_FREQUENCY = [
  { id: 1, value: "Daily" },
  { id: 2, value: "Weekly" },
  { id: 3, value: "Semi-weekly" },
  { id: 4, value: "Bi weekly" },
  { id: 5, value: "Monthly" },
  { id: 6, value: "Semi-monthly" },
  { id: 7, value: "Quarterly" },
  { id: 8, value: "Annually" },
];

export const PAYROLL_PROVIDER = [
  { id: 1, value: "Gusto" },
  { id: 2, value: "Bamboo HR" },
  { id: 3, value: "Justworks" },
  { id: 4, value: "Paylocity" },
  { id: 5, value: "QuickBooks Payroll" },
  { id: 6, value: "Rippling" },
  { id: 7, value: "Run Powered by ADP" },
  { id: 8, value: "Zenefits" },
];

export const EMPLOYER_STATUS = [
  { id: 1, value: "Pre Launch setup" },
  { id: 2, value: "Employer InComm Benefits setup complete" },
  { id: 3, value: "On Hold or Suspended" },
];

export const FSA_LABELS = {
  min_employee_contribution_amount: "Minimum contribution",
  max_employee_contribution_amount: "Maximum contribution",
  employer_contribution_amount: "Employer contribution Amount",
  run_out_period_in_days: "Runout period",
  dateOfServiceThrough: "Date of service through",
  allow_claim_file_post_termination_within_service_date:
    "Allow claim to be filed with a date of service after termination",
    terminated_employees_run_out: "Runout period for terminated employee",
  daysFollowingBy: "Days following by",
  is_rollover_enabled: "Rollover or grace period",
  min_rollover_amount: "Minimum rollover",
  max_rollover_amount: "Maximum rollover",
  grace_period_end_date: "Date of ending grace period",
};