import React from "react";
import { Radio as AntRadio, Form } from "antd";
import { Typography } from "../index";

export const Radio = ({ disabled, data, rules, label, name, defaultValue, defaultChecked }) => {
  return (
    <Form.Item
      label={label}
      rules={rules}
      name={name}
      className="radio-wrapper">
      <AntRadio.Group
        value={defaultValue}
        disabled={disabled}
      >
        {data.map((el, index) => (
          <AntRadio defaultChecked={defaultChecked} disabled={el.disabled} value={el.value} key={index}>
            <Typography
              className={"radio-label"}
              variant={"body-3"}
              label={el.label}
            />
          </AntRadio>
        ))}
      </AntRadio.Group>
    </Form.Item>
  )
};
