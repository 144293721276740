const awsConfig = {
  ssr: true,
  Auth: {
    //Amazon Cognito Region
    region: process.env.REACT_APP_PUBLIC_AWS_REGION,
    //Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_PUBLIC_USER_POOL_ID,
    //Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_PUBLIC_USER_POOL_WEBCLIENT_ID,
    //Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_PUBLIC_AUTH_DOMAIN,
      redirectSignIn: process.env.REACT_APP_PUBLIC_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_PUBLIC_OAUTH_REDIRECT_SIGNOUT,
      responseType: "code",
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    },
    //Customized storage object
    storage: window.localStorage,
  },
};

export default awsConfig;
