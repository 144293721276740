export const employeeChartConfigOptions = (data, totalCount, onLegendClick) => {
    const options = {
        legend: {
            show: false,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            onItemClick: {
                toggleDataSeries: false
            },
            onItemHover: {
                highlightDataSeries: false
            },
        },
        labels: data.map(opt => opt.account_holder_status),
        dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
                return opt.w.config.series[opt.seriesIndex]
            },
            style: {
                fontSize: "12px",
                // fontWeight: "bold",
                color: "#000"
            }
        },
        chart: {
            type: 'donut',
            // commenting till we get api updated
            // events: {
            //     legendClick: onLegendClick
            // }
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: "75%",
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            offsetY: 0
                        },
                        value: {
                            show: true,
                            formatter: function () {
                                return totalCount
                            },
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#000"
                        },
                        total: {
                            showAlways: true,
                            show: true,
                            label: "Total employees:",
                            formatter: function () {
                                return totalCount
                            },
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#000"
                        }
                    }
                },
            }
        },
        responsive: [{
            breakpoint: 780,
            options: {
                chart: {
                    width: 400
                },
                legend: {
                    position: 'bottom'
                }
            }

        }],

    }
    return options
}