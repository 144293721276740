import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminDetails,
  getAllAvailableReports,
  getCurrentEmployer,
  getEmployerTypeData,
  getLoggedInAdminData,
} from "../../redux/actions";
import { SwitchEmployer } from "../admin/settings/employer";
import { ROLE_CONSTANT } from "../../utils/constants";

import { Layout, Row, Col } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { UserDropdown } from "./UserDropdown";
import useShowSecondSection from "../../hooks/useShowSection";
import { identifyEnvironment } from "../../utils";

function EmployerName({
  isZendaAdmin,
  showEmployerSelect,
  setShowEmployerSelect,
}) {
  const currentEmployer = useSelector(
    (state) => state.employer.currentEmployer,
  );

  const getEmployerName = () =>
    `${currentEmployer.data.employer_name} ${isZendaAdmin ? `(${currentEmployer.data.employer_id})` : ""
    }`;

  if (window.location.pathname.includes("search")) {
    return (
      <div className={"avatar"}>
        <h2>Global</h2>
      </div>
    );
  } else {
    return (
      <div
        className={isZendaAdmin ? "avatar" : "employer-name"}
        onClick={
          isZendaAdmin ? () => setShowEmployerSelect(!showEmployerSelect) : null
        }
      >
        <h2>{currentEmployer ? getEmployerName() : null}</h2>
        {showEmployerSelect ? <LeftOutlined /> : <RightOutlined />}
      </div>
    );
  }
}

function AdminHeader({ showWarning }) {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const isZendaAdmin = userDetails?.roleType === ROLE_CONSTANT.ZENDA_ADMIN;

  useEffect(() => {
    dispatch(getAdminDetails());
    dispatch(getCurrentEmployer());
    dispatch(getLoggedInAdminData());
    dispatch(getAllAvailableReports());

    isZendaAdmin && dispatch(getEmployerTypeData());
  }, [dispatch, isZendaAdmin]);

  const { Header } = Layout;

  const url = window.location.href;
  const environment = identifyEnvironment(url);

  return (
    <Header>
      <Row>
        <Col sm={20}>
          {
            !sessionStorage.getItem("isSwitchEmpClicked") &&
            isZendaAdmin &&
            environment &&
            <div className="blink_me d-flex justify-content-center">{environment}</div>
          }
        </Col>
        <Col sm={4} >
          <div className="d-flex justify-flex-end">
            <UserDropdown />
          </div>
        </Col>
      </Row>
    </Header>
  );
}

export { AdminHeader };
