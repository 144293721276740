import { Col, Row } from "antd";
import {
  Button,
  TextField,
  Typography,
} from "../../../../../../design-systems";
import { mandatory, validateAccountNumber, validateRoutingNumber } from "../../../../../../utils/validations";
import { useState } from "react";

export const Division = ({ key, name, restField, division }) => {
  const [addBank, setAddBank] = useState(false);

  return (
    <Col xs={24} md={24} className="division-col" key={key}>
      <div className="division-wrapper">
        <div className="division-head">
          <Typography
            variant="subhead-2"
            label={division?.division_identifier}
          />

          {!addBank && (
            <Button
              className="button-wrapper-child"
              variant="secondary"
              onclickCallback={() => setAddBank(true)}
              label="Add bank"
            />
          )}
        </div>

        {addBank && (
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <TextField
                {...restField}
                maxLength={9}
                label="Routing number"
                placeholder="Enter routing number"
                name={[name, "routing_number"]}
                onKeyPress={(event) => {
                  if (!/[0-9-]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid routing number',
                  },
                  {
                    validator: validateRoutingNumber,
                  },
                ]}
                onPaste={(event) => {
                  const clipboardData = event.clipboardData || window.clipboardData;
                  const pastedText = clipboardData.getData('Text');
    
                  if (!/^\d+$/.test(pastedText)) {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <TextField
                {...restField}
                label="Account number"
                placeholder="Enter account number"
                name={[name, "account_number"]}
                maxLength={12}
                onKeyPress={(event) => {
                  if (!/[0-9-]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid account number',
                  },
                  {
                    validator: validateAccountNumber,
                  },
                ]}
                onPaste={(event) => {
                  const clipboardData = event.clipboardData || window.clipboardData;
                  const pastedText = clipboardData.getData('Text');
    
                  if (!/^\d+$/.test(pastedText)) {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <TextField
                {...restField}
                label="Name of account"
                placeholder="Enter name of account"
                name={[name, "name_of_account"]}
                rules={[
                  mandatory({
                    message: "Please enter a valid name of account",
                  }),
                ]}
              />
            </Col>
          </Row>
        )}
      </div>
    </Col>
  );
};
