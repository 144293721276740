import React, { useState } from "react";
import { maxLength, required } from "../../../utils/validations";

import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { DownloadFileTemplateButton } from "../../../components/common/DownloadFileTemplateButton";

import { Button, Card, Col, Form, Input, Row } from "antd";
import { UploadEmployeesModal } from "../../../components/admin/employees";

function UploadNewEmployeeCensusFile() {
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onFinish = (values) => {
    setIsModalVisible(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <HeaderWithContent>
      <Card
        title="Upload new employees"
        bordered={false}
        className="data-grid upload-census-file"
      >
        <p className="text-muted">
          Currently, we only support the upload of new employees. Please use
          this feature only when adding new employees.
        </p>

        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          className="common-form-container"
        >
          <Row gutter={32} className={"formWrap"}>
            <Col span={12} xs={24} md={12} offset={6}>
              <Form.Item
                label="Census Batch Name"
                rules={[required("Census Batch Name"), maxLength(150)]}
                name="census_batch_name"
              >
                <Input
                  placeholder="Name"
                  prefix={<i className="ni ni-caps-small" />}
                  maxLength={150}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="btn-block">
            <Button type="primary" htmlType="submit" className="button-primary">
              Upload File
            </Button>
            <DownloadFileTemplateButton employee />
          </div>
        </Form>

        <UploadEmployeesModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          bulkUploadName={form.getFieldValue("census_batch_name")}
        />
      </Card>
    </HeaderWithContent>
  );
}

export { UploadNewEmployeeCensusFile };
