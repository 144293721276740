export const FEDERATED_ID_NAME = {
  OKTA_SSO: "OktaSSO",
  GOOGLE_SSO: "GoogleSSO",
  ZENDA_BIZ_SSO: "ZendaBizSSO",
};

export const EMAIL_DOMAIN_NAME = {
  OKTA_COM: "okta.com",
  GMAIL_COM: "gmail.com",
  GETZENDA_COM: "getzenda.com",
  ZENDA_BIZ_COM: "zenda.biz",
  INVALID_DOMAIN: "invalid_domain",
};
