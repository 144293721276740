import React from "react";

import { Card } from "antd";
import { ExternalLink, Typography } from "../../../../design-systems";
import MaskData from "../../../common/MaskData";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "../../../../utils/constants";
import moment from "moment";

function ProfileSection({ data, employee, panelHeader }) {
  const currentEmployer = useSelector((state) => state.employer.currentEmployer);
  const renderAddress = (a) => {
    return a ? (
      <span>
        {a.address_line_1 && <span>{a.address_line_1}, </span>}
        {a.address_line_2 && <span>{a.address_line_2}, </span>}
        {a.city && <span>{a.city}, </span>}
        {a.state && <span>{a.state} </span>}
        {a.postal_code && <span>{a.postal_code}</span>}
      </span>
    ) : null;
  };

  const handleEmployerIconClick = (e) => {
    e.preventDefault();
    const newTab = window.open(`/admin/employer-detail`, '_blank');
    if (newTab !== null) {
      newTab.propValue = "employerDetails";
    }
  }

  const renderEmployerIcon = () => {
    return <Link
      to={{ pathname: `/admin/employer-detail` }}
      target="_blank"
      className="employer-icon-link"
      onClick={(e) => handleEmployerIconClick(e)}
    >
      <ExternalLink />
    </Link>
  }

  return (
    <Card bordered={false} className="account-holder-cards">
      <div className="employer-name-wrapper d-flex justify-space-between">
        <Typography
          variant="subhead-3"
          label={`${employee?.firstName?.value ?? ""} ${employee?.middleName?.value ?? ""} ${employee?.lastName?.value ?? ""}`}
          className="employer-name word-break-all"
        />
        {panelHeader}
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Employer"
          className="card-info-label account-holder-label"
        />
        <div className="d-flex gap-10">
          <Typography
            variant="body-3"
            label={currentEmployer?.data?.employer_name}
            className="card-info-value account-holder-value"
          />
        {renderEmployerIcon()}
        </div>
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Login email"
          className="card-info-label account-holder-label word-break-all"
        />
        <Typography
          variant="body-3"
          label={data?.personal_email ?? "-"}
          className="card-info-value account-holder-value"
        />
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Date of birth"
          className="card-info-label account-holder-label"
        />
        <MaskData
          date={data?.date_of_birth ? moment(data?.date_of_birth).format(DATE_FORMAT.FOR_UI_FIRST_MM) : ""}
        />
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="SSN"
          className="card-info-label account-holder-label"
        />
        <Typography
          variant="body-3"
          label={data?.ssn}
          className="card-info-value account-holder-value"
        />
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="User address"
          className="card-info-label account-holder-label"
        />
        <Typography
          variant="body-3"
          label={renderAddress(data?.user_address)}
          className="card-info-value word-break-all account-holder-value"
        />
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Employee address"
          className="card-info-label account-holder-label"
        />
        <Typography
          variant="body-3"
          label={renderAddress(data?.employee_address)}
          className="card-info-value word-break-all account-holder-value word"
        />
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Account status"
          className="card-info-label account-holder-label"
        />
        <Typography
          variant="body-3"
          label={data?.account_holder_status}
          className="card-info-value account-holder-value"
        />
      </div>
      {
        data?.kyc_status &&
        <div className="account-holder-section-gap">
          <Typography
            variant="body-2"
            label="KYC status"
            className="card-info-label account-holder-label"
          />
          <Typography
            variant="body-3"
            label={data?.kyc_status ?? "NA"}
            className="card-info-value account-holder-value"
          />
        </div>
      }
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Mobile phone number"
          className="card-info-label account-holder-label"
        />
        <Typography
          variant="body-3"
          label={data?.phone_number}
          className="card-info-value account-holder-label"
        />
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Account number"
          className="card-info-label account-holder-label"
        />
        <Typography
          variant="body-3"
          label={data?.zenda_account_num}
          className="card-info-value account-holder-value"
        />
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Braze ID"
          className="card-info-label account-holder-label"
        />
        <Typography
          variant="body-3"
          label={data?.braze_id}
          className="card-info-value word-break-all account-holder-value"
        />
      </div>
      <div className="account-holder-section-gap">
        <Typography
          variant="body-2"
          label="Presignup hash"
          className="card-info-label account-holder-label"
        />
        <Typography
          variant="body-3"
          label={data?.presignup_hash}
          className="card-info-value word-break-all account-holder-value"
        />
      </div>
    </Card >
  );
}

export { ProfileSection };
