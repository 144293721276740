import { Modal } from "antd";
import { useMemo } from "react";
import { Button, Typography } from "../../../../design-systems";
import { Close } from "../../../../design-systems/Icons";

export const CancelModal = ({ open, onCancel, onOk, page }) => {
  const getModalData = useMemo(() => {
    if (page === "add-employee") {
      return "All the information entered on this page will be lost."
    } else if (page === "add-employer") {
      return "All the information entered on this page will be lost. Don’t forget, you can complete this page and save your progress."
    }
  }, [page]);

  return (
    <Modal
      title={
        <div className="cancel-modal-header">
          <Typography variant="subhead-2" label="Are you sure you want to cancel?" />

          <Close height={16} width={16} onClick={onCancel} />
        </div>
      }
      closable={false}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      className="cancel-employer-modal"
      centered
      footer={
        <div className="cancel-modal-footer">
          <Button
            variant="secondary"
            label="Yes, cancel"
            onclickCallback={onOk}
          ></Button>
          <Button
            variant="primary"
            label="No, keep editing"
            onclickCallback={onCancel}
          ></Button>
        </div>
      }
    >
      <Typography
        variant="body-3"
        label={getModalData}
      />
    </Modal>
  )
};
