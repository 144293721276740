import { Modal } from "antd";
import { useCallback } from "react";
import { useMemo } from "react";
import { Button, Close, Typography } from "../../../../design-systems";
import { GreenCheck } from "../../../../design-systems/Icons/GreenCheck";

export const SuccessModal = ({ plansData, title, onCancel, checkLastRecord, open, page, onPrimaryActionClick, onSecondaryActionClick, form, primaryLabel, secondaryLabel }) => {
  const business_name = form?.getFieldValue("business_name") || "";
  const getModalData = useCallback(() => {
    if (page === "add-employer") {
      return <div className="typo-label"><b>{`${business_name}`}</b> has been successfully added.</div>
    } else if (page === "claim-action") {
      if (title === "Approve claim") {
        return <div className="typo-label">Claim approved successfully.</div>
      } else if (title === "Partially approve") {
        return <div className="typo-label">Claim partially approved successfully.</div>
      } else if (title === "Deny") {
        return <div className="typo-label">Claim denied successfully.</div>
      } else if (title === "Cancel claim") {
        return <div className="typo-label">Claim cancelled successfully.</div>
      } else {
        return <div className="typo-label">Claim has been updated.</div>
      }
    } else if (page === "add-employee") {
      return <div className="typo-label">Employee added successfully.</div>
    } else if (page === "add-plan") {
      return <div className="typo-label">Plan added successfully.</div>
    }
  }, [page, title])
  return (
    <Modal
      title={
        page === "claim-action" &&
        <div className="cancel-modal-header justify-flex-end pointer d-flex">
          <Close height={16} width={16} onClick={onCancel} />
        </div>
      }
      closable={false}
      visible={open}
      className="success-employer-modal"
      centered
      footer={null}
    >
      <div className="green-check-icon">
        <GreenCheck />
      </div>
      {
        page === "add-employer" ?
          <Typography
            className="success-label"
            variant="subhead-2"
            label={<div className="typo-label"><b>{`${business_name}`}</b> has been successfully added.</div>}
          /> :
          <Typography
            className="success-label"
            variant="subhead-2"
            label={getModalData()}
          />
      }
      <div className="success-modal-footer">
        <Button
          variant="secondary"
          label={secondaryLabel}
          onclickCallback={onSecondaryActionClick}
        ></Button>
        {
          page === "claim-action" ?
            <Button
              disabled={checkLastRecord}
              label={primaryLabel}
              variant="primary"
              onclickCallback={() => {
                onPrimaryActionClick()
                onCancel()
              }}
            ></Button> :
            page === "add-plan" && !plansData?.length ?
              null :
              <Button
                label={primaryLabel}
                variant="primary"
                onclickCallback={onPrimaryActionClick}
              ></Button>
        }
      </div>
    </Modal>
  );
}