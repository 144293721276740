import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

import Logo from "../../assets/img/incomm_logo.svg";

function Header() {
  return (
    <div className="header">
      <div className="container">
        <Row gutter={0} align="middle">
          <Col xs={24} sm={24} md={20} lg={20} className="text-left">
            <Link to="/">
              <img src={Logo} alt="InComm Benefits-logo" className="imgHeight" />
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export { Header };
