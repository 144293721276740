import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAllDivisions,
  getBankDetailsByDivisionId,
} from "../../../redux/actions";
import { ROLE_CONSTANT } from "../../../utils/constants";
import { SpinLoader } from "../../../components/common/SpinLoader";

import { BankAccountForm } from "../../../components/admin/settings/banking";

import { Card, Col, Row, Divider } from "antd";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";

function Banking() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formHasChanged, setFormHasChanged] = useState(false);
  const [divisionList, setDivisionList] = useState(null);
  const [bankAccountData, setBankAccountData] = useState(null);

  const role = useSelector((state) =>
    state.userDetails && state.userDetails.roleType
      ? state.userDetails.roleType
      : ROLE_CONSTANT.ADMIN,
  );

  useEffect(() => {
    if (role === ROLE_CONSTANT.ADMIN) {
      history.push({ pathname: "/admin/dashboard" });
    }
  }, [role, history]);

  useEffect(() => {
    console.log("Inside launchBankAccount");
    launchBankAccount();

    // eslint-disable-next-line
  }, []);

  const launchBankAccount = () => {
    dispatch(
      getAllDivisions((res) => {
        console.log(res, "RES");
        setDivisionList(res.data);
        if (res.data.length) {
          getBankInfo(res.data[0].division_id);
        }
      }),
    );
  };

  const getBankInfo = (id) => {
    console.log({ id }, "54s");
    dispatch(
      getBankDetailsByDivisionId(id, (res) => {
        setBankAccountData(res.data);
      }),
    );
  };

  return (
    <HeaderWithContent showWarning={formHasChanged}>
      <div className="banking-acc-block">
        <Row gutter={0}>
          <Col span={24}>
            <Card title="Bank Account Settings" bordered={false}>
              <SpinLoader showContent={!!bankAccountData}>
                <div className="fund-method-block">
                  <h2>Funds Transfer Method</h2>
                  <h3>
                    Zenda will pull contributions from your business bank
                    account
                  </h3>
                  <Divider />
                  <BankAccountForm
                    setFormHasChanged={setFormHasChanged}
                    getBankInfo={getBankInfo}
                    divisionList={divisionList}
                    bankAccountData={bankAccountData}
                  />
                </div>
              </SpinLoader>
            </Card>
          </Col>
        </Row>
      </div>
    </HeaderWithContent>
  );
}

export { Banking };
