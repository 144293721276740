export const CONTRIBUTION_STATUSES = {
  SUCCESSFUL: "successful",
  ENABLED: "enabled",
  ERRORED: "errored",
  FAILED: "failed",
  CANCELLED: "cancelled",
  ACH_FAILED: "ach-failed",
  ACH_IN_PROGRESS: "ach-in-progress",
  EXEC_IN_PROGRESS: "exec-in-progress",
  AMT_CREDITED: "amt_credited",
  READY: "ready",
  INCOMPLETE: "incomplete",
  VALIDATION_IN_PROGRESS: "validation-in-progress",
  VALIDATION_ERROR: "validation-error",
};

export const LEDGER_TRANSACTION_STATUSES = {
  PROCESSING: "processing",
  POSTED: "posted",
  FAILED: "failed",
};
