import React from "react";
import { Modal } from "antd";

function WarningInfo({ isModalVisible, handleCancel, handleOk }) {
  return (
    <Modal
      title="Confirmation"
      visible={isModalVisible}
      okText="Continue"
      cancelText="Cancel"
      onOk={handleOk}
      onCancel={handleCancel}
      className="common-modal-style upload-csv warning-msg"
    >
      Be sure to save your bank information before proceeding.
    </Modal>
  );
}

export { WarningInfo };
