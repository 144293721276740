import { Collapse as AntCollapse } from "antd";
import { copayStep } from "../../../../../utils";
import { PanelHeader } from "../PanelHeader";
import { useEffect, useState } from "react";
import { Collapse } from "../../../../../design-systems";
import { useRouter } from "../../../../../utils/router";
import { useSelector } from "react-redux";

const { Panel } = AntCollapse;

export const Copays = ({ form2, form, copaysCate, setCopaysCate, mode }) => {
  const [expandedPanels, setExpandedPanels] = useState(copayStep.map(({ key }) => key));
  const router = useRouter()
  const { employerId } = useSelector((state) => state.employer)

  const onChange = async (updatedPanels) => {
    if (updatedPanels?.length < expandedPanels.length) {
      try {
        const recentKey = expandedPanels.find(
          (key) => !updatedPanels.includes(key),
        );
        setExpandedPanels(updatedPanels);
      } catch (err) { }
    } else {
      setExpandedPanels(updatedPanels);
    }
  };
  useEffect(() => {
    if (!employerId) {
      router.push('/admin/employer/add/1')
    }
  }, [employerId]);

  return (
    <Collapse
      className="horizontal-collapse test copays-collapse-add"
      onChange={onChange}
      activeKey={expandedPanels}
      panels={copayStep.map(({ content, title, key }) => (
        <Panel
          header={<PanelHeader page="add-emp" label={title} form={form} expandedPanels={expandedPanels} setExpandedPanels={setExpandedPanels} />}
          key={key}
          showArrow={true}
        >
          {content({ form, copaysCate, setCopaysCate, mode })}
        </Panel>
      ))}
    />
  );
};
