import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../utils/router/customRouterHook";
import {
  capitalizeFirstLetter,
  renderAdminStatusLabel,
  renderAdminStatusTag,
} from "../../../utils";
import { ADMIN_STATUSES } from "../../../utils/constants";
import { getUsersList } from "../../../redux/actions";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";

import { SearchOutlined } from "@ant-design/icons";
import { Card, Col, Row, Table, Input, Button } from "antd";

function UsersList() {
  const dispatch = useDispatch();
  const router = useRouter();

  const [userList, setUserList] = useState(null);
  const [searchRes, setSearchRes] = useState(null);

  const getUserList = () => {
    dispatch(
      getUsersList((res) => {
        setUserList(res.admins);
        setSearchRes(res.admins);
      }),
    );
  };

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line
  }, []);

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);

    router.history.push({
      pathname: router.pathname,
      search: `?page=${pagination.current}`,
    });
  };

  const applySearchFilter = (e) => {
    const term = e.target.value;

    if (term !== "") {
      const value = term.toLowerCase();

      const matches = userList?.filter((user) => {
        const fullName = `${user.first_name || ""} ${user.middle_name || ""} ${
          user.last_name || ""
        }`;
        const email = user.email;

        return (
          fullName.toLowerCase().includes(value) ||
          email.toLowerCase().includes(value)
        );
      });

      setSearchRes(matches);
    } else {
      setSearchRes(userList);
    }
  };

  const columns = [
    {
      title: "Name",
      render: (row) => {
        const fullName = `${row.first_name || ""} ${row.middle_name || ""} ${
          row.last_name || ""
        }`;

        return (
          <span className="label-name">{capitalizeFirstLetter(fullName)}</span>
        );
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span className="label-email">{text}</span>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      filters: Object.values(ADMIN_STATUSES).map((status) => ({
        text: renderAdminStatusLabel(status),
        value: status,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (tag) => renderAdminStatusTag(tag),
    },
    {
      title: "Role",
      dataIndex: "role",
    },
  ];

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card
            title="All Administrative Users"
            bordered={false}
            className="data-grid"
          >
            <SpinLoader showContent={!!userList}>
              <div className="search-grid-data">
                <div className="left-block">
                  <SearchOutlined />
                  <Input
                    placeholder="Search users"
                    onChange={(e) => applySearchFilter(e)}
                  />
                </div>
                <div className="right-block">
                  <Button
                    type="submit"
                    className="button-primary-s"
                    onClick={() => {
                      router.push("/admin/settings/user-detail/new-user");
                    }}
                  >
                    Add Admin User
                  </Button>
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={searchRes}
                onChange={onChange}
                bordered
                scroll={searchRes?.length ? { x: 1000 } : undefined}
                rowClassName={"pointer"}
                pagination={{
                  current: router.query.page ? Number(router.query.page) : 1,
                  hideOnSinglePage: true,
                }}
                onRow={(record) => ({
                  onClick: (event) => {
                    router.push(
                      `/admin/settings/user-detail/${record.admin_email_id}`,
                    );
                  },
                })}
              />
            </SpinLoader>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { UsersList };
