import React, { useMemo } from "react";
import { SectionHeader } from "./SectionHeader";
import { Typography } from "../../../../design-systems";
import { SpinLoader } from "../../../common/SpinLoader";
import { useState } from "react";
import { useEffect } from "react";
import { hideBankingData, stripLetters } from "../../../../utils";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import MaskData from "../../../common/MaskData";

export const BusinessInfo = ({ data }) => {
  const [showTin, setShowTin] = useState(false)
  const { contacts, business_information } = data || {}
  const { address, divisions, profile } = business_information || {}
  const showDivisions = useMemo(() => {
    if (divisions?.length === 1 && divisions[0].identifier === "default") {
      return true
    }
    return false
  }, [divisions])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (Object.keys(data).length) {
      setLoading(false)
    }
  }, [data])

  return (
    <>
      {loading ?
        <SpinLoader showContent={!!data}>
        </SpinLoader>
        :
        <div className="content">
          <SectionHeader name={"Profile"} />
          <div className="row-wrapper section-break">
            <div className="column-wrapper-left">
              <Typography variant={"subhead-2"} label={profile?.employer_name} />
              <div className="row-wrapper section-margin">
                {" "}
                <Typography
                  className={"data-label"}
                  variant={"body-3"}
                  label={`Tax ID: `}
                />{" "}
                <Typography
                  className={"data-content"}
                  variant={"body-2"}
                  label={profile?.tin_num}
                />
              </div>
              <div className="row-wrapper section-margin">
                {" "}
                <Typography
                  className={"data-label"}
                  variant={"body-3"}
                  label={`Employee identifier: `}
                />{" "}
                <Typography
                  className={"data-content"}
                  variant={"body-2"}
                  label={profile?.primary_employee_identifier}
                />
              </div>
            </div>
          </div>

          <SectionHeader name={"Address"} />
          <div className="row-wrapper section-break">
            <div className="column-wrapper-left address-column">
              <Typography
                className={"section-title"}
                variant={"body-2"}
                label={"Corporate address"}
              />
              <Typography
                className={"section-data section-margin"}
                variant={"body-3"}
                label={<>
                  {address?.corporate_address?.corporate_address_1 && <span>{address?.corporate_address?.corporate_address_1}, </span>}
                  {address?.corporate_address?.corporate_address_2 && <span>{address?.corporate_address?.corporate_address_2}, </span>}
                  {address?.corporate_address?.corporate_address_city && <span>{address?.corporate_address?.corporate_address_city}, </span>}
                  {address?.corporate_address?.corporate_address_state && <span>{address?.corporate_address?.corporate_address_state} </span>}
                  {address?.corporate_address?.corporate_address_postal_code && <span>{address?.corporate_address?.corporate_address_postal_code}</span>}
                </>
        }
              />
            </div>
            <div className="column-wrapper-left">
              <Typography
                className={"section-title"}
                variant={"body-2"}
                label={"Mailing address"}
              />
              <Typography
                className={"section-data section-margin"}
                variant={"body-3"}
                label={address?.same_as_corporate_address ? <>
                   {address?.corporate_address?.corporate_address_1 && <span>{address?.corporate_address?.corporate_address_1}, </span>}
                  {address?.corporate_address?.corporate_address_2 && <span>{address?.corporate_address?.corporate_address_2}, </span>}
                  {address?.corporate_address?.corporate_address_city && <span>{address?.corporate_address?.corporate_address_city}, </span>}
                  {address?.corporate_address?.corporate_address_state && <span>{address?.corporate_address?.corporate_address_state} </span>}
                  {address?.corporate_address?.corporate_address_postal_code && <span>{address?.corporate_address?.corporate_address_postal_code}</span>}
                </> : <>
                  {address?.mailing_address?.mailing_address_1 && <span>{address?.mailing_address?.mailing_address_1}, </span>}
                  {address?.mailing_address?.mailing_address_2 && <span>{address?.mailing_address?.mailing_address_2}, </span>}
                  {address?.mailing_address?.mailing_address_city && <span>{address?.mailing_address?.mailing_address_city}, </span>}
                  {address?.mailing_address?.mailing_address_state && <span>{address?.mailing_address?.mailing_address_state} </span>}
                  {address?.mailing_address?.mailing_address_postal_code && <span>{address?.mailing_address?.mailing_address_postal_code}</span>}
                </>
                }
              />
            </div>
          </div>
          {
            !showDivisions &&
            <>
              <SectionHeader name={"Divisions"} />
              <div className="column-wrapper-left section-break">
                <Typography
                  className={"section-title"}
                  variant={"body-2"}
                  label={"Divisions"}
                />

                {divisions?.map((divisionObj) => (
                  <div className="row-wrapper">
                    <Typography
                      className={"section-data division-section"}
                      variant={"body-3"}
                      label={divisionObj.identifier}
                    />
                    <Typography
                      className={"section-data"}
                      variant={"body-3"}
                      label={divisionObj.description}
                    />
                  </div>
                ))}
              </div>
            </>
          }
          <SectionHeader name={"Contacts"} />
          <div className="row-wrapper">
            <div className="column-wrapper-left contact-margin">
              <Typography
                className={"section-title"}
                variant={"body-2"}
                label={"Primary contact"}
              />
              <Typography
                className={"section-data section-margin"}
                variant={"body-3"}
                label={contacts?.primary_contact?.full_name}
              />
              <Typography
                className={"section-data "}
                variant={"body-3"}
                label={contacts?.primary_contact?.email}
              />
              <Typography
                className={"section-data "}
                variant={"body-3"}
                label={contacts?.primary_contact?.phone_no}
              />
            </div>
            {
              contacts?.additional_contact_exist &&
              <div className="column-wrapper-left">
                <Typography
                  className={"section-title"}
                  variant={"body-2"}
                  label={"Additional contact"}
                />
                <Typography
                  className={"section-data section-margin"}
                  variant={"body-3"}
                  label={contacts?.secondary_contact?.full_name}
                />
                <Typography
                  className={"section-data "}
                  variant={"body-3"}
                  label={contacts?.secondary_contact?.email}
                />
                <Typography
                  className={"section-data "}
                  variant={"body-3"}
                  label={contacts?.secondary_contact?.phone_no}
                />
              </div>
            }
          </div>
        </div>
      }
    </>
  );
};
