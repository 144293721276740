export const Error = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 9C1.5 4.8525 4.8525 1.5 9 1.5C13.1475 1.5 16.5 4.8525 16.5 9C16.5 13.1475 13.1475 16.5 9 16.5C4.8525 16.5 1.5 13.1475 1.5 9ZM11.168 12.2241C11.4605 12.5166 11.933 12.5166 12.2255 12.2241C12.5105 11.9316 12.5105 11.4516 12.2255 11.1666L10.058 8.99906L12.2255 6.83156C12.518 6.53906 12.518 6.06656 12.2255 5.77406C11.933 5.48156 11.4605 5.48156 11.168 5.77406L9.00052 7.94156L6.83302 5.77406C6.54052 5.48156 6.06802 5.48156 5.77552 5.77406C5.63508 5.91419 5.55615 6.10442 5.55615 6.30281C5.55615 6.5012 5.63508 6.69144 5.77552 6.83156L7.94302 8.99906L5.77552 11.1666C5.63508 11.3067 5.55615 11.4969 5.55615 11.6953C5.55615 11.8937 5.63508 12.0839 5.77552 12.2241C6.06802 12.5166 6.54052 12.5166 6.83302 12.2241L9.00052 10.0566L11.168 12.2241Z"
      fill="#AA232C"
    />
  </svg>
);
