import { Button, Typography } from "../../../../design-systems";
import React from "react";
import { GreenCheck } from "../../../../design-systems/Icons/GreenCheck";
import { Modal } from "antd";

export const SuccessModal = ({ open, onPrimaryActionClick }) => {
    return (
        <Modal
            title={""}
            closable={false}
            visible={open}
            className="success-employer-modal"
            centered
            footer={null}
        >
            <div className="green-check-icon">
                <GreenCheck />
            </div>
            <Typography
                className="success-label"
                variant="subhead-2"
                label={<div className="typo-label">Account holder details updated successfully.</div>}
            />
            <div className="d-flex justify-content-center">
            <Button
                label={"Close"}
                variant="primary"
                onclickCallback={onPrimaryActionClick}
                className="d-flex m-auto"
            ></Button>
            </div>
        </Modal>
    )
}