import { ApiErrors } from "../../utils";
import axios from "axios";
import { EMPLOYER_CONSTANT } from "../../utils/constants";
import {
  disableDataLoader,
  disableGlobalLoader,
  enableDataLoader,
  enableGlobalLoader,
  enableLoader,
  disableLoader
} from ".";
import {
  addEmployerService,
  getAccountHolderDetailsService,
  getCurrentEmployerService,
  getDivisionsService,
  getEmployeeSummaryService,
  getEmployerLedgerService,
  getEmployerService,
  switchEmployerService,
  getEmployeeContributionIdentifierService,
  getEmployeeRunoutOptionsService,
  getCopaysCategoryService,
  addNewEmployerService,
  updateEmployerService,
  getEmployerDataByIdService,
  getAccountHolderDetailsServiceV2,
  getExpenseGroupDetailsService,
  getEmployerPlanDataService,
  getEmployerDetailsByEmployerId,
  copyPlanService,
  updatePlanService
} from "../../services";

export function updateEmployer(reqData, callback) {
  const processName = "updateEmployer";

  return (dispatch) => {
    dispatch(enableGlobalLoader());
    updateEmployerService
      (reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableGlobalLoader(processName));
      });
  };
}

export function getEmployerTypeData(callback) {
  return (dispatch) => {
    getEmployerService()
      .then((empType) => {
        const data = empType.data.filter((d) => d.employer_id)
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYER_TYPE,
          payload: data,
        });
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  };
}

export function switchEmployerTypeData(reqData, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    switchEmployerService(reqData)
      .then((switchType) => {
        callback && callback(switchType);
        dispatch(disableGlobalLoader());
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
        dispatch(disableGlobalLoader());
      })
      .finally(() => { });
  };
}

export function getCurrentEmployerDetailsByTab(tabName, callback) {
  return dispatch => {
    dispatch(enableLoader());
    getEmployerDataByIdService(tabName)
      .then((data) => {
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYER_DATA_BY_TAB,
          payload: data.data,
          tabName
        })
        dispatch(disableLoader());
        callback && callback(data);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
        dispatch(disableLoader());
      })
      .finally(() => { });
  }
}

export function getEmployerDetailsById(id, callback) {
  return dispatch => {
    dispatch(enableGlobalLoader());
    getEmployerDetailsByEmployerId(id)
      .then((data) => {
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYER_DETAILS_BY_ID,
          payload: data.data,
        })
        dispatch(disableGlobalLoader());
        callback && callback(data);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
        dispatch(disableGlobalLoader());
      })
      .finally(() => { });
  }
}

export function addEmployer(reqData, callback) {
  return (dispatch) => {
    addEmployerService(reqData)
      .then((emp) => {
        callback && callback(emp);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function getCurrentEmployer(reqData, callback) {
  return (dispatch) => {
    getCurrentEmployerService(reqData)
      .then((currentEmployer) => {
        dispatch({
          type: EMPLOYER_CONSTANT.SET_CURRENT_EMPLOYER_TYPE,
          payload: currentEmployer,
        });
        callback && callback(currentEmployer);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function getEmployerLedger(reqData, callback) {
  const processName = "getEmployerLedger";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getEmployerLedgerService(reqData)
      .then((employerLedger) => {
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYER_LEDGER,
          payload: employerLedger,
        });
        callback && callback(employerLedger);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getEmployeeSummary(callback) {
  const processName = "getEmployeeSummary";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getEmployeeSummaryService()
      .then((employerSummary) => {
        callback && callback(employerSummary);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getAllDivisions(callback) {
  const processName = "getAllDivisions";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getDivisionsService()
      .then((res) => {
        callback && callback(res);
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYER_DIVISIONS,
          payload: res.data,
        });
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
        callback && callback({ error: true });
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getExpenseGroupDetails(data, pagination, callback) {

  return (dispatch) => {
    getExpenseGroupDetailsService(data, pagination)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  };
}

export function getAccountHolderDetailsV2(zan, callback) {
  const processName = "getAccountHolderDetailsV2";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getAccountHolderDetailsServiceV2(zan)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getAccountHolderDetails(emailID, callback) {
  const processName = "getAccountHolderDetails";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getAccountHolderDetailsService(emailID)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getEmployeeContributionIdentifier(callback) {
  const processName = 'getEmployeeContributionIdentifier'
  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getEmployeeContributionIdentifierService()
      .then((res) => {
        callback && callback(res);
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYEE_CONTRIBUTION_IDENTIFIERS,
          payload: res.data.identifiers.map((item) => ({
            value: item.id,
            label: item.value
          })),
        });
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getEmployeeRunoutOptions(callback) {
  const processName = 'getEmployeeRunoutOptions'
  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getEmployeeRunoutOptionsService()
      .then((res) => {
        callback && callback(res);
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYEE_RUNOUT_OPTIONS,
          payload: res.data.options.map((item) => ({
            value: item.id,
            label: item.value
          })),
        });
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getEmployeeCopaysCategories(form, plans, callback) {
  const processName = 'getEmployeeCopaysCategories'
  const plansArr = form.getFieldValue("plans")
  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getCopaysCategoryService()
      .then((res) => {
        callback && callback(res);
        const categories = res.data.categories;
        const plansData = plansArr && plansArr?.length ? plansArr : plans;
        const filteredCategories = plansData?.includes('DCFSA')
          ? categories
          : categories?.filter((category) => category.name !== 'Dependant Care');
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYEE_COPAYS_CATEGORIES,
          payload: filteredCategories.map((item) => ({
            value: item.id,
            label: item.name
          })),
        });
      })
      .catch((err) => {
        callback && callback(err);
        if(!axios.isCancel(err)) {
          ApiErrors(err, dispatch);
        }
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function addNewEmployer(data, flag, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    addNewEmployerService(data)
      .then((res) => {
        callback && callback(res);
        if (data.step_id === 1) {
          dispatch({
            type: EMPLOYER_CONSTANT.SET_EMPLOYER_ID,
            payload: res.employer_id
          });

          dispatch({
            type: EMPLOYER_CONSTANT.SET_SAME_AS_CORPORATE_ADDRESS,
            payload: data.data.same_as_corporate_address
          });
        }
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYER_CREATE_SUCCESS,
          payload: flag
        });
      }).catch((err) => {
        dispatch(disableGlobalLoader())
        callback && callback(err);
        ApiErrors(err, dispatch);
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYER_CREATE_ERROR,
        });
      })
      .finally(() => {
        dispatch(disableGlobalLoader())
      })
  }
}

export function resetEmployerApiSuccess() {
  return dispatch => {
    dispatch({
      type: EMPLOYER_CONSTANT.RESET_EMPLOYER_DATA
    })
  }
}

export function resetEmployerAddData() {
  return dispatch => {
    dispatch({
      type: EMPLOYER_CONSTANT.RESET_EMPLOYER_ADD_DATA
    })
  }
}

export function showAdditionalContactForm(data) {
  return dispatch => {
    dispatch({
      type: EMPLOYER_CONSTANT.SET_SHOW_ADDITIONAL_CONTACT,
      payload: data
    })
  }
}

export function setShowSecondSection(reqData, callback) {
  return dispatch => {
    dispatch({
      type: EMPLOYER_CONSTANT.SET_SHOW_SECTION2,
      payload: reqData
    })
  }
}

export function setPrimaryContactData(data) {
  return dispatch => {
    dispatch({
      type: EMPLOYER_CONSTANT.SET_PRIMARY_CONTACT_INFO,
      payload: data
    })
  }
}

export const setCheckboxValue = (checkboxName, value) => ({
  type: EMPLOYER_CONSTANT.SET_PRIMARY_CONTACT_INFO,
  payload: { checkboxName, value },
});

export function getEmployerInfo(callback) {
  return (dispatch) => {
    getEmployerPlanDataService()
      .then((res) => {
        callback && callback(res);
        dispatch({
          type: EMPLOYER_CONSTANT.SET_EMPLOYER_INFO,
          payload: res?.data ?? []
        })
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  };
}
export function copyPlan(callback) {
  return (dispatch) => {
    copyPlanService()
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
        callback && callback(err);
      })
      .finally(() => {
      });
  };
}

export function updateEmployerPlan(data, callback) {
  return (dispatch) => {
    updatePlanService(data)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
        callback && callback(err);
      })
      .finally(() => {
      });
  };
}

export const saveCurrentEditEmployerPlanAction = (data) => ({
  type: EMPLOYER_CONSTANT.SAVE_EMPLOYER_PLAN_UPDATE_ACTION,
  payload: data
})

export const saveEmployerRouteInfo = (data) => ({
  type: EMPLOYER_CONSTANT.SAVE_EMPLOYER_ROUTE_INFO,
  payload: data
})

export const saveEmployerPlanData = (data) => ({
  type: EMPLOYER_CONSTANT.SAVE_EMPLOYER_PLAN_INFO,
  payload: data
})

export const deleteEmployerProgressData = () => ({
  type: EMPLOYER_CONSTANT.DELETE_EMPLOYER_PROGRESS_DATA
})

export const saveCopaysData = (data) => ({
  type: EMPLOYER_CONSTANT.SAVE_COPAYS_DATA,
  payload: data
})

export const saveUpdateEmployerOnBoardingInfo = (data, step) => {
  return (dispatch) => {
    dispatch({
      type: EMPLOYER_CONSTANT.UPDATE_SAVED_EMPLOYER_PROGRESS_DATA,
      payload: data,
      step
    })
  }
}