import React, { useEffect, useMemo, useState } from "react";
import { Tag } from "antd";
import { SectionHeader } from "./SectionHeader";
import { Typography, TextField, Select } from "../../../../design-systems";
import { EditableTable } from "../../../common/EditableTable";
import { mandatory } from "../../../../utils/validations";
import { clearBodyObject } from "../../../../utils";
import { getCurrentEmployerDetailsByTab, showSuccessMessage, updateEmployer } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { langs } from "../../../../i18n";
import moment from "moment";
import { SpinLoader } from "../../../common/SpinLoader";
import { ROLE_CONSTANT } from "../../../../utils/constants";


export const AdminUsers = ({ data, tabKey }) => {
  const roleOptions = [
    {
      label: "Super Admin",
      value: "SUPER_ADMIN",
    },
    {
      label: "Admin",
      value: "ADMIN",
    },
    {
      label: "Internal Admin",
      value: "ZENDA_ADMIN",
    },
  ];

  const role = useSelector((state) => state?.userDetails?.roleType)
  const isAdminRole = useMemo(() => {
    return (role === ROLE_CONSTANT.ADMIN || role === ROLE_CONSTANT.SUPER_ADMIN);
  }, [role])

  const isAdminRoleOptions = roleOptions.filter(role => role.value !== ROLE_CONSTANT.ZENDA_ADMIN)
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      additionNode: ({ editingKey }) => (
        <TextField
          name="add-name"
          rules={[mandatory({ message: "Please enter name" })]}
          disabled={editingKey !== ""}
          placeholder="Enter name"
        />
      ),
      editingNode: ({ editingKey }) => (
        <TextField
          name="name"
          rules={[mandatory({ message: "Please enter name" })]}
          placeholder="Enter name"
        />
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      editable: true,
      additionNode: ({ editingKey }) => (
        <TextField
          name="add-email"
          rules={[mandatory({ message: "Please enter email" }), {
            type: 'email',
            message: 'Please enter a valid email address.'
          },]}
          disabled={editingKey !== ""}
          placeholder="Enter email"
        />
      ),
      editingNode: ({ editingKey }) => (
        <TextField
          name="email"
          rules={[mandatory({ message: "Please enter email" }), {
            type: 'email',
            message: 'Please enter a valid email address.'
          }]}
          placeholder="Enter email"
        />
      ),
    },
    {
      title: "role & permissions",
      dataIndex: "role",
      key: "role",
      editable: true,
      additionNode: (data, record) => {
        return (
          <Select
            placeholder="Select role"
            name="add-role"
            options={isAdminRole ? isAdminRoleOptions : roleOptions}
            rules={[mandatory({ message: "Please select a valid role" })]}
            disabled={data.editingKey !== ""}
          />
        )
      },
      editingNode: (data, record) => {
        let updatedRoles = isAdminRole ? isAdminRoleOptions : roleOptions
        if (data?.record?.role !== "ZENDA_ADMIN") {
          updatedRoles = updatedRoles.filter(role => role.value !== "ZENDA_ADMIN");
        }
        return (
          <Select
            placeholder="Select role"
            name="role"
            options={updatedRoles}
            rules={[mandatory({ message: "Please select a valid role" })]}
          />
        )
      },
    },
    {
      title: "Added on",
      dataIndex: "added_on",
      key: "date",
      render: (text) => (
        text ? moment(text).format("MMM DD, YYYY") : null
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, fields) => {
        return (
          <div className="row-wrapper align-wrapper">
            {" "}
            <Tag className={`status-admin-users ${text?.toLowerCase()}`}>{text}</Tag>{" "}
            <Typography
              className={"resend"}
              variant={"body-4"}
              label={getStatusLinks(text)}
              onClick={() => resendInvite(text, fields)}
            />
          </div>
        )
      },
    },
  ];

  const getStatusLinks = (status) => {
    let label = "";
    switch (status) {
      case "Active":
        break;
      case "Invited":
        label = "Resend invite";
        break;
      case "Inactive":
        label = "Resend-enable";
        break;
      case "Disabled":
        label = "Re-enable";
        break;
      case "Enabled":
        label = "Disable";
        break;
      default:
    }
    return label;
  }
  const [adminUsers, setAdminUsers] = useState(data?.administrative_users);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setAdminUsers(data?.administrative_users)
    if (Object.keys(data).length) {
      setIsLoading(false);
    }
  }, [data, data?.administrative_users])

  const dispatch = useDispatch()

  const getSuccessMessage = (status) => {
    let msg = ""
    switch (status) {
      case "Invited":
        msg = langs.messages.invitation_was_resent
        break;
      case "Disabled":
      case "Enabled":
        msg = langs.messages.admin_details_updated;
        break;
      default:
        return msg
    }
    return msg
  }


  const resendInvite = (status, fields) => {

    const payload = {
      admin_email_id: fields.email.toLowerCase()
    };
    if (status === "Invited") {
      payload.invite = "re-invite-email"
    }
    if (status === "Inactive") {
      payload.status = "ENABLE"
    }
    if (status === "Disabled") {
      payload.status = "ENABLE"
    }
    if (status === "Enabled") {
      payload.status = "DISABLE"
    }
    const requestData = clearBodyObject(payload)
    dispatch(
      updateEmployer(requestData, (res) => {
        if (res instanceof Error) {
        } else {
          dispatch(getCurrentEmployerDetailsByTab(tabKey, (res) => {
            if (!(res instanceof Error)) {
              const updatedAdminUsers = res?.data?.administrative_users.map((user, index) => ({
                ...user,
                key: index
              }))
              setAdminUsers(updatedAdminUsers)
            }
          }))
          dispatch(showSuccessMessage(getSuccessMessage(status)))
        }
      }),
    );
  }

  return (
    <div className="content">
      {isLoading ? (
        <SpinLoader showContent={false} />
      ) : (
        <>
          <SectionHeader name={"Administrative users"} />
          <div className="admin-users-wrapper">
            <EditableTable
              roleOptions={isAdminRole ? isAdminRoleOptions : roleOptions}
              makeApiCall={true}
              columns={columns}
              data={adminUsers}
              tabKey={tabKey}
              onDataChange={(adminData) => {
                setAdminUsers(adminData);
              }}
              page="adminUsers"
            />
          </div>
        </>
      )}
    </div>
  );
};
