import React, { useEffect, Suspense } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Amplify, Hub } from "aws-amplify";

import { SpinLoader } from "./components/common/SpinLoader";
import { getAWSCredentials } from "./utils";
import awsConfig from "./utils/awsConfig";
import { AdminLayout, UnauthorizedLayout } from "./components/layouts";
import { Loader } from "./components/common/Loader";

import "react-toastify/dist/ReactToastify.css";
import "./assets/nucleo/css/nucleo.css";
import "./App.scss";

Amplify.configure(awsConfig);

const browserHistory = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoggedIn = useSelector((state) => state.userDetails.isLoggedIn);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          break;
        case "cognitoHostedUI":
          getAWSCredentials(dispatch, history);
          break;
        case "signOut":
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });

    //eslint-disable-next-line
  }, []);

  return (
    <SpinLoader fullscreen>
      <div className="App">
        <Suspense fallback={<Loader />}>
          <Router history={browserHistory}>
            {isLoggedIn ? <AdminLayout /> : <UnauthorizedLayout />}
          </Router>
        </Suspense>
      </div>
      <ToastContainer autoClose={5000} />
    </SpinLoader>
  );
};

export default App;
