import { Collapse as AntCollapse } from "antd";
import { ChevronDown } from "../Icons";

const defaultExpandIcon = ({ isActive }) => (
  <ChevronDown className={isActive && "chevron-up"} height={16} width={16} />
);

export const Collapse = ({
  panels,
  onChange,
  className = "horizontal-collapse",
  expandIcon,
  activeKey,
  accordion,
  defaultActiveKey,
  expandIconPosition
}) => (
  <AntCollapse
  accordion={accordion}
    expandIconPosition={expandIconPosition}
    activeKey={activeKey}
    bordered={false}
    defaultActiveKey={defaultActiveKey}
    expandIcon={expandIcon || defaultExpandIcon}
    className={className}
    onChange={onChange}
  >
    {panels}
  </AntCollapse>
);
