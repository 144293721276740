import React from "react";
import { useRouter } from "../../utils/router/customRouterHook";
import { BreadCrumbNav } from "./BreadCrumbNav";
import { AdminFooter } from "./AdminFooter";
import { AdminHeader } from "./AdminHeader";
import { Content } from "antd/lib/layout/layout";

import { Layout } from "antd";

const HeaderWithContent = ({ children, showWarning, customFooter }) => {
  const router = useRouter();

  return (
    <Layout className="site-layout">
      <AdminHeader showWarning={showWarning} />
      <Content>
        <BreadCrumbNav currentRouteName={router.pathname} />
        <div className="site-layout-content">
          <div className="site-card-wrapper">{children}</div>
        </div>
      </Content>
      <AdminFooter customFooter={customFooter} />
    </Layout>
  );
};

export { HeaderWithContent };
