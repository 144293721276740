import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Header } from "../common/Header";
import { Footer } from "../common/Footer";
import { unauthorizedRoutes } from "../../utils/router";
import DesignSystems from "../../pages/DesignSystems";

export const UnauthorizedLayout = () => (
  <div className="login-layout">
    <Header />
    <Switch>
      {unauthorizedRoutes.map((path, index) => (
        <Route key={index} {...path} />
      ))}
      <Route path="/design-systems" component={() => <DesignSystems />} />
      <Route component={() => <Redirect to="/" />} />
    </Switch>
    <Footer />
  </div>
);
