export const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  FAIL: false,
  PRECONDITION_FAILED: 412,
  SESSION_EXPIRED: 411,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  GONE: 410,
  UNSUPPORTED_MEDIA_TYPE: 415,
  UNPROCESSABLE_ENTITY: 422,
  TO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  VALIDATION: 100,
  BAD_GATEWAY: 502,
  NOT_IMPLEMENTED: 501,
  UNKNOWN_IMPLEMENTED: 409,
  REQUEST_TIMEOUT: 408,
  GATEWAY_TIMEOUT: 504,
};
