export const english = {
  fullname: "Fullname",
  username: "Username",
  email_address: "Email Address",
  forgot_email_address: "Email",
  password: "Password",
  confirm_password: "Confirm password",
  date_of_birth: "Date of birth",
  create_an_account: "Create an account",
  success: "Success",
  error: "Error",
  warning: "Warning",
  authentication_failed: "Oops Authentication is failed, please login again",
  continue_with_facebook: "Continue with Facebook",
  continue_with_google: "Continue with Google",
  new: "new",
  max_16_chars: "Max 16 characters",
  unconfirmed_email: "Unconfirmed email.",
  confirm_your_email: "Please confirm your email first",
  forgot_password: "Forgot password",
  login_success: "Log in Success",
  send_new_password_label: "Enter your email",
  submit: "Submit",
  confirm_email_first: "Please confirm your email first",
  reset_your_password: "Reset your password",
  my_courses: "My trainings",
  add_a: "Add a",
  challenge: "training",
  suggested_courses: "Suggestions for you",
  buy: "Buy",
  try_it: "Try it",
  free: "Free",
  sponsors: "Supported by",
  super_challenges: "Super challenges",
  modules: "Modules",
  buy_full_course: "Buy full course",
  payment: "Payment",
  enter_your_payment_info:
    "Please enter your payment information. The transaction is 100% safe",
  investment: "Investment",
  cpf: "CPF",
  card_number: "Card number",
  name_on_card: "Name on card",
  mm_yyyy: "MM/YYYY",
  security_code: "Security code",
  complete_purchase: "Complete purchase",
  class: "Training",
  extras: "Extras",
  exercises: "Exercises",
  previous_class: "Previous training",
  next_class: "Next training",
  title_of_content: "Title of content",
  view_more: "View more",
  extra_contents: "Extra Contents",
  follow: "Follow",
  comment: "Comment",
  to_evaluate: "Evaluate",
  send_exercise: "Send exercise",
  unfollow: "Unfollow",
  evaluate_this_exercise: "Evaluate this exercise",
  submit_review: "Submit review",
  about_your_teacher: "About your teacher",
  no_data_available: "No data available!",
  mission: "Your turn!",
  tips: "Tips",
  class_progress: "Training progress",
  classroom_progress: "Classroom progress",
  no_image: "No image",
  loading: "Loading",
  request_processing: "Please wait, your request is processing",
  just_now: "just now",
  course: "Explore",
  network_of_knowledge: "Network of Knowledge",
  notifications: "Notifications",
  logout: "Logout",
  login_for_nav: "Login",
  no_challenges_available: "No challenges available!",
  contact: "Contact",
  telephone: "Telephone",
  who_we_are: "Who we are",
  connect_with_us: "Connect with us",
  privacy: "Privacy",
  terms_conditions: "Terms and conditions",
  start_training_now: "Click here to start training!",
  share_participation: "Share a text, audio, video or link",
  share_on_net: "Share on the net",
  your_discussion_will_be: "Your discussion will be",
  shared_in_feed: "shared in Feed. This",
  content_will_be_seen: "content will be seen",
  by_his_followers: "by his followers",
  send_mission: "Send Mission",
  discussions: "discussions",
  comments: "Comment(s)",
  report_abuse: "Report Abuse",
  explain_what_happened: "Explain what happened here",
  evaluate_the_mission: "Evaluate the mission",
  unfollow_confirm_message: "Do you want to unfollow",
  view_profile: "View profile",
  edit_profile: "Edit profile",
  enter_current_password: "Enter your current password",
  enter_new_password: "Enter your new password",
  current_average: "Current average",
  following: "Following",
  followers: "Followers",
  save_profile: "Save profile",
  update_password: "Update password",
  change_password: "Change password",
  delete: "Delete",
  confirm_new_password: "Confirm new password",
  add_valuable_comments: "Please add your valuable comments",
  page_not_found: "Page not found!",
  minute: "minute(s)",
  hour: "hours(s)",
  day: "day(s)",
  month: "month(s)",
  year: "year(s)",
  language: "Language",
  per_month: "month",
  subscription_banner_heading:
    "Be a subscriber: The shortest way to become a star",
  subscription_banner_title: "Ut enim ad minim veniam, quis nostrud",
  subscription_banner_desc:
    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  not_now: "Not now",
  sign_it: "Sign it",
  already_carrier_subscription: "I have already a mobile carrier subscription",
  continue: "Continue",
  credit_card: "Credit card",
  pay_via: "Pay via",
  ok: "Ok",
  my_carrier: "My carrier",
  phone_number: "Phone number",
  pin: "PIN",
  confirm: "Confirm",
  my_progress: "My progress",
  neymar_train_you: "Train with Neymar Jr.",
  profile: "Profile",
  my_challenges: "My challenges",
  next: "Next",
  notification_del_confirm_msg: "Do you want to delete notification",
  delete_site: "Do you want to delete the site?",
  delete_users: "Do you want to delete the users?",
  select_users_First: "please select users for delete operation first !!",
  seconds: "second(s)",
  copyright: "Copyright @ 2012 - 2018 earthDECKS",
  role_description_admin:
    "Basic Admins will have access to employee data and contribution features. Basic Admins will not have access to employer banking information and can not add new administrators.",
  role_description_superadmin:
    "Super admins will have access to all InComm Benefits features and employee data. Super admins can view and edit employer banking information and add new administrators.",
  role_description_zendaadmin:
    "Internal admin will have access to all features and employee data across all employer accounts.",
  messages: {
    invalid_credentials:
      "The email address or password provided is not valid. Please enter a valid email address or password to login.",
    invalid_otp:
      "The verification code you entered is not correct. Please enter a valid verification code.",
    password_requirements_are_not_met:
      "The password provided does not meet the password requirement. Please enter a password that meets all the specified requirements.",
    invalid_confirm_password:
      "The new password and confirm password do not match.",
    email_already_exists: "Email already exists.",
    registration_success: "Your registration has been successful.",
    logout_success: "You have been successfully logged out.",
    login_invalid_email:
      "The email address provided is not valid. Please enter a valid email address.",
    forgot_password_invalid_email:
      "The email address provided is not valid. Please enter a valid email address to reset your password.",
    forgot_password_success:
      "We have received your request and an email containing a reset password link has been sent to your registered email.",
    set_password_success:
      "Your password has been set successfully. You may login now.",
    reset_password_invalid: "Please fulfil all of the password cases.",
    reset_password_success:
      "Your password has been reset successfully. You may now login with the new password.",
    unauthenticated_user: "You are not an authenticated user.",
    resource_not_found: "Resource not found.",
    confirmation_email_sent_success: "Confirmation email sent successfully.",
    email_sent_success: "Email has been sent, please check your inbox.",
    error_in_input_data: "Error in Input data.",
    default_error: "Sorry some error occurred, please try again later.",
    session_expire_error: "Your session has been expired please login again.",
    add_employee_success: "Employee has been added successfully.",
    update_employee_success: "Employee details has been updated successfully.",
    switch_employee_success: "Employer has been switched successfully.",
    insert_employee_success: "Employer has been inserted successfully.",
    resend_code_success: "Verification code has been resent successfully.",
    update_kyc_success: "Kyc has been updated successfully.",
    csv_upload_success: "CSV has been uploaded successfully.",
    user_invite_success: "User has been invited successfully.",
    contribution_run_success: "Contribution has been set successfully.",
    contribution_run_start_validating:
      "Contribution run file processing is in progress. This may take a few minutes.",
    contribution_run_ach_pull_success: "ACH Initiated",
    contribution_run_execute_success: "Contribution run successful",
    contribution_run_execute_time_out: "Contribution run initiated",
    contribution_run_is_being_executed: "Contribution run is being executed",
    contribution_canceled: "Contribution canceled",
    add_employer_error: "There is an error. Please check your Json again.",
    there_was_an_error: "There was an error. Please try again",
    please_fix_the_errors_and_try_again: "Please fix the errors and try again.",
    please_upload_valid_CSV_file: "Please upload valid CSV file.",
    contribution_run_file_name_is_too_long:
      "Contribution run file name is too long. Please shorten the file name and try again.",
    CSV_file_is_required: "CSV file is required.",
    email_already_registered_as_an_admin:
      "The email address entered is already registered as an administrator for your account.",
    invitation_was_sent: "Invitation was sent",
    invitation_was_resent: "Invitation was resent",
    admin_details_updated: "Admin details updated",
    admin_was_deleted: "Admin was deleted",
    contr_validation_in_progress:
      "Contribution validation in progress. Please wait",
    file_validation_in_progress: "File validation in progress. Please wait",
    error_loading_the_report_link: "Error loading the report link",
    report_not_available: "Report not available",
    execution_has_been_cancelled: "Execution has been cancelled",
    execution_has_been_started: "Execution has been started",
    file_uploaded_successfully: "File uploaded successfully",
    files_uploaded_successfully: "Files uploaded successfully",
    file_upload_error: "File upload error",
    file_upload_has_been_cancelled: "Census file upload has been cancelled",
    employee_has_been_deleted: "Employee has been deleted",
    kyc_updated: "KYC status has been updated",
    socure_id_must_be_passed:
      "One of the ‘Socure Reference Id’ or ‘Zendesk Ticket Id’ must be passed",
    invalid_sso_domain: "SSO is not supported for this Email",
    kyc_not_allowed: "KYC is not allowed for this user",
    employer_update_error: "Update employee failed",
    expense_created_successfully: "Expense created successfully.",
    plan_updated_successfully: "Plan updated successfully."

  },
  validation_messages: {
    invalid_email: "Please enter the valid email address.",
    required: "This field is required.",
    password: "Please enter your password!",
    cPassword: "Please confirm your password!",
    passwordNotMatch: "Password and confirm password does not match!",
    currentPassword: "Please enter your current password!",
    newPassword: "Please enter your new password!",
    imgRequired: "This field is required.",
    imgSize: "File size should be less than or equals to 4 mb.",
    imgType: "Only JPG, JPEG, PNG & PDF files are accepted.",
    newConfirmPassword: "New password and confirm password does not match!",
    socureIdValidation: "Please enter a valid socure ID",
    user_not_found: "User not found please try with some other user",
    valid_csv: "Please upload a valid CSV file.",
    file_reject: "File is rejected. Please retry with another file.",
    file_reject_multiple: "Files are rejected. Please retry again with different files."
  },
  key: {},
  userType: {},
};
