import React, { useCallback, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row, Radio, Table } from "antd";
import { HeaderWithContent } from "../../../../components/common/HeaderWithContent";
import { SpinLoader } from "../../../../components/common/SpinLoader";
import { Typography, Select, TextField, Button as DesignButton } from "../../../../design-systems";
import { DATE_FORMAT } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { useRouter } from "../../../../utils/router";
import {
    getReportName,
    getClaimsPaybackReport,
    getFundingLedgerColumns,
    getEnrollmentReportColumns,
    getExtraCreditReportColumns,
    getClaimsPaymentReportColumns,
    getFundingSummaryReportColumns,
    getPlanStatusSummaryReportColumns,
    getReportDescription,
    getHSAContributionDetailReportColumns,
    getHSAHeldReportColumns,
    getHSAContributionSummaryReportColumns,
    getHSAPersonalContributionDetailReportColumns,
    getReportsAPILink,
    getReportsDownloadAPILink,
    fixAmountFormat,
    handleKeyDown,
} from "../../../../utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getReportByType, getReportPlanFilters, getReportsLink, showErrorMessage } from "../../../../redux/actions";
import moment from "moment/moment";
import useShowSecondSection from "../../../../hooks/useShowSection";
import { langs } from "../../../../i18n";
import { validateMaxAmounts, validateMinAmounts } from "../../../../utils/validations";
import Paginations from "../../../../design-systems/Pagination";
import DatePicker2 from "../../../../design-systems/DatePicker2";
import { Reports } from "../Reports";

export const ReportsList = () => {
    const router = useRouter();
    const role = useSelector((state) => state?.userDetails?.roleType);
    const getReportPathName = () => {
        let name = router.match.params.id ?? "";
        if (router.match.params.type !== null && router.match.params.type !== undefined) {
            name += `/${router.match.params.type}`;
        }
        return name;
    }

    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState([null, null]);

    const [allValues, setAllValues] = useState({})
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [apiPayload, setApiPayload] = useState({});
    const [fundingStatus, setFundingStatus] = useState({})
    const [data, setData] = useState([]);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [startDate, setStartDate] = useState(null)
    const [selectedPlanFilter, setSelectedPlanFilter] = useState(null);
    const [limit] = useState(10);
    const [form] = Form.useForm();
    const { reportsData, planFilters } = useSelector(state => state.reports);
    const { dataLoading, loading } = useSelector((state) => state.loader);
    const noDivisions = reportsData?.divisions?.length === 0 || reportsData?.divisions?.length === 1;
    const updatedPlans = useMemo(() => {
        return reportsData?.plans?.filter(plan => plan !== "HSA")
    }, [reportsData?.plans])
    const reportPathName = getReportPathName();
    const isFundingSummaryReport = reportPathName === "fsa/funding_summary";
    const isClaimPaybackReport = reportPathName === "fsa/claims_payback";
    const isHSAContributionSummaryReport = reportPathName === "hsa/contribution_summary";
    const isHSAReports = reportPathName.startsWith("hsa/");
    let firstInitialReportPageLoad = false;


    const planTypes = form.getFieldValue("plan_type");
    const includeDeniedClaims = form.getFieldValue("include_denied_claims");
    const reportName = useMemo(() => {
        form.setFieldValue("plan_type", null);
        form.setFieldValue("tax_year", null)
        setStartDate(null);
        return getReportName[reportPathName]
    }, [router.match.params.id, router.match.params.type])

    useShowSecondSection();
    useEffect(() => {
        form.resetFields()
        setAllValues({})
        setApiPayload({})
        setDateRange([null, null])
        setStartDate(null)
        form.setFieldValue("tax_year", null)
        setData([])
        setCurrentPage(1)
        setTotalCount(0)
        setIsFormChanged(false)
        form.setFieldValue("include_denied_claims", "1")
        firstInitialReportPageLoad = true;
        if (reportPathName === "enrollment") {
            dispatch(getReportPlanFilters())
        }
        getReportData()
    }, [router.match.params.id, router.match.params.type])



    const getReportData = (params) => {
        if (reportPathName === "hsa/contributions") {
            return;
        }

        const payload = {
            page: currentPage,
            limit,
            ...apiPayload
        }

        if (reportPathName.startsWith("hsa/")) {
            if ((firstInitialReportPageLoad || isEveryValueNull(dateRange)) && reportPathName !== "hsa/contributions_held") {
                return;
            }

            payload.from_date = moment(dateRange[0]).format(DATE_FORMAT.FOR_SERVER);
            payload.to_date = moment(dateRange[1]).format(DATE_FORMAT.FOR_SERVER);
            payload.tax_year = form.getFieldValue("tax_year")?.getFullYear();
            params = payload;
        }

        if (isClaimPaybackReport) {
            payload.include_denied_claims = includeDeniedClaims;
        }
        dispatch(getReportByType(getReportsAPILink[reportPathName], params ? params : payload, (res) => {
            if (!(res instanceof Error)) {
                if (isFundingSummaryReport) {
                    setData(res?.data?.data?.funding_details);
                    setFundingStatus(res?.data?.data?.funding_status)
                } else {
                    setData(res?.data?.data);
                    //setCurrentPage(res?.data?.current_page);
                    setTotalCount(res?.data?.total_records);
                }
            }
            firstInitialReportPageLoad = false;
        }))
    }

    function clearBodyObject(object) {
        for (let key in object) {
            if (object[key] === null || object[key] === undefined || object[key] === "") {
                delete object[key];
            }
        }
        return object;
    }

    useEffect(() => {
        getReportData()
    }, [currentPage])

    const getColumnsByReportName = useMemo(() => {
        switch (reportName) {
            case "Enrollment":
                return getEnrollmentReportColumns();
            case "Claim payments":
                return getClaimsPaymentReportColumns(data);
            case "Claim payback":
                return getClaimsPaybackReport(data);
            case "Employer account detail":
            case "Employee ledger":
                return getFundingLedgerColumns();
            case "Employer plan summary":
                return getFundingSummaryReportColumns();
            case "Employee plan status":
                return getPlanStatusSummaryReportColumns(data);
            case "Extra credit":
                return getExtraCreditReportColumns();
            case "Contribution Summary":
                return getHSAContributionSummaryReportColumns(role)
            case "Contribution Details":
                return getHSAContributionDetailReportColumns()
            case "Contribution Held":
                return getHSAHeldReportColumns()
            case "Personal Contribution Details":
                return getHSAPersonalContributionDetailReportColumns()
            default:
                return []
        }
    }, [reportName])

    const removeCurrentPageKey = (obj) => {
        delete obj.page
        return obj
    }

    const onFinish = (values) => {
        firstInitialReportPageLoad = false
        let payload = {
            page: currentPage,
            limit
        };
        if (reportName === "Claim payments") {
            payload = {
                ...payload,
                plan_type: values?.plan_type?.join(","),
                amount_requested_start: values?.amount_requested_start,
                amount_requested_end: values?.amount_requested_end
            };
            if (values?.dateRange?.length > 0) {
                payload.processed_start_date = moment(values?.dateRange[0]).format(DATE_FORMAT.FOR_SERVER);
                payload.processed_end_date = moment(values?.dateRange[1]).format(DATE_FORMAT.FOR_SERVER);
            }
            if (!noDivisions) {
                payload.division = values?.division
            }
        } else if (reportName === "Claim payback") {
            payload = {
                ...payload,
                plan_type: values?.plan_type?.join(","),
                division: values?.division,
                include_denied_claims: values?.include_denied_claims,
            };
        } else if (reportName === "Employer account detail" || reportName === "Employee ledger") {
            payload = {
                ...payload,
            };
            if (values?.dateRange?.length > 0) {
                payload.processed_start_date = moment(values?.dateRange[0]).format(DATE_FORMAT.FOR_SERVER);
                payload.processed_end_date = moment(values?.dateRange[1]).format(DATE_FORMAT.FOR_SERVER);
            }
        } else if (reportName === "Employee plan status") {
            payload = {
                ...payload,
                plan_type: values?.plan_type?.join(","),
            };
        } else if (reportPathName.startsWith("hsa/")) {
            payload = {
                ...payload
            }
            if (values?.dateRange?.length > 0) {
                payload.from_date = moment(values?.dateRange[0]).format(DATE_FORMAT.FOR_SERVER);
                payload.to_date = moment(values?.dateRange[1]).format(DATE_FORMAT.FOR_SERVER);
            }
        } else if (reportPathName === "enrollment") {
            payload.plan_filter = selectedPlanFilter ? selectedPlanFilter : null
        }
        setApiPayload(removeCurrentPageKey(clearBodyObject(payload)))

        setData([])
        setCurrentPage(1)
        setTotalCount(0)
        getReportData(removeCurrentPageKey(clearBodyObject(payload)));
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleClear = () => {
        setSelectedPlanFilter(null);
        form.resetFields();
        form.setFieldValue("include_denied_claims", "1")
        form.setFieldValue("tax_year", null)
        setCurrentPage(1);
        setTotalCount(0);
        setApiPayload({});
        setIsFormChanged(false);
        setDateRange([null, null]);
        if (isHSAReports) {
            setData([])
        } else {
            getReportData({})
        }
    }

    const renderCommonButtons = (
        <Col sm={24} md={24}>
            <div className="report-action-buttons">
                <div>
                    <DesignButton
                        variant="secondary"
                        onclickCallback={() => {
                            handleClear()
                        }}
                        disabled={!isFormChanged}
                        className=" button-secondary font-size-14 mr-10"
                        label="Clear"
                    />
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="button-primary"
                        disabled={!isFormChanged}
                    >
                        Get report
                    </Button>
                </div>
                {
                    !isFundingSummaryReport &&
                    <div>
                        <DesignButton
                            variant="secondary"
                            onclickCallback={() => {
                                getReportLink(getReportsDownloadAPILink[getReportPathName()], apiPayload)
                            }}
                            className=" button-secondary font-size-14 mr-10"
                            label="Export as CSV"
                            disabled={loading || dataLoading?.length || !data.length}
                        />
                    </div>
                }
            </div>
        </Col>
    );

    const isEveryValueNull = (arr) => {
        return Array.isArray(arr) && arr.every(date => date === null)
    }

    const handleCalendarChange = (dates) => {
        if (isEveryValueNull(dates) && Object?.keys(apiPayload)?.length !== 0 && !isHSAReports) {
            const payload = {
                limit: 10,
                page: currentPage,
                plan_type: planTypes?.join(" ,"),
                amount_requested_start: allValues?.amount_requested_start,
                amount_requested_end: allValues?.amount_requested_end
            }
            getReportData(removeCurrentPageKey(clearBodyObject(payload)))
        }
    };

    const handleChange = (selectedValues) => {
        if (!selectedValues?.length && Object.keys(apiPayload)?.length !== 0) {
            let payload = {
                plan_type: selectedValues?.join(","),
                limit: 10,
                page: currentPage,
                amount_requested_start: allValues?.amount_requested_start,
                amount_requested_end: allValues?.amount_requested_end
            }
            if (allValues?.dateRange?.length > 0) {
                payload.processed_start_date = moment(allValues?.dateRange[0]).format(DATE_FORMAT.FOR_SERVER);
                payload.processed_end_date = moment(allValues?.dateRange[1]).format(DATE_FORMAT.FOR_SERVER);
            }

            getReportData(removeCurrentPageKey(clearBodyObject(payload)))
        }
    };

    const handleAmountChange = (e, name) => {
        if (e.type === 'click') {
            if (!e.target.value && Object.keys(apiPayload)?.length !== 0) {
                let payload = {
                    amount_requested_start: allValues?.amount_requested_start,
                    amount_requested_end: allValues?.amount_requested_end,
                    plan_type: planTypes?.join(" ,"),
                    limit: 10,
                    page: currentPage,
                    [name]: "",
                }
                if (allValues?.dateRange?.length > 0) {
                    payload.processed_start_date = moment(allValues?.dateRange[0]).format(DATE_FORMAT.FOR_SERVER);
                    payload.processed_end_date = moment(allValues?.dateRange[1]).format(DATE_FORMAT.FOR_SERVER);
                }

                getReportData(removeCurrentPageKey(clearBodyObject(payload)))
            }
        }
    }

    const renderFormFields = () => {
        if (reportName === "Claim payments") {
            return (
                <Row gutter={30}>
                    <Col sm={24} md={6}>
                        <div style={{ marginTop: -4 }}>
                            <Form.Item name="dateRange">
                                <DatePicker2
                                    readOnly={false}
                                    selectsRange={true}
                                    showImportant={false}
                                    placeholder="Start date - End date"
                                    startDate={dateRange[0]}
                                    endDate={dateRange[1]}
                                    format={['MM/dd/yyyy', 'M/d/yyyy']}
                                    onChange={(dates) => {
                                        if (isEveryValueNull(dates)) {
                                            setIsFormChanged(false)
                                        }
                                        handleCalendarChange(dates)
                                        setDateRange(dates);
                                        form.setFieldValue({ dateRange: dates })
                                    }}
                                    onKeyDown={handleKeyDown}
                                    isClearable={true}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col sm={24} md={6}>
                        <Select
                            className="select-multiple-plans"
                            mode="multiple"
                            name="plan_type"
                            placeholder="Plan"
                            options={
                                updatedPlans?.map(key => ({
                                    label: key,
                                    value: key,
                                }))
                            }
                            onChange={handleChange}
                        />
                    </Col>
                    {!noDivisions && (
                        <Col sm={24} md={6}>
                            <Select
                                placeholder="Select division"
                                name="division"
                                options={reportsData?.divisions?.map(key => ({
                                    label: key,
                                    value: key,
                                }))}
                            />
                        </Col>
                    )}
                    <Col sm={24} md={6}>
                        <TextField
                            name="amount_requested_start"
                            id="amount_requested_start"
                            placeholder="Amount requested (Min)"
                            rules={[
                                validateMinAmounts(form)
                            ]}
                            allowClear={true}
                            type="number"
                            onKeyPress={(event) => {
                                if (!/[0-9.]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onInput={(e) => {
                                const value = e.target.value;
                                if (value === "") return;
                                if (!/^\d*\.?\d{0,2}$/.test(value)) {
                                    e.target.value = parseFloat(value).toFixed(2);
                                }
                            }}
                            onChange={(e) => handleAmountChange(e, "amount_requested_start")}
                        />
                    </Col>
                    <Col sm={24} md={6}>
                        <TextField
                            name="amount_requested_end"
                            id="amount_requested_end"
                            placeholder="Amount requested (Max)"
                            rules={[
                                validateMaxAmounts(form)
                            ]}
                            type="number"
                            onKeyPress={(event) => {
                                if (!/[0-9.]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onInput={(e) => {
                                const value = e.target.value;
                                if (value === "") return;
                                if (!/^\d*\.?\d{0,2}$/.test(value)) {
                                    e.target.value = parseFloat(value).toFixed(2);
                                }
                            }}
                            allowClear={true}
                            onChange={(e) => handleAmountChange(e, "amount_requested_end")}
                        />
                    </Col>
                    {renderCommonButtons}
                </Row>
            );
        }
        if (reportName === "Claim payback") {
            return (
                <Row gutter={30}>
                    <Col sm={24} md={6}>
                        <Select
                            className="select-multiple-plans"
                            mode={"multiple"}
                            name="plan_type"
                            placeholder="Plan"
                            options={
                                updatedPlans?.map(key => ({
                                    label: key,
                                    value: key,
                                }))
                            }
                            onChange={handleChange}
                        />
                    </Col>
                    {!noDivisions && (
                        <Col sm={24} md={6}>
                            <Select
                                placeholder="Select division"
                                name="division"
                                options={reportsData?.divisions?.map(key => ({
                                    label: key,
                                    value: key,
                                }))}
                            />
                        </Col>
                    )}
                    <Col sm={24} md={6}>
                        <Form.Item name="include_denied_claims" label="Include denied claims that have been repaid">
                            <Radio.Group defaultValue={"1"}>
                                <Radio value="1">Yes</Radio>
                                <Radio value="0">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {renderCommonButtons}
                </Row>
            );
        }
        if (["Employer account detail", "Employee ledger", "Contribution Summary", "Contribution Details", "Personal Contribution Details"].includes(reportName)) {
            return (
                <Row gutter={30}>
                    <Col sm={24} md={6}>
                        <div>
                            <Form.Item name="dateRange">
                                <DatePicker2
                                    readOnly={false}
                                    selectsRange={true}
                                    showImportant={false}
                                    placeholder="Start date - End date"
                                    startDate={dateRange[0]}
                                    endDate={dateRange[1]}
                                    format={['MM/dd/yyyy', 'M/d/yyyy']}
                                    onChange={(dates) => {
                                        if (isEveryValueNull(dates)) {
                                            setIsFormChanged(false)
                                        }
                                        handleCalendarChange(dates)
                                        setDateRange(dates);
                                        form.setFieldValue({ dateRange: dates })
                                    }}
                                    onKeyDown={handleKeyDown}
                                    isClearable={true}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    {
                        isHSAContributionSummaryReport && (
                            <Col sm={24} md={6}>
                                <DatePicker2
                                    showImportant={false}
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date)
                                        form.setFieldsValue({
                                            tax_year: date
                                        })
                                    }}
                                    placeholder="Tax year"
                                    id="tax_year"
                                    name="tax_year"
                                    yearItemNumber={9}
                                    isClearable={true}
                                    readOnly={false}
                                    onKeyDown={handleKeyDown}
                                    format="yyyy"
                                    showYearPicker={true}
                                    yearPickerHeadline="Tax year"
                                    minDate={new Date("2021")}
                                    maxDate={new Date("2035")}
                                />
                            </Col>
                        )
                    }
                    {(!noDivisions && !isHSAReports) && (
                        <Col sm={24} md={6}>
                            <Select
                                placeholder="Select division"
                                name="division"
                                options={reportsData?.divisions?.map(key => ({
                                    label: key,
                                    value: key,
                                }))}
                            />
                        </Col>
                    )}
                    {renderCommonButtons}
                </Row>
            );
        }
        if (reportName === "Contribution Held") {
            return (
                <div className="d-flex justify-space-between">
                    <div></div>
                    <div>
                        <div className="report-action-buttons">
                            <div>
                                <DesignButton
                                    variant="secondary"
                                    onclickCallback={() => {
                                        getReportLink(getReportsDownloadAPILink[getReportPathName()], apiPayload)
                                    }}
                                    className=" button-secondary font-size-14 mr-10"
                                    label="Export as CSV"
                                    disabled={loading || dataLoading?.length || !data.length}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (reportName === "Employee plan status") {
            return (
                <Row gutter={30}>
                    <Col sm={24} md={6}>
                        <Select
                            mode="multiple"
                            className="select-multiple-plans"
                            name="plan_type"
                            placeholder="Plan"
                            options={
                                updatedPlans.map(key => ({
                                    label: key,
                                    value: key,
                                }))
                            }
                            onChange={handleChange}
                        />
                    </Col>
                    {renderCommonButtons}
                </Row>
            );
        }
        if (reportName === "Enrollment") {
            return (
                <Row gutter={30}>
                    <Col sm={24} md={6}>
                        <Select
                            className="enrollment-plan-year"
                            name="plan_year"
                            placeholder="Plan year"
                            options={
                                planFilters?.map(key => ({
                                    label: key,
                                    value: key,
                                }))
                            }
                            onChange={(val) => {
                                if (!val) {
                                    getReportData({})
                                }
                                setSelectedPlanFilter(val);
                            }}
                        />
                    </Col>
                    {renderCommonButtons}
                </Row>
            );
        }

        return null;
    };

    const getReportLink = (reportName, params) => {
        if (form.getFieldValue("tax_year")) {
            params.tax_year = form.getFieldValue("tax_year")?.getFullYear();
        }
        if (reportPathName === "enrollment") {
            params.plan_filter = selectedPlanFilter ? selectedPlanFilter : null;
        }
        dispatch(
            getReportsLink(reportName.replaceAll("-", "_"), params, (res) => {
                if (res.data !== null) {
                    if (reportName === "funding_ledger") {
                        res.data = res.data.replace(/EOA_XFSA/g, "ALL")
                        res.data = res.data.replace(/EOA_DCFSA/g, "DCFSA")
                        res.data = res.data.replace(/EOA_LFSA/g, "LFSA")
                        res.data = res.data.replace(/EOA_FSA/g, "FSA")
                    }
                    const blob = new Blob([res.data], { type: 'text/csv' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = res?.fileName || `${getReportName[reportName].replaceAll(" ", "-")}-${moment().format(DATE_FORMAT.FOR_SERVER)}.csv`
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    dispatch(
                        showErrorMessage(langs.messages.error_loading_the_report_link),
                    );
                }
            }),
        );
    };

    const getReportDateById = useCallback(() => {
        const id = router.match.params.id
        const reportDate = reportsData.reports.find(data => data?.type === id);
        if (reportDate?.report_date) {
            return moment(reportDate?.report_date).format(DATE_FORMAT.FOR_UI_FIRST_MM)
        }
        return ""
    }, [router.match.params.id, reportsData?.reports])

    if (reportPathName === "hsa/contributions") {
        return <Reports />
    }
    return (
        <HeaderWithContent>
            <Row gutter={0}>
                <Col span={24}>
                    <Card title="" bordered={false} className="claims-wrapper">
                        <Typography
                            label={`${reportName}`}
                            variant="subhead-1"
                            className="employee-listing-title mb-3"
                        />
                        <Typography
                            label={getReportDescription[router.match.params.type]}
                            variant="section-title-2 section-gap"
                            className="employee-listing-title "
                        />
                        <Form
                            name="basic"
                            form={form}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            layout="vertical"
                            autoComplete="off"
                            className="common-form-container edit-employee-form"
                            onValuesChange={(changedValues, allValues) => {
                                setAllValues(allValues)
                                const areAllFieldsEmpty = Object.values(allValues).every(value => {
                                    return value === null || value === undefined || value === '' || !value?.length || !Object.keys(value)?.length;
                                });
                                setIsFormChanged(!areAllFieldsEmpty);
                            }}
                        >
                            {renderFormFields()}
                        </Form>
                        <SpinLoader>
                            {
                                !isFundingSummaryReport &&
                                <div>{`# Records: ${totalCount !== undefined && totalCount !== null ? totalCount : data.length}`}</div>
                            }
                            {
                                isFundingSummaryReport &&
                                <div className="row-wrapper section-break">
                                    <div className="column-wrapper-left address-column">
                                        <Typography
                                            className={"section-title"}
                                            variant={"body-2"}
                                            label={"Funds received"}
                                        />
                                        <Typography
                                            className={"section-data section-margin"}
                                            variant={"body-3"}
                                            label={fixAmountFormat(fundingStatus?.funds_received ?? 0)}
                                        />
                                    </div>
                                    <div className="column-wrapper-left">
                                        <Typography
                                            className={"section-title"}
                                            variant={"body-2"}
                                            label={"Available balance"}
                                        />
                                        <Typography
                                            className={"section-data section-margin"}
                                            variant={"body-3"}
                                            label={fixAmountFormat(fundingStatus?.available_balance ?? 0)}
                                        />
                                    </div>
                                </div>
                            }
                            <Table
                                className="table-wrapper claims-list-table reports-table reportv2-table"
                                columns={getColumnsByReportName}
                                dataSource={data}
                                pagination={false}
                            />
                            <div className={getReportDateById(router.match.params.id) ? "date-pagination-wrapper-between" : "date-pagination-wrapper-end"}>
                                {
                                    getReportDateById(router.match.params.id) &&
                                    <div>{`Data as of ${getReportDateById(router.match.params.id)}`}</div>
                                }
                                {
                                    !isFundingSummaryReport &&
                                    <div className="pagination-wrapper">
                                        <Paginations
                                            onChange={(page) => setCurrentPage(page)}
                                            total={totalCount}
                                            currentPage={currentPage}
                                            defaultCurrentPage={1}
                                            defaultPageSize={limit}
                                        />
                                    </div>
                                }
                            </div>
                        </SpinLoader>
                    </Card>
                </Col>
            </Row>
        </HeaderWithContent>
    )
}