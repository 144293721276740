import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from "antd";
import {
  Radio,
  Select,
  TextField,
} from "../../../../../design-systems";
import { useDispatch, useSelector } from "react-redux";
import { mandatory, validateMaxContributionLFSA, validateMaxLength, validateMaxRolloverLFSA, validateMinContributionLFSA, validateMinLength, validateMinRolloverLFSA } from "../../../../../utils/validations";
import { getEmployeeRunoutOptions } from '../../../../../redux/actions';
import moment from 'moment';
import { handleKeyDown, irsValueToYearMap } from '../../../../../utils';
import DatePicker2 from '../../../../../design-systems/DatePicker2';

export const LFSAPlanDetails = ({ form, formData, setFormData }) => {
  const dispatch = useDispatch()
  const runOutOptions = useSelector((state) => state.employer?.runOutOptions);
  const [disableRadio, setDisableRadio] = useState(false);

  useEffect(() => {
    if (!runOutOptions.length) {
      dispatch(getEmployeeRunoutOptions())
    }
  }, [runOutOptions])

  const employeeRunOut = Form.useWatch("lfsa_terminated_employees_run_out", form);
  useEffect(() => {
    let value;
    const selectedOptionValue = runOutOptions?.find(opt => opt.value === employeeRunOut)
    if (selectedOptionValue !== undefined) {
      value = selectedOptionValue?.label
    }
    if (employeeRunOut && value === "Days after the last day they were active") {
      setDisableRadio(true)
      form.setFieldsValue({
        "lfsa_allow_claims": "no"
      })
    }
    else {
      setDisableRadio(false)
    }
  }, [employeeRunOut])

  const customValidation = () => {
    let limit = 2850, startYear;
    const startDate = form.getFieldValue("start_date");
    if (startDate) {
      startYear = moment(startDate).year();
      limit = irsValueToYearMap[startYear]["LFSA"]
    }
    const { lfsa_employee_maximum_contribution } = form.getFieldsValue();
    if (Number(lfsa_employee_maximum_contribution) > Number(limit)) {
      return Promise.reject(`Maximum IRS contribution exceeded. Employee contribution amount must be below or equal to ${limit}`);
    }
    return Promise.resolve('valid');
  };

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <TextField
          label="Employee minimum contribution"
          placeholder="Enter minimum contribution"
          name="lfsa_employee_minimum_contribution"
          rules={[
            mandatory({
              message: "Please enter a valid minimum contribution",
            }),
            validateMinContributionLFSA(form)
          ]}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onInput={(e) => {
            const value = e.target.value;
            if (value === "") return;
            if (!/^\d*\.?\d{0,2}$/.test(value)) {
              e.target.value = parseFloat(value).toFixed(2);
            }
          }}
          step="1"
          min={0}
          prefix="$"
          type="number"
        />
      </Col>
      <Col xs={24} md={12}>
        <TextField
          label="Employee maximum contribution"
          placeholder="Enter maximum contribution"
          name="lfsa_employee_maximum_contribution"
          rules={[
            mandatory({
              message: "Please enter a valid maximum contribution",
            }),
            validateMaxContributionLFSA(form)
          ]}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onInput={(e) => {
            const value = e.target.value;
            if (value === "") return;
            if (!/^\d*\.?\d{0,2}$/.test(value)) {
              e.target.value = parseFloat(value).toFixed(2);
            }
          }}
          step="1"
          min={0}
          prefix="$"
          type="number"
        />
      </Col>
      {/* <Col xs={24} md={12}>
        <TextField
          label="Employer contribution amount"
          placeholder="Enter contribution amount"
          name="lfsa_employee_contribution_amount"
          prefix="$"
          type="number"
          min={0}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onInput={(e) => {
            const value = e.target.value;
            if (value === "") return;
            if (!/^\d*\.?\d{0,2}$/.test(value)) {
              e.target.value = parseFloat(value).toFixed(2);
            }
          }}
          step="1"
        />
      </Col>
      <Col xs={24} md={12}></Col> */}

      <Col xs={24} md={12}>
        <Select
          disabled={!runOutOptions?.length}
          label="Terminated employees run out"
          placeholder="Select an option"
          name="lfsa_terminated_employees_run_out"
          options={runOutOptions}
          rules={[
            mandatory({
              message: "Please select a valid terminated employees run out",
            }),
          ]}
        />
      </Col>
      <Col xs={24} md={12}>
        <TextField
          label="Run out period"
          placeholder="Enter run out period"
          name="lfsa_run_out_period"
          rules={[
            mandatory({
              message: "Please enter a valid run out period",
            }),
            validateMinLength(),
            validateMaxLength(),
          ]}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          suffix="Days"
        />
      </Col>
      {/* <Col xs={24} md={24}>
        <Radio
          label="Allow claim to be filed with a date of service after termination"
          rules={[
            mandatory({
              message: "Please select a valid option",
            }),
          ]}
          disabled={disableRadio}
          data={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          name="lfsa_allow_claims"
        />
      </Col> */}
      {/* <Col xs={24} md={12}></Col> */}

      <Col xs={24} md={24}>
        <Radio
          label="Rollover or grace period"
          rules={[
            mandatory({
              message: "Please select a valid option",
            }),
          ]}
          data={[
            { label: "Rollover", value: "rollover" },
            { label: "Grace period", value: "grace-period" },
            { label: "None", value: "none" },
          ]}
          name="lfsa_rollover_or_grace_period"
        />
      </Col>
      <Col xs={24} md={12}></Col>
     

      <Col xs={24} md={24} className="should-update-col">
        <Form.Item shouldUpdate className="should-update-form-item">
          {() =>
            form.getFieldValue("lfsa_rollover_or_grace_period") === "rollover" ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <TextField
                    label="Maximum rollover"
                    placeholder="00.00"
                    name="lfsa_maximum_rollover"
                    rules={[
                      mandatory({
                        message: "Please enter a valid maximum rollover",
                      }),
                      validateMaxRolloverLFSA(form)
                    ]}
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value === "") return;
                      if (!/^\d*\.?\d{0,2}$/.test(value)) {
                        e.target.value = parseFloat(value).toFixed(2);
                      }
                    }}
                    step="1"
                    prefix="$"
                    type="number"
                  />
                </Col>
                <Col xs={24} md={12}></Col>
                <Col xs={24} md={12}>
                  <Radio
                    label="Allow rollover if not elected for following plan year?"
                    rules={[
                      mandatory({
                        message: "Please select a valid option",
                      }),
                    ]}
                    data={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    name="lfsa_allow_rollover_not_elected_for_following_plan_year"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <TextField
                    label="Minimum rollover"
                    placeholder="00.00"
                    name="lfsa_minimum_rollover"
                    rules={[
                      mandatory({
                        message: "Please enter a valid minimum rollover",
                      }),
                      validateMinRolloverLFSA(form)
                    ]}
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value === "") return;
                      if (!/^\d*\.?\d{0,2}$/.test(value)) {
                        e.target.value = parseFloat(value).toFixed(2);
                      }
                    }}
                    step="1"
                    prefix="$"
                    type="number"
                  />
                </Col>
              </Row>
            ) : form.getFieldValue("lfsa_rollover_or_grace_period") ===
              "grace-period" ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <DatePicker2
                    isClearable={true}
                    id="lfsa_grace_period_end_date"
                    selected={formData["lfsa_grace_period_end_date"]}
                    name="lfsa_grace_period_end_date"
                    label="Date for ending grace period"
                    rules={[
                      mandatory({ message: "Please choose valid grace period" }),
                    ]}
                    placeholder="MM/DD/YYYY"
                    format={['MM/dd/yyyy', 'M/d/yyyy']}
                    filterDate={(date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);
                      return date >= today;
                    }}
                    readOnly={false}
                    onKeyDown={handleKeyDown}
                    onChange={(date) => {
                      form.setFieldsValue({ 'lfsa_grace_period_end_date': date });
                      setFormData(((prevData) => ({
                        ...prevData,
                        'lfsa_grace_period_end_date': date
                      })))
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <div className="nothing-here"></div>
            )
          }
        </Form.Item>
      </Col>
      <Col xs={24} md={24}>
        <Form.Item
          name="lfsa"
          className="dummy-error should-update-form-item"
        ></Form.Item>
      </Col>
    </Row>
  )
};
