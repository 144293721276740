export const Success = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9ZM4.27845 9.68215L6.97095 12.3746C7.26345 12.6671 7.74345 12.6671 8.02845 12.3746L13.7209 6.68215C14.0134 6.38965 14.0134 5.91715 13.7209 5.62465C13.4284 5.33215 12.9559 5.33215 12.6634 5.62465L7.50345 10.7846L5.33595 8.62465C5.04345 8.33215 4.57095 8.33215 4.27845 8.62465C4.13801 8.76477 4.05908 8.95501 4.05908 9.1534C4.05908 9.35179 4.13801 9.54202 4.27845 9.68215Z"
      fill="#02880F"
    />
  </svg>
);
