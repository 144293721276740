import { toast } from "react-toastify";
import { fileUploadService, getfileUploadStatusService } from "../../services";
import { ApiErrors } from "../../utils";

export function showSuccessMessage(msg) {
  return (dispatch) => {
    return toast.success(msg);
  };
}

export function showErrorMessage(msg) {
  return (dispatch) => {
    return toast.error(msg);
  };
}

export function showInfoMessage(msg) {
  return (dispatch) => {
    return toast.info(msg);
  };
}

export function showWarningMessage(msg) {
  return (dispatch) => {
    return toast.warning(msg);
  };
}

export function getFileUploadUrl(data, callback) {
  return dispatch => {
    fileUploadService(data)
      .then(res => {
        callback && callback(res)
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  }
}

export function getFileUploadStatus(fileID, callback) {
  return dispatch => {
    getfileUploadStatusService(fileID)
      .then(res => {
        callback && callback(res)
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  }
}