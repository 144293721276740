import { Col, Form, Modal, Row } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Select,
  TextField,
  Typography,
  DatePicker
} from "../../../../../../../design-systems";
import DatePicker2 from "../../../../../../../design-systems/DatePicker2";
import { langs } from "../../../../../../../i18n";
import { getEmployeesData, showSuccessMessage, updatePersonalInformation } from "../../../../../../../redux/actions";
import { clearBodyObject, comparisonValues, disablePastDates, handleKeyDown, hideBankingData, stripLetters } from "../../../../../../../utils";
import { DATE_FORMAT, STATE_NAMES } from "../../../../../../../utils/constants";
import { useRouter } from "../../../../../../../utils/router";
import {
  email,
  mandatory,
  maxLength,
  minLength,
  required,
  SSNValidation,
  validateFirstNameRules,
  validateLastNameRules,
  validateMiddleNameRules,
  validatePhoneNumber,
  zipCodeValidation,
} from "../../../../../../../utils/validations";

export const PersonalInformationForm = ({ visible, onCancel, form }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { employeeDetails } = useSelector((state) => state.employees);

  // const [showSSN, setShowSSN] = useState(false);
  const [dob, setDob] = useState(null)
  const [btnName, setBtnName] = useState(null)
  const [loading, setLoading] = useState({
    value: false,
    for: null
  });
  const [formChanged, setFormChanged] = useState(true);
  const [allowKyc, setAllowKyc] = useState(false);
  const [captureKyc, setCaptureKyc] = useState(false);

  useEffect(() => {
    setAllowKyc(employeeDetails?.allowKYC?.value);
    setCaptureKyc(employeeDetails?.captureKyc?.value);
    setDob(new Date(employeeDetails?.DOB?.value))
  }, [employeeDetails]);

  const getEmployeeData = () => {
    const id = router.match.params.id;
    dispatch(getEmployeesData(id));
  };

  const initialValues = {
    first_name: employeeDetails?.firstName?.value,
    middle_name: employeeDetails?.middleName?.value,
    last_name: employeeDetails?.lastName?.value,
    addr1: employeeDetails?.addr1?.value,
    mobile_phone_num: employeeDetails?.mobileNum?.value,
    work_email: employeeDetails?.workEmail?.value?.toLowerCase(),
    residential_address_1: employeeDetails?.addr1?.value,
    residential_address_2: employeeDetails?.addr2?.value,
    residential_city: employeeDetails?.city?.value,
    residential_state: employeeDetails?.st?.value,
    residential_postal_code: employeeDetails?.postal?.value,
    ssn: null,
    dob: employeeDetails?.DOB?.value
  };

  const clearedData = (object, middleNameIsChanged, address2IsChanged, mobileNumIsChanged) => {
    const modifiedObject = {};

    for (let key in object) {
      if ((key === "middle_name" && middleNameIsChanged) ||
        (key === "residential_address_2" && address2IsChanged) ||
        (key === "mobile_phone_num" && mobileNumIsChanged)) {
        modifiedObject[key] = object[key] === "" ? null : object[key];
      } else if (object[key] !== null && object[key] !== undefined) {
        modifiedObject[key] = object[key];
      }
    }
    return modifiedObject
  }

  const onFinish = (values) => {
    const emailIsChanged = initialValues.work_email !== values.work_email;
    const firstnameIsChanged = initialValues.first_name !== values.first_name;
    const lastnameIsChanged = initialValues.last_name !== values.last_name;
    const middleNameIsChanged = initialValues.middle_name !== values.middle_name;
    const address1IsChanged = initialValues.residential_address_1 !== values.residential_address_1;
    const address2IsChanged = initialValues.residential_address_2 !== values.residential_address_2;
    const cityIsChanged = initialValues.residential_city !== values.residential_city;
    const stateIsChanged = initialValues.residential_state !== values.residential_state;
    const zipCodeIsChanged = initialValues.residential_postal_code !== values.residential_postal_code;
    const mobileNumIsChanged = initialValues.mobile_phone_num !== values.mobile_phone_num
    const ssnIsChanged = values.ssn !== "" && initialValues.ssn !== values.SSN;
    const dobIsChanged = values?.date_of_birth && moment(values?.date_of_birth).isSame(initialValues?.dob) ? false : true;

    const data = { ...values };

    data.work_email = emailIsChanged ? values?.work_email?.toLowerCase() : null;
    data.mobile_phone_num = mobileNumIsChanged ? values.mobile_phone_num : null;
    data.first_name = firstnameIsChanged ? values.first_name : null;
    data.middle_name = middleNameIsChanged ? values.middle_name : null;
    data.last_name = lastnameIsChanged ? values.last_name : null;
    data.residential_address_1 = address1IsChanged ? values.residential_address_1 : null;
    data.residential_address_2 = address2IsChanged ? values.residential_address_2 : null;
    data.residential_city = cityIsChanged ? values.residential_city : null;
    data.residential_state = stateIsChanged ? values.residential_state : null;
    data.residential_postal_code = zipCodeIsChanged ? values?.residential_postal_code : null;
    data.date_of_birth = dobIsChanged && values?.date_of_birth ? moment(values.date_of_birth).format("YYYY-MM-DD") : null;
    data.ssn = ssnIsChanged ? values.ssn : null;
    data.run_kyc = allowKyc;
    data.capture_kyc = captureKyc;

    dispatch(updatePersonalInformation(employeeDetails?.employeeID?.value, clearedData(data, middleNameIsChanged, address2IsChanged, mobileNumIsChanged), (res) => {
      setLoading({
        value: true,
        for: null
      });
      if (!(res instanceof Error)) {
        getEmployeeData()
        setFormChanged(true)
        setLoading(false);
        onCancel()
        dispatch(showSuccessMessage((btnName && btnName === "captureKyc") ? langs.messages.update_employee_success : langs.messages.kyc_updated));
      }
    }))
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo, "134s");
    setLoading({
      value: false,
      for: null
    })
  };

  const isDisable = (key) => {
    return employeeDetails?.[key]?.canEdit ? false : true;
  };

  const disableFutureDates = date => {
    const today = moment().startOf('day');
    return moment(date).isAfter(today) ? false : true
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
        setFormChanged(true)
      }}
      title={<Typography variant="subhead-2" label="Edit personal details" />}
      width={700}
      footer=""
      className="personal-information-modal-wrapper"
    >
      <Form
        className="personal-information-form"
        layout="vertical"
        name="basic"
        onValuesChange={(values, allValues) => {
          if(allValues.ssn === "") {
            allValues.ssn = null
          }
          setFormChanged(comparisonValues(initialValues, allValues));
        }}
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="form-wrapper">
          <Row gutter={[16, 0]}>
            <Col sm={12}>
              <Form.Item
                name="first_name"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("firstName")}
                  label="First name"
                  placeholder="Add first name"
                  name="first_name"
                  rules={
                    [
                      { validator: validateFirstNameRules, required: true },
                    ]
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="middle_name"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("middleName")}
                  label="Middle name"
                  placeholder="Add middle name"
                  name="middle_name"
                  rules={[{ validator: validateMiddleNameRules }]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="last_name"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("lastName")}
                  label="Last Name"
                  rules={
                    [
                      { validator: validateLastNameRules, required: true },
                    ]
                  }
                  placeholder="Add last name"
                  name="last_name"
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="residential_address_1"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("addr1")}
                  label="Street address 1"
                  placeholder="Add street address 1"
                  name="residential_address_1"
                  rules={[required("Street address 1"), maxLength(100)]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="residential_address_2"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("addr2")}
                  label="Street address 2"
                  placeholder="Add street address 2"
                  name="residential_address_2"
                  rules={[maxLength(100)]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="residential_city"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("city")}
                  label="City"
                  placeholder="Add city"
                  name="residential_city"
                  rules={[required("City"), maxLength(60)]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="residential_state"
                normalize={(value) => value.trimStart()}
              >
                <Select
                  disabled={isDisable("st")}
                  label="State"
                  placeholder="Select state"
                  name="residential_state"
                  options={STATE_NAMES.map(({ abbreviation }) => ({
                    label: abbreviation,
                    value: abbreviation,
                  }))}
                  rules={[mandatory({ message: "Please select a valid state" })]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="residential_postal_code"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("postal")}
                  label="Zip code"
                  placeholder="Add zip code"
                  name="residential_postal_code"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  rules={[required("Zip code"), maxLength(5), minLength(5), zipCodeValidation]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="work_email"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("workEmail")}
                  label="Work email"
                  placeholder="Add work email"
                  name="work_email"
                  rules={[required("Email id"), email]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="mobile_phone_num"
                normalize={(value) => value.trimStart()}
              >
                <TextField
                  disabled={isDisable("mobileNum")}
                  label="Mobile phone number"
                  placeholder="Add mobile phone number"
                  name="mobile_phone_num"
                  rules={[{
                    validator: validatePhoneNumber
                  }]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
              >
                <TextField
                  name="ssn"
                  label="SSN"
                  rules={[SSNValidation]}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={isDisable("SSN")}
                  placeholder="SSN"
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
              >
                <DatePicker2
                  maxDate={new Date()}
                  isClearable={true}
                  id="date_of_birth"
                  selected={dob}
                  name="date_of_birth"
                  rules={[required("Date of birth")]}
                  label="Date of Birth"
                  placeholder="MM/DD/YYYY"
                  format={['MM/dd/yyyy', 'M/d/yyyy']}
                  filterDate={disableFutureDates}
                  readOnly={false}
                  onKeyDown={handleKeyDown}
                  disabled={isDisable("DOB")}
                  onChange={(date) => {
                    form.setFieldsValue({ 'date_of_birth': date });
                    setFormChanged(false)
                    setDob(date)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="footer-wrapper">
          <Button
            variant="secondary"
            loading={loading?.value && loading?.for === "captureKyc"}
            onclickCallback={() => {
              setLoading({
                value: true,
                for: "captureKyc"
              });
              setBtnName("captureKyc")
              setAllowKyc(false)
              setCaptureKyc(false);
            }}
            label="Save without KYC"
            htmlType="submit"
            disabled={formChanged}
          />
          <Button
            variant="primary"
            loading={loading?.value && loading?.for === "setKyc"}
            onclickCallback={() => {
              setLoading({
                value: true,
                for: "setKyc"
              });
              setBtnName("setKyc")
            }}
            label="Save & run KYC"
            htmlType="submit"
            disabled={formChanged
              || (!employeeDetails?.captureKyc?.value && !employeeDetails?.allowKYC?.value)
            }
          />
        </div>
      </Form>
    </Modal>
  );
};
