import React from "react";

import { Card, Col, Row } from "antd";
import { TransactionHistory } from "../../../components/admin/dashboard";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { Typography } from "../../../design-systems";

function AccountActivity() {
  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col xs={24}>
          <Card title="" bordered={false} className="data-grid employee-listing-v2">
            <Typography
              label="HSA account activity"
              variant="subhead-1"
              className="employee-listing-title section-gap"
            />
            <TransactionHistory fullView />
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { AccountActivity };
