import { useRef } from "react";
import { stripLetters, hideBankingData } from "../../../../utils";
import { Input } from "antd";

export default function BankDataInput({
  value,
  valueVisible,
  className,
  ...props
}) {
  const inputRef = useRef(null);

  const maskedValue = valueVisible
    ? stripLetters(inputRef.current?.input.value ?? value)
    : hideBankingData(value);

  return (
    <div className="noSelect">
      <Input
        type="text"
        className={`maskedInput ${className ? className : ""}`}
        value={maskedValue}
        onFocus={(e) => {
          inputRef?.current?.focus();
        }}
        {...props}
      />
      <Input
        type="text"
        className="pureInput"
        value={value}
        ref={inputRef}
        {...props}
      />
    </div>
  );
}
