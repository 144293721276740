import React, { useEffect, useMemo, useState } from "react";
import { useRouter } from "../../../utils/router/customRouterHook";
import { EMPLOYEE_STATUSES } from "../../../utils/constants";
import { getEmployeeListingColumns, renderEmployeeAccountStatusTag, accountHolderIdMap } from "../../../utils";
import { getEmployeesListWithFilters } from "../../../redux/actions";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { Card, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { renderTitle, Table, Typography } from "../../../design-systems";
import { SearchInput } from "./SearchInput";
import useShowSecondSection from "../../../hooks/useShowSection";

function EmployeeList() {
  const dispatch = useDispatch();
  const router = useRouter();

  const [empList, setEmpList] = useState(null);
  const [searchRes, setSearchRes] = useState([]);
  const [search, setSearch] = useState("")
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit] = useState(10)
  const [sortFields, setSortFields] = useState({
    sort: "",
    order: "asc"
  })

  const [filter, setFilter] = useState([])

  useShowSecondSection();

  const getAccountHolderId = () => {
    let result = [];
    if (Array.isArray(filter) && filter.length) {
      for (const status of filter) {
        if (accountHolderIdMap[status]) {
          result.push(accountHolderIdMap[status])
        }
      }
    } 
    return result;
  }

  const getAllEmployee = (signal) => {
    const payload = {
      page: currentPage,
      limit: limit,
      status_filter_key: "account_holder_status_id",
      status_filter_value: getAccountHolderId(),
    };

    if (sortFields.sort) {
      payload.sort = sortFields.sort;
      payload.order = sortFields.order;
    }


    dispatch(getEmployeesListWithFilters(payload, (res) => {
      setEmpList(res.employees);
      setTotalCount(res.total_records);
      if (router.query.searchTerm) {
        applySearchFilter(res.employees, router.query.searchTerm, router.query.searchField);
        router.replace("/admin/employees/employee-list");
      } else {
        setSearchRes(res.employees);
      }
    }, signal));
  };

  useEffect(() => {
    if (router.location?.state?.userStatus) {
      setFilter([router.location.state.userStatus])
    }
    // eslint-disable-next-line
  }, [router.location]);


  useEffect(() => {
    setCurrentPage(1)
  }, [search, sortFields])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAllEmployee(signal)
    return () => {
      controller.abort()
    };
  }, [currentPage, limit, sortFields, search, filter]);


  const applySearchFilter = (value) => {
    setSearch(value)
  };

  const handleTableChange = (pagination, filters, sorter, { _, action }) => {
    switch (action) {
      case 'sort': {
        if (!sorter?.column) {
          setSortFields({
            sort: "",
            order: "asc"
          });
          return;
        }
        setSortFields({
          sort: sorter.field,
          order: sorter.order === "ascend" ? "asc" : "desc"
        })
        break;
      }
      case 'filter':
        setFilter(filters?.account_holder_status ?? []);
      default:
        break;
    }
  }

  const activeTabColumns = useMemo(() => {
    const columns = getEmployeeListingColumns().filter(col => col.key !== "account_holder_status" && col.key !== "kyc_status")
    columns.splice(2, 0, {
      title: (titleProps) =>
        renderTitle(titleProps, "Account holder status", "account_holder_status"),
      key: "account_holder_status",
      dataIndex: "account_holder_status",
      width: 200,
      render: (tag) => {
        return renderEmployeeAccountStatusTag(tag)
      },
      filters: Object.values(EMPLOYEE_STATUSES).map((status) => ({
        text: status,
        value: status,
      })),
      defaultFilteredValue: router.location.state
        ? [router.location.state.userStatus]
        : [],
    });
    return columns
  }, [router?.location?.state]);

  return (
    <HeaderWithContent>
      <Row gutter={[4, 4]}>
        <Col span={24}>
          <Card title="" bordered={false} className="data-grid employee-listing-v2">
            <Typography
              label="Employee"
              variant="subhead-1"
              className="employee-listing-title"
            />
            <div className="input-search-wrapper">
              <SpinLoader showContent={!!empList}>
                <Row gutter={[8]}>
                  <Col xs={24}>
                    <div className="input-wrapper input-search-wrapper">
                      <SearchInput
                        placeholder={"Search by first name or last name or email"}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setSearch("")
                          }
                        }}
                        onSearch={(e) => applySearchFilter(e)}
                      />
                    </div>
                  </Col>
                  <Col xs={24}>
                    <Table
                      className="claims-list-table employee-table"
                      columns={activeTabColumns}
                      dataSource={searchRes}
                      onChange={handleTableChange}
                      bordered
                      scroll={searchRes?.length ? { x: 1200 } : undefined}
                      rowClassName={"pointer"}
                      onRow={(record, rowIndex) => ({
                        onClick: (e) => {
                          router.push(`/admin/employee-detail/${record.employee_id}`);
                        },
                      })}
                      pagination={{
                        onChange: (page) => setCurrentPage(page),
                        total: totalCount,
                        current: currentPage,
                        defaultCurrentPage: 1,
                        defaultPageSize: limit,
                      }}
                    />
                  </Col>
                </Row>
              </SpinLoader>
            </div>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { EmployeeList };
