import React, { useState } from "react";
import { SectionHeader } from "./SectionHeader";
import { Button, Typography } from "../../../../design-systems";
import { ROLE_CONSTANT } from "../../../../utils/constants";
import moment from "moment";
import { Col, Collapse, Empty, Row } from "antd";
import { useSelector } from "react-redux";
import { SpinLoader } from "../../../common/SpinLoader";
import { fixAmountFormat, planInformationEmpKeys } from "../../../../utils";
import { useRouter } from "../../../../utils/router";
import { useDispatch } from "react-redux";
import { copyPlan, getCurrentEmployerDetailsByTab, saveEmployerPlanData } from "../../../../redux/actions";

export const PlanInfo = ({ data }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { Panel } = Collapse;
  const { current_plans = [], previous_plans = [], upcoming_plans = [] } = data || {};
  const [loading, setLoading] = useState(false)
  const { runOutOptions } = useSelector((state) => state.employer);
  const role = useSelector((state) =>
    state.userDetails && state.userDetails.roleType
      ? state.userDetails.roleType
      : ROLE_CONSTANT.ADMIN,
  );
  const isZendaAdmin = role === ROLE_CONSTANT.ZENDA_ADMIN;
  const onChange = (key) => { };

  const getRolloverOrGracePeriod = (plan) => {
    if (Number(plan?.is_rollover_enabled) === 1) {
      return "Rollover";
    } else if (Number(plan?.is_grace_period_enabled) === 1) {
      return "Grace";
    } else {
      return "None";
    }
  };

  const renderPlanInfo = (plan, title) => (
    <div className="column-wrapper-left">
      <Typography
        className={"section-title"}
        variant={"body-2"}
        label={title}
      />
      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Minimum contribution"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${fixAmountFormat(plan?.min_employee_contribution_amount)}`}
        />
      </div>
      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3 "}
          label={"Maximum contribution"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${fixAmountFormat(plan?.max_employee_contribution_amount)}`}
        />
      </div>
      {/* <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3 "}
          label={"Employer contribution"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${fixAmountFormat(plan?.employer_contribution_amount)}`}
        />
      </div> */}

      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Runout period"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${plan?.run_out_period_in_days} ${Number(plan?.run_out_period_in_days) > 1 ? "days" : "day"}`}
        />
      </div>
      {/* commenting right now. keeping for future need */}
      {/* <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Date of service through"}
        />
        <Typography
          className={"plan-data "}
          variant={"plan-3"}
          label={moment(plan_information?.plan_year?.plan_start_date).format("MMM DD, YYYY")}
        />
      </div> */}
      {
        title === "DCFSA" &&
        <div className="row-wrapper section-margin">
          <Typography
            className={"plan-data-label "}
            variant={"plan-3"}
            label={"Allow claim to be filed with a date of service after termination"}
          />
          <Typography
            className={"plan-data align-right mr-3"}
            variant={"plan-3"}
            label={plan?.allow_claim_file_post_termination_within_service_date === 1 ? "Yes" : "No"}
          />
        </div>
      }
      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Runout period for terminated employee"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${plan?.run_out_period_in_days} ${Number(plan?.run_out_period_in_days) > 1 ? "days" : "day"}`}
        />
      </div>
      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Days following by"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={runOutOptions?.find(opt => opt.value === plan?.terminated_employees_run_out)?.label || "-"}
        />
      </div>
      {
        title !== "DCFSA" &&
        <>
          <div className="row-wrapper section-margin">
            <Typography
              className={"plan-data-label "}
              variant={"plan-3"}
              label={"Rollover or grace period"}
            />
            <Typography
              className={"plan-data align-right mr-3"}
              variant={"plan-3"}
              label={getRolloverOrGracePeriod(plan)}
            />
          </div>
          {
            getRolloverOrGracePeriod(plan) === "Rollover" &&
            <>
              <div className="row-wrapper section-margin">
                <Typography
                  className={"plan-data-label "}
                  variant={"plan-3"}
                  label={"Minimum rollover"}
                />
                <Typography
                  className={"plan-data align-right mr-3"}
                  variant={"plan-3 align-right mr-3"}
                  label={`${fixAmountFormat(plan?.min_rollover_amount)}`}
                />
              </div>
              <div className="row-wrapper section-margin">
                <Typography
                  className={"plan-data-label "}
                  variant={"plan-3"}
                  label={"Maximum rollover"}
                />
                <Typography
                  className={"plan-data align-right mr-3"}
                  variant={"plan-3"}
                  label={`${fixAmountFormat(plan?.max_rollover_amount)}`}
                />
              </div>
              <div className="row-wrapper section-margin">
                <Typography
                  className={"plan-data-label "}
                  variant={"plan-3"}
                  label={"Allow rollover if not elected for following plan year?"}
                />
                <Typography
                  className={"plan-data align-right mr-3"}
                  variant={"plan-3"}
                  label={plan?.rollover_if_no_fsa_next_year ? "Yes" : "No"}
                />
              </div>
            </>
          }
        </>
      }
      {
        getRolloverOrGracePeriod(plan) === "Grace" &&
        <>
          <div className="row-wrapper section-margin">
            <Typography
              className={"plan-data-label"}
              variant={"plan-3"}
              label={"Date of ending grace period"}
            />
            <Typography
              className={"plan-data align-right mr-3"}
              variant={"plan-3"}
              label={moment(plan?.grace_period_end_date).format("MMM DD, YYYY")}
            />
          </div>
        </>
      }
    </div>
  )

  const switchEmployer = (plans, panelKeyPrefix) => {
    if (panelKeyPrefix === "current") {
      setLoading(true);
      dispatch(copyPlan((res) => {
        if (!(res instanceof Error)) {
          setLoading(false)
          dispatch(getCurrentEmployerDetailsByTab(planInformationEmpKeys?.PLAN_INFO, (res) => {
            if (!(res instanceof Error)) {
              setLoading(false)
            } else {
              setLoading(false)
            }
          }))
        } else {
          setLoading(false)
        }
      }))
    } else {
      dispatch(saveEmployerPlanData(plans))
      router.push("/admin/employer/plan/add/1")
    }
  };

  const renderPreviousPlans = (previousPlans) => {
    return (
      <Collapse onChange={onChange} className="account-holder mb-15">
        <Panel header={<Row><Col sm={8}>Previous plan</Col></Row>} key="1">
          <Collapse onChange={onChange} className="account-holder mb-15">
            {previousPlans.map((planInformation, index) => {
              const isHSASelected = () => {
                if (planInformation?.plan_information?.selected_plans.length === 1 && planInformation?.plan_information?.selected_plans.includes("HSA")) {
                  return true;
                }
                return false;
              };
              return (
                <Panel
                  header={
                    <Row>
                      <Col sm={8}>
                        <div className="column-wrapper-left">
                          <Typography
                            className={"section-title"}
                            variant={"body-2"}
                            label={"Start date"}
                          />
                          <Typography
                            className={"section-data section-margin"}
                            variant={"body-3"}
                            label={moment(planInformation?.plan_information?.plan_year?.plan_start_date).format("MMM DD, YYYY")}
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <div className="column-wrapper-left">
                          <Typography
                            className={"section-title"}
                            variant={"body-2"}
                            label={"End date"}
                          />
                          <Typography
                            className={"section-data section-margin"}
                            variant={"body-3"}
                            label={moment(planInformation?.plan_information?.plan_year?.plan_end_date).format("MMM DD, YYYY")}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  key={index + 1}
                >
                  <Row>
                    <Col sm={12}>
                      <SectionHeader name={"Plan"} />
                    </Col>
                    <div className="plan-date">
                      <div className="column-wrapper-left">
                        <Typography
                          className={"section-title"}
                          variant={"body-2"}
                          label={"Selected plans"}
                        />
                        <Typography
                          className={"section-data section-margin"}
                          variant={"body-3"}
                          label={planInformation?.plan_information?.selected_plans?.join(", ")}
                        />
                      </div>
                    </div>
                  </Row>

                  <div className="plan-date header-margin">
                    {planInformation?.plan_information?.selected_plans?.includes("FSA") && renderPlanInfo(planInformation?.plan_information?.fsa, "FSA")}
                    {planInformation?.plan_information?.selected_plans?.includes("LFSA") && renderPlanInfo(planInformation?.plan_information?.lfsa, "LFSA")}
                    {planInformation?.plan_information?.selected_plans?.includes("DCFSA") && renderPlanInfo(planInformation?.plan_information?.dcfsa, "DCFSA")}
                  </div>
                  {!isHSASelected() && (
                    <>
                      <SectionHeader name={"Copays"} />
                      <div className="copays">
                        {planInformation?.copay_configuration?.map((copay) => (
                          <div className="copays-child column-wrapper-left" key={copay.expense_category}>
                            <Typography
                              className={"section-title copay-label"}
                              variant={"body-2"}
                              label={copay.expense_category}
                            />
                            <Typography
                              className={"section-data section-margin copay-data"}
                              variant={"body-3"}
                              label={copay.copay_amount}
                            />
                          </div>
                        ))}
                        {planInformation.copay_configuration?.length === 0 && (
                          <div className="empty-container">
                            <Empty />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Panel>
              )
            })}
          </Collapse>
        </Panel>
      </Collapse>
    );
  };

  const renderPlans = (plans, panelKeyPrefix) => {
    if (plans.length === 0) {
      return (
        <Collapse className="account-holder mb-15">
          <Panel header={<Row><Col sm={8}>{panelKeyPrefix === "current" ? "Current plan" : panelKeyPrefix === "previous" ? "Previous plan" : "Upcoming plan"}</Col></Row>} key={`${panelKeyPrefix}_no_data`}>
            <Empty description="No Data" />
          </Panel>
        </Collapse>
      );
    }
    return plans.map((plan, index) => {
      const isHSASelected = () => {
        if (plan?.plan_information?.selected_plans.length === 1 && plan?.plan_information?.selected_plans.includes("HSA")) {
          return true;
        }
        return false;
      };
      return (
        <Collapse
          onChange={onChange}
          defaultActiveKey={["current_0"]}
          className="account-holder mb-15"
        >
          <Panel
            header={
              <Row>
                <Col sm={8}>
                  {panelKeyPrefix === "current" ? "Current plan" : "Upcoming plan"}
                </Col>
                <Col sm={8}>
                  <div className="column-wrapper-left">
                    <Typography
                      className={"section-title"}
                      variant={"body-2"}
                      label={"Start date"}
                    />
                    <Typography
                      className={"section-data section-margin"}
                      variant={"body-3"}
                      label={moment(plan.plan_information?.plan_year?.plan_start_date).format("MMM DD, YYYY")}
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <div className="column-wrapper-left">
                    <Typography
                      className={"section-title"}
                      variant={"body-2"}
                      label={"End date"}
                    />
                    <Typography
                      className={"section-data section-margin"}
                      variant={"body-3"}
                      label={moment(plan.plan_information?.plan_year?.plan_end_date).format("MMM DD, YYYY")}
                    />
                  </div>
                </Col>
              </Row>
            }
            key={`${panelKeyPrefix}_${index}`}
          >
            <Row>
              <Col sm={12}>
                <SectionHeader name={"Plan"} />
              </Col>
              {
                plan?.admin_action?.length > 0 && isZendaAdmin &&
                <Col sm={12} align="end">
                  <Button
                    className="create-expense-button"
                    variant="primary"
                    onclickCallback={() => switchEmployer(plans, panelKeyPrefix)}
                    label={panelKeyPrefix === "current" ? "Add plan" : "Edit plan"}
                    loading={loading}
                    htmlType="submit"
                  />
                </Col>
              }
            </Row>

            <div className="plan-date">
              <div className="column-wrapper-left">
                <Typography
                  className={"section-title"}
                  variant={"body-2"}
                  label={"Selected plans"}
                />
                <Typography
                  className={"section-data section-margin"}
                  variant={"body-3"}
                  label={plan.plan_information?.selected_plans?.join(", ")}
                />
              </div>
            </div>
            <div className="plan-date header-margin">
              {plan.plan_information?.selected_plans?.includes("FSA") && (
                renderPlanInfo(plan.plan_information?.fsa, "FSA")
              )}
              {plan.plan_information?.selected_plans?.includes("LFSA") && (
                renderPlanInfo(plan.plan_information?.lfsa, "LFSA")
              )}
              {plan.plan_information?.selected_plans?.includes("DCFSA") && (
                renderPlanInfo(plan.plan_information?.dcfsa, "DCFSA")
              )}
            </div>
            {!isHSASelected() && (
              <>
                <SectionHeader name={"Copays"} />
                <div className="copays">
                  {plan.copay_configuration?.map((copay) => (
                    <div className="copays-child column-wrapper-left">
                      <Typography
                        className={"section-title copay-label"}
                        variant={"body-2"}
                        label={copay.expense_category}
                      />
                      <Typography
                        className={"section-data section-margin copay-data"}
                        variant={"body-3"}
                        label={copay?.copay_amount}
                      />
                    </div>
                  ))}
                  {plan.copay_configuration?.length === 0 && (
                    <div className="empty-container">
                      <Empty />
                    </div>
                  )}
                </div>
              </>
            )}
          </Panel>
        </Collapse>
      )
    });
  };

  return (
    <div className="content">
      <SpinLoader showContent={!!data}>
        {previous_plans && previous_plans?.length === 0 && upcoming_plans && upcoming_plans?.length === 0 && current_plans && current_plans?.length === 0 ?
          <Empty description="No Data" />
          :
          <>
            {current_plans && current_plans?.length > 0 ? renderPlans(current_plans, "current") : null}
            {previous_plans && previous_plans?.length > 0 ? renderPreviousPlans(previous_plans) : null}
            {upcoming_plans && upcoming_plans?.length > 0 ? renderPlans(upcoming_plans, "upcoming") : null}
          </>
        }
      </SpinLoader>
    </div>
  );
};
