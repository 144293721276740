import React, { useEffect } from "react";
import { useRouter } from "../../../utils/router/customRouterHook";
import { Form, Col, Row } from "antd";
import { SearchInput } from "../../../pages/admin/employee/SearchInput";
// to be used later
// import { searchFields } from "../../../utils";
// import { Select } from "../../../design-systems";

function GlobalSearch() {
  const router = useRouter();
  const [form] = Form.useForm();
  const queryParameters = new URLSearchParams(window.location.search);
  const keyword = queryParameters.get("query");

  useEffect(() => {
    if (keyword) {
      form.setFieldsValue({
        searchKeyword: keyword,
      });
    }
    // eslint-disable-next-line
  }, [form, keyword]);

  const onFormSubmit = (value) => {
    const new_keyword = encodeURIComponent(value.trim());
    if (new_keyword) {
      router.replace({
        pathname: "/admin/search",
        search: `?query=${new_keyword}`,
      });
    }
  }

  const onFinish = (values) => {
    onFormSubmit(values?.searchKeyword)
  };

  // const searchBy = Form.useWatch("search_by", form)

  const onReset = () => {
    if (keyword) {
      router.replace({
        pathname: "/admin/search",
      });
    } else {
      form.setFieldsValue({
        searchKeyword: "",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <>
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="global-search-wrapper">
        <Form.Item name="searchKeyword">
            <SearchInput
              placeholder="Enter first name or last name or email"
              onChange={(e) => {
                if (!e.target.value) {
                  onReset()
                }
              }}
              allowClear
              onSearch={(e) => onFormSubmit(e)}
            />
          </Form.Item>
          {/* to be used later */}
          {/* <Row gutter={[8]}>
            <Col xs={12}>
              <Form.Item>
                <Select
                  className={"select-field-search"}
                  placeholder="Select field to search"
                  name="search_by"
                  options={searchFields.map(({ label, value }) => ({
                    label,
                    value,
                  }))}
                // onChange={(value) => {
                //   setSearchBy(value)
                // }}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item name="searchKeyword">
                <SearchInput
                  disabled={!searchBy?.length}
                  placeholder="Enter first name or last name or email"
                  onChange={(e) => {
                    if (!e.target.value) {
                      onReset()
                    }
                  }}
                  allowClear
                  onSearch={(e) => onFormSubmit(e)}
                />
              </Form.Item>
            </Col>
          </Row> */}
        </div>
      </Form>
    </>
  );
}

export { GlobalSearch }