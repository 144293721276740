import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import { ExternalLinkIcon } from "../../../../design-systems/Icons/ExternalLinkIcon";
import moment from "moment";

const EmployeeDetails = ({ empName, employee_id, claim_id, employer_name, last_updated_on }) => {
  const { Text } = Typography;
  return (
    <Row className="mt-30 mb-30 ml-30">
      <Col span={24}>
        <Row gutter={16}>
          <Col lg={6} xs={24}>
            <div>
              <Text type="secondary">Employee</Text>
              <br />
              <Link className="link-text" to={`/admin/employee-detail/${employee_id}`} target="_blank">
                {empName ?? ""} <ExternalLinkIcon />
              </Link>
            </div>
          </Col>
          <Col lg={6} xs={24}>
            <div>
              <Text type="secondary">Employer</Text>
              <br />
              <Link className="link-text" to="/admin/employer-detail" target="_blank">
                  {employer_name ?? ""} <ExternalLinkIcon />
              </Link>
            </div>
          </Col>
          <Col lg={6} xs={24}>
            <div>
              <Text type="secondary">Claim number</Text>
              <br />
              <span className="claim-info">{claim_id ?? ""}</span>
            </div>
          </Col>
          <Col lg={6} xs={24}>
            <div>
              <Text type="secondary">Last updated on</Text>
              <br />
              <span className="claim-info">{last_updated_on ? moment(last_updated_on).format("MM/DD/YYYY") : ""}</span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EmployeeDetails;
