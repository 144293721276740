import { Card, Col, Row } from "antd";
import { CardTitle } from "./CardTitle";
import { Table, Typography } from "../../../../../../design-systems";
import { fixAmountFormat, getPlanInformationColumns, getYTDContributionsLabel, getYTDLabels } from "../../../../../../utils";
import { DownOutlined } from "@ant-design/icons";
import { PlanInformationForm } from "./forms";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddNewPlan } from "./AddNewPlan";
import { getCoverages, getEmployerInfo } from "../../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../../../../utils/router";

const renderData = (plan, [label, value]) => {
  return (
    <Col sm={24} className="plan-information-data">
      <Typography label={label ? getYTDContributionsLabel(label, plan) : ""} variant="body-3" />
      <Typography className="align-right" label={value !== null ? `${fixAmountFormat(value)}` : "$0.00"} variant="body-2" />
    </Col>
  )
};

const expandedRowRender = (row) => {
  const { plan_type, ytd_contribution } = row || {};
  return (
    <Row gutter={[16, 16]}>
      {["HSA", "DCFSA"].includes(plan_type) && (
        <Col sm={12}>
          <Row gutter={[16, 16]}>
            <Col sm={24}>
              <Typography label="YTD Contributions" variant="body-2" />
            </Col>
            {Object.entries(ytd_contribution).map(([key, value]) => renderData(plan_type, [key, value]))}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export const PlanInformation = ({ hideTable=false, planName, planYear, planInfo, record, getEmployeeData, employee, notEnrolledPlans=[], employerPlan={}, planConfigId = null, planStatus = null  }) => {

  const dispatch = useDispatch();
  const router = useRouter()
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [expandedKey, setExpandedKey] = useState(null);
  const [plansData, setPlansData] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [formData, setFormData] = useState({});
  const [changedValue, setChangedValues] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const role = useSelector((state) => state?.userDetails?.roleType);
  const [ showAddPlanButton, setShowAddPlanButton ] = useState(hideTable);
  const onExpand = (_, { plan_id }) => {
    expandedKey === plan_id ? setExpandedKey(null) : setExpandedKey(plan_id);
  }

  const id = router.match.params.id;


  useEffect(() => {
    dispatch(getCoverages());
  }, [])

  const { employerPlanInfo } = useSelector((state) => state.employer);
  const { employeePlans } = useSelector((state) => state.employees);
  useEffect(() => {
    const empPlans = [];

    if (planName === "HSA") {
      empPlans.push(employeePlans?.HSA);
      if(hideTable) {
        setPlansData(["HSA"]);
        setShowAddPlanButton(true);
      }
    }

    if (planName === "FSA") {
      employeePlans?.FSA?.forEach((plan) => {
        if (plan.plan_config_id === planConfigId) {
          empPlans.push(...plan.plan_data);   
        }
      });
      setShowAddPlanButton(notEnrolledPlans.length > 0);
      setPlansData(notEnrolledPlans);
    }
    setPlansList(empPlans);
  }, [employeePlans, employerPlanInfo])
  return (
    <Card
      title={<CardTitle
        title={`${planName} Plan Information ${planYear ? `(${planYear})` : ""}`}
        addPlan={showAddPlanButton}
        onAddPlanClick={() => setOpen(true)}
        plansData={plansData || []}
        tagText={planStatus}
      />}
      bordered
      className="emp-detail-card plan-information-wrapper"
    >
      {!hideTable ? <Table
        expandIconColumnIndex={9}
        className={"activity-logs-table"}
        rowKey={(record) => record?.plan_id}
        columns={getPlanInformationColumns({
          role,
          record,
          onEdit: (plan) => {
            setSelectedPlan(plan);
            setVisible(true);
          },
          showEditColumn: planStatus !== "INACTIVE",
        })}
        dataSource={plansList ?? []}
        pagination={false}
        expandable={{
          onExpand: onExpand,
          expandedRowKeys: [expandedKey],
          expandedRowRender,
          rowExpandable: (record) => (record?.plan_type === 'DCFSA' || record?.plan_type === 'HSA'),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.plan_type === 'DCFSA' || record?.plan_type === 'HSA') {
              return (
                <DownOutlined
                  className={`arrow-up-sorter ${expanded ? "expanded" : "collapsed"
                    }`}
                  onClick={() => onExpand(record)}
                />
              )
            } else {
              return null
            }
          },
          expandIconPosition: "right",
        }}
      /> : null}
      {visible && <PlanInformationForm
        id={employee?.employeeID?.value ?? id}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setSelectedPlan(null);
        }}
        getEmployeeData={getEmployeeData}
        formData={formData}
        setFormData={setFormData}
        changedValue={changedValue}
        setChangedValues={setChangedValues}
        planInfo={planInfo}
        selectedPlanDetails={selectedPlan}
        employerPlanInfo={employerPlan}
      />}

      <AddNewPlan
        open={open}
        setOpen={setOpen}
        getEmployeeData={getEmployeeData}
        plansData={plansData ?? []}
        planYear={planYear}
        planConfigId = {planConfigId}
      />

    </Card>
  );
};
