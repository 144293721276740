import { ApiErrors } from "../../utils";
import {
  disableDataLoader,
  disableLoader,
  enableDataLoader,
  enableLoader,
} from ".";
import {
  cancelContributionRunService,
  createContributionService,
  executeContributionRunService,
  executeContributionRunRetryService,
  executingContributionStatusService,
  getContributionHistoryService,
  getContributionValidationReportService,
  initiateACHPullRetryService,
  initiateACHPullService,
  updateContributionService,
  getBenefitAccountTypeService,
} from "../../services";
import { CONTRIBUTION } from "../../utils/constants";

export function getContributionHistory(callback) {
  const processName = "getContributionHistory";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getContributionHistoryService()
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getContributionHistoryWithoutLoader(callback) {
  return (dispatch) => {
    getContributionHistoryService()
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {});
  };
}

export function createContributionRun(reqData, file, callback) {
  return (dispatch) => {
    createContributionService(reqData, file)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {});
  };
}

export function updateContributionRun(id, reqData, file, callback) {
  return (dispatch) => {
    updateContributionService(id, reqData, file)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {});
  };
}

export function getContributionValidationReport(id, page, limit, callback) {
  const processName = "getContributionValidationReport";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getContributionValidationReportService(id, page, limit)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function startInitiateACHPull(id, callback) {
  return (dispatch) => {
    dispatch(enableLoader());
    initiateACHPullService(id)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableLoader());
      });
  };
}

export function retryInitiateACHPull(id, callback) {
  return (dispatch) => {
    dispatch(enableLoader());
    initiateACHPullRetryService(id)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableLoader());
      });
  };
}

export function startExecuteContributionRun(id, callback) {
  return (dispatch) => {
    executeContributionRunService(id)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {});
  };
}

export function retryExecuteContributionRun(id, comments, callback) {
  return (dispatch) => {
    dispatch(enableLoader());
    executeContributionRunRetryService(id, comments)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableLoader());
      });
  };
}

export function executingContributionStatus(id, callback) {
  return (dispatch) => {
    executingContributionStatusService(id)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {});
  };
}

export function cancelContribution(id, callback) {
  return (dispatch) => {
    dispatch(enableLoader());
    cancelContributionRunService(id)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableLoader());
      });
  };
}

export function getBenefitAccountType(callback) {
  return (dispatch) => {
    dispatch(enableLoader());
    getBenefitAccountTypeService()
      .then((res) => {
        callback && callback(res);

        dispatch({
          type: CONTRIBUTION.SET_BENEFIT_ACCOUNT_TYPE,
          payload: res.data,
        });
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableLoader());
      });
  };
}
