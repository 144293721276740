import React, { useState, useEffect } from "react";
import { Table, Card } from "antd";
import { useRouter } from "../../../../utils/router/customRouterHook";
import { HeaderWithContent } from "../../../common/HeaderWithContent";
import {
  Typography,
  Button,
  TextField,
  Link,
  ContributionIcon,
  EmployeesIcon,
} from "../../../../design-systems";
import { Search } from "../../../../design-systems/Icons";
import { renderEmployerStatusTag } from "../../../../utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCurrentEmployer, getEmployerTypeData, saveEmployerRouteInfo, setShowSecondSection, showSuccessMessage, switchEmployerTypeData } from "../../../../redux/actions";
import { langs } from "../../../../i18n";
import { SearchInput } from "../../../../pages/admin/employee/SearchInput";
import { SpinLoader } from "../../../common/SpinLoader";
import { useLocation } from "react-router-dom";
import { EMPLOYER_CONSTANT } from "../../../../utils/constants";

//table data from api
function GetToolTipContent({ title }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>{title}</div>
  )
}
export const EmployerList = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const employerType = useSelector((state) => state.employer.employerType)
  const [apiData, setApiData] = useState(null);
  const [employerListData, setEmployerListData] = useState(null);
  const [search, setSearch] = useState("");

  const addNewEmployer = () => {
    dispatch({
      type: EMPLOYER_CONSTANT.SET_EMPLOYER_ID,
      payload: ""
    });
    router.push("/admin/employer/add/1");
  }

  const onContributionClick = () =>
    router.push("/admin/contributions/run-history");

  const onEmployerSelection = () => {
    //1.todo set the selected employer
    //2. route to the employer
    // todo:if pending route to add employers
    //router.push("/admin/employees/employee-list")
    //else route to dashboard
    return "/admin/employer-detail";
  };

  const fetchEmployers = () => {
    dispatch(getEmployerTypeData((res) => {
      setApiData(res);
      setEmployerListData(res);
    }))
  };

  const handleSearchChange = (e) => setSearch(e);

  const handleSwitchEmployer = (employer_id) => {
    dispatch(
      switchEmployerTypeData(
        { employer_id: employer_id },
        (res) => {
          dispatch(showSuccessMessage(langs.messages.switch_employee_success));
          dispatch(getCurrentEmployer());
          dispatch(setShowSecondSection(true))
          sessionStorage.setItem("isSwitchEmpClicked", true)
          sessionStorage.removeItem("claimListApiData")
          router.push("/admin/dashboard")
        },
      ),
    );
  }

  useEffect(() => {
    setApiData(employerType);
    setEmployerListData(employerType);
  }, [employerType])


  const switchEmployer = (record) => {
    handleSwitchEmployer(record?.employer_id)
    dispatch({
      type: EMPLOYER_CONSTANT.SET_EMPLOYER_ID,
      payload: record.employer_id
    });
  }

  const continueOnboarding = (record) => {
    dispatch({
      type: EMPLOYER_CONSTANT.SET_EMPLOYER_ID,
      payload: record.employer_id
    });
    dispatch(saveEmployerRouteInfo({ editDraft: true, employer_id: record?.employer_id }))
    router.push("/admin/employer/add/1")
  }

  const columns = [
    {
      show: false,
      title: <Typography variant={"body-4"} label={"Name"} />,
      key: "employer_name",
      colSpan: 1,
      dataIndex: "employer_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.employer_name.localeCompare(b.employer_name),
    },
    {
      show: false,
      title: <Typography variant={"body-4"} label={"ID"} />,
      key: "employer_id",
      colSpan: 1,
      dataIndex: "employer_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.employer_id.localeCompare(b.employer_id),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const isRecordActive = record?.status === "ACTIVE";
        return (
          <div className="">
            <Button
              variant="ghost"
              className="button-wrapper-child btn-switch-emp"
              onclickCallback={() => isRecordActive ? switchEmployer(record) : continueOnboarding(record)}
              label={isRecordActive ? "Switch employer" : "Continue onboarding"}
              disabled={!record?.employer_id}
              style={{ height: "100px" }}
            />
          </div>
        )
      },
    },
  ];
  // const columns = [
  //   {
  //     title: <Typography variant={"body-4"} label={"Name"} />,
  //     key: "employer_name",
  //     width: "400px",
  //     colSpan: 1,
  //     dataIndex: "employer_name",
  //     sortDirections: ["descend", "ascend"],
  //     sorter: (a, b) => a.employer_name.localeCompare(b.employer_name),
  // render: (employerName) => (
  //   <Link
  //     label={employerName}
  //     disabled={false}
  //     size="large"
  //     variant="primary"
  //     url={onEmployerSelection}
  //   />
  // ),
  //   },
  // {
  //   title: <Typography variant={"body-4"} label={"Status"} />,
  //   key: "tags",
  //   dataIndex: "employer_status",
  //   render: (status) => "NA"
  //   // renderEmployerStatusTag(status?.toLowerCase())
  //   ,
  // },
  //    {
  //     title: "",
  //     key: "action",
  //     width: 100,
  //     //dataIndex: "plans",
  //     render: () => (
  //       <div className="">
  //         <Icon
  //           style={{ marginRight: 10, height: 18 }}
  //           onClick={onContributionClick}
  //           component={ContributionIcon}
  //         />
  //         <Icon onClick={onContributionClick} component={EmployeesIcon} />
  //       </div>
  //     ),
  //   },
  // {
  //   title: <Typography variant={"body-4"} label={"Primary Contact Name"} />,
  //   key: "primary_contact_name",
  //   dataIndex: "primary_contact_name",
  //   render: (primaryName) => (
  //     <Typography variant={"body-3"} label={primaryName} />
  //   ),
  // },
  // {
  //   title: <Typography variant={"body-4"} label={"Phone number"} />,
  //   key: "phone_number",
  //   dataIndex: "phone_number",
  //   render: (contact) => <Typography variant={"body-3"} label={contact} />,
  // },
  // {
  //   title: <Typography variant={"body-4"} label={"Plans"} />,
  //   key: "plans",
  //   dataIndex: "plans",
  //   render: (plans) => (
  //     //  <div>hi</div>
  //     <Typography
  //       variant={"body-3"}
  //       label={plans?.map((a, c) => `${c !== 0 ? `${c}, ` : ""}${a}`)}
  //     />
  //   ),
  // },
  // {
  //   title: "",
  //   key: "action",
  //   width: 100,
  //   //dataIndex: "plans",
  //   render: () => (
  //     <div className="employer-action-header">
  //       <Icon
  //         style={{ marginRight: 10, height: 18 }}
  //         onClick={onContributionClick}
  //         component={ContributionIcon}
  //       />
  //       <Icon onClick={onContributionClick} component={EmployeesIcon} />
  //     </div>
  //   ),
  // },
  // ];

  useEffect(() => {
    localStorage.removeItem("claimListApiData");
    fetchEmployers();
  }, []);

  useEffect(() => {
    if (apiData) {
      let filtered = [...apiData];
      if (search?.length > 1) {
        filtered = [...apiData].filter((el) =>
          el.employer_name.toLowerCase().includes(search.trim().toLowerCase()),
        );
      }
      setEmployerListData(filtered);
    }
  }, [search, apiData]);

  return (
    <HeaderWithContent>
      <Card title="" bordered={false} className="data-grid employer-card">
        <div className="employer-list-wrapper">
          <div className="header-wrapper">
            <div className="search-wrapper">
              <Typography
                variant={`subhead-1`}
                label={"Employers"}
                className={"label"}
              />
              <SearchInput
                placeholder={"Search employers"}
                onChange={(e) => {
                  if (!e.target.value) {
                    setSearch("")
                  }
                }}
                onSearch={handleSearchChange}
              />
            </div>
            <div className="add-wrapper">
              {" "}
              <Button
                variant="primary"
                onclickCallback={addNewEmployer}
                label="Add new employer"
              />
            </div>
          </div>
          <SpinLoader showContent={!!employerListData || !!apiData}>
            <div className="employer-list">
              <Table
                className="employer-list-table"
                columns={columns}
                dataSource={employerListData}
                loading={!employerListData}
                scroll={employerListData?.length ? { y: 500 } : undefined}
                pagination={false}
              />
            </div>
          </SpinLoader>
        </div>
      </Card>
    </HeaderWithContent>
  );
};
