import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useRouter } from "../../../utils/router/customRouterHook";
import moment from "moment";
import { langs } from "../../../i18n";
import { maxLength, required } from "../../../utils/validations";
import { DATE_FORMAT, ROLE_CONSTANT } from "../../../utils/constants";
import { comparisonValues, handleKeyDown } from "../../../utils";
import {
  getEmployerTypeData,
  createContributionRun,
  showSuccessMessage,
  updateContributionRun,
  getBenefitAccountType,
  uploadFileS3,
  getFileUploadUrl,
  getFileUploadStatus,
  showErrorMessage,
} from "../../../redux/actions";

import { Form, Input, Button, Row, Col, Card, DatePicker, Select } from "antd";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { UploadContributionModal } from "../../../components/admin/contribution";
import { DownloadFileTemplateButton } from "../../../components/common/DownloadFileTemplateButton";
import DatePicker2 from "../../../design-systems/DatePicker2";

function CreateContribution() {
  const dispatch = useDispatch();
  const history = useHistory();
  const router = useRouter();
  const [form] = Form.useForm();

  const [selectedData, setSelectedData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [payrollDate, setPayrollDate] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [uploadStatus, setUploadStatus] = useState({});
  const [intervalId, setIntervalId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileUploaderError, setFileUploaderError] = useState({
    error: false,
    msg: "",
  });

  const currentEmployer = useSelector(
    (state) => state.employer.currentEmployer,
  );
  const benefitAccountTypes = useSelector(
    (state) => state.contribution.benefitAccountTypes,
  );

  const userDetails = useSelector((state) => state.userDetails);

  const initialValues = {
    contribution_run_date: null,
    contribution_run_name: "",
  };

  useEffect(() => {
    if (benefitAccountTypes?.length === 1) {
      form.setFieldsValue({ benefits_account_type: JSON.stringify(benefitAccountTypes[0]) });
    }
  }, [benefitAccountTypes]);

  useEffect(() => {
    userDetails?.roleType === ROLE_CONSTANT.ZENDA_ADMIN &&
      dispatch(getEmployerTypeData());
    dispatch(getBenefitAccountType());

    if (
      router.location &&
      router.location.state &&
      router.location.state.runSummary
    ) {
      const accountType = 
      JSON.stringify(benefitAccountTypes.filter(plan => plan.isActive).filter((plan) => plan.planConfigId === router.location.state.runSummary.plan_config_id)[0]);
      const fieldVal = {
        contribution_run_date: moment(
          router.location.state.runSummary.contribution_run_date,
          DATE_FORMAT.FOR_SERVER,
        ),
        contribution_run_name:
          router.location.state.runSummary.contribution_run_name,
        benefits_account_type: accountType,
      };
      setPayrollDate(new Date(router.location.state.runSummary.contribution_run_date))
      form.setFieldsValue({ ...fieldVal });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

  }, [])

  const onFinish = (values) => {
    setIsModalVisible(true);
    setSelectedData(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log(form.getFieldValue(), "Failed:", errorInfo);
  };

  const onValuesChange = (value, allValues) => {
    setFormHasChanged(!comparisonValues(initialValues, allValues));
  };

  const onDateChange = (date, dateString) => {
    console.log("date select", date, dateString);
  };

  const handleCancel = () => {
    setUploadedFile(null);
    setIsModalVisible(false);
    setFileUploaderError({ error: false, msg: "" });
  };

  useEffect(() => {
    if (uploadStatus[uploadedFile?.name] === "success") {
      dispatch(
        showSuccessMessage(
          langs.messages.contribution_run_start_validating,
        ),
      );

      history.push({ pathname: "/admin/contributions/run-history" });
    }
  }, [uploadStatus])

  const submitUploadedFile = () => {
    if (!uploadedFile) return;

    const id = router.match.params.id;
    const plan = JSON.parse(selectedData.benefits_account_type);

    const payload = {
      service: "admin-portal",
      module: "contribution",
      type: id ? "contribution_re_run" : "contribution",
      payload: {
        contribution_run_date: moment(selectedData.contribution_run_date).format(
          DATE_FORMAT.FOR_SERVER,
        ),
        contribution_run_name: selectedData.contribution_run_name,
        benefits_account_type: plan.type,
        plan_config_id: plan.planConfigId,
        sent_at: String(moment().unix()),
      },
      file_names: [
        uploadedFile?.name
      ]
    }
    if (id) {
      payload.payload.contribution_run_id = id;
    }
    setLoading(true)
    dispatch(getFileUploadUrl(payload, (res) => {
      if (!(res instanceof Error)) {
        if (res?.data?.data?.presigned_url?.length) {
          res?.data?.data?.presigned_url?.forEach(data => {
            const presignedUrl = data.url;
            if (presignedUrl) {
              dispatch(
                uploadFileS3(presignedUrl, res?.xRayTraceId, uploadedFile, (res) => {
                  if (res instanceof Error) {
                    dispatch(showErrorMessage(langs.messages.file_upload_error));
                  } else {
                    pollUploadStatus(data?.file_id, data?.file_name);
                  }
                }),
              );
            }
          });
        }
      }
    }));
  };

  const pollUploadStatus = (fileId, fileName) => {
    const intervalId = setInterval(() => {
      dispatch(getFileUploadStatus(fileId, (res) => {
        if (!(res instanceof Error)) {
          const { status = "", progress_percentage, message } = res?.data?.data?.data;
          setUploadProgress((prev) => ({
            ...prev,
            [fileName]: { status, progress_percentage },
          }));
          if (message?.length) {
            if (status !== "FILE_ACCEPTED") {
              dispatch(showErrorMessage(message));
            }
          }
          if (status === "ERRORED_OUT") {
            clearInterval(intervalId);
            setLoading(false);
            setFileList([])
          }
          if (status === "FILE_REJECTED") {
            clearInterval(intervalId);
            setLoading(false);
            setFileList([]);
            dispatch(showErrorMessage("File is rejected. Please use another file."));
          }
          if (progress_percentage === 100 || status === "FILE_ACCEPTED") {
            clearInterval(intervalId);
            setLoading(false)
            setUploadStatus((prev) => ({
              ...prev,
              [fileName]: (status === 'SUCCESS' || status === 'FILE_ACCEPTED') ? 'success' : 'failed',
            }));
            setIntervalId((prev) => {
              const { [fileName]: _, ...rest } = prev;
              return rest;
            });
          } else if (progress_percentage < 100) {
            setUploadStatus((prev) => ({
              ...prev,
              [fileName]: 'in-progress',
            }));
          }
        } else {
          clearInterval(intervalId);
          dispatch(showErrorMessage(langs.messages.file_upload_error));
          setUploadStatus((prev) => ({
            ...prev,
            [fileName]: 'failed',
          }));
          setIntervalId((prev) => {
            const { [fileName]: _, ...rest } = prev;
            return rest;
          });
        }
      }));
    }, 2000);

    setIntervalId((prev) => ({ ...prev, [fileName]: intervalId }));
  };

  useEffect(() => {
    setUploadedFile(null);
    setFileList([])
    setUploadProgress([])
    setUploadStatus({})
  }, [isModalVisible])

  return (
    <HeaderWithContent showWarning={formHasChanged}>
      <Row gutter={0}>
        <Col span={24}>
          <Card
            title="Contribution Information"
            bordered={false}
            extra={
              <div className="contribution-run-key">
                <label> Contribution Run Key:</label>
                <span>
                  {!currentEmployer.error &&
                    currentEmployer.data.contribution_run_key_column}
                </span>
              </div>
            }
            className="add-employee"
          >
            <Form
              form={form}
              name="createcontribution"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              initialValues={initialValues}
              layout="vertical"
              autoComplete="off"
              className="common-form-container"
            >
              <div className="contribution-information-block">
                <Row gutter={32} className={"formWrap"}>
                  <Col span={12} xs={24} md={12}>
                    <div className="date-picker">
                      <Form.Item
                        label="Payroll Date"
                        rules={[required("Contribution date")]}
                        name="contribution_run_date"
                        className="contribution-date"
                        extra={
                          <div className="subtitle mb-15">
                            Enter the date money should be transferred to your
                            employee account.
                          </div>
                        }
                      >
                        <DatePicker2
                          showImportant={false}
                          className="contribution-date"
                          isClearable={true}
                          id="contribution_run_date"
                          selected={payrollDate}
                          name="contribution_run_date"
                          placeholder={moment().format(
                            DATE_FORMAT.FOR_UI_FIRST_MM,
                          )}
                          format={['MM/dd/yyyy', 'M/d/yyyy']}
                          readOnly={false}
                          onKeyDown={handleKeyDown}
                          onChange={(date) => {
                            setPayrollDate(date)
                            form.setFieldValue({ contribution_run_date: new Date(date) })
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12} xs={24} md={12} id="contribution_name" >
                    <Form.Item
                      label="Contribution Name"
                      rules={[required("Contribution name"), maxLength(150)]}
                      name="contribution_run_name"
                    >
                      <Input
                        placeholder="Name"
                        prefix={<i className="ni ni-caps-small" />}
                        maxLength={150}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} xs={24} md={12}>
                    <Form.Item
                      label="Benefit Account Type"
                      rules={[required("Benefit account type")]}
                      name="benefits_account_type"
                    >
                      <Select
                        disabled={benefitAccountTypes.length <= 1}
                        placeholder="Benefit account type"
                        className="benefit-account-type-dropdown"
                      >
                        {benefitAccountTypes.filter(plan => plan.isActive).map((plan) => (
                          <Select.Option value={JSON.stringify(plan)}>{plan.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="btn-block">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button-primary"
                  >
                    Upload File
                  </Button>
                  <DownloadFileTemplateButton contribution />
                </div>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
      <UploadContributionModal
        handleCancel={handleCancel}
        submitUploadedFile={submitUploadedFile}
        isModalVisible={isModalVisible}
        uploadedFile={uploadedFile}
        setFileList={setFileList}
        fileList={fileList}
        loading={loading}
        uploadProgress={uploadProgress}
        setUploadedFile={setUploadedFile}
        fileUploaderError={fileUploaderError}
        setFileUploaderError={setFileUploaderError}
      />
    </HeaderWithContent>
  );
}

export { CreateContribution };
