import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { langs } from "../../../i18n";
import { required } from "../../../utils/validations";
import { useRouter } from "../../../utils/router/customRouterHook";
import { STATUS_CODES, CONTRIBUTION_STATUSES } from "../../../utils/constants";
import { getReportURL, downloadReport } from "../../../utils";
import {
  cancelContribution,
  retryExecuteContributionRun,
  retryInitiateACHPull,
  startExecuteContributionRun,
  startInitiateACHPull,
  showSuccessMessage,
  executingContributionStatus,
  enableLoader,
  disableLoader,
} from "../../../redux/actions";

import { Button, Form, Input, Modal } from "antd";
import { BENEFIT_ACCOUNT_TYPES } from "../../../utils/constants/benefitAccountTypes";

const displayReports =
  process.env.REACT_APP_PUBLIC_DISPLAY_REPORTS === "true" ? true : false;

function ContributionActions({ runSummary, updateReport, reportURL }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = Form.useForm();

  const [showRetryModal, setShowRetryModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [executionIsOngoing, setExecutionIsOngoing] = useState(false);
  const [isChangedCommentsForm, setIsChangedCommentsForm] = useState(false);
  const [executionCompletionPercent, setExecutionCompletionPercent] =
    useState(null);

  const contributionStatus = runSummary?.contribution_status;
  const benefitAccountType = runSummary?.benefits_account_type;
  const contributionRunID = router.match.params.id;

  let checkExecutionStatusIntervalId;

  useEffect(() => {
    dispatch(
      executingContributionStatus(contributionRunID, (res) => {
        setExecutionCompletionPercent(res.completion_percent);
      }),
    );

    contributionStatus === CONTRIBUTION_STATUSES.EXEC_IN_PROGRESS &&
      checkContributionExecutionStatus();

    return () => {
      clearInterval(checkExecutionStatusIntervalId);
    };

    //eslint-disable-next-line
  }, []);

  const checkContributionExecutionStatus = () => {
    setExecutionIsOngoing(true);

    function f() {
      dispatch(
        executingContributionStatus(contributionRunID, (res) => {
          setExecutionCompletionPercent(res.completion_percent);

          if (res.pending_employees_count === 0) {
            dispatch(
              showSuccessMessage(
                langs.messages.contribution_run_execute_success,
              ),
            );

            clearInterval(checkExecutionStatusIntervalId);

            setExecutionCompletionPercent(null);

            updateReport();
          }
        }),
      );
    }

    if (!checkExecutionStatusIntervalId) {
      checkExecutionStatusIntervalId = setInterval(f, 30000);
    }
  };

  const disableButtonAfterClick = () => {
    setButtonIsDisabled(true);
    setTimeout(function () {
      setButtonIsDisabled(false);
    }, 2000);
  };

  const onInitiateACH = () => {
    disableButtonAfterClick();

    dispatch(
      startInitiateACHPull(contributionRunID, (res) => {
        if (res instanceof Error) {
          updateReport();
        } else {
          dispatch(
            showSuccessMessage(
              langs.messages.contribution_run_ach_pull_success,
            ),
          );
          updateReport();
        }
      }),
    );
  };

  const onACHPullRetry = () => {
    disableButtonAfterClick();

    dispatch(
      retryInitiateACHPull(contributionRunID, (res) => {
        if (res instanceof Error) {
          updateReport();
        } else {
          dispatch(
            showSuccessMessage(
              langs.messages.contribution_run_ach_pull_success,
            ),
          );
          updateReport();
        }
      }),
    );
  };

  const onExecute = () => {
    dispatch(
      startExecuteContributionRun(contributionRunID, (res) => {
        if (res instanceof Error) {
          updateReport();
        } else {
          if (
            res.status === STATUS_CODES.GATEWAY_TIMEOUT ||
            res.status === STATUS_CODES.REQUEST_TIMEOUT
          ) {
            dispatch(
              showSuccessMessage(
                langs.messages.contribution_run_execute_time_out,
              ),
            );
          } else {
            dispatch(
              showSuccessMessage(
                langs.messages.contribution_run_is_being_executed,
              ),
            );
          }

          clearInterval(checkExecutionStatusIntervalId);

          updateReport();
          checkContributionExecutionStatus();
        }
      }),
    );
  };

  const onExecuteRetry = () => {
    const comments = form.getFieldValue(["comments"]).trim();

    setShowRetryModal(false);

    dispatch(
      retryExecuteContributionRun(contributionRunID, comments, (res) => {
        if (res instanceof Error) {
          updateReport();
        } else {
          dispatch(showSuccessMessage(res.data.message));
          setIsChangedCommentsForm(false);
          form.resetFields();
          updateReport();
        }
      }),
    );
  };

  const onDownloadReport = async () => {
    disableButtonAfterClick();

    dispatch(enableLoader());
    const currentReportURL = await getReportURL(contributionRunID);
    downloadReport(dispatch, currentReportURL);
    dispatch(disableLoader());
  };

  const onCancel = () => {
    disableButtonAfterClick();

    dispatch(
      cancelContribution(contributionRunID, (res) => {
        dispatch(showSuccessMessage(langs.messages.contribution_canceled));
        updateReport();
      }),
    );

    setShowCancelModal(false);
  };

  const onCancelRetryModal = () => {
    setIsChangedCommentsForm(false);
    setShowRetryModal(false);
    form.resetFields();
  };

  const onChangeCommentsForm = (value, allValues) => {
    setIsChangedCommentsForm(value.comments === "" ? false : true);
  };

  return (
    <div className="confirm-contribution-btn-block">
      {BENEFIT_ACCOUNT_TYPES.HSA === benefitAccountType &&
        contributionStatus === CONTRIBUTION_STATUSES.ENABLED && (
          <Button
            type="default"
            className="button-primary"
            onClick={() => onInitiateACH()}
            disabled={buttonIsDisabled}
          >
            Initiate Transfer
          </Button>
        )}
      {contributionStatus === CONTRIBUTION_STATUSES.ENABLED && (
        <Button
          type="default"
          className="button-primary"
          onClick={() => setShowCancelModal(true)}
          disabled={buttonIsDisabled}
          style={{ order: BENEFIT_ACCOUNT_TYPES.DCFSA === benefitAccountType ? 2: 0 }}
        >
          Cancel
        </Button>
      )}
      {((BENEFIT_ACCOUNT_TYPES.HSA === benefitAccountType &&
        contributionStatus === CONTRIBUTION_STATUSES.AMT_CREDITED) ||
        (BENEFIT_ACCOUNT_TYPES.DCFSA === benefitAccountType &&
          contributionStatus === CONTRIBUTION_STATUSES.ENABLED) ||
        contributionStatus === CONTRIBUTION_STATUSES.EXEC_IN_PROGRESS) && (
        <Button
          type="default"
          className="button-primary"
          onClick={() => onExecute()}
          disabled={buttonIsDisabled}
          loading={executionIsOngoing}
        >
          {executionIsOngoing
            ? `Executing… ${
                executionCompletionPercent
                  ? `${executionCompletionPercent}%`
                  : ""
              }`
              : BENEFIT_ACCOUNT_TYPES.DCFSA === benefitAccountType ? "Post contributions now" : "Execute"}
          </Button>
        )}
      {contributionStatus === CONTRIBUTION_STATUSES.ACH_FAILED && (
        <Button
          type="default"
          className="button-primary"
          onClick={() => onACHPullRetry()}
          disabled={buttonIsDisabled}
        >
          Reinitiate Transfer
        </Button>
      )}
      {contributionStatus === CONTRIBUTION_STATUSES.ERRORED && (
        <Button
          type="default"
          className="button-primary"
          onClick={() => setShowRetryModal(true)}
          disabled={buttonIsDisabled}
        >
          Rerun
        </Button>
      )}
      {displayReports &&
        BENEFIT_ACCOUNT_TYPES.DCFSA !== benefitAccountType &&
        (contributionStatus === CONTRIBUTION_STATUSES.SUCCESSFUL ||
          contributionStatus === CONTRIBUTION_STATUSES.ERRORED) &&
        reportURL && (
          <Button
            type="default"
            className="button-primary"
            style={{ marginLeft: "auto" }}
            onClick={onDownloadReport}
            disabled={buttonIsDisabled}
          >
            Download summary report
          </Button>
        )}

      <Modal
        visible={showRetryModal}
        closable={false}
        okText="Confirm"
        cancelText="Cancel"
        onOk={onExecuteRetry}
        onCancel={onCancelRetryModal}
        okButtonProps={{ disabled: !isChangedCommentsForm }}
        className="common-modal-style contribution-retry-modal"
      >
        <div className="text-muted sub-title mb-15">Add a comment</div>
        <Form
          form={form}
          onValuesChange={onChangeCommentsForm}
          layout="vertical"
          autoComplete="off"
          className="common-form-container"
        >
          <Form.Item name="comments" rules={[required("Comments")]}>
            <Input placeholder="Comments" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={showCancelModal}
        closable={false}
        okText="Yes"
        cancelText="No"
        onCancel={() => setShowCancelModal(false)}
        onOk={onCancel}
        className="common-modal-style contribution-cancel-modal"
      >
        <div className="mb-15">Do you wish to cancel the contribution?</div>
      </Modal>
    </div>
  );
}

export { ContributionActions };
