import moment from "moment";
import React, { useCallback } from "react";
import { expenseType, fixAmountFormat } from "../../../../utils";

// import { EmployeeExpenseDetailsForm } from "./EmployeeExpenseDetailsForm";
// import { EditIcon } from "../../../../design-systems";

const ExpenseDetails = ({ claimsData }) => {
  const {
    total_expense_amount,
    total_travel_exp_amount,
    merchant_name, expense_for,
    expense_amount,
    transaction_date,
    date_submitted,
    expense_type,
    mileage,
    mileage_rate,
    mileage_travel_amount,
    other_travel_amount,
    expense_mode,
    expense_note,
    accounts,
    dcfsa_end_date,
    dcfsa_start_date
  } = claimsData || {}

  // Out of scope right now. Keeping for future use
  // const [opeExpenseModal, setOpenExpenseModal] = useState(false)
  const getTransactionDate = useCallback(() => {
    if (dcfsa_end_date && dcfsa_start_date) {
      return `${moment(dcfsa_start_date).format("MM/DD/YYYY")} - ${moment(dcfsa_end_date).format("MM/DD/YYYY")}`
    } else {
      return moment(transaction_date).format("MM/DD/YYYY") ?? "-"
    }
  }, [
    dcfsa_end_date,
    dcfsa_start_date,
    transaction_date])

  const isExpenseTypeTravel = expenseType[expense_type] === expenseType.TRAVEL;

  return (
    <>
      <div className="emp-details-wrapper">
        {/* Out of scope right now. Keeping for future use */}
        {/* <EmployeeExpenseDetailsForm visible={opeExpenseModal} 
            onCancel={() => setOpenExpenseModal(false)}
             /> */}
        <div className="claim-status-typo section-gap">
          Expense details
          {/* Out of scope right now. Keeping for future use */}
          {/* <span className="employee-edit-icon" onClick={() => setOpenExpenseModal(true)}><EditIcon color="#296DB2" /> Edit</span> */}
        </div>
        <div className="expense-details-wrapper">
          <div className="emp-details">
            <div className="title">{`${!isExpenseTypeTravel ? "Total" : ""} Expense amount`}</div>
            <div className="value align-right total_expense mr-3">{fixAmountFormat(total_expense_amount?.toFixed(2))}</div>
          </div>
          <div className="emp-details">
            <div className="title">Date submitted</div>
            <div className="value align-right mr-3">{moment(date_submitted).format("MM/DD/YYYY")}</div>
          </div>
          <div className="emp-details">
            <div className="title">Expense type</div>
            <div className="value align-right mr-3">{expenseType[expense_type]}</div>
          </div>
          <div className="emp-details">
            <div className="title">{isExpenseTypeTravel ? "Date of service" : "Transaction date"}</div>
            <div className="value align-right mr-3">{getTransactionDate()}</div>
          </div>
          <div className="emp-details">
            <div className="title">Merchant</div>
            <div className="value align-right mr-3">{merchant_name ?? "N/A"}</div>
          </div>
          {
            !isExpenseTypeTravel &&
            <>
              <div className="emp-details">
                <div className="title">Expense amount</div>
                <div className="value align-right mr-3 ">{fixAmountFormat(expense_amount?.toFixed(2))}</div>
              </div>
              <div className="emp-details">
                <div className="title">Who the expense is for</div>
                <div className="value align-right mr-3">{expense_for}</div>
              </div>
            </>
          }
          <div className="emp-details">
            <div className="title">Mileage {`(${mileage ?? ""} mi * $${mileage_rate?.toFixed(2)})`}</div>
            <div className="value align-right mr-3">
              ${mileage_travel_amount?.toFixed(2) ?? "0.00"}
            </div>
          </div>

          <div className="emp-details">
            <div className="title">Other travel expenses</div>
            <div className="value align-right mr-3">${other_travel_amount?.toFixed(2) ?? "0.00"}</div>
          </div>
          {
            !isExpenseTypeTravel &&
            <div className="emp-details">
              <div className="title">Total travel expenses</div>
              <div className="value align-right mr-3">${total_travel_exp_amount?.toFixed(2) ?? "0.00"}</div>
            </div>
          }
        </div>
      </div>
      <div className="emp-details-wrapper">
        <div className="claim-status-typo section-gap">Additional details </div>
        <div className="expense-details-wrapper">
          <div className="emp-details">
            <div className="title">Claim Type</div>
            <div className="value align-right mr-3">{expense_mode}</div>
          </div>
          <div className="emp-details">
            <div className="title">Expense Note</div>
            <div className="value align-right mr-3">{expense_note ?? "-"}</div>
          </div>
          <div className="emp-details">
            <div className="title">Account(s)</div>
            <div className="value align-right mr-3">{accounts?.join(", ")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseDetails;
