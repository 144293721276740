import { Table as AntTable } from "antd";
import { Typography } from "../Typography";
import { ArrowUp } from "../Icons";

export const Table = ({
  style,
  columns,
  dataSource,
  scrollX = 1200,
  pagination,
  className,
  rowClassName,
  onChange,
  onRow,
  expandable,
  rowKey,
  title,
  expandIconColumnIndex,
  headerClassName,
  loading,
  locale
}) => (
  <AntTable
    locale={locale}
    title={title}
    style={style}
    loading={loading}
    columns={columns}
    dataSource={dataSource}
    scroll={dataSource?.length ? { x: scrollX } : undefined}
    className={`table-wrapper ${className}`}
    rowClassName={`row-extended ${rowClassName}`}
    headerClassName={headerClassName}
    pagination={pagination}
    onChange={onChange}
    onRow={onRow}
    expandable={expandable}
    rowKey={rowKey}
    expandIconColumnIndex={expandIconColumnIndex}
  />
);

export const renderTitle = ({ sortColumns }, label, key, className = "") => {
  const sortedColumn = sortColumns?.find(({ column }) => column.key === key);

  return (
    <div
      className={`title-wrapper ${className}`}
    // style={{ display: "flex", alignItems: "center", gap: "5px" }}
    >
      <Typography className={"text-transform-initial"} variant="body-4" label={label} />
      <ArrowUp className={`arrow-up-sorter ${sortedColumn?.order}`} />
    </div>
  );
};
