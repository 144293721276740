import moment from "moment";
import Papa from "papaparse";
import { cloneDeep } from "lodash";
import { DATE_FORMAT } from "../../utils/constants";

export const ADDRESS1_VALIDATION1 = ["apo", "po", "apob", "pob", "gpobox"];

export const ADDRESS1_VALIDATION2 = ["post office", "call box"];

export function validateEmployeeID(employeeID) {
  const employeeIdRegex = /^[ A-Za-z0-9_\-@/\\|]*$/;
  if (employeeID === undefined || employeeID.length <= 0)
    return "Employee ID is required.";
  if (employeeID.length > 30) return "Employee ID is too long.";
  if (!employeeIdRegex.test(employeeID))
    return "Forbidden special character found";
  return "valid";
}

export function validateEmployeeDateOfJoining(employeeDateOfJoining) {
  if (
    moment(employeeDateOfJoining, DATE_FORMAT.FOR_UI_FIRST_MM, true).isValid()
  )
    return "valid";
  if (employeeDateOfJoining === undefined || employeeDateOfJoining.length === 0)
    return "Employee date of joining is required.";
  return "Employee date of joining is invalid.";
}

export function validateEmploymentStatusLite(employmentStatus) {
  return "valid";
}
export function validateEmploymentStatus(employmentStatus, employmentStatuses) {
  if (!employmentStatus) return "Employment Status is required";
  if (employmentStatus in employmentStatuses) return "valid";
  return "Invalid Employment Status.";
}

export function validateUserStatusLite(userStatus) {
  return "valid";
}
export function validateUserStatus(userStatus, userStatuses) {
  if (userStatus in userStatuses) return "valid";
  return "Invalid User Status.";
}

export function validateCoverageLite(coverage) {
  return "valid";
}
export function validateCoverage(coverage, coverages) {
  if (!coverage) return "Coverage Type is required";
  if (coverage in coverages) return "valid";
  return "Invalid Coverage Type.";
}

export function validateCountryLite(country) {
  return "valid";
}
export function validateCountry(country, countries) {
  if (!country) return "Country is required";
  if (country in countries) return "valid";
  return "Invalid Country.";
}

export function validateDivisionLite(division) {
  return "valid";
}
export function validateDivision(divName, divList) {
  const isDefault =
    Array.isArray(divList) &&
    divList.length === 1 &&
    divList[0].division_name === "default";

  if (isDefault) {
    return "valid";
  } else if (divName === "") {
    return "Division is required.";
  } else if (!divList.filter((el) => el.division_name === divName).length) {
    return "Division is invalid.";
  } else {
    return "valid";
  }
}

export function validateFirstName(firstName) {
  const firstNameRex = /^[ A-Za-z'-]*$/;
  if (firstName === undefined || firstName.length === 0)
    return "First name is required.";
  else if (firstName.length < 2)
    return "First name should be at least 2 char long.";
  else if (firstName.length > 60) return "First name is too long.";
  else if (!firstNameRex.test(firstName))
    return "First Name is not allowed special characters and numbers.";
  else return "valid";
}

export function validateFirstNameRules(rule, name, callback) {
  const firstNameRex = /^[ A-Za-z'-]*$/;
  if (!name) {
    callback("First name is required.");
  } else if (!firstNameRex.test(name)) {
    callback("First Name is not allowed special characters.");
  } else if (name.length > 60) {
    callback("First name is too long.");
  } else if (name.length < 2) {
    callback("First name should be at least 2 char long.");
  } else {
    callback();
  }
}

export function validateLastName(lastName) {
  const lastNameRex = /^[ A-Za-z']*$/;
  if (lastName === undefined || lastName.length === 0)
    return "Last name is required.";
  else if (lastName.length < 2)
    return "Last name should be at least 2 char long.";
  else if (lastName.length > 60) return "Last name is too long.";
  else if (!lastNameRex.test(lastName))
    return "Last Name is not allowed special characters and numbers.";
  else return "valid";
}

export function validateLastNameRules(rule, name, callback) {
  const firstNameRex = /^[ A-Za-z'-]*$/;
  if (!name) {
    callback("Last name is required.");
  } else if (!firstNameRex.test(name)) {
    callback("Last name is not allowed special characters.");
  } else if (name.length > 60) {
    callback("Last name is too long.");
  } else if (name.length < 2) {
    callback("Last name should be at least 2 char long.");
  } else {
    callback();
  }
}

export function validateMiddleName(middleName) {
  const middleNameRex = /^[ A-Za-z']*$/;
  if (!middleName) return "valid";
  else if (middleName.length > 60) return "Middle name is too long.";
  else if (!middleNameRex.test(middleName))
    return "Middle Name is not allowed special characters and numbers.";
  else return "valid";
}

export function validateMiddleNameRules(rule, name, callback) {
  const middleNameRex = /^[ A-Za-z'-]*$/;
  if (!name) {
    callback();
  } else if (!middleNameRex.test(name)) {
    callback("Middle name is not allowed special characters.");
  } else if (name.length > 60) {
    callback("Middle name is too long.");
  } else {
    callback();
  }
}

export function validateDOB(DOB) {
  if (moment(DOB, DATE_FORMAT.FOR_UI_FIRST_MM, true).isValid()) {
    return "valid";
  }
  if (DOB === undefined || DOB.length === 0) return "DOB is required";
  return "DOB is invalid.";
}

export function validateSSN(SSN) {
  if (SSN === undefined || SSN.length === 0) return "SSN is required.";
  const SSNRex = /^[0-9]{9}$/;
  if (SSNRex.test(SSN)) {
    return "valid";
  }
  return "SSN is invalid.";
}

export function validateMobileNum(mobileNum) {
  if (!mobileNum) return "valid";
  const mobileNumRex = /^[0-9]{10}$/;
  if (mobileNumRex.test(mobileNum)) {
    return "valid";
  }
  return "Mobile Number is invalid.";
}

export function amountGreaterThanZero(_, value) {
  if (value && parseFloat(value) === 0) {
    return Promise.reject(`Value should be greater than 0.`)
  }
  else {
    return Promise.resolve()
  }
}

export function allowValidNumbers(_, value) {
  if (value && isNaN(value)) {
    return Promise.reject(`Please input a valid amount.`)
  }
  else {
    return Promise.resolve()
  }
}

export function validateMobileNumWithCountryCode(mobileNum) {
  if (!mobileNum) return "valid";
  if (mobileNum[0] !== "+")
    return "+ is missing in Country code of Mobile Number.";
  if (mobileNum.length < 12 || mobileNum.length > 13)
    return "Mobile Number is invalid.";
  const mobileNumRex = /^[0-9]+$/;
  if (mobileNumRex.test(mobileNum.substring(1, mobileNum.length))) {
    return "valid";
  }
  return "Mobile Number is invalid.";
}

export function validateAddr1(addr1) {
  const addr1Array = addr1.split(" ");
  const address1Validation1Text = ADDRESS1_VALIDATION1.includes(
    addr1Array[0].toLowerCase(),
  );
  const address1Validation2Text = ADDRESS1_VALIDATION2.includes(
    (addr1Array[0] + " " + addr1Array[1]).toLowerCase(),
  );

  if (addr1 === undefined || addr1.length === 0)
    return "Home Street Address 1 is required.";
  else if (addr1.length > 100) return "Home Street Address 1 is too long.";
  else if (address1Validation1Text)
    return `Address 1 can not start with "APO", "PO", "POB" and "gpobox"`;
  else if (address1Validation2Text)
    return `Address 1 can not start with "post office" and "call box"`;
  return "valid";
}

export function validateAddr2(addr2) {
  if (addr2 && addr2.length > 100) return "Home Street Address 2 is too long.";
  return "valid";
}

export function validateCity(city) {
  if (city === undefined || city.length === 0) return "City is required.";
  else if (city.length > 60) return "City is too long.";
  return "valid";
}

export function validateSt(st) {
  if (st === undefined || st.length === 0) return "State is required.";
  else if (st.length !== 2) return "State should be 2 char long.";
  return "valid";
}

export function validatePostal(postal) {
  if (postal === undefined || postal.length === 0)
    return "Zip code is required.";
  else if (postal.length !== 5) return "Zip code should be 5 char long.";
  return "valid";
}

export function validateWorkEmail(workEmail) {
  if (workEmail === undefined || workEmail.length === 0)
    return "Work email is required.";
  const emailRex =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (emailRex.test(workEmail.toLowerCase())) return "valid";
  return "Work email is invalid.";
}

export function validateCoverageEffectiveDate(coverageEffectiveDate) {
  if (
    moment(coverageEffectiveDate, DATE_FORMAT.FOR_UI_FIRST_MM, true).isValid()
  )
    return "valid";
  if (coverageEffectiveDate === undefined || coverageEffectiveDate.length <= 0)
    return "Coverage Effective date is required.";
  return "Coverage Effective date is invalid.";
}

export function validateHSAPlanEligibilityDate(HSAPlanEligibilityDate) {
  if (
    moment(HSAPlanEligibilityDate, DATE_FORMAT.FOR_UI_FIRST_MM, true).isValid()
  )
    return "valid";
  if (
    HSAPlanEligibilityDate === undefined ||
    HSAPlanEligibilityDate.length <= 0
  )
    return "HSA plan eligibility date is required.";
  return "HSA plan eligibility date is invalid.";
}

export function validateHSAPlanStartDate(HSAPlanStartDate) {
  if (moment(HSAPlanStartDate, DATE_FORMAT.FOR_UI_FIRST_MM, true).isValid())
    return "valid";
  if (HSAPlanStartDate === undefined || HSAPlanStartDate.length <= 0)
    return "HSA plan start date is required.";
  return "HSA plan start date is invalid.";
}

export function validateAccountStatus(accountStatus) {
  return "valid";
}

export function validateAll(employee, params) {
  const { employmentStatuses, countries, coverages, divisionList } = params;
  const {
    DOB,
    SSN,
    addr1,
    addr2,
    city,
    country,
    coverage,
    employeeDateOfJoining,
    employeeID,
    employmentStatus,
    firstName,
    lastName,
    postal,
    st,
    workEmail,
    mobileNum,
    CoverageEffectiveDate,
    division,
  } = employee;

  const errors = {
    employeeIDState: validateEmployeeID(employeeID),
    employeeDateOfJoiningState: validateEmployeeDateOfJoining(
      employeeDateOfJoining,
    ),
    firstNameState: validateFirstName(firstName),
    lastNameState: validateLastName(lastName),
    DOBState: validateDOB(DOB),
    SSNState: validateSSN(SSN),
    mobileNumState: validateMobileNum(mobileNum),
    addr1State: validateAddr1(addr1),
    addr2State: validateAddr2(addr2),
    cityState: validateCity(city),
    stState: validateSt(st),
    postalState: validatePostal(postal),
    workEmailState: validateWorkEmail(workEmail),
    CoverageEffectiveDate: validateCoverageEffectiveDate(CoverageEffectiveDate),
    countryState: validateCountry(country, countries),
    coverageState: validateCoverage(coverage, coverages),
    employmentStatusState: validateEmploymentStatus(
      employmentStatus,
      employmentStatuses,
    ),
    division: validateDivision(division, divisionList),
  };

  return errors;
}

export function validateHeader(headers) {
  const { validHeaders2 } = getHeaderMappings(headers);

  const currentHeaders = {
    firstName: [],
    middleName: [],
    lastName: [],
    workEmail: [],
    employmentStatus: [],
    SSN: [],
    mobileNum: [],
    employeeID: [],
    addr1: [],
    addr2: [],
    city: [],
    st: [],
    postal: [],
    country: [],
    DOB: [],
    employeeDateOfJoining: [],
    CoverageEffectiveDate: [],
    coverage: [],
    division: [],
  };

  for (let H in headers) {
    const h = headers[H].toLowerCase();
    if (h in validHeaders2) {
      currentHeaders[validHeaders2[h]].push(headers[H]);
    } else {
      return headers[H] + " is not a valid column.";
    }
  }
  for (let k in currentHeaders) {
    if (currentHeaders[k].length === 0)
      return "Column for " + k + " is missing.";
    if (currentHeaders[k].length > 1)
      return "Duplicate columns found for " + k + ".";
  }
  return "valid";
}

export function getHeaderMappings(header) {
  const validHeaders = {
    firstName: ["First Name"],
    middleName: ["Middle Name"],
    lastName: ["Last Name"],
    workEmail: ["Work Email", "Email"],
    employmentStatus: ["Employment Status"],
    SSN: ["SSN", "Social Security Number", "Social Security"],
    mobileNum: [
      "Mobile #",
      "Mobile Number",
      "Phone Number",
      "Mobile Phone Number",
    ],
    employeeID: ["Employee ID", "Employee #"],
    addr1: [
      "Street Address 1",
      "Address 1",
      "Home Address 1",
      "Home Street Address 1",
    ],
    addr2: [
      "Street Address 2",
      "Address 2",
      "Home Address 2",
      "Home Street Address 2",
    ],
    city: ["Home City", "City"],
    st: ["Home State", "State"],
    postal: ["Postal", "Zip", "Postal Code", "Zip Code"],
    country: ["Country"],
    DOB: ["Date of Birth", "DOB", "Birth Date"],
    employeeDateOfJoining: [
      "Employee Date Of Joining",
      "Employment Start Date",
      "Joining Date",
    ],
    CoverageEffectiveDate: ["Coverage Effective Date"],
    coverage: ["Coverage Type", "Coverage"],
    division: ["Division"],
  };

  const validHeaders2 = {};

  for (let k in validHeaders) {
    for (let i in validHeaders[k]) {
      validHeaders2[validHeaders[k][i].toLowerCase()] = k;
    }
  }

  return { validHeaders, validHeaders2 };
}

export function duplicateCheck(inputEmployees) {
  const employees = cloneDeep(inputEmployees);

  const SSNMap = {};
  const employeeIDMap = {};
  const workEmailMap = {};

  for (let i = 0; i < employees.length; i++) {
    const emp = employees[i];
    if (emp.SSN in SSNMap && emp.errors.SSNState === "valid")
      SSNMap[emp.SSN].push(i);
    else if (emp.errors.SSNState === "valid") SSNMap[emp.SSN] = [i];

    if (
      emp.employeeID in employeeIDMap &&
      emp.errors.employeeIDState === "valid"
    )
      employeeIDMap[emp.employeeID].push(i);
    else if (emp.errors.employeeIDState === "valid")
      employeeIDMap[emp.employeeID] = [i];

    if (emp.workEmail in workEmailMap && emp.errors.workEmailState === "valid")
      workEmailMap[emp.workEmail].push(i);
    else if (emp.errors.workEmailState === "valid")
      workEmailMap[emp.workEmail] = [i];
  }

  for (let SSN in SSNMap) {
    for (let i = 0; i < SSNMap[SSN].length; i++) {
      if (SSNMap[SSN].length > 1)
        employees[SSNMap[SSN][i]].errors.SSNState = "Duplicate SSN.";
    }
  }

  for (let employeeID in employeeIDMap) {
    for (let i = 0; i < employeeIDMap[employeeID].length; i++) {
      if (employeeIDMap[employeeID].length > 1)
        employees[employeeIDMap[employeeID][i]].errors.employeeIDState =
          "Duplicate Employee ID.";
    }
  }

  for (let workEmail in workEmailMap) {
    for (let i = 0; i < workEmailMap[workEmail].length; i++) {
      if (workEmailMap[workEmail].length > 1)
        employees[workEmailMap[workEmail][i]].errors.workEmailState =
          "Duplicate Work Email.";
    }
  }

  return employees;
}

export function backendCheckEmployees(inputEmployees) {
  const employees = cloneDeep(inputEmployees);
  const payload = [];

  for (let i = 0; i < employees.length; i++) {
    const emp = employees[i];
    const { workEmail, employeeID, ssn, errors } = emp;
    const { workEmailState, employeeIDState, SSNState } = errors;
    if (
      workEmailState !== "valid" &&
      employeeIDState !== "valid" &&
      SSNState !== "valid"
    )
      continue;
    emp.workEmail =
      workEmailState === "valid" ? workEmail : "someone@something.com";
    emp.employeeID =
      employeeIDState === "valid" ? employeeID : "AAAAAAAAAAAAAAAA";
    emp.ssn = SSNState === "valid" ? ssn : 123456789;
    payload.push(emp);
  }
  return payload;
}

export function errorInfo(employees) {
  let numberOfErrorRecords = 0;
  let numberOfDuplicateRecords = 0;
  let numberOfNewRecords = 0;
  let allOK = true;

  for (let i = 0; i < employees.length; i++) {
    const { errors } = employees[i];
    let isError = false,
      isDuplicate = false;
    for (let k in errors) {
      if (errors[k] !== "valid") {
        allOK = false;
        isError = true;
        const messageSplit = errors[k].split(" ").map((x) => x.toLowerCase());
        if ("duplicate" in messageSplit) isDuplicate = true;
      }
    }
    if (isDuplicate) numberOfDuplicateRecords++;
    if (isError) numberOfErrorRecords++;
    if (!isDuplicate && !isError) numberOfNewRecords++;
  }

  return {
    numberOfErrorRecords,
    numberOfDuplicateRecords,
    numberOfNewRecords,
    allOK,
  };
}

export async function validateFile(file) {
  if (!file) return "CSV file is required.";
  const fileSplit = file.name.split(".");
  if (fileSplit[fileSplit.length - 1] !== "csv")
    return "Please upload a valid CSV file.";

  const papaPromise = (importFile) =>
    new Promise((resolve, reject) => {
      Papa.parse(importFile, {
        header: false,
        preview: 1,
        skipEmptyLines: true,
        error: function (err, file, inputElem, reason) {
          reject(err);
        },
        step: function (results, parser) {
          resolve(results);
        },
        complete: function (results) {
          console.log("complete");
        },
      });
    });
  try {
    const results = await papaPromise(file);
    if (results.errors.length !== 0) return "CSV header is not valid.";
    return validateHeader(results.data);
  } catch (error) {
    console.log(error);
    return "Please try again.";
  }
}
