export const DentalIcon = ({ className = "" }) => (
    <svg className={className} fill="#000000" height="16" width="16" version="1.1" id="Layer_1" viewBox="0 0 512 512" >
<g>
	<g>
		<path d="M447.337,42.461C420.484,15.524,384.903,0.448,347.146,0.01c-26.613-0.311-52.627,6.678-75.331,20.208
			c-9.756,5.811-21.872,5.811-31.627,0C217.483,6.688,191.45-0.284,164.855,0.009C127.098,0.448,91.516,15.524,64.662,42.46
			c-26.852,26.935-41.82,62.564-42.145,100.323c-0.273,31.694,9.555,61.843,28.422,87.188
			c19.022,25.554,32.002,56.391,37.537,89.179c8.402,49.771,20.338,95.454,33.61,128.635C139.371,490.995,158.015,512,179.082,512
			c7.884,0,15.217-3.192,21.208-9.231c20.36-20.523,28.299-74.563,27.404-186.527c-0.186-23.117,10.126-44.702,28.306-59.269
			c18.177,14.56,28.491,36.148,28.306,59.269c-0.896,111.963,7.044,166.003,27.404,186.527c5.991,6.039,13.325,9.231,21.208,9.231
			c21.067,0,39.712-21.005,56.995-64.214c13.272-33.182,25.208-78.865,33.609-128.635c5.535-32.787,18.516-63.625,37.537-89.178
			c18.867-25.345,28.695-55.495,28.422-87.188C489.157,105.026,474.19,69.397,447.337,42.461z M444.697,217.792
			c-20.941,28.13-35.218,62.006-41.287,97.962c-19.28,114.218-50.708,175.848-70.492,175.848c-1.654,0-3.914-0.364-6.727-3.199
			c-22.68-22.863-21.813-131.211-21.487-171.998c0.237-29.521-12.959-57.057-36.192-75.544l-0.344-0.274
			c-3.582-2.871-7.875-4.306-12.168-4.306s-8.587,1.436-12.171,4.307l-0.332,0.265c-23.242,18.493-36.437,46.031-36.201,75.552
			c0.325,40.789,1.191,149.136-21.489,171.998c-2.813,2.836-5.072,3.2-6.726,3.2c-19.782,0-51.21-61.63-70.492-175.846
			c-6.07-35.957-20.346-69.832-41.287-97.963c-16.188-21.748-24.621-47.624-24.387-74.833
			c0.576-66.8,55.385-121.776,122.175-122.551c22.815-0.261,45.176,5.729,64.654,17.335c16.194,9.648,36.314,9.649,52.51,0
			c19.476-11.606,41.848-17.615,64.654-17.335c66.793,0.775,121.602,55.752,122.176,122.553
			C469.319,170.168,460.885,196.045,444.697,217.792z"/>
	</g>
</g>
<g>
	<g>
		<path d="M131.499,50.63c-2.535-5.032-8.669-7.056-13.698-4.52c-36.36,18.318-59.165,54.833-59.512,95.295
			c-0.048,5.632,4.478,10.238,10.112,10.286c0.03,0,0.06,0,0.09,0c5.591,0,10.148-4.509,10.196-10.11
			c0.282-32.786,18.787-62.389,48.292-77.253C132.01,61.793,134.033,55.66,131.499,50.63z"/>
	</g>
</g>
<g>
	<g>
		<path d="M164.681,34.684c-3.218,0.038-6.47,0.222-9.667,0.548c-5.603,0.571-9.683,5.577-9.112,11.18
			c0.534,5.253,4.966,9.166,10.134,9.166c0.346,0,0.695-0.017,1.046-0.053c2.59-0.264,5.226-0.413,7.836-0.444
			c5.633-0.065,10.145-4.686,10.08-10.318C174.933,39.131,170.322,34.632,164.681,34.684z"/>
	</g>
</g>
</svg>
);