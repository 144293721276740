import React from 'react';
import { Col, Row } from "antd";
import { TextField, DatePicker } from "../../../../../design-systems";
import {
    email,
    required,
    SSNValidation,
    validateFirstNameRules,
    validateLastNameRules,
    validateMiddleNameRules,
    validatePhoneNumber,
    whiteSpace
} from "../../../../../utils/validations";
import { DATE_FORMAT } from '../../../../../utils/constants';
import moment from 'moment';
import { handleKeyDown } from '../../../../../utils';
import DatePicker2 from '../../../../../design-systems/DatePicker2';

export const BasicDetails = ({ form, formData, setFormData }) => {
    const disableFutureDates = date => {
        const today = moment().startOf('day');
        return moment(date).isAfter(today) ? false : true
    };
    return (
        <Row gutter={16}>
            <Col xs={24} lg={8}>&nbsp;</Col>
            <Col xs={24} lg={16}>
                <div className="should-update-col division-layout">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <TextField
                                label="First name"
                                placeholder="Enter first name"
                                name="first_name"
                                onKeyPress={(event) => {
                                    if (/[ 0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                rules={[
                                    { validator: validateFirstNameRules, required: true },
                                    whiteSpace({ message: "First name should not contain only spaces." })]}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <TextField
                                label="Middle name"
                                placeholder="Enter middle name"
                                name="middle_name"
                                onKeyPress={(event) => {
                                    if (/[ 0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                rules={[
                                    whiteSpace({ message: "Middle name should not contain only spaces." }),
                                    { validator: validateMiddleNameRules }
                                ]}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <TextField
                                label="Last name"
                                placeholder="Enter last name"
                                name="last_name"
                                onKeyPress={(event) => {
                                    if (/[ 0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                rules={[
                                    { validator: validateLastNameRules, required: true },
                                    whiteSpace({ message: "Last name should not contain only spaces." })]}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <DatePicker2
                                maxDate={new Date()}
                                isClearable={true}
                                id="date_of_birth"
                                selected={formData["date_of_birth"]}
                                name="date_of_birth"
                                rules={[required("Date of birth")]}
                                label="Date of Birth"
                                placeholder="MM/DD/YYYY"
                                format={['MM/dd/yyyy', 'M/d/yyyy']}
                                filterDate={disableFutureDates}
                                readOnly={false}
                                onKeyDown={handleKeyDown}
                                onChange={(date) => {
                                    form.setFieldsValue({ 'date_of_birth': date });
                                    setFormData(((prevData) =>({
                                        ...prevData,
                                        'date_of_birth': date
                                    })))
                                }}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <TextField
                                label="Social security number"
                                placeholder="Enter social security number"
                                name="ssn"
                                maxLength={9}
                                rules={[required("Social security number"), SSNValidation]}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onPaste={(event) => {
                                    const clipboardData = event.clipboardData || window.clipboardData;
                                    const pastedText = clipboardData.getData('Text');

                                    if (!/^[0-9]+$/.test(pastedText)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <TextField
                                label="Work email"
                                placeholder="Enter work email"
                                name="work_email"
                                rules={[required("Work email"), email]}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <TextField
                                label="Mobile phone number"
                                placeholder="Enter mobile phone number"
                                name="mobile_phone_num"
                                maxLength={10}
                                rules={[{
                                    validator: validatePhoneNumber
                                }]}
                                onKeyPress={(event) => {
                                    if (!/^\d+$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onPaste={(event) => {
                                    const clipboardData = event.clipboardData || window.clipboardData;
                                    const pastedText = clipboardData.getData('Text');

                                    if (!/^\d+$/.test(pastedText)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
};
