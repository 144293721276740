import React from "react";
import { fixAmountFormat } from "../../../../utils";

const StatusActionInfo = ({ claimsData }) => {
    const {
        approved_amount,
        claim_reason,
        claim_reason_details,
        claim_status,
        denied_amount,
        reimbursement_mode,
        reimbursable_amount,
        payback_amount,
        expense_mode
    } = claimsData || {};

    const isCardExpenseType = expense_mode === "Card";
    const renderCommonData = () => {
        if (!isCardExpenseType) {
            return <>
                {(claim_status === "APPROVED" || claim_status === "PARTIALLY_APPROVED") &&
                    <div className="emp-details">
                        <div className="title">Pending Reimbursement</div>
                        <div className="align-right value">{fixAmountFormat(reimbursable_amount?.toFixed(2) ?? "-")}</div>
                    </div>
                }
                <div className="emp-details">
                    <div className="title">Reimbursement method</div>
                    <div className="value align-right">{
                        reimbursement_mode ?
                            reimbursement_mode === "EXTERNAL" ?
                                "LINKED ACCOUNT" :
                                reimbursement_mode
                            : "-"}</div>
                </div>
            </>
        }
    }

    return (
        <>
            <div className="emp-details-wrapper">
                {
                    claim_status === "APPROVED" &&
                    <>
                        <div className="emp-details">
                            <div className="title">Approved amount</div>
                            <div className="value align-right">{fixAmountFormat(approved_amount?.toFixed(2)) ?? "-"}</div>
                        </div>
                        <div className="emp-details">
                            <div className="title">Denied amount</div>
                            <div className="value align-right">{fixAmountFormat(denied_amount?.toFixed(2)) ?? "-"}</div>
                        </div>
                        {renderCommonData()}
                    </>
                }
                {
                    claim_status === "NEEDS_MORE_INFO" &&
                    <>
                        <div className="emp-details">
                            <div className="title">Reason</div>
                            <div className="value align-left">
                                <div>{claim_reason ?? "-"}</div>
                                {
                                    claim_reason_details &&
                                    <div>{claim_reason_details}</div>
                                }
                            </div>
                        </div>
                        {renderCommonData()}
                    </>
                }
                {
                    claim_status === "PARTIALLY_APPROVED" &&
                    <>
                        <div className="emp-details">
                            <div className="title">Reason</div>
                            <div className="value align-left">
                                <div >{claim_reason ?? "-"}</div>
                                {
                                    claim_reason_details &&
                                    <div>{claim_reason_details}</div>
                                }
                            </div>
                        </div>
                        <div className="emp-details">
                            <div className="title">Approved amount</div>
                            <div className="value align-right">{fixAmountFormat(approved_amount?.toFixed(2)) ?? "-"}</div>
                        </div>
                        <div className="emp-details">
                            <div className="title">Denied amount</div>
                            <div className="value align-right">{fixAmountFormat(denied_amount?.toFixed(2)) ?? "-"}</div>
                        </div>
                        {
                            isCardExpenseType && <div className="emp-details">
                                <div className="title">Amount to be paid back</div>
                                <div className="value align-right">{fixAmountFormat(payback_amount?.toFixed(2)) ?? "-"}</div>
                            </div>
                        }
                        {renderCommonData()}
                    </>
                }
                {
                    claim_status === "DENIED" &&
                    <>
                        <div className="emp-details">
                            <div className="title">Reason</div>
                            <div className="value align-left">
                                <div >{claim_reason ?? "-"}</div>
                                {
                                    claim_reason_details &&
                                    <div>{claim_reason_details ?? "-"}</div>
                                }
                            </div>
                        </div>
                        <div className="emp-details">
                            <div className="title">Approved amount</div>
                            <div className="value align-right">{fixAmountFormat(approved_amount?.toFixed(2)) ?? "-"}</div>
                        </div>
                        <div className="emp-details">
                            <div className="title">Denied amount</div>
                            <div className="value align-right">{fixAmountFormat(denied_amount?.toFixed(2)) ?? "-"}</div>
                        </div>
                        {
                            isCardExpenseType && <div className="emp-details">
                                <div className="title">Amount to be paid back</div>
                                <div className="value align-right">{fixAmountFormat(payback_amount?.toFixed(2)) ?? "-"}</div>
                            </div>
                        }
                        {renderCommonData()}
                    </>
                }
                {(claim_status === "AWAITING_REVIEW" || claim_status === "CANCELLED") &&
                    renderCommonData()
                }
            </div>
        </>
    );
};

export default StatusActionInfo;
