import { CONTRIBUTION } from "../../utils/constants";
import moment from "moment";

const initialState = {
  benefitAccountTypes: [],
};

const getPlans = (payload) => {
  const accounts = [];
    if(payload) {
      let hasHSAPlan = false;
      payload.plans.map((item) => {
        if(item.plans.length > 0 && item.name === "DCFSA") {
          item.plans.map((plan) => {
            accounts.push({
              name: item.name + " " + moment(plan.plan_start_date).format("YYYY"),
              planYear : new Date(plan.plan_start_date).getTime(),
              planConfigId : plan.plan_config_id,
              type: item.name,
              isActive: plan.is_active
            });
          });

        } else if(item.name === "HSA") {
         hasHSAPlan = true;
        }        
    });
  
    accounts.sort((a, b) => b.planYear - a.planYear);
      if (hasHSAPlan) {
        accounts.unshift({
          name: "HSA",
          planYear: null,
          type: "HSA",
          planConfigId: null,
          isActive: true
        });
      }
    }
    return accounts;
};

export function contribution(state = initialState, action) {
  switch (action.type) {
    case CONTRIBUTION.SET_BENEFIT_ACCOUNT_TYPE:
      return { ...state, benefitAccountTypes: getPlans(action.payload) };

    default:
      return state;
  }
}
