import { ApiErrors } from "../../utils";
import { disableDataLoader, enableDataLoader } from ".";
import { employeeGlobalSearchService } from "../../services";

export function findEmployees(searchTerm, callback) {
  const processName = "findEmployees";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    employeeGlobalSearchService(searchTerm)
      .then((resData) => {
        callback && callback(resData);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}
