import { combineReducers } from "redux";
import { userDetails } from "./User";
import { loader } from "./Loader";
import { employees } from "./Employees";
import { employer } from "./Employer";
import { contribution } from "./Contribution";
import { AUTH_CONSTANT } from "../../utils/constants";
import { claims } from "./Claims";
import { reports } from "./Reports";
import { expenses } from "./Expenses";

const rootReducer = (state, action) => {
  if (action.type === AUTH_CONSTANT.LOGOUT) {
    state = undefined;
  }

  return allReducers(state, action);
};

const allReducers = combineReducers({
  userDetails,
  loader,
  employees,
  employer,
  contribution,
  claims,
  reports,
  expenses
});

export default rootReducer;
