import { Card, Col, Divider, Form, Row } from "antd";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { useForm } from "antd/lib/form/Form";
import { clearFieldErrors, copyPlanSteps, formDataForCopyPlan, renderAddEmployerPlanSteps, renderSteps, steps, stepToTabNameMap } from "../../../utils";
import { useCallback, useEffect, useRef, useState } from "react";
import {
    CancelModal,
    Footer,
    Header,
    SuccessModal
} from "../../../components/admin/employer";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../utils/router";
import { formatDataForStep } from "../../../utils";
import { addNewEmployer, getCurrentEmployerDetailsByTab, getCurrentEmployerDetailsByTabWithLoader, getEmployeeCopaysCategories, getEmployerDetailsById, resetEmployerAddData, resetEmployerApiSuccess, saveCopaysData, saveCurrentEditEmployerPlanAction, showAdditionalContactForm, updateEmployerPlan } from "../../../redux/actions";
import { Button } from "../../../design-systems";
import { CopyPlanFooter } from "../../../components/admin/employer/AddEmployer/CopyPlanFooter";
import moment from "moment";

const AddPlan = () => {
    const initialValues = {};
    const [form] = useForm();
    const router = useRouter();
    const formRef = useRef();
    const dispatch = useDispatch();
    const controller = new AbortController();
    const signal = controller.signal;
    const {
        employerId,
        success,
        operationFlag,
        employerRouteInfo,
        copaysCategories,
        plansData,
        currentEmployerPlanEditAction
    } = useSelector((state) => state.employer);
    const [loading, setLoading] = useState(false)
    const currentStep = +router.match.params.step;
    const [formData, setFormData] = useState({})
    const [employerSteps, setEmployerSteps] = useState(copyPlanSteps);
    const [cancelModalOpened, setCancelModalOpened] = useState(false);
    const [successModalOpened, setSuccessModalOpened] = useState(false);
    const [copaysCate, setCopaysCate] = useState([])
    const getCurrentStepData = () => {
        if (!plansData?.length) return {};
        const currentStepData = plansData[0];
        if (currentStepData) return currentStepData
        return {}
    }

    useEffect(() => {
        if (plansData?.length > 0) {
            prefillFormData();
        }
    }, [employerRouteInfo, plansData, plansData?.length, currentStep]);


    const prefillFormData = useCallback(() => {
        const data = getCurrentStepData();

        if (currentStep === 1) {
            const { copay_configuration } = data;
            const { plan_year, selected_plans } = data?.plan_information || {};
            form.setFieldsValue({
                add_copay: copay_configuration?.length > 0,
                copays: copay_configuration?.map((copay, index) => ({
                    category: copay.expense_category,
                    copay_amount: copay.copay_amount,
                    key: index
                })) ?? [],
            })
            let formData = {
                add_copay: copay_configuration?.length > 0,
                copays: copay_configuration?.map((copay, index) => ({
                    category: copay.expense_category,
                    copay_amount: copay.copay_amount,
                    key: index
                })) ?? [],
                plans: selected_plans,
                select_all_plan: selected_plans?.length === 4,
                start_date: plan_year?.plan_start_date && moment(plan_year?.plan_start_date).toDate(),
                end_date1: plan_year?.plan_end_date && moment(plan_year?.plan_end_date).toDate()
            }
            data?.plan_information?.selected_plans?.forEach(plan => {
                const planKey = plan.toLowerCase();
                const planData = data?.plan_information[planKey];
                if (planData) {
                    formData[`${planKey}_employee_minimum_contribution`] = planData.min_employee_contribution_amount;
                    formData[`${planKey}_employee_maximum_contribution`] = planData.max_employee_contribution_amount;
                    formData[`${planKey}_employer_contribution_amount`] = planData.employer_contribution_amount;
                    formData[`${planKey}_run_out_period`] = planData.run_out_period_in_days;
                    formData[`${planKey}_allow_claim_file_post_termination`] = planData.allow_claim_file_post_termination_within_service_date;
                    formData[`${planKey}_terminated_employees_run_out`] = planData.terminated_employees_run_out;
                    formData[`${planKey}_is_grace_period_enabled`] = planData.is_grace_period_enabled;
                    formData[`${planKey}_is_rollover_enabled`] = planData.is_rollover_enabled;
                    formData[`${planKey}_maximum_rollover`] = planData.max_rollover_amount;
                    formData[`${planKey}_minimum_rollover`] = planData.min_rollover_amount;
                    formData[`${planKey}_grace_period_end_date`] = planData?.grace_period_end_date;

                    if (planKey === "dcfsa") {
                        formData['dcfsa_grace_period'] = data?.plan_information['dcfsa']?.is_grace_period_enabled ? "yes" : "no";
                    }
                }
                if (planData?.is_grace_period_enabled) {
                    setFormData((prevData) => ({
                        ...prevData,
                        [`${planKey}_grace_period_end_date`]: planData?.grace_period_end_date ? moment(planData?.grace_period_end_date).toDate() : null
                    }));
                }
                if (['fsa', 'lfsa']?.includes(planKey) && planData.is_rollover_enabled) {
                    formData[`${planKey}_allow_rollover_not_elected_for_following_plan_year`] = planData.rollover_if_no_fsa_next_year ? "yes" : "no";
                }
                if (['dcfsa']?.includes(planKey)) {
                    formData['dcfsa_allow_claims'] = planData?.allow_claim_file_post_termination_within_service_date ? 'yes' : 'no'
                }
                if (planData?.is_rollover_enabled) {
                    formData[`${planKey}_rollover_or_grace_period`] = "rollover";
                } else if (planData?.is_grace_period_enabled) {
                    formData[`${planKey}_rollover_or_grace_period`] = "grace-period";
                } else {
                    formData[`${planKey}_rollover_or_grace_period`] = "none";
                }
            });
            setCopaysCate(copay_configuration?.map((copay, index) => ({
                category: copay.expense_category,
                copay_amount: copay.copay_amount,
                key: index
            })))
            dispatch(saveCopaysData(copay_configuration?.map((copay, index) => ({
                category: copay.expense_category,
                copay_amount: copay.copay_amount,
                key: index
            }))))
            form.setFieldsValue(formData)
            setFormData(((prevData) => ({
                ...prevData,
                start_date: plan_year?.plan_start_date ? plan_year?.plan_start_date : "",
                end_date1: plan_year?.plan_end_date ? plan_year?.plan_end_date : ""
            })))
        }
    }, [currentStep, form, plansData, plansData?.length]);

    useEffect(() => {
        if (employerRouteInfo?.editDraft && employerId) {
            dispatch(getEmployeeCopaysCategories(form))
        }
        return () => {
            controller.abort()
            dispatch(resetEmployerAddData())
            dispatch(resetEmployerApiSuccess())
        }
    }, [])

    useEffect(() => {
        if (currentStep === 1) {
            if (router.location && router.location.state && !router.location.state?.editDraft)
                form.setFieldsValue({
                    same_as_corporate: true,
                    authorized_for_plan_document: true,
                    is_super_admin: true,
                    fsa_employee_contribution_amount: 0,
                    lfsa_employee_contribution_amount: 0,
                    dcfsa_employee_contribution_amount: 0,
                })
        }
    }, [router])

    useEffect(() => {
        if (currentStep > 0 && currentStep <= employerSteps.length) {
            setEmployerSteps((prev) => [
                ...prev.map((step, index) => {
                    const currentIndex = currentStep - 1;
                    const isActive = currentIndex === index;
                    const isComplete = currentIndex > index;

                    return { ...step, isActive, isComplete };
                }),
            ]);
        } else {
            router.push("/employer");
        }
    }, [currentStep, employerSteps.length, router]);

    const onFinish = useCallback(
        async (values) => {
            setLoading(true)
            let apiData = formDataForCopyPlan(values, currentStep, currentEmployerPlanEditAction, copaysCategories)
            dispatch(updateEmployerPlan(apiData, (res) => {
                if (!(res instanceof Error)) {
                    setLoading(false)
                    router.push('/admin/employer-detail', { showPlanInfoTab: true })
                } else {
                    setLoading(false)
                    if (res?.response?.data?.error?.errored_fields?.length) {
                        res?.response?.data?.error?.errored_fields.forEach(err => {
                            let simplifiedFieldName = err?.field.replace(/^\./, "").replace(/\./g, "_").toLowerCase();
                            let errorMessage = err?.message;
                            // Handle specific cases for start_date and end_date
                            if (simplifiedFieldName === 'plan_year_start_date') {
                                simplifiedFieldName = 'start_date';
                                errorMessage = "Please choose valid start date"
                            } else if (simplifiedFieldName === 'plan_year_end_date') {
                                simplifiedFieldName = 'end_date1';
                                errorMessage = "Please choose valid end date"
                            }

                            form.setFields([
                                {
                                    name: simplifiedFieldName,
                                    errors: [errorMessage],
                                },
                            ]);
                        })
                    }
                }
            }))
        },
        [currentStep, employerSteps.length, dispatch, router, success, currentEmployerPlanEditAction, copaysCategories],
    );

    useEffect(() => {
        if (currentStep < employerSteps.length && success) {
            if (operationFlag === 'saveForm') {
                router.push(`/admin/employer/add/${currentStep + 1}`);
            }
        }
        if (success && currentStep === employerSteps.length) {
            setSuccessModalOpened(true)
        }
    }, [success]);

    const onFinishFailed = useCallback((errorInfo) => {
    }, []);

    const onValuesChange = useCallback(
        (_, allValues) => {
            clearFieldErrors(form, allValues);
        },
        [form],
    );

    const onCancel = useCallback(() => {
        setCancelModalOpened(true);
    }, []);

    const onCancelConfirm = useCallback(() => {
        router.push('/admin/employers')
    }, []);

    const onSaveProgress = useCallback(async () => {
        try {
            const values = await form.validateFields();
            let apiData = formatDataForStep(values, currentStep, form)
            apiData.employer_id = employerId
            apiData.is_save_progress = true
            dispatch(addNewEmployer(apiData, 'saveProgress'))
        } catch (err) {
        }
    }, [form, currentStep]);

    const onPrevious = useCallback(() => {
        if (true && currentStep > 1) {
            // if form field valid
            dispatch(resetEmployerApiSuccess())
            router.push(`/admin/employer/add/${currentStep - 1}`);
        }
    }, [currentStep, router]);

    const OnAddAnother = () => {
        form.resetFields();
        setSuccessModalOpened(false)
        dispatch(resetEmployerAddData())
        setFormData({})
        router.push("/admin/employer/add/1");
    }

    const onSeeList = () => {
        form.resetFields();
        setSuccessModalOpened(false)
        setFormData({})
        router.push(`/admin/employers`)
    }
    const saveAction = (action) => {
        dispatch(saveCurrentEditEmployerPlanAction(action))
    }

    const data = getCurrentStepData()
    return (
        <>
            <HeaderWithContent
                customFooter={
                    <CopyPlanFooter
                        onNext={() => {
                            formRef?.current?.submit()
                            dispatch(resetEmployerApiSuccess())
                        }}

                        action={currentEmployerPlanEditAction}
                        loading={loading}
                        saveAction={saveAction}
                    />
                }
            >
                <Form
                    name="login"
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                    autoComplete="off"
                    validateTrigger="onSubmit"
                    ref={formRef}
                >
                    <Row gutter={0}>
                        <Col span={24}>
                            <Card
                                title={
                                    <Header
                                        steps={employerSteps}
                                        currentStep={currentStep - 1}
                                        nextStep={currentStep}
                                        onSaveProgress={onSaveProgress}
                                        page="copyPlan"
                                    />
                                }
                                bordered={false}
                                className="add-employer-card"
                            >
                                <Divider />

                                {renderAddEmployerPlanSteps({ selectedPlans: data?.plan_information?.selected_plans || [], hideCopays: true, form, step: currentStep, formData, setFormData, copaysCate, setCopaysCate })}
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </HeaderWithContent>
            <CancelModal
                open={cancelModalOpened}
                onCancel={() => setCancelModalOpened(false)}
                onOk={onCancelConfirm}
                page={"add-employer"}
            />

            <SuccessModal
                page="add-employer"
                form={form}
                onCancel={() => setSuccessModalOpened(false)}
                open={successModalOpened}
                onSecondaryActionClick={onSeeList}
                onPrimaryActionClick={OnAddAnother}
                primaryLabel="Add another"
                secondaryLabel="See employer list"
            />
        </>
    );
};

export default AddPlan;
