import { Collapse as AntCollapse, Form } from "antd";
import { planInformationKeys, planInformationStep, planInformationStepWithCopays, validateFields } from "../../../../../utils";
import { PanelHeader } from "../PanelHeader";
import { useEffect, useMemo, useState } from "react";
import { Collapse } from "../../../../../design-systems";
import { useRouter } from "../../../../../utils/router";
import { useSelector } from "react-redux";
import { AddCopays } from "../Copays/AddCopays";

const { Panel } = AntCollapse;

export const PlanInformation = ({ selectedPlans, hideCopays, type, form, formData, setFormData, copaysCate, setCopaysCate }) => {
  const router = useRouter();
  const { employerId } = useSelector((state) => state.employer);
  const plans = Form.useWatch("plans", form) || selectedPlans;
  const isHSAOnlyPlan = useMemo(() => {
    if (plans?.length > 0) {
      return plans?.length === 1 && plans.every(plan => plan.toLowerCase() === "hsa");
    } else {
      return true
    }
  }, [plans])

  const steps = hideCopays ?
    isHSAOnlyPlan ?
      planInformationStepWithCopays?.filter(step => step.key !== planInformationKeys.ADD_COPAYS) :
      planInformationStepWithCopays :
    planInformationStep

    useEffect(() => {
      if (!employerId) {
      const path = type === "copyPlan" ? '/admin/employer/plan/add/1' : '/admin/employer/add/1'
      router.push(path)
    }
  }, [employerId])
  const [expandedPanels, setExpandedPanels] = useState(
    steps.map(({ key }) => key),
    );
    const [plansExt, setPlansExt] = useState([]);
    
    const filteredPlanInformationStep = useMemo(() => {
      return steps.filter(
        ({ planKey }) => !planKey || (planKey && plans?.includes(planKey)),
        );
      }, [plans]);
      
      const onChange = (updatedPanels) => {
        if (updatedPanels?.length < expandedPanels.length) {
          setExpandedPanels(updatedPanels);
        } else {
          setExpandedPanels(updatedPanels);
        }
  };
  return (
    <>
      <Collapse
        activeKey={expandedPanels}
        onChange={onChange}
        panels={filteredPlanInformationStep.map(({ content, title, key }) => {
          return (
            <Panel style={{ display: "flex", flexDirection: key === planInformationKeys.ADD_COPAYS ? "column" : "" }} className={key === planInformationKeys.ADD_COPAYS ? "copays-collapse horizontal-collapse test" : ""} showArrow={true} header={<PanelHeader label={title} form={form} expandedPanels={expandedPanels} setExpandedPanels={setExpandedPanels} />} key={key}>
              {content({ form, plansExt, setPlansExt, formData, setFormData, copaysCate, setCopaysCate, selectedPlans })}
            </Panel>
          )
        })}
      />
    </>
  );
};
