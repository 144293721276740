import React from "react";
import { NoClaimIcon } from "../../../design-systems";
import { Typography } from "../../../design-systems";
export const NoClaims = () => {
    return (
        <div className="no-claims-wrapper">
            <NoClaimIcon />
            <Typography className="no-claims-subhead" variant="subhead-2" label="No claims matching the selected filters." />
            <Typography className="no-claims-desc" label="You can view past claims based on the applied filters."></Typography>
        </div>
    )
}