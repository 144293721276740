import { USER_CONSTANT } from "../../utils/constants";

const initialState = { isLoggedIn: false, adminDetails: null, user: null };

export function userDetails(state = initialState, action) {
  switch (action.type) {
    case USER_CONSTANT.SAVE_LOGGED_IN_DETAILS:
      return { ...state, isLoggedIn: true, user: action.user };
    case USER_CONSTANT.SAVE_ADMIN_USER_DETAILS:
      return { ...state, adminDetails: action.user.admin };
    case USER_CONSTANT.SAVE_NON_VERIFIED_USER:
      return { ...state, user: action.user };
    case USER_CONSTANT.SAVE_ROLE_TYPE:
      return { ...state, roleType: action.role };
    case USER_CONSTANT.SAVE_EMAIL:
      return { ...state, email: action.email };
    default:
      return state;
  }
}
