import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../utils/router/customRouterHook";
import {
  getUserDetails,
  getAdminDetails,
  showSuccessMessage,
  showErrorMessage,
  updateAdmin,
  deleteAdmin,
} from "../../../redux/actions";
import {
  required,
  email,
  validatePhone,
  validateFirstNameRules,
  validateLastNameRules,
} from "../../../utils/validations";
import {
  clearBodyObject,
  clearFieldErrors,
  comparisonValues,
  stripLetters,
} from "../../../utils";
import { langs } from "../../../i18n";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import {
  ROLE_CONSTANT,
  ADMIN_STATUSES,
  ADMIN_ACTIONS,
} from "../../../utils/constants";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { WarningDeleteAdminModal } from "../../../components/admin/settings/users";

import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  Divider,
  Radio,
  Space,
} from "antd";
import { MobileFilled } from "@ant-design/icons";

function UserDetails() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = Form.useForm();

  const [userData, setUserData] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const emailId = router.query.email;

  const currentRole = userData?.role.value;
  const currentStatus = userData?.status.value;

  useEffect(() => {
    assignAdminDetails();
    // eslint-disable-next-line
  }, [emailId]);

  const assignAdminDetails = () => {
    dispatch(
      emailId
        ? getUserDetails(emailId, (res) => {
            setUserData(res.admin);
            setInitialValues({
              email: res.admin.email.value,
              firstname: res.admin.first_name.value,
              lastname: res.admin.last_name.value,
              phone: res.admin.phone.value,
              role: res.admin.role.value,
              status: res.admin.status.value,
            });

            form.resetFields();
          })
        : getAdminDetails((res) => {
            setUserData(res.admin);
            setInitialValues({
              email: res.admin.email.value,
              firstname: res.admin.first_name.value,
              lastname: res.admin.last_name.value,
              phone: res.admin.phone.value,
              role: res.admin.role.value,
              status: res.admin.status.value,
            });

            form.resetFields();
          }),
    );
  };

  const updateAdminDetails = () => {
    const fields = form.getFieldsValue();

    const emailIsChanged = initialValues.email !== fields.email;
    const firstnameIsChanged = initialValues.firstname !== fields.firstname;
    const lastnameIsChanged = initialValues.lastname !== fields.lastname;
    const phoneIsChanged = initialValues.phone !== fields.phone;
    const statusIsChanged = initialValues.status !== fields.status;
    const roleIsChanged = initialValues.role !== fields.role;

    const payload = {
      admin_email_id: userData.admin_email_id,
      upd_admin_email_id: emailIsChanged ? fields.email : null,
      first_name: firstnameIsChanged ? fields.firstname : null,
      last_name: lastnameIsChanged ? fields.lastname : null,
      mobile_phone_num: phoneIsChanged ? Number(fields.phone) : null,
      status: statusIsChanged ? fields.status.toUpperCase() : null,
      role: roleIsChanged ? fields.role : null,
      invite: emailIsChanged ? ADMIN_ACTIONS.UPDATE_EMAIL : null,
    };

    const requestData = clearBodyObject(payload);

    dispatch(
      updateAdmin(requestData, (res) => {
        if (res instanceof Error) {
          dispatch(showErrorMessage(res.message));
        } else {
          assignAdminDetails();
          setFormHasChanged(false);
          dispatch(getAdminDetails());
          dispatch(showSuccessMessage(langs.messages.admin_details_updated));
        }
      }),
    );
  };

  const resendInvite = () => {
    const fields = form.getFieldsValue();

    const emailIsChanged = initialValues.email !== fields.email;
    const firstnameIsChanged = initialValues.firstname !== fields.firstname;
    const lastnameIsChanged = initialValues.lastname !== fields.lastname;
    const roleIsChanged = initialValues.role !== fields.role;

    const payload = {
      admin_email_id: userData.admin_email_id,
      upd_admin_email_id: emailIsChanged ? fields.email : null,
      first_name: firstnameIsChanged ? fields.firstname.trim() : null,
      last_name: lastnameIsChanged ? fields.lastname.trim() : null,
      role: roleIsChanged ? fields.role : null,
      invite: emailIsChanged
        ? ADMIN_ACTIONS.UPDATE_EMAIL
        : ADMIN_ACTIONS.RE_INVITE_EMAIL,
    };

    const requestData = clearBodyObject(payload);

    dispatch(
      updateAdmin(requestData, (res) => {
        if (res instanceof Error) {
          const errMessage = res.response.data.error.message;

          if (errMessage.includes("Email already exists")) {
            const fieldsWidthEmailError = [
              {
                name: "email",
                errors: [langs.messages.email_already_registered_as_an_admin],
              },
            ];

            form.setFields(fieldsWidthEmailError);
          } else {
            dispatch(showErrorMessage(errMessage));
          }
        } else {
          router.push("/admin/settings/users-list");
          dispatch(showSuccessMessage(langs.messages.invitation_was_resent));
        }
      }),
    );
  };

  const deleteInvitedAdmin = () => {
    setShowWarningModal(false);

    dispatch(
      deleteAdmin(emailId, (res) => {
        router.push("/admin/settings/users-list");
        dispatch(showSuccessMessage(langs.messages.admin_was_deleted));
      }),
    );
  };

  const discardFormChanges = () => {
    setFormHasChanged(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const isDisable = useCallback(
    (key) => (userData ? !userData[key].canEdit : false),
    [userData],
  );

  const onValuesChange = (value, allValues) => {
    clearFieldErrors(form, allValues);
    setFormHasChanged(!comparisonValues(initialValues, allValues));
  };

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card title="User Information" bordered={false}>
            <SpinLoader showContent={!!initialValues}>
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={
                  currentStatus === ADMIN_STATUSES.INVITED
                    ? resendInvite
                    : updateAdminDetails
                }
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
                initialValues={initialValues}
                layout="vertical"
                autoComplete="off"
                validateTrigger="onSubmit"
                className="common-form-container"
              >
                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="First Name"
                      name="firstname"
                      normalize={(value) => value.trimStart()}
                      rules={[
                        { validator: validateFirstNameRules, required: true },
                      ]}
                    >
                      <Input
                        disabled={isDisable("first_name")}
                        prefix={<i className="ni ni-circle-08" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Last Name"
                      name="lastname"
                      normalize={(value) => value.trimStart()}
                      rules={[
                        { validator: validateLastNameRules, required: true },
                      ]}
                    >
                      <Input
                        disabled={isDisable("last_name")}
                        prefix={<i className="ni ni-circle-08" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[required("Email id"), email]}
                    >
                      <Input
                        disabled={isDisable("email")}
                        prefix={<i className="ni ni-email-83" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Phone"
                      name="phone"
                      rules={
                        currentStatus !== ADMIN_STATUSES.INVITED
                          ? [validatePhone]
                          : []
                      }
                      onChange={(e) => {
                        form.setFieldsValue({
                          phone: stripLetters(e.target.value),
                        });
                      }}
                    >
                      <Input
                        disabled={isDisable("phone")}
                        maxLength={10}
                        prefix={<MobileFilled />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <div className="role-block">
                  <Form.Item label="Role" name="role">
                    <Radio.Group disabled={isDisable("role")}>
                      <Space direction="vertical">
                        <Radio
                          value={ROLE_CONSTANT.ADMIN}
                          className="radio-role"
                        >
                          <small>ADMIN</small>
                          <p>{langs.role_description_admin}</p>
                        </Radio>
                        <Radio
                          value={ROLE_CONSTANT.SUPER_ADMIN}
                          className="radio-role"
                        >
                          <small>SUPER ADMIN</small>
                          <p>{langs.role_description_superadmin}</p>
                        </Radio>
                        {currentRole === ROLE_CONSTANT.ZENDA_ADMIN && (
                          <Radio
                            value={ROLE_CONSTANT.ZENDA_ADMIN}
                            className="radio-role"
                          >
                            <small>INTERNAL ADMIN</small>
                            <p>{langs.role_description_zendaadmin}</p>
                          </Radio>
                        )}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <Divider />
                <div className="status-block">
                  <Form.Item label="Status" name="status">
                    <Radio.Group disabled={isDisable("status")}>
                      <Space direction="vertical">
                        <Radio value={ADMIN_STATUSES.ENABLE}>
                          <span className="enable">ENABLE</span>
                        </Radio>
                        <Radio value={ADMIN_STATUSES.DISABLE}>
                          <span className="disable">DISABLE</span>
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>

                {emailId && (
                  <Row gutter={(0, 30)}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className="btn-block">
                        {currentStatus === ADMIN_STATUSES.INVITED ? (
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="button"
                              className="button-secondary mr-10"
                              onClick={discardFormChanges}
                              disabled={!formHasChanged}
                            >
                              Discard Changes
                            </Button>
                            <Button
                              type="primary"
                              htmlType="button"
                              className="button-primary mr-10"
                              onClick={() => setShowWarningModal(true)}
                            >
                              Delete
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="button-primary"
                            >
                              {formHasChanged ? "Save" : "Resend invite"}
                            </Button>
                          </Form.Item>
                        ) : (
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="button"
                              className="button-secondary mr-10"
                              onClick={discardFormChanges}
                              disabled={!formHasChanged}
                            >
                              Discard Changes
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="button-primary"
                              disabled={!formHasChanged}
                            >
                              Save
                            </Button>
                          </Form.Item>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            </SpinLoader>
          </Card>
        </Col>
      </Row>

      <WarningDeleteAdminModal
        showWarningModal={showWarningModal}
        deleteAdmin={deleteInvitedAdmin}
        onCancel={() => setShowWarningModal(false)}
      />
    </HeaderWithContent>
  );
}

export { UserDetails };
