import React, { useEffect, useState } from "react";

import { Card, Col, Button, Row, Tag, Empty } from "antd";
import moment from "moment";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined
} from '@ant-design/icons'
import { DATE_FORMAT } from "../../../../utils/constants";
import { Typography } from "../../../../design-systems";

function CardSection({ data }) {
  const [currentCard, setCurrentCard] = useState({});
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  useEffect(() => {
    if (data?.length > 0) {
      setCurrentCard(data[0])
      setCurrentCardIndex(0)
    }
  }, [data?.length]);

  useEffect(() => {
    if (data?.length > 0 && currentCardIndex < data.length) {
      setCurrentCard(data[currentCardIndex]);
    }
  }, [currentCardIndex]);

  const handleNext = () => {
    if (currentCardIndex < data?.length - 1) {
      setCurrentCardIndex((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex((prev) => prev - 1);
    }
  };

  return (
    <>
      <Card bordered={false} className={`account-holder-cards ${data?.length ? "card-section-header" : ""}`}>
        {data?.length > 0 ?
          <>
            <div className="d-flex justify-space-between align-items-center">
              <>
                <Typography
                  variant="subhead-3"
                  label="Cards"
                  className="account-holder-card-header"
                />
                <div className="d-flex align-items-center gap-10">
                  <div className="d-flex">
                    <Button
                      disabled={currentCardIndex <= 0}
                      variant="ghost"
                      onClick={handlePrevious}
                      style={{ borderRadius: 25 }}
                    >
                      <ArrowLeftOutlined />
                    </Button>
                    <Button
                      disabled={currentCardIndex >= data?.length - 1}
                      variant="ghost"
                      onClick={handleNext}
                      style={{ borderRadius: 25 }}
                    >
                      <ArrowRightOutlined />
                    </Button>
                  </div>
                </div>
              </>
            </div>
            <div className="mt-30"></div>
            <Row gutter={[20, 10]}>
              <Col md={24}>
                <Typography
                  variant=""
                  label="PAN"
                  className="account-holder-value"
                />
                <div className="d-flex justify-space-around gap-15 align-item-center">
                  <Typography
                    variant=""
                    style={{ color: 'black', fontWeight: 600 }}
                    label={currentCard?.masked_pan ?? "-"}
                    className="account-holder-card-values word-break-all"
                  />
                  {
                    Boolean(currentCard?.is_primary) &&
                    <Tag className="primary-tag">Primary</Tag>
                  }
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} >
                <Typography
                  variant=""
                  label="Name"
                  className="account-holder-value"
                />
                <Typography
                  variant=""
                  label={`${currentCard?.first_name ?? ""} ${currentCard?.middle_name ?? ""} ${currentCard?.last_name ?? ""}`}
                  className="account-holder-card-values"
                />
              </Col>
              <Col md={12}>
                <Typography
                  variant=""
                  label="Card status"
                  className="account-holder-value"
                />
                <Typography
                  variant=""
                  label={currentCard?.card_status ?? "-"}
                  className="account-holder-card-values"
                />
              </Col>
              <Col md={12}>
                <Typography
                  variant=""
                  label="Date issued"
                  className="account-holder-value"
                />
                <Typography
                  variant=""
                  label={currentCard?.date_issued ? moment(currentCard?.date_issued).format(DATE_FORMAT.FOR_UI_FIRST_MM) : "-"}
                  className="account-holder-card-values"
                />
              </Col>
              <Col md={12}>
                <Typography
                  variant=""
                  label="Pin"
                  className="account-holder-value"
                />
                <Typography
                  variant=""
                  label={currentCard?.pin_set ? "Set" : "Not set"}
                  className="account-holder-card-values"
                />
              </Col>
              <Col md={12}>
                <Typography
                  variant=""
                  label="Code"
                  className="account-holder-value"
                />
                <Typography
                  variant=""
                  label={currentCard?.code ?? "-"}
                  className="account-holder-card-values"
                />
              </Col>
              <Col md={12}>
                <Typography
                  variant=""
                  label="ID"
                  className="account-holder-value"
                />
                <Typography
                  variant=""
                  label={currentCard?.card_id ?? "-"}
                  className="account-holder-card-values"
                />
              </Col>
            </Row>
          </>
          :
          <div>
            <Typography
              variant="subhead-3"
              label="Cards"
              className="account-holder-card-header mb-20"
            />
            <div>
              <Empty />
            </div>
          </div>
        }
      </Card>
    </>
  );
}

export { CardSection };
