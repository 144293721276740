import React from "react";

export const IncommLoaderIcon = ({
    color,
    height="22",
    width="24"
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 22" fill="none">
            <path d="M16.9769 6.6662C16.646 6.18998 15.9636 5.59243 15.1347 4.92814C11.0755 1.66435 5.71894 0.00514817 2.50029 0.630001C1.48696 0.827164 0.766496 1.24575 0.359406 1.87061C-0.665642 3.43577 0.605417 6.8391 2.86052 8.64086C3.26468 8.96239 3.3994 8.41336 3.26468 8.23137C1.61289 5.99888 1.70954 4.23656 2.70237 3.65114C3.37012 3.25681 7.27994 1.20632 16.5639 7.09996C16.9417 7.33959 17.2141 6.99986 16.9769 6.66317V6.6662Z" fill={color} />
            <path d="M9.68106 16.8431C9.47312 16.9068 9.26811 16.9735 9.05724 17.0251C3.64206 18.3536 1.79991 16.837 2.04592 15.9907C2.11328 15.7572 2.41494 15.8937 2.55844 15.9998C2.74002 16.1333 3.17054 16.197 3.53956 16.197C4.57632 16.197 6.28962 15.6965 8.27235 14.8624C8.73509 15.56 9.20368 16.2213 9.68106 16.84V16.8431ZM23.7154 3.82733C22.09 0.848659 18.0191 1.06402 14.6189 2.156C14.3231 2.25003 14.4812 2.79905 14.777 2.74748C16.8857 2.38046 18.763 2.32586 20.2185 3.60893C20.6549 3.99416 20.8599 4.47948 20.8306 5.0558C20.775 6.08104 19.9433 7.84641 16.2882 10.4156C15.3364 11.0829 14.3289 11.726 13.3097 12.3235C13.6465 12.7269 13.9043 13.0242 14.0448 13.188L14.0565 13.2032C14.5017 13.7158 14.9088 14.1586 15.2807 14.5378C16.5137 13.8765 17.6764 13.1425 18.7425 12.3387C21.1264 10.543 22.9071 8.48339 23.6363 6.68466C24.0932 5.55629 24.1196 4.56744 23.7154 3.82733Z" fill={color} />
            <path d="M20.3921 13.1815C20.2339 12.9116 19.8298 13.0845 19.8913 13.3271C20.313 14.956 20.2515 16.7153 19.5017 17.7041C19.162 18.1531 18.752 18.3806 18.2453 18.3988C16.9157 18.4382 15.0003 17.0429 12.5636 14.2311C12.3352 13.9672 8.1325 9.36873 5.71339 5.04936C5.53767 4.73693 5.05736 5.03419 5.13058 5.25259C5.69582 6.97852 6.04434 8.52245 7.72834 11.5102C11.1901 17.6465 15.3898 21.4108 18.4211 21.4988C19.821 21.5412 20.7201 20.5008 21.051 19.7031C21.8799 17.7132 21.4728 15.0379 20.3862 13.1815H20.3921Z" fill={color} />
        </svg>
    )
}