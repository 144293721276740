import React from "react";
import { Input } from 'antd';
import { Search } from "../../../design-systems";

export const SearchInput = ({
    placeholder,
    onChange,
    onSearch,
    value,
    disabled,
    defaultValue,
    style
}) => {
    return (
        <Input.Search
            style={style}
            disabled={disabled}
            value={value}
            className="search-input-global"
            placeholder={placeholder ? placeholder : "Search"}
            enterButton={<div className="search-input-button">
                Search</div>}
            prefix={<Search />}
            size="large"
            onChange={onChange}
            allowClear
            onSearch={(e) => onSearch(e)}
            defaultValue={defaultValue}
        />
    )
}