import { Col, Form, Row } from "antd";
import { CheckBox, TextField } from "../../../../../../design-systems";
import { mandatory, maxLength, minLength, validateAccountNumber, validateRoutingNumber } from "../../../../../../utils/validations";
import { useEffect, useMemo } from "react";
import { Division } from "./Division";

export const AddBankAccountDetails = ({ form }) => {
  const divisions = useMemo(
    () =>
    form
    .getFieldValue("divisions")
    ?.filter((division) => !!Object.keys(division).length) || [],
    [form],
    );
    

  const setBankAccounts = () => {
    form.setFieldsValue({
      single_bank_account_for_all: true,
      bank_accounts: divisions.map((division) => (
        {
        ...division,
        routing_number: "",
        account_number: "",
        name_of_account: "",
      })),
    });
  };

  useEffect(() => {
    setBankAccounts();

    // eslint-disable-next-line
  }, []);

  const customValidation = () => {
    if (
      form
        .getFieldValue("bank_accounts")
        .some(
          ({ account_number, routing_number, name_of_account }) =>
            !name_of_account || !routing_number || !account_number,
        ) && !form.getFieldValue('single_bank_account_for_all')
    ) {
      return Promise.reject(
        "You need to add bank information for all divisions or need to add single bank account for all",
      );
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={16}>
      {divisions.length > 1 && (
        <Col xs={24} md={24}>
          <CheckBox
            label="Single bank account for all"
            name="single_bank_account_for_all"
          />
        </Col>
      )}
      <Col xs={24} md={24}>
        <Form.Item shouldUpdate className="should-update-form-item">
          {() =>
            form.getFieldValue("single_bank_account_for_all") ||
            divisions.length <= 1 ? (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <TextField
                    label="Routing number"
                    placeholder="Enter routing number"
                    name="routing_number"
                    maxLength={9}
                    onKeyPress={(event) => {
                      if (!/[0-9-]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a valid routing number',
                      },
                      {
                        validator: validateRoutingNumber,
                      },
                    ]}
                    onPaste={(event) => {
                      const clipboardData = event.clipboardData || window.clipboardData;
                      const pastedText = clipboardData.getData('Text');
        
                      if (!/^\d+$/.test(pastedText)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <TextField
                    label="Account number"
                    placeholder="Enter account number"
                    name="account_number"
                    maxLength={12}
                    onKeyPress={(event) => {
                      if (!/[0-9-]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a valid account number',
                      },
                      {
                        validator: validateAccountNumber,
                      },
                    ]}
                    onPaste={(event) => {
                      const clipboardData = event.clipboardData || window.clipboardData;
                      const pastedText = clipboardData.getData('Text');
        
                      if (!/^\d+$/.test(pastedText)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <TextField
                    label="Name on account"
                    placeholder="Enter name on account"
                    name="name_of_account"
                    rules={[
                      mandatory({
                        message: "Please enter a valid name on account",
                      }),
                      maxLength(40)
                    ]}
                  />
                </Col>
              </Row>
            ) : (
              <Form.List name="bank_accounts">
                {(fields) =>
                  fields.map(({ key, name, ...restField }, index) => (
                    <Division
                      key={key}
                      name={name}
                      restField={restField}
                      division={divisions[index]}
                    />
                  ))
                }
              </Form.List>
            )
          }
        </Form.Item>
      </Col>
      <Col xs={24} md={24}>
        <Form.Item
          rules={[{ validator: customValidation }]}
          name="bank_accounts_err"
          className="dummy-error should-update-form-item"
        ></Form.Item>
      </Col>
    </Row>
  );
};
