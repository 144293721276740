import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../utils/router/customRouterHook";
import { ROLE_CONSTANT } from "../../../utils/constants";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import {
  required,
  email,
  validatePhone,
  validateFirstNameRules,
  validateLastNameRules,
} from "../../../utils/validations";
import { clearFieldErrors, stripLetters, validateEmail } from "../../../utils";
import {
  inviteAdmin,
  showSuccessMessage,
  validateAdminEmail,
} from "../../../redux/actions";
import { langs } from "../../../i18n";
import { SpinLoader } from "../../../components/common/SpinLoader";

import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  Divider,
  Radio,
  Space,
} from "antd";
import { MobileFilled } from "@ant-design/icons";

function AddNewUser() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = Form.useForm();

  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const currentRole = useSelector((state) =>
    state.userDetails && state.userDetails.roleType
      ? state.userDetails.roleType
      : "ADMIN",
  );

  const onFinish = (values) => {
    disableButtonAfterClick();

    const userDetails = {
      adminEmailId: values.email.toLowerCase().trim(),
      role: values.role,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phone: values.phone,
    };

    if (!validateEmail(userDetails.adminEmailId)) return;

    dispatch(
      validateAdminEmail(userDetails.adminEmailId, (res) => {
        if (res instanceof Error) {
          const fieldsWidthEmailError = [
            {
              name: "email",
              errors: [langs.messages.email_already_registered_as_an_admin],
            },
          ];

          form.setFields(fieldsWidthEmailError);
        } else {
          dispatch(
            inviteAdmin(userDetails, (res) => {
              router.push("/admin/settings/users-list");
              dispatch(showSuccessMessage(langs.messages.invitation_was_sent));
            }),
          );
        }
      }),
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    form.resetFields();
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  const disableButtonAfterClick = () => {
    setButtonIsDisabled(true);
    setTimeout(function () {
      setButtonIsDisabled(false);
    }, 2000);
  };

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card title="New User" bordered={false}>
            <SpinLoader showContent={true}>
              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
                layout="vertical"
                autoComplete="off"
                className="common-form-container"
                validateTrigger="onSubmit"
              >
                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="First Name"
                      name="firstName"
                      normalize={(value) => value.trimStart()}
                      rules={[
                        { validator: validateFirstNameRules, required: true },
                      ]}
                    >
                      <Input prefix={<i className="ni ni-circle-08" />} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      normalize={(value) => value.trimStart()}
                      rules={[
                        { validator: validateLastNameRules, required: true },
                      ]}
                    >
                      <Input prefix={<i className="ni ni-circle-08" />} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={(0, 30)}>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[required("Email id"), email]}
                    >
                      <Input prefix={<i className="ni ni-email-83" />} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Phone"
                      name="phone"
                      rules={[validatePhone]}
                      onChange={(e) => {
                        form.setFieldsValue({
                          phone: stripLetters(e.target.value),
                        });
                      }}
                    >
                      <Input maxLength={10} prefix={<MobileFilled />} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <div className="role-block">
                  <h3 className="text-muted">Role</h3>
                  <Form.Item name="role">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio
                          value={ROLE_CONSTANT.ADMIN}
                          className="radio-role"
                        >
                          <small>ADMIN</small>
                          <p>{langs.role_description_admin}</p>
                        </Radio>
                        <Radio
                          value={ROLE_CONSTANT.SUPER_ADMIN}
                          className="radio-role"
                        >
                          <small>SUPER ADMIN</small>
                          <p>{langs.role_description_superadmin}</p>
                        </Radio>
                        {currentRole === ROLE_CONSTANT.ZENDA_ADMIN && (
                          <Radio
                            value={ROLE_CONSTANT.ZENDA_ADMIN}
                            className="radio-role"
                          >
                            <small>INTERNAL ADMIN</small>
                            <p>{langs.role_description_zendaadmin}</p>
                          </Radio>
                        )}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <Row gutter={(0, 30)}>
                  <div className="btn-block text-center w-100">
                    <Form.Item shouldUpdate>
                      {({ getFieldsValue }) => {
                        const { email, role, firstName, lastName } =
                          getFieldsValue();

                        const formIsChanged =
                          !!email || !!role || !!firstName || !!lastName;
                        const formIsComplete =
                          !!email && !!role && !!firstName && !!lastName;

                        return (
                          <>
                            <Button
                              type="primary"
                              htmlType="button"
                              className="button-secondary mr-10"
                              onClick={handleCancel}
                              disabled={!formIsChanged || buttonIsDisabled}
                            >
                              Discard Changes
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="button-primary"
                              disabled={!formIsComplete || buttonIsDisabled}
                            >
                              Save & Invite
                            </Button>
                          </>
                        );
                      }}
                    </Form.Item>
                  </div>
                </Row>
              </Form>
            </SpinLoader>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { AddNewUser };
