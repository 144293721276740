export const mandatory = ({ message }) => ({
  required: true,
  message,
});

export const whiteSpace = ({ message }) => ({
  whitespace: true,
  message
})

export const validEmail = ({ message }) => ({
  type: 'email',
  message
})


export const validateMinContributionFSA = (form) => {
  const { fsa_employee_maximum_contribution } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) < 0) {
        return Promise.reject('Minimum contribution must be above 0');
      } else if (Number(value) >= Number(fsa_employee_maximum_contribution)) {
        return Promise.reject('Minimum contribution must be less than the maximum contribution');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMaxContributionFSA = (form) => {
  const { fsa_employee_minimum_contribution } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) <= 0) {
        return Promise.reject('Maximum contribution must be above 0');
      } else if (Number(value) <= fsa_employee_minimum_contribution) {
        return Promise.reject('Maximum contribution must be greater than the minimum contribution');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMinContributionLFSA = (form) => {
  const { lfsa_employee_maximum_contribution } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (value && Number(value) < 0) {
        return Promise.reject('Minimum contribution must be above 0');
      } else if (Number(value) >= Number(lfsa_employee_maximum_contribution)) {
        return Promise.reject('Minimum contribution must be less than the maximum contribution');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMinRolloverFSA = (form) => {
  const { fsa_maximum_rollover } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) <= 0) {
        return Promise.reject('Minimum rollover  must be above 0');
      } else if (Number(value) >= Number(fsa_maximum_rollover)) {
        return Promise.reject('Minimum rollover must be less than the maximum rollover');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMaxRolloverFSA = (form) => {
  const { fsa_minimum_rollover } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) <= 0) {
        return Promise.reject('Maximum rollover must be above 0');
      } else if (Number(value) <= Number(fsa_minimum_rollover)) {
        return Promise.reject('Maximum rollover must be greater than the minimum rollover');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMinRolloverLFSA = (form) => {
  const { lfsa_maximum_rollover } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) <= 0) {
        return Promise.reject('Minimum rollover  must be above 0');
      } else if (Number(value) >= Number(lfsa_maximum_rollover)) {
        return Promise.reject('Minimum rollover must be less than the maximum rollover');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMaxRolloverLFSA = (form) => {
  const { lfsa_minimum_rollover } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) <= 0) {
        return Promise.reject('Maximum rollover must be above 0');
      } else if (Number(value) <= Number(lfsa_minimum_rollover)) {
        return Promise.reject('Maximum rollover must be greater than the minimum rollover');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMaxContributionLFSA = (form) => {
  const { lfsa_employee_minimum_contribution } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) <= 0) {
        return Promise.reject('Maximum contribution must be above 0');
      } else if (value <= Number(lfsa_employee_minimum_contribution)) {
        return Promise.reject('Maximum contribution must be greater than the minimum contribution');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMinContributionDCFSA = (form) => {
  const { dcfsa_employee_maximum_contribution } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) < 0) {
        return Promise.reject('Minimum contribution must be above 0');
      } else if (Number(value) >= Number(dcfsa_employee_maximum_contribution)) {
        return Promise.reject('Minimum contribution must be less than the maximum contribution');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMaxContributionDCFSA = (form) => {
  const { dcfsa_employee_minimum_contribution } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) <= 0) {
        return Promise.reject('Maximum contribution must be above 0');
      } else if (value <= Number(dcfsa_employee_minimum_contribution)) {
        return Promise.reject('Maximum contribution must be greater than the minimum contribution');
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateMinLength = (number) => {
  return {
    validator(_, value) {
      if (value < 0) {
        return Promise.reject("Run out period cannot be less than 0.");
      }
      return Promise.resolve('valid');
    },
  };
};

/** Field max length validation */
export const validateMaxLength = (number) => {
  return {
    validator(_, value) {
      if (value > 0 && value > 365) {
        return Promise.reject("Run out period cannot be greater than 365.");
      }
      return Promise.resolve('valid');
    },
  };
};

export const validateEmployerTaxID = (rule, value, callback) => {
  const empIdRegex = /^[0-9]+$/;
  if (!value) {
    callback();
  }
  else if (!empIdRegex.test(value)) {
    callback("Please enter valid employer tax ID")
  }
  else if (value.length > 0 && value.length < 9) {
    callback(`Employer Tax ID cannot be less than 9 digits.`);
  } else if (value.length > 9) {
    callback("Employer Tax ID cannot be more than 9 digits.")
  }
  else {
    callback();
  }
}

export const employerContributionAmount = (rule, value, callback) => {
  if (Number(value) < 0) {
    callback("Contribution amount must be above 0")
  }
  callback()
}

export const validatePhoneNumber = (rule, value, callback) => {
  const phoneNumberRegex = /^[0-9]{10}$/;
  if (!value) {
    callback();
  } else if (!phoneNumberRegex.test(value)) {
    callback("Please enter a valid 10-digit phone number")
  }
  else {
    callback();
  }
};

export const validateAccountNumber = (rule, value, callback) => {

  if (value) {
    if (value.length < 8 || value.length > 12) {
      callback('Account number must be between 8 and 12 digits.');
    }
  }
  callback()
};

export const validateRoutingNumber = (rule, value, callback) => {

  if (value) {
    if (value.length > 9) {
      callback('Routing number cannot be more than 9 digits.');
    }
    if (value.length > 0 && value.length < 9) {
      callback('Routing number must be atleast 9 digits.');
    }
  }
  callback()
};

export function validateZipCode() {
  return {
    validator(_, value) {
      const zipCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
      if (value) {
        if (!zipCodeRegex.test(value)) {
          return Promise.reject("Please enter a valid zip code.");
        }
      }
      return Promise.resolve('valid');
    },
  };
}

export function validateFullName() {
  return {
    validator(_, value) {
      const zipCodeRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;
      if (value === undefined || value.length <= 0)
        return Promise.reject("Name is required.");
      if (!zipCodeRegex.test(value)) {
        return Promise.reject("Please enter a valid full name.");
      }
      return Promise.resolve('valid');
    },
  };
}

export function validateCopaysAmount() {
  return {
    validator(_, value) {
      if (value <= 0) {
        return Promise.reject("Copays amount should be greater than 0");
      }
      return Promise.resolve('valid');
    }
  }
}

export function validateBankDetailNumbers(field) {
  return {
    validator(_, value) {
      if (value === undefined || value.length <= 0)
        return Promise.reject(`${field} is required.`);
      if (value.length > 0 && value.length < 9)
        return Promise.reject(`${field} cannot be less than 8 digits.`);
      if (value.length > 12)
        return Promise.reject(`${field} cannot be greater than 12 digits.`);
      return Promise.resolve('valid');
    }
  }
}

export const validateMaxIRS = (form, field, limit) => {
  const {  [field]: employeeMaximumContribution,  } = form.getFieldsValue();
  return {
    validator(_, value) {
      if (Number(value) < 0) {
        return Promise.reject("Contribution amount must be above 0")
      }
      if ((Number(value) + Number(employeeMaximumContribution)) > Number(limit)) {
        return Promise.reject(`Maximum IRS contribution exceeded. The sum of employee and employer contribution amount must be below or equal to ${limit}`);
      }
      return Promise.resolve('valid');
    },
  };
};