import axios from "./axiosInterceptors";
import { formatDate } from "../utils";
import { ADMIN_STATUSES, ROLE_CONSTANT } from "../utils/constants";

export const getLoggedInAdminDataService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/admin/me",
    };

    const response = await axios(request);

    return { error: false, data: response.data.body };
  } catch (error) {
    throw error;
  }
};

export const getUserDetailsService = async (email) => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/admin/detail",
      params: { admin_email_id: email },
    };

    const response = await axios(request);

    const returnData = { error: false, admin: response.data.body.data[0] };
    const status = returnData.admin.status;

    returnData.admin.first_name = {
      value: returnData.admin.first_name,
      canEdit: !(status === ADMIN_STATUSES.DISABLE),
    };
    returnData.admin.last_name = {
      value: returnData.admin.last_name,
      canEdit: !(status === ADMIN_STATUSES.DISABLE),
    };
    returnData.admin.email = {
      value: returnData.admin.admin_email_id,
      canEdit: !(
        status === ADMIN_STATUSES.ENABLE || status === ADMIN_STATUSES.DISABLE
      ),
    };
    returnData.admin.phone = {
      value: returnData.admin.mobile_phone_num,
      canEdit: false,
    };
    returnData.admin.role = {
      value: returnData.admin.role,
      canEdit: !(status === ADMIN_STATUSES.DISABLE),
    };
    returnData.admin.status = {
      value: returnData.admin.status,
      canEdit: !(status === ADMIN_STATUSES.INVITED),
    };

    return returnData;
  } catch (error) {
    throw error;
  }
};

export const getAdminDetailsService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/admin/profile",
    };

    const response = await axios(request);

    const returnData = { error: false, admin: response.data.body.data[0] };

    returnData.admin.first_name = {
      value: returnData.admin.first_name,
      canEdit: false,
    };
    returnData.admin.last_name = {
      value: returnData.admin.last_name,
      canEdit: false,
    };
    returnData.admin.email = {
      value: returnData.admin.admin_email_id,
      canEdit: false,
    };
    returnData.admin.phone = {
      value: returnData.admin.mobile_phone_num,
      canEdit: false,
    };
    returnData.admin.status = {
      value: returnData.admin.status,
      canEdit: false,
    };
    returnData.admin.role = {
      value: returnData.admin.role,
      canEdit: false,
    };

    return returnData;
  } catch (error) {
    throw error;
  }
};

export const getUsersListService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/admin/summary",
    };

    const response = await axios(request);

    const returnData = { error: false, admins: response.data.body.data };
    for (let i = 0; i < returnData.admins.length; i++) {
      const { first_name, last_name } = returnData.admins[i];
      returnData.admins[i].role = returnData.admins[i].role.replace("_", " ");
      returnData.admins[i].email = returnData.admins[i].admin_email_id;
      let _first_name = first_name === "UNKNOWN" ? "" : first_name;
      let _last_name = last_name === "UNKNOWN" ? "" : " " + last_name;
      returnData.admins[i] = {
        ...returnData.admins[i],
        name: _first_name + _last_name,
        id: i,
      };
    }
    return returnData;
  } catch (error) {
    throw error;
  }
};

export const validateAdminService = async (email) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/is_valid/admin",
      data: { employer_admin: [{ email }] },
    };

    const response = await axios(request);

    return response;
  } catch (error) {
    throw error;
  }
};

export const inviteAdminService = async (params) => {
  const { adminEmailId, role, firstName, lastName, phone } = params;
  let roleID;

  switch (role) {
    case ROLE_CONSTANT.ZENDA_ADMIN:
      roleID = 3;
      break;
    case ROLE_CONSTANT.SUPER_ADMIN:
      roleID = 2;
      break;
    case ROLE_CONSTANT.ADMIN:
      roleID = 1;
      break;
    default:
      console.log("Error in selecting role id.");
  }

  const request = {
    method: "post",
    url: "/employer/payroll/register/admin",
    data: {
      payload: [
        {
          first_name: firstName,
          last_name: lastName,
          mobile_phone_num: phone,
          admin_email_id: adminEmailId,
          role_id: roleID,
          sent_at: formatDate(),
        },
      ],
    },
  };
  const response = await axios(request);

  return response;
};

export const updateAdminService = async (reqData) => {
  try {
    const request = {
      method: "patch",
      url: "/employer/payroll/admin/detail",
      data: {
        payload: {
          ...reqData,
          sent_at: formatDate(),
        },
      },
    };

    const response = await axios(request);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAdminService = async (emailId) => {
  try {
    const request = {
      method: "delete",
      url: `/employer/payroll/admin/${emailId}`,
    };

    const response = await axios(request);

    return response.data;
  } catch (error) {
    throw error;
  }
};
