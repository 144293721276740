import React, { useEffect, useState } from "react";
import { useRouter } from "../../../utils/router/customRouterHook";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { Col, Empty, Row, Table } from "antd";
import { useDispatch } from "react-redux";
import {
  findEmployees,
  switchEmployerTypeData,
  getCurrentEmployer,
} from "../../../redux/actions";

function SearchResults({ data }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [empList, setEmpList] = useState([]);
  const [queryOn, setQueryOn] = useState(false);
  const [numResults, setNumResults] = useState(0);

  const queryParameters = new URLSearchParams(window.location.search);
  const keyword = queryParameters.get("query");

  useEffect(() => {
    if (keyword) {
      dispatch(
        findEmployees(keyword, (res) => {
          if (+res.status === 200) {
            const numResults = res.data.body.data.length;
            setNumResults(numResults);
            setQueryOn(true);
            if (numResults > 0) {
              setEmpList(res.data.body.data);
            } else {
              setEmpList([]);
            }
          } else {
            setNumResults(0);
          }
        }),
      );
    }else {
      setEmpList([]);
      setNumResults(0);
      setQueryOn(false)
    }
    // eslint-disable-next-line
  }, [dispatch, keyword]);

  const switchEmployerAndRedirect = async (record) => {

    await dispatch(
      switchEmployerTypeData({ employer_id: record.employer_id }, (res) => {
        dispatch(getCurrentEmployer());
        router.push(`/admin/employee-detail/${record.employee_id}`);
      }),
    );
  };

  const onEmployeeClick = (record) => {
    switchEmployerAndRedirect(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Employer",
      dataIndex: "employer_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.employer_name.localeCompare(b.employer_name),
    },
    {
      title: "Work email",
      dataIndex: "work_email",
      sorter: (a, b) => a.employer_name.localeCompare(b.work_email),
    },
    {
      title: "Login email",
      dataIndex: "personal_email",
      sorter: (a, b) => a.employer_name.localeCompare(b.personal_email),
    },
    {
      title: "Account holder status",
      dataIndex: "account_holder_status",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.account_holder_status.localeCompare(b.account_holder_status),
    },
    // {
    //   title: "Date of Birth",
    //   dataIndex: "date_of_birth",
    //   sorter: (a, b) => a.employer_name.localeCompare(b.date_of_birth),
    // },
    {
      title: "Employee ID",
      key: "tags",
      dataIndex: "employee_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.employee_id.localeCompare(b.employee_id),
    },
  ];

  if (!empList) {
    return (
      <div className="text-center">
        <Row gutter={0}>
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      </div>
    );
  } else {
    if (queryOn && empList) {
      return (
        <SpinLoader showContent={!!empList}>
          <div className="">
            <Row gutter={0}>
              <Col span={24}>
                <div className="text-left ml-10 mb-10">
                  <span> {numResults} Matches Found</span>
                </div>
                <Table
                  columns={columns}
                  dataSource={empList}
                  bordered
                  scroll={empList?.length ? { x: 1200 } : undefined}
                  rowClassName={"pointer"}
                  onRow={(record, rowIndex) => ({
                    onClick: (e) => {
                      onEmployeeClick(record);
                    },
                  })}
                />
              </Col>
            </Row>
          </div>
        </SpinLoader>
      );
    } else {
      return "";
    }
  }
}

export { SearchResults };
