import axios from "./axiosInterceptors";

export const getBankByDivisionService = async (divId) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/bank-account/${divId}`,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data.bank_account };
  } catch (error) {
    throw error;
  }
};

export const updateBankDivisionService = async (reqData) => {
  let data = {
    account_number: reqData.account_number,
    routing_number: reqData.routing_number,
    name_on_account: reqData.name_on_account,
    sent_at: String(Date.now()),
  };

  try {
    const request = {
      method: "put",
      url: `/employer/payroll/bank-account/${reqData.division_id}`,
      data,
    };

    const response = await axios(request);

    return { error: false, data: response.data };
  } catch (error) {
    throw error;
  }
};
