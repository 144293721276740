export const EMPLOYEE_STATUSES = {
  REGISTERED: "Registered",
  ENROLLED: "Enrolled",
  ACTIVATED: "Activated",
  SUSPENDED: "Suspended",
  CLOSED: "Closed",
};
export const EMPLOYER_STATUSES = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  INPROGRESS: "inprogress",
};

export const KYC_STATUSES = {
  "NA": "na",
  "Success": "success",
  "Failed": "failed"
}
export const ADMIN_STATUSES = {
  INVITED: "Invited",
  ENABLE: "Enable",
  DISABLE: "Disable",
};

export const ADMIN_ACTIONS = {
  RE_INVITE_EMAIL: "re-invite-email",
  UPDATE_EMAIL: "update-email",
};
