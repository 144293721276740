import { LOADER_CONSTANT } from "../../utils/constants";

const initialState = { globalLoading: false, loading: false, dataLoading: [] };

export function loader(state = initialState, action) {
  switch (action.type) {
    case LOADER_CONSTANT.ENABLE_LOADER:
      return { ...state, loading: true };
    case LOADER_CONSTANT.DISABLE_LOADER:
      return { ...state, loading: false };
    case LOADER_CONSTANT.ENABLE_GLOBAL_LOADER:
      return { ...state, globalLoading: true };
    case LOADER_CONSTANT.DISABLE_GLOBAL_LOADER:
      return { ...state, globalLoading: false };
    case LOADER_CONSTANT.ENABLE_DATA_LOADER:
      if (!state.dataLoading) return { ...state, dataLoading: [] };

      const process = !state.dataLoading.includes(action.payload)
        ? action.payload
        : null;

      return {
        ...state,
        dataLoading: process
          ? [...state.dataLoading, process]
          : [...state.dataLoading],
      };
    case LOADER_CONSTANT.DISABLE_DATA_LOADER:
      if (!state.dataLoading) return { ...state, dataLoading: [] };

      return {
        ...state,
        dataLoading: state.dataLoading.filter(
          (process) => process !== action.payload,
        ),
      };
    default:
      return state;
  }
}
