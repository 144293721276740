import { Form, Input } from "antd";

const { TextArea: AntTextArea } = Input;

export const TextArea = ({
  name,
  label,
  rules,
  placeholder,
  supportiveText,
  disabled,
  rows = 2,
  maxLength = 64,
  showHelpText = undefined,
}) => (
  <Form.Item
    name={name}
    label={label}
    help={showHelpText && (supportiveText || `Max ${maxLength} characters`)}
    rules={rules}
    className={`text-area-wrapper`}
  >
    <AntTextArea
      autoComplete="off"
      placeholder={placeholder}
      disabled={disabled}
      rows={rows}
      maxLength={maxLength}
    />
  </Form.Item>
);
