export const validateApprovedAmount = (amount) => {
    return {
        validator(_, value) {
            if (Number(value) <= 0) {
                return Promise.reject('Approved amount must be above 0');
            } else if (Number(value) >= Number(amount)) {
                return Promise.reject('Approved amount must be less than the total expense amount');
            } 
            return Promise.resolve('valid');
        },
    };
};