import { Modal } from "antd";
import React from "react";
import { Close, CloseIcon, Typography } from "../../../../design-systems";
import { titleWidthMap } from "../../../../utils";

export const ExpandedModalView = (
    {
        open,
        setOpen,
        renderJSX,
        renderFilters,
        title,
        afterClose
    }
) => {
    return (
        <Modal
            afterClose={afterClose}
            closeIcon={<CloseIcon />}
            visible={open}
            closable={false}
            title={
                <div className="d-flex justify-space-between">
                    <Typography
                        variant="subhead-2"
                        label={title}
                    />
                    <div className="d-flex justify-space-between align-items-center">
                        {title === "Transactions" &&
                            <div>{renderFilters()}</div>
                        }
                        <div className="cancel-modal-header justify-flex-end pointer d-flex">
                            <Close height={16} width={16} onClick={() => setOpen(false)} />
                        </div>
                    </div>
                </div>
            }
            width={titleWidthMap[title]}
            footer=""
            className="plan-information-modal-wrapper"
        >
            {renderJSX()}
        </Modal>
    )
}