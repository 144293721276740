import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HeaderWithContent } from "../../../common/HeaderWithContent";
import { Card, Empty } from "antd";
import { Tabs, Typography } from "../../../../design-systems";
import { BusinessInfo } from "./BusinessInfo";
import { BankInfo } from "./BankInfo";
import { AdminUsers } from "./AdminUsers";
import { PlanInfo } from "./PlanInfo";
import { getCurrentEmployer, getCurrentEmployerDetailsByTab, getEmployeeRunoutOptions } from "../../../../redux/actions";
import { EMPLOYER_CONSTANT, ROLE_CONSTANT } from "../../../../utils/constants";
import { useMemo } from "react";
import useShowSecondSection from "../../../../hooks/useShowSection";
import { useRouter } from "../../../../utils/router";

export const ViewEmployer = () => {
  const router = useRouter()
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState(router?.history?.location?.state?.showPlanInfoTab ? "plan-info" : "biz-info")
  const { currentEmployer, currentEmployerDetails, runOutOptions } = useSelector(
    (state) => state.employer,
  );
  useShowSecondSection();
  const role = useSelector((state) => state.userDetails.roleType)

  useEffect(() => {
    dispatch(getCurrentEmployer());
    dispatch(getCurrentEmployerDetailsByTab(tabKey))
    return () => {
      dispatch({
        type: EMPLOYER_CONSTANT.RESET_CURRENT_EMPLOYER_DATA
      })
    }
  }, []);

  useEffect(() => {
    if (tabKey === "admin-users-info" || tabKey === "plan-info") {
      dispatch(getCurrentEmployerDetailsByTab(tabKey))
    } else {
      if (currentEmployerDetails && !Object.keys(currentEmployerDetails[tabKey]).length) {
        dispatch(getCurrentEmployerDetailsByTab(tabKey))
      }
    }
  }, [tabKey, dispatch]);

  useEffect(() => {
    if (router?.history?.location?.state?.showPlanInfoTab) {
      setTabKey("plan-info");
    } else {
      setTabKey("biz-info");
    }
  }, [router.location, router.history, dispatch]);

  const onChange = (key) => {
    setTabKey(key)
  };

  useEffect(() => {
    if (!runOutOptions.length) {
      dispatch(getEmployeeRunoutOptions())
    }
  }, [runOutOptions, dispatch])

  const isAdminRole = useMemo(() => {
    return (role === ROLE_CONSTANT.ADMIN);
  }, [role])

  const tabItems = useMemo(() => {
    return [
      {
        key: "biz-info",
        title: (
          <Typography
            variant={"body-2"}
            label={"Business information"}
          />
        ),
        content:
          <BusinessInfo data={currentEmployerDetails[tabKey]} />
      },
      {
        key: "plan-info",
        title: (
          <Typography
            variant={"body-2"}
            label={"Plan information"}
          />
        ),
        content:
          <PlanInfo data={currentEmployerDetails[tabKey]} />
      },
      {
        key: "bank-info",
        title: (
          <Typography
            variant={"body-2"}
            label={"Bank information"}
          />
        ),
        content:
          <BankInfo data={currentEmployerDetails[tabKey]} />
      },
      isAdminRole ?
        null :
        {
          key: "admin-users-info",
          title: (
            <Typography
              variant={"body-2"}
              label={"Administrative users"}
            />
          ),
          content:
            <AdminUsers data={currentEmployerDetails[tabKey]} tabKey={tabKey} />
        },
    ]
  }, [currentEmployerDetails[tabKey], tabKey])

  return (
    <>
      <HeaderWithContent>
        <Card
          title={""}
          bordered={false}
          className="data-grid employee-details-wrapper"
        >
          <Typography
            label={currentEmployer?.data?.employer_name}
            variant="subhead-1"
            className="employee-listing-title "
          />
          <div className="employer-detail-wrapper">
            <div>
              <Tabs
                activeKey={tabKey}
                destroyInactiveTabPane={true}
                onTabChange={onChange}
                tabs={tabItems}
              />
            </div>
          </div>
        </Card>
      </HeaderWithContent>
    </>
  );
};
