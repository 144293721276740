export const ZoomIn = (props) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.80208 12.6667C10.7476 12.6667 13.1354 10.2789 13.1354 7.33333C13.1354 4.38781 10.7476 2 7.80208 2C4.85656 2 2.46875 4.38781 2.46875 7.33333C2.46875 10.2789 4.85656 12.6667 7.80208 12.6667Z"
      stroke="#989FAE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.4679 13.9996L11.5679 11.0996"
      stroke="#989FAE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.80225 5.33398V9.33398"
      stroke="#989FAE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.80225 7.33398H9.80225"
      stroke="#989FAE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
