import { Card, Col, Divider, Form, Row } from "antd";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { useForm } from "antd/lib/form/Form";
import { clearFieldErrors, renderSteps, steps } from "../../../utils";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CancelModal,
  Footer,
  Header,
  SuccessModal
} from "../../../components/admin/employer";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../utils/router";
import { formatDataForStep } from "../../../utils";
import { addNewEmployer, deleteEmployerProgressData, getEmployeeCopaysCategories, getEmployerDetailsById, resetEmployerAddData, resetEmployerApiSuccess, saveCopaysData, saveUpdateEmployerOnBoardingInfo, showAdditionalContactForm } from "../../../redux/actions";
import moment from "moment";

const Add = () => {
  const [form] = useForm();
  const router = useRouter();
  const formRef = useRef();
  const dispatch = useDispatch();
  const {
    employerId,
    success,
    operationFlag,
    currentEmployerData,
    employerRouteInfo,
    copaysCategories
  } = useSelector((state) => state.employer);

  const currentStep = +router.match.params.step;
  const [mode, setMode] = useState("");
  const [formData, setFormData] = useState({})
  const [copaysCate, setCopaysCate] = useState([])
  const [employerSteps, setEmployerSteps] = useState(steps);
  const [cancelModalOpened, setCancelModalOpened] = useState(false);
  const initialValues = {};
  const [successModalOpened, setSuccessModalOpened] = useState(false);

  const getCurrentStepData = () => {
    if (!currentEmployerData?.length) return {};
    const currentStepData = currentEmployerData?.find(d => d?.step_id === currentStep);
    if (currentStepData) return currentStepData?.data
    return {}
  }

  const prefillFormData = useCallback(() => {
    const data = getCurrentStepData();

    if (currentStep === 1) {
      const {
        business_details,
        corporate_address,
        divisions,
        mailing_address,
        same_as_corporate_address
      } = data || {};
      const isDefaultDivision = !divisions?.length || (divisions?.length === 1 && divisions[0].identifier === "default");

      form.setFieldsValue({
        business_name: business_details?.business_name,
        employer_tax_id: business_details?.employer_tax_id,
        primary_employee_identifier: business_details?.primary_employee_identifier,
        corporate_address_line_1: corporate_address?.address_line_1,
        corporate_address_line_2: corporate_address?.address_line_2,
        corporate_city: corporate_address?.city,
        corporate_state: corporate_address?.state,
        corporate_zip_code: corporate_address?.postal_code,
        same_as_corporate: same_as_corporate_address,
        mailing_address_line_1: same_as_corporate_address ? corporate_address?.address_line_1 : mailing_address?.address_line_1,
        mailing_address_line_2: same_as_corporate_address ? corporate_address?.address_line_2 : mailing_address?.address_line_2,
        mailing_city: same_as_corporate_address ? corporate_address?.city : mailing_address?.city,
        mailing_state: same_as_corporate_address ? corporate_address?.state : mailing_address?.state,
        mailing_zip_code: same_as_corporate_address ? corporate_address?.postal_code : mailing_address?.postal_code,
        add_division: !isDefaultDivision,
        divisions: isDefaultDivision ? [] : divisions.map((div, index) => ({
          division_identifier: div.identifier,
          description: div?.description,
          key: index
        }))
      });
    } else if (currentStep === 2) {
      const { primary_contact, additional_contact, additional_contact_exist } = data || {};
      let formData = {
        email: primary_contact?.email,
        full_name: primary_contact?.full_name,
        job_title: primary_contact?.job_title,
        phone_number: primary_contact?.phone_no,
        authorized_for_plan_document: primary_contact?.is_document_placeholder,
        is_super_admin: primary_contact?.is_super_admin
      }
      if (additional_contact_exist && additional_contact && Object.keys(additional_contact)?.length > 0) {
        dispatch(showAdditionalContactForm(true))
        formData.additional_contact_exist = true
        formData.additional_full_name = additional_contact?.full_name
        formData.additional_job_title = additional_contact?.job_title
        formData.additional_phone_number = additional_contact?.phone_no
        formData.additional_email = additional_contact?.email
      } else {
        formData.additional_contact_exist = false
      }
      form.setFieldsValue(formData);
    } else if (currentStep === 3) {
      const { plan_year, selected_plans } = data || {};
      let formData = {
        plans: selected_plans,
        select_all_plan: selected_plans?.length === 4,
        start_date: plan_year?.start_date && moment(plan_year?.start_date)?.toDate(),
        end_date1: plan_year?.end_date && moment(plan_year?.end_date)?.toDate()
      }
      data?.selected_plans?.forEach(plan => {
        const planKey = plan.toLowerCase();
        const planData = data[planKey];
        if (data[planKey]) {
          formData[`${planKey}_employee_minimum_contribution`] = planData.min_employee_contribution_amount;
          formData[`${planKey}_employee_maximum_contribution`] = planData.max_employee_contribution_amount;
          formData[`${planKey}_employer_contribution_amount`] = planData.employer_contribution_amount;
          formData[`${planKey}_run_out_period`] = planData.run_out_period_in_days;
          formData[`${planKey}_allow_claim_file_post_termination`] = planData.allow_claim_file_post_termination_within_service_date;
          formData[`${planKey}_terminated_employees_run_out`] = planData.terminated_employees_run_out;
          formData[`${planKey}_is_grace_period_enabled`] = planData.is_grace_period_enabled ? "yes" : "no";
          formData[`${planKey}_is_rollover_enabled`] = planData.is_rollover_enabled;
          formData[`${planKey}_maximum_rollover`] = planData.max_rollover_amount;
          formData[`${planKey}_minimum_rollover`] = planData.min_rollover_amount;
          formData[`${planKey}_grace_period_end_date`] = planData?.grace_period_end_date;

          if (planKey === "dcfsa") {
            formData['dcfsa_grace_period'] = data['dcfsa']?.is_grace_period_enabled ? "yes" : "no";
          }
        }
        if (planData?.is_grace_period_enabled) {
          setFormData((prevData) => ({
            ...prevData,
            [`${planKey}_grace_period_end_date`]: planData?.grace_period_end_date ? moment(planData?.grace_period_end_date)?.toDate() : null
          }));
        }
        if (['fsa', 'lfsa']?.includes(planKey) && planData.is_rollover_enabled) {
          formData[`${planKey}_allow_rollover_not_elected_for_following_plan_year`] = planData.rollover_if_no_fsa_next_year ? "yes" : "no";
        }
        if (['dcfsa']?.includes(planKey)) {
          formData['dcfsa_allow_claims'] = planData?.allow_claim_file_post_termination_within_service_date ? 'yes' : 'no'
        }
        if (planData?.is_rollover_enabled) {
          formData[`${planKey}_rollover_or_grace_period`] = "rollover";
        } else if (planData?.is_grace_period_enabled) {
          formData[`${planKey}_rollover_or_grace_period`] = "grace-period";
        } else {
          formData[`${planKey}_rollover_or_grace_period`] = "none";
        }
      });
      form.setFieldsValue(formData)
      setFormData(((prevData) => ({
        ...prevData,
        start_date: plan_year?.start_date ? moment(plan_year?.start_date)?.toDate() : "",
        end_date1: plan_year?.end_date ? moment(plan_year?.end_date)?.toDate() : ""
      })))
    } else if (currentStep === 4) {
      const {
        copay_configuration
      } = data;

      setCopaysCate(copay_configuration?.map((copay, index) => ({
        category: copay.category_id,
        copay_amount: Number(copay.copay_amount),
        key: index
      })))
      form.setFieldsValue({
        add_copay: copay_configuration?.length > 0,
        copays: getCopayValues(copay_configuration) ?? []
      })
      dispatch(saveCopaysData(copay_configuration?.map((copay, index) => ({
        category: copay.category_id,
        copay_amount: copay.copay_amount,
        key: index
      }))))
    }
  }, [currentStep, form, currentEmployerData, currentEmployerData?.length]);

  const getCopayValues = (copay_configuration) => {
    return copay_configuration?.map((data, index) => ({
      category: copaysCategories?.find(cate => cate.value === data.category_id)?.label,
      copay_amount: data?.copay_amount,
      key: index
    }))
  }

  useEffect(() => {
    if (employerRouteInfo?.editDraft && employerRouteInfo?.employer_id) {
      dispatch(getEmployerDetailsById(employerRouteInfo?.employer_id));
      dispatch(getEmployeeCopaysCategories(form))
    }
    return () => {
      dispatch(resetEmployerAddData())
      dispatch(resetEmployerApiSuccess())
    }
  }, [])

  useEffect(() => {
    setMode(employerRouteInfo && Object.keys(employerRouteInfo)?.length > 0 ? "edit" : "add")
  }, [employerRouteInfo])

  useEffect(() => {
    if (currentEmployerData?.length > 0 && employerRouteInfo && Object.keys(employerRouteInfo)?.length > 0) {
      prefillFormData();
    }
  }, [employerRouteInfo, currentEmployerData, currentEmployerData?.length, currentStep]);

  useEffect(() => {
    if (currentStep === 1) {
      form.setFieldsValue({
        same_as_corporate: true,
        authorized_for_plan_document: true,
        is_super_admin: true,
        processor: "Galileo",
        fsa_employee_contribution_amount: 0,
        lfsa_employee_contribution_amount: 0,
        dcfsa_employee_contribution_amount: 0,
      })
    }
  }, [router])

  useEffect(() => {
    if (currentStep > 0 && currentStep <= employerSteps.length) {
      setEmployerSteps((prev) => [
        ...prev.map((step, index) => {
          const currentIndex = currentStep - 1;
          const isActive = currentIndex === index;
          const isComplete = currentIndex > index;

          return { ...step, isActive, isComplete };
        }),
      ]);
    } else {
      router.push("/employer");
    }
  }, [currentStep, employerSteps.length, router]);
  const isContinueOnboardingFlow = employerRouteInfo && Object.keys(employerRouteInfo)?.length > 0
  const onFinish = useCallback(
    async (values) => {
      let apiData = formatDataForStep(values, currentStep, form, isContinueOnboardingFlow, copaysCategories)
      if (employerRouteInfo && Object.keys(employerRouteInfo)?.length) {
        apiData.employer_id = employerRouteInfo?.employer_id;
        dispatch(saveUpdateEmployerOnBoardingInfo(apiData?.data, currentStep))
      } else {
        apiData.employer_id = currentStep === 1 && !employerId ? null : employerId
      }
      dispatch(addNewEmployer(apiData, 'saveForm', (res) => {
        if (res instanceof Error) {
          if (res?.response?.data?.error?.errored_fields?.length) {
            res?.response?.data?.error?.errored_fields.forEach(err => {
              let simplifiedFieldName = err?.field.replace(/^\./, "").replace(/\./g, "_").toLowerCase();
              let errorMessage = err?.message;
              // Handle specific cases for start_date and end_date
              if (simplifiedFieldName === 'plan_year_start_date') {
                simplifiedFieldName = 'start_date';
                errorMessage = "Please choose valid start date"
              } else if (simplifiedFieldName === 'plan_year_end_date') {
                simplifiedFieldName = 'end_date1';
                errorMessage = "Please choose valid end date"
              }

              form.setFields([
                {
                  name: simplifiedFieldName,
                  errors: [errorMessage],
                },
              ]);
            })
          }
        } else {
          if (employerRouteInfo && Object.keys(employerRouteInfo)?.length) {
            dispatch(getEmployerDetailsById(employerRouteInfo?.employer_id));
          }
        }
      }))
    },
    [currentStep, employerSteps.length, dispatch, router, success],
  );
  useEffect(() => {
    if (currentStep < employerSteps.length && success) {
      if (operationFlag === 'saveForm') {
        router.push(`/admin/employer/add/${currentStep + 1}`);
      }
    }
    if (success && currentStep === employerSteps.length) {
      setSuccessModalOpened(true)
    }
  }, [success]);
  const onFinishFailed = useCallback((errorInfo) => {
  }, []);

  const onValuesChange = useCallback(
    (_, allValues) => {
      clearFieldErrors(form, allValues);
    },
    [form],
  );

  const onCancel = useCallback(() => {
    setCancelModalOpened(true);
  }, []);

  const onCancelConfirm = useCallback(() => {
    router.push('/admin/employers')
  }, []);

  const onSaveProgress = useCallback(async () => {
    try {
      const values = await form.validateFields();
      let apiData = formatDataForStep(values, currentStep, form)
      apiData.employer_id = employerId
      apiData.is_save_progress = true
      dispatch(addNewEmployer(apiData, 'saveProgress'))
    } catch (err) {
    }
  }, [form, currentStep]);

  const onPrevious = useCallback(() => {
    if (true && currentStep > 1) {
      // if form field valid
      dispatch(resetEmployerApiSuccess())
      router.push(`/admin/employer/add/${currentStep - 1}`);
    }
  }, [currentStep, router]);

  const OnAddAnother = () => {
    form.resetFields();
    setSuccessModalOpened(false)
    dispatch(resetEmployerAddData());
    dispatch(deleteEmployerProgressData());
    setFormData({})
    router.push("/admin/employer/add/1");
  }

  const onSeeList = () => {
    form.resetFields();
    setSuccessModalOpened(false)
    setFormData({})
    router.push(`/admin/employers`)
  }

  return (
    <>
      <HeaderWithContent
        customFooter={
          <Footer
            onNext={() => {
              formRef?.current?.submit()
              dispatch(resetEmployerApiSuccess())
            }}
            onCancel={onCancel}
            onPrevious={onPrevious}
            isFirstStep={currentStep === 1}
            isLastStep={currentStep === employerSteps.length}
          />
        }
      >
        <Form
          name="login"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          layout="vertical"
          autoComplete="off"
          validateTrigger="onSubmit"
          ref={formRef}
        >
          <Row gutter={0}>
            <Col span={24}>
              <Card
                title={
                  <Header
                    steps={employerSteps}
                    currentStep={currentStep - 1}
                    nextStep={currentStep}
                    onSaveProgress={onSaveProgress}
                  />
                }
                bordered={false}
                className="add-employer-card"
              >
                <Divider />

                {renderSteps({ form, step: currentStep, formData, setFormData, copaysCate, setCopaysCate, mode })}
              </Card>
            </Col>
          </Row>
        </Form>
      </HeaderWithContent>
      <CancelModal
        open={cancelModalOpened}
        onCancel={() => setCancelModalOpened(false)}
        onOk={onCancelConfirm}
        page={"add-employer"}
      />

      <SuccessModal
        page="add-employer"
        form={form}
        onCancel={() => setSuccessModalOpened(false)}
        open={successModalOpened}
        onSecondaryActionClick={onSeeList}
        onPrimaryActionClick={OnAddAnother}
        primaryLabel="Add another"
        secondaryLabel="See employer list"
      />
    </>
  );
};

export default Add;
