import { Alert as AntAlert } from "antd";
import { AlertFilled, Close, Error, InfoFilled, Success } from "../Icons";

const renderIcon = ({ type }) => {
  switch (type) {
    case "success":
      return <Success />;
    case "info":
      return <InfoFilled />;
    case "warning":
      return <AlertFilled />;
    case "error":
      return <Error />;
    default:
      return "";
  }
};

export const Alert = ({
  message,
  type,
  showIcon,
  description,
  closable = true,
}) => (
  <AntAlert
    message={message}
    type={type}
    showIcon={showIcon}
    description={description}
    icon={renderIcon({ type })}
    className="alert-wrapper"
    closable={closable}
    closeIcon={<Close />}
  />
);
