import { ApiErrors } from "../../utils";
import { disableDataLoader, enableDataLoader } from "./loader.actions";
import {
  getAllAvailableReportsService,
  getContributionReportIDService,
  getContributionReportLinkService,
  getContributionReportsListService,
  getReportByTypeService,
  getReportsLinkService,
  getReportPlanFiltersService
} from "../../services";
import { REPORTS_CONSTANT } from "../../utils/constants";

export function getContributionReportsList(callback) {
  const processName = "getContributionReportsList";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getContributionReportsListService()
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getContributionReportID(contributionRunID, callback) {
  const processName = "getContributionReportID";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getContributionReportIDService(contributionRunID)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getContributionReportLink(reportId, callback) {
  const processName = "getContributionReportLink";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getContributionReportLinkService(reportId)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getReportsLink(reportId, params, callback) {
  const processName = "getReportsLink";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getReportsLinkService(reportId, params)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getAllAvailableReports(reportId, callback) {
  const processName = "getAllAvailableReports";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getAllAvailableReportsService(reportId)
      .then((res) => {
        callback && callback(res);
        dispatch({
          type: REPORTS_CONSTANT.GET_ALL_REPORTS,
          payload: res?.data
        })
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getReportByType(type, payload, callback) {
  const processName = "getReportByType";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getReportByTypeService(type, payload)
      .then((res) => {
        callback && callback(res);
        dispatch({
          type: REPORTS_CONSTANT.GET_REPORT_BY_TYPE,
          payload: res?.data
        })
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
  

}


export function getReportPlanFilters(callback) {
  const processName = "getReportPlanFilters";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getReportPlanFiltersService()
      .then((res) => {
        callback && callback(res);
        dispatch({
          type: REPORTS_CONSTANT.GET_REPORT_PLAN_FILTERS,
          payload: res?.data?.data
        })
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}