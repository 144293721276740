import React, { useState } from "react";

import { Card, Col, Empty, Row } from "antd";
import { ExpandIcon, Typography, Table } from "../../../../design-systems";
import { ExpandedModalView } from "./ExpandedViewModal";
import { hideBankingData } from "../../../../utils";

const renderExternalAccountDetails = (
  data,
  open,
  setOpen
) => {
  return (
    <Card bordered={false} className="account-holder-cards ">
      {data?.length > 0 ?
        <>
          <Typography
            variant="subhead-3"
            label="External account"
            className="account-holder-card-header mb-20"
          />
          <Row className="mb-20" gutter={[20, 10]}>
            <Col md={12}>
              <Typography
                variant=""
                label="Institution"
                className="account-holder-value"
              />
              <Typography
                variant=""
                label={data?.[0]?.institution_name ?? "-"}
                className="account-holder-card-values"
              />
            </Col>
            <Col md={12}>
              <Typography
                variant=""
                label="Account no."
                className="account-holder-value"
              />
              <Typography
                variant=""
                label={data?.[0]?.account_number ?? "-"}
                className="account-holder-card-values"
              />
            </Col>
            <Col md={12}>
              <Typography
                variant=""
                label="Routing no."
                className="account-holder-value"
              />
              <Typography
                variant=""
                label={data?.[0]?.routing_number ?? "-"}
                className="account-holder-card-values"
              />
            </Col>
            <Col md={12}>
              <Typography
                variant=""
                label="User name"
                className="account-holder-value"
              />
              <Typography
                variant=""
                label={data?.[0]?.plaid_name ?? "-"}
                className="account-holder-card-values"
              />
            </Col>
            <Col md={12}>
              <Typography
                variant=""
                label="Type"
                className="account-holder-value"
              />
              <Typography
                variant=""
                label={data?.[0]?.plaid_type ?? "-"}
                className="account-holder-card-values"
              />
            </Col>
            <Col md={12}>
              <Typography
                variant=""
                label="Subtype"
                className="account-holder-value"
              />
              <Typography
                variant=""
                label={data?.[0]?.plaid_sub_type ?? "-"}
                className="account-holder-card-values"
              />
            </Col>

          </Row>
        </> :
        <div>
          <Typography
            variant="subhead-3"
            label="External account"
            className="account-holder-card-header mb-20"
          />
          <Empty />
        </div>
      }
    </Card>
  )
}

const renderExternalAccountTable = (
  AccountColumns,
  data,
  setCurrentPage,
  totalCount,
  currentPage,
  limit
) => {
  return (
    <Card
      bordered={false}
    >
      <Table
        bordered
        columns={AccountColumns}
        dataSource={data}
        pagination={{
          onChange: (page) => setCurrentPage(page),
          total: totalCount,
          current: currentPage,
          defaultCurrentPage: 1,
          defaultPageSize: limit,
        }}
        scroll={data?.length ? { x: 600 } : undefined}
        className="compact-table activity-logs-table"
      />
    </Card>
  )
}

function ExternalAccountSection({ data }) {
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit] = useState(10)
  const [sortFields, setSortFields] = useState({
    sort: "claim_id",
    order: "desc"
  })

  const AccountColumns = [
    {
      title: "Institution name",
      dataIndex: "institution_name",
    },
    {
      title: "Account number",
      dataIndex: "account_number",
      render: (number) => hideBankingData(number)
    },
    {
      title: "Routing number",
      dataIndex: "routing_number",
      render: (number) => hideBankingData(number)
    },
    // {
    //   title: "Accounts linked",
    //   dataIndex: "links",
    //   render: (links) => links?.join(", ")
    // },
    {
      title: "Username",
      dataIndex: "plaid_name",
    },
    {
      title: "Type",
      dataIndex: "plaid_type",
    },
    {
      title: "Sub type",
      dataIndex: "plaid_sub_type",
    },
  ];

  return (
    <>
      {renderExternalAccountDetails(
        data,
        false,
        setOpen,
      )}
    </>
  )

}

export { ExternalAccountSection };
