import { useSelector } from "react-redux";
import { Typography } from "../../../../../design-systems";

export const EmployeeDetailHeader = ({ employee, showData }) => {
  const currentEmployer = useSelector((state) => state.employer.currentEmployer);
  return (
    <div className="employee-detail-header">
      <div className="employee-name">
        <Typography variant="body-3" label="Employee" className="label" />
        {
          showData &&
          <Typography
            variant="subhead-1"
            label={`${employee?.firstName?.value ?? ""} ${employee?.middleName?.value ?? ""} ${employee?.lastName?.value ?? ""}`}
            className="value" />
        }
      </div>

      <div className="employer-name-v2">
        <Typography variant="body-3" label="Employer" className="label" />
        {
          showData &&
          <Typography
            variant="section-title-1"
            label={currentEmployer?.data?.employer_name ?? ""}
            className="value"
          />}
      </div>
    </div>
  )
};
