import React, { useEffect, useState } from "react";
import { useRouter } from "../../utils/router/customRouterHook";

import BreadcrumbData from "../../data/BreadCrumb.json";

import { Breadcrumb } from "antd";
import { HomeFilled, LeftOutlined } from "@ant-design/icons";

function BreadCrumbNav({ currentRouteName }) {
  const router = useRouter();

  const [currentRoute, setCurrentRoute] = useState();
  const loc = router.pathname.slice(0, router.pathname.lastIndexOf("/"));

  useEffect(() => {
    const currRoute = BreadcrumbData.filter(
      (el) =>
        String(el.route) === String(currentRouteName) ||
        String(el.route) === loc,
    );
    if (currRoute.length) {
      setCurrentRoute(currRoute[currRoute.length - 1]);
    }
  }, [loc, currentRouteName]);

  return (
    <div className="breadcrumb-block">
      {currentRoute && currentRoute.show && (
        <>
          {currentRoute.showGoBack && (
            <LeftOutlined onClick={() => router.history.goBack()} />
          )}
          <Breadcrumb separator="-">
            <Breadcrumb.Item className="active-page">
              {currentRoute && currentRoute.showTitle && currentRoute.title}
            </Breadcrumb.Item>
            {currentRoute.showIcon ? (
              <Breadcrumb.Item href="/admin/dashboard">
                <HomeFilled />
              </Breadcrumb.Item>
            ) : null}
            {currentRoute.subModule && (
              <Breadcrumb.Item href={currentRoute.subModulePath}>
                <span>{currentRoute && currentRoute.subModule}</span>
              </Breadcrumb.Item>
            )}
            {currentRoute.showMiddleTitle && (
              <Breadcrumb.Item>
                {currentRoute && currentRoute.title}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </>
      )}
    </div>
  );
}
export { BreadCrumbNav };
