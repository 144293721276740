import React, { useEffect } from "react";
import { Col, Form, Row, Spin } from "antd";
import { CheckBox, Select, TextField } from "../../../../../design-systems";
import { EditableTable } from "../../../../common/EditableTable";
import { mandatory, validateCopaysAmount } from "../../../../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeCopaysCategories } from "../../../../../redux/actions";
import { useCallback } from "react";

export const AddCopays = ({ setCopaysCate, mode, form, copaysCate }) => {
  const dispatch = useDispatch()
  const plans = form.getFieldValue("plans")
  const { copaysCategories } = useSelector((state) => state.employer);
  useEffect(() => {
    dispatch(getEmployeeCopaysCategories(form, plans));
  }, [plans])

  const filterCategories = useCallback(() => {
    const selectedCategories = copaysCate?.map(copay => copay?.category) || [];
    return copaysCategories.filter(category =>
      !selectedCategories?.includes(category?.value) &&
      !selectedCategories?.includes(category?.label))
  }, [copaysCategories]);

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      width: "40%",
      editable: true,
      additionNode: ({ editingKey }) => {
        return (
          <Select
            placeholder="Select category"
            name="add-category"
            options={copaysCategories}
            rules={[mandatory({ message: "Please select a valid category" })]}
            disabled={!!editingKey || !copaysCategories?.length}
          />
        )
      },
      editingNode: () => (
        <Select
          placeholder="Select category"
          name="category"
          options={copaysCategories}
          rules={[mandatory({ message: "Please select a valid category" })]}
        />
      ),
    },
    {
      title: "Copay amount",
      dataIndex: "copay_amount",
      width: "40%",
      editable: true,
      additionNode: ({ editingKey }) => (
        <TextField
          name="add-copay_amount"
          disabled={!!editingKey}
          prefix="$"
          type="number"
          rules={[mandatory({ message: "Please enter copay amount" }), validateCopaysAmount()]}
          placeholder="Enter copay amount"
        />
      ),
      editingNode: ({ editingKey }) => (
        <TextField
          name="copay_amount"
          prefix="$"
          type="number"
          rules={[mandatory({ message: "Please enter copay amount" }), validateCopaysAmount()]}
          placeholder="Enter copay amount"
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col xs={24} lg={8}>&nbsp;</Col>

      <Col xs={24} lg={16} >
        <div className="should-update-col division-layout">
          <Form.Item shouldUpdate className="should-update-form-item">
            {() =>
              form.getFieldValue("add_copay") ? (
                <Form.Item
                  shouldUpdate
                  name="copays"
                >
                  <EditableTable
                    key="update-copays"
                    columns={columns}
                    data={copaysCate}
                    onDataChange={(copays) => {
                      setCopaysCate(copays)
                      form.setFieldsValue({ copays })
                    }}
                    copaysCategories={copaysCategories}
                  />
                </Form.Item>
              ) : (
                <div className="nothing-here"></div>
              )
            }
          </Form.Item>
        </div>
      </Col>
    </Row>
  );
};
