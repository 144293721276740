import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPasswordEmail } from "../../utils/validations";
import { clearFieldErrors } from "../../utils";
import { forgotPassword } from "../../redux/actions";
import { useRouter } from "../../utils/router";
import { useForm } from "antd/lib/form/Form";

import { Form, Input, Button } from "antd";

function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = useForm();

  const onFinish = (values) => {
    dispatch(
      forgotPassword(values, (res) => {
        if (res instanceof Error) {
          return;
        } else {
          router.push({
            pathname: "/auth/reset-password",
            state: { username: values.email },
          });
        }
      }),
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onValuesChange = (values, allValues) => {
    clearFieldErrors(form, allValues);
  };

  return (
    <div className="auth-body-content">
      <div className="forgot-form-container">
        <div className="form-container">
          <h1 className="text-center">Forgot Password</h1>
          <div className="form-body">
            <div className="text-muted mb-4">
              <small>
                Please enter the email address associated with your admin
                account below. We will send you an email with steps to reset
                your password.
              </small>
            </div>
            <Form
              name="login"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              layout="vertical"
              autoComplete="off"
              validateTrigger="onSubmit"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[forgotPasswordEmail]}
                normalize={(value) => value.trim()}
              >
                <Input
                  placeholder="Enter your email"
                  prefix={<i className="ni ni-email-83" />}
                />
              </Form.Item>

              <Form.Item shouldUpdate className="btn-block text-center">
                {({ getFieldsValue }) => {
                  const { email } = getFieldsValue();
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button-primary"
                      disabled={!email}
                    >
                      Continue
                    </Button>
                  );
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="text-left text-muted back-to-login">
          <Link to="/auth/login">
            <small>Return to Log In</small>
          </Link>
        </div>
      </div>
    </div>
  );
}

export { ForgotPasswordPage };
