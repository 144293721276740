import moment from "moment";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { expenseType, fixAmountFormat } from "../../../../utils";


const TravelTransactionDetails = ({ claimsData, page, emp_id }) => {
    const {
        associated_claim
    } = claimsData || {}

    const {
        transaction_date,
        merchant,
        total_expense_amount,
        accounts,
        claim_id
    } = associated_claim || {}
    return (
        <>
            <div className="emp-details-wrapper">
                <div className="claim-status-typo section-gap">Associated transaction details </div>
                <div className="expense-details-wrapper">
                    <div className="emp-details">
                        <div className="title">Transaction date</div>
                        <div className="value align-right mr-3">{transaction_date ?? "N/A"}</div>
                    </div>
                    <div className="emp-details">
                        <div className="title">Merchant name</div>
                        <div className="value align-right mr-3">{merchant ?? "N/A"}</div>
                    </div>
                    <div className="emp-details">
                        <div className="title">Amount</div>
                        <div className="value align-right mr-3">{fixAmountFormat(total_expense_amount) ?? "N/A"}</div>
                    </div>
                    <div className="emp-details">
                        <div className="title">Account(s)</div>
                        <div className="value align-right mr-3">{accounts?.length ? accounts?.join(", ") : "N/A"}</div>
                    </div>
                    <div className="mt-10" />
                    <div className="emp-details">
                        <div className="title">Claim</div>
                        <div className="value align-right mr-3">
                            {
                                claim_id ?
                                    <Link
                                        style={{ textDecoration: "underline" }}
                                        to={{ pathname: page === "employee-claims" ? `/admin/employee-claims/${claim_id}?eid=${emp_id}`: `/admin/claims/details/${claim_id}` }}
                                        target="_blank"
                                    >
                                        {claim_id}
                                    </Link>:
                                    "N/A"
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TravelTransactionDetails;
