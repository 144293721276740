import { Layout } from "antd";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ArrowRight, Button } from "../../../../design-systems";
import { getClaimsSummary, saveClaimsActionReasons, saveCurrentClaimId } from "../../../../redux/actions/claims.actions";
import { claimStatusMap } from "../../../../utils";
import { useRouter } from "../../../../utils/router";
import { ClaimActionDialog } from "./ClaimActionDialog";

const { Footer: AntFooter } = Layout;

export const Footer = ({ nextClaimId, page }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const [title, setTitle] = useState("");
  const [visible, setVisible] = useState(false);

  const currentEmployeeId = router?.query?.eid ? decodeURIComponent(router?.query?.eid) : "";

  const { claimsDetails, claimsSummary, claimListApiData } = useSelector((state) => state.claims);
  useEffect(() => {
    if ((title === "Deny" || title === "Need more information" || title === "Partially approve")) {
      dispatch(saveClaimsActionReasons(claimStatusMap[title]))
    }
  }, [title])

  const onNext = async () => {
    setTitle("")
    if (currentEmployeeId === "") {
      router.push( { pathname: `/admin/claims/details/${nextClaimId}`})
    } else {
      router.push( { pathname: `/admin/employee-claims/${nextClaimId}`, search: `?eid=${ encodeURIComponent(currentEmployeeId)}` })
    }
    
  }

  const checkLastRecord = useMemo(() => {
    return (nextClaimId === null || isNaN(nextClaimId))
  }, [nextClaimId])

  const isNeedsMoreInfo = claimsDetails?.claim_status === "NEEDS_MORE_INFO";
  const isCardExpenseType = claimsDetails?.expense_mode === "Card";
  const isDeniedClaim = claimsDetails.claim_status === "DENIED"
  const showAllStatuses = (claimsDetails.claim_status === "AWAITING_REVIEW" || claimsDetails.claim_status === "NEEDS_MORE_INFO")
  return (
    <AntFooter className="custom-view-claims-footer">
      <ClaimActionDialog
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        checkLastRecord={checkLastRecord}
        onNext={onNext}
        title={title}
        page={page}
        emp_id={currentEmployeeId}
      />

      <div className={showAllStatuses ? "claim-detail-footer" : "claim-detail-footer justify-end"}>
        {
          showAllStatuses &&
          <div className="claim-actions">
            <Button
              variant="secondary"
              onclickCallback={() => {
                setVisible(true)
                setTitle("Approve claim")
              }}
              label="Approve"
              disabled={!Object.keys(claimsDetails || {}).length}
            />
            {
              !isNeedsMoreInfo &&
              <Button
                variant="secondary"
                onclickCallback={() => {
                  setVisible(true)
                  setTitle("Need more information")
                }}
                label="Need more information"
                disabled={!Object.keys(claimsDetails || {}).length}
              />
            }
            <Button variant="secondary"
              onclickCallback={() => {
                setVisible(true)
                setTitle("Deny")
              }}
              label="Deny"
              disabled={!Object.keys(claimsDetails || {}).length} />
            <Button
              variant="secondary"
              onclickCallback={() => {
                setVisible(true)
                setTitle("Partially approve")
              }}
              label="Partially approve"
              disabled={!Object.keys(claimsDetails || {}).length}
            />
            {
              !isCardExpenseType &&
              <Button
                variant="secondary"
                onclickCallback={() => {
                  setVisible(true)
                  setTitle("Cancel claim")
                }}
                label="Cancel claim"
                disabled={!Object.keys(claimsDetails || {}).length}
              />
            }
          </div>
        }
        {/* {isNeedsMoreInfo && !isCardExpenseType && (
          <div className="claim-actions cancel-button">
            <Button
              variant="secondary"
              onclickCallback={() => {
                setVisible(true);
                setTitle("Cancel claim");
              }}
              label="Cancel claim"
              disabled={!Object.keys(claimsDetails || {}).length}
            />
          </div>
        )} */}
        {/* {
          claimsDetails.claim_status === "DENIED" && isCardExpenseType &&
          <div className="claim-actions">
            <Button
              variant="secondary"
              onclickCallback={() => {
                setVisible(true)
                setTitle("Approve claim")
              }}
              label="Approve"
              disabled={!Object.keys(claimsDetails || {}).length}
            />
            <Button
              variant="secondary"
              onclickCallback={() => {
                setVisible(true)
                setTitle("Partially approve")
              }}
              label="Partially approve"
              disabled={!Object.keys(claimsDetails || {}).length}
            />
          </div>
        } */}
        <div className="claim-next">
          <Button
            variant="primary"
            onclickCallback={() => { onNext() }}
            label="Next claim"
            IconRight={ArrowRight}
            disabled={checkLastRecord}
          />
        </div>
      </div>
    </AntFooter>
  );
};
