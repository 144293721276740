import { ApiErrors } from "../../utils";
import { USER_CONSTANT } from "../../utils/constants";
import { disableDataLoader, enableDataLoader } from ".";
import {
  getAdminDetailsService,
  getLoggedInAdminDataService,
  getUserDetailsService,
  getUsersListService,
  inviteAdminService,
  validateAdminService,
  updateAdminService,
  deleteAdminService,
} from "../../services";

export function getLoggedInAdminData(callback) {
  const processName = "getLoggedInAdminData";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getLoggedInAdminDataService(dispatch)
      .then(({ data }) => {
        dispatch({ type: USER_CONSTANT.SAVE_ROLE_TYPE, role: data.type });
        dispatch({ type: USER_CONSTANT.SAVE_EMAIL, email: data.email });
        callback && callback(data);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getUserDetails(username, callback) {
  const processName = "getUserDetails";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getUserDetailsService(username)
      .then((user) => {
        callback && callback(user);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getAdminDetails(callback) {
  const processName = "getAdminDetails";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));

    getAdminDetailsService()
      .then((user) => {
        dispatch({ type: USER_CONSTANT.SAVE_ADMIN_USER_DETAILS, user });
        callback && callback(user);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getUsersList(callback) {
  const processName = "getUsersList";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));

    getUsersListService()
      .then((user) => {
        callback && callback(user);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function validateAdminEmail(reqData, callback) {
  const processName = "validateAdminEmail";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    validateAdminService(reqData)
      .then((user) => {
        callback && callback(user);
      })
      .catch((err) => {
        callback && callback(err);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function inviteAdmin(reqData, callback) {
  const processName = "inviteAdmin";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    inviteAdminService(reqData)
      .then((user) => {
        callback && callback(user);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function updateAdmin(reqData, callback) {
  const processName = "updateAdmin";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    updateAdminService(reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function deleteAdmin(reqData, callback) {
  const processName = "deleteAdmin";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    deleteAdminService(reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}
