import { LOADER_CONSTANT } from "../../utils/constants";

export function enableGlobalLoader() {
  return (dispatch) => {
    return dispatch({ type: LOADER_CONSTANT.ENABLE_GLOBAL_LOADER });
  };
}

export function disableGlobalLoader() {
  return (dispatch) => {
    return dispatch({ type: LOADER_CONSTANT.DISABLE_GLOBAL_LOADER });
  };
}

export function enableDataLoader(payload) {
  return (dispatch) => {
    return dispatch({ type: LOADER_CONSTANT.ENABLE_DATA_LOADER, payload });
  };
}

export function disableDataLoader(payload) {
  return (dispatch) => {
    return dispatch({ type: LOADER_CONSTANT.DISABLE_DATA_LOADER, payload });
  };
}

export function enableLoader() {
  return (dispatch) => {
    return dispatch({ type: LOADER_CONSTANT.ENABLE_LOADER });
  };
}

export function disableLoader() {
  return (dispatch) => {
    return dispatch({ type: LOADER_CONSTANT.DISABLE_LOADER });
  };
}
