export const COMMON_CONSTANT = {
  SHOW_SUCCESS: "SHOW_SUCCESS",
};

export const LOADER_CONSTANT = {
  ENABLE_GLOBAL_LOADER: "ENABLE_GLOBAL_LOADER",
  DISABLE_GLOBAL_LOADER: "DISABLE_GLOBAL_LOADER",
  ENABLE_DATA_LOADER: "ENABLE_DATA_LOADER",
  DISABLE_DATA_LOADER: "DISABLE_DATA_LOADER",
  ENABLE_LOADER: "ENABLE_LOADER",
  DISABLE_LOADER: "DISABLE_LOADER",
};

export const AUTH_CONSTANT = {
  LOGOUT: "USERS_LOGOUT",
};

export const EMPLOYEES_CONSTANT = {
  RESET_EMPLOYEES_CREATE_DATA: "RESET_EMPLOYEES_CREATE_DATA",
  SAVE_SELECTED_DIVISION_ID: "SAVE_SELECTED_DIVISION_ID",
  RESET_EMPLOYEES_CREATE_SUCCESS: "RESET_EMPLOYEES_CREATE_SUCCESS",
  SET_EMPLOYEE_ID: "SET_EMPLOYEE_ID",
  SET_EMPLOYEE_CREATE_SUCCESS: "SET_EMPLOYEE_CREATE_SUCCESS",
  SET_EMPLOYEE_CREATE_ERROR: "SET_EMPLOYEE_CREATE_ERROR",
  SET_EMPLOYEES_LIST: "SET_EMPLOYEES_LIST",
  SET_COUNTRIES_LIST: "SET_COUNTRIES_LIST",
  SET_COVERAGES_LIST: "SET_COVERAGES_LIST",
  SET_EMPLOYEMENT_STATUS: "SET_EMPLOYEMENT_STATUS",
  SET_UPDATE_KYC_STATUS: "SET_UPDATE_KYC_STATUS",
  SET_ACCOUNT_STATUS: "SET_ACCOUNT_STATUS",
  SET_USER_STATUS: "SET_USER_STATUS",
  SET_SWITCH_EMPLOYEES_TYPE: "SET_SWITCH_EMPLOYEES_TYPE",
  SET_UPLOADED_FILES_TYPE: "SET_UPLOADED_FILES_TYPE",
  SAVE_SELECTED_EMPLOYEE_DATA: "SAVE_SELECTED_EMPLOYEE_DATA",
  RESET_EMPLOYEES_DATA: "RESET_EMPLOYEES_DATA",
  SAVE_EMPLOYEE_PLANS_DATA: "SAVE_EMPLOYEE_PLANS_DATA",
  UPDATE_EMPLOYEE_DATA: "UPDATE_EMPLOYEE_DATA"
};

export const CONTRIBUTION = {
  SET_BENEFIT_ACCOUNT_TYPE: "SET_BENEFIT_ACCOUNT_TYPE",
};

export const EMPLOYER_CONSTANT = {
  SET_EMPLOYER_TYPE: "SET_EMPLOYER_TYPE",
  SET_SWITCH_EMPLOYER_TYPE: "SET_SWITCH_EMPLOYER_TYPE",
  SET_CURRENT_EMPLOYER_TYPE: "SET_CURRENT_EMPLOYER_TYPE",
  SET_EMPLOYER_LEDGER: "SET_EMPLOYER_LEDGER",
  SET_EMPLOYER_DIVISIONS: "SET_EMPLOYER_DIVISIONS",
  SET_EMPLOYEE_CONTRIBUTION_IDENTIFIERS: "SET_EMPLOYEE_CONTRIBUTION_IDENTIFIERS",
  SET_EMPLOYEE_RUNOUT_OPTIONS: "SET_EMPLOYEE_RUNOUT_OPTIONS",
  SET_EMPLOYEE_COPAYS_CATEGORIES: "SET_EMPLOYEE_COPAYS_CATEGORIES",
  SET_EMPLOYER_ID: "SET_EMPLOYER_ID",
  SET_EMPLOYER_CREATE_SUCCESS: "SET_EMPLOYER_CREATE_SUCCESS",
  SET_EMPLOYER_CREATE_ERROR: "SET_EMPLOYER_CREATE_ERROR",
  RESET_EMPLOYER_DATA: "RESET_EMPLOYER_DATA",
  RESET_EMPLOYER_ADD_DATA: "RESET_EMPLOYER_ADD_DATA",
  SET_SHOW_ADDITIONAL_CONTACT: "SET_SHOW_ADDITIONAL_CONTACT",
  SET_SAME_AS_CORPORATE_ADDRESS: "SET_SAME_AS_CORPORATE_ADDRESS",
  SET_EMPLOYER_DATA: "SET_EMPLOYER_DATA",
  SET_PRIMARY_CONTACT_INFO: "SET_PRIMARY_CONTACT_INFO",
  SET_EMPLOYER_DATA_BY_TAB: "SET_EMPLOYER_DATA_BY_TAB",
  RESET_CURRENT_EMPLOYER_DATA: "RESET_CURRENT_EMPLOYER_DATA",
  SET_SHOW_SECTION2: "SET_SHOW_SECTION2",
  SET_EMPLOYER_INFO: "SET_EMPLOYER_INFO",
  SET_EMPLOYER_DETAILS_BY_ID: "SET_EMPLOYER_DETAILS_BY_ID",
  SAVE_EMPLOYER_ROUTE_INFO: "SAVE_EMPLOYER_ROUTE_INFO",
  SAVE_EMPLOYER_PLAN_INFO: "SAVE_EMPLOYER_PLAN_INFO",
  DELETE_EMPLOYER_PROGRESS_DATA: "DELETE_EMPLOYER_PROGRESS_DATA",
  SAVE_COPAYS_DATA: "SAVE_COPAYS_DATA",
  UPDATE_SAVED_EMPLOYER_PROGRESS_DATA: "UPDATE_SAVED_EMPLOYER_PROGRESS_DATA",
  SAVE_EMPLOYER_PLAN_UPDATE_ACTION: "SAVE_EMPLOYER_PLAN_UPDATE_ACTION"
};

export const USER_CONSTANT = {
  SAVE_ADMIN_USER_DETAILS: "SAVE_ADMIN_USER_DETAILS",
  SAVE_LOGGED_IN_DETAILS: "SAVE_LOGGED_IN_DETAILS",
  SAVE_NON_VERIFIED_USER: "SAVE_NON_VERIFIED_USER",
  SAVE_ROLE_TYPE: "SAVE_ROLE_TYPE",
  SAVE_EMAIL: "SAVE_EMAIL",
};

export const CLAIMS_CONTANT = {
  SAVE_CLAIMS_SUMMARY: "SAVE_CLAIMS_SUMMARY",
  SAVE_CLAIMS_STATUS: "SAVE_CLAIMS_STATUS",
  RESET_CLAIMS_SUMMARY: "RESET_CLAIMS_SUMMARY",
  SAVE_CLAIM_DETAILS_BY_ID: "SAVE_CLAIM_DETAILS_BY_ID",
  RESET_CLAIMS_DETAILS: "RESET_CLAIMS_DETAILS",
  SAVE_INTERNAL_NOTES: "SAVE_INTERNAL_NOTES",
  SAVE_CLAIMS_DOCUMENTS: "SAVE_CLAIMS_DOCUMENTS",
  SAVE_CLAIM_ACTION_REASONS: "SAVE_CLAIM_ACTION_REASONS",
  UPDATE_CLAIM_ACTION: "UPDATE_CLAIM_ACTION",
  ADD_CLAIMS_DOCUMENTS: "ADD_CLAIMS_DOCUMENTS",
  SAVE_CLAIMS_PRE_SIGNED_URL: "SAVE_CLAIMS_PRE_SIGNED_URL",
  SAVE_CURRENT_CLAIM_ID: "SAVE_CURRENT_CLAIM_ID",
  SAVE_CLAIM_LIST_API_DATA: "SAVE_CLAIM_LIST_API_DATA",
  SAVE_CLAIM_ACTIVITY_LOG: "SAVE_CLAIM_ACTIVITY_LOG"
}

export const REPORTS_CONSTANT = {
  GET_ALL_REPORTS: "GET_ALL_REPORTS",
  GET_REPORT_BY_TYPE: "GET_REPORT_BY_TYPE",
  GET_REPORT_PLAN_FILTERS: "GET_REPORT_PLAN_FILTERS"
}

export const EXPENSES_CONSTANT = {
  GET_EXPENSES_TYPES: "GET_EXPENSES_TYPES",
  SAVE_EXPENSES_AS_DRAFT: "SAVE_EXPENSES_AS_DRAFT",
  RESET_EXPENSE_DATA: "RESET_EXPENSE_DATA",
  SAVE_UPLOADED_PRESIGNED_URL: "SAVE_UPLOADED_PRESIGNED_URL",
  UPDATE_SAVED_PRESIGNED_URL: "UPDATE_SAVED_PRESIGNED_URL",
  SAVE_EVALUATE_EXPENSE_DATA: "SAVE_EVALUATE_EXPENSE_DATA"
}