import React, { useEffect, useMemo, useState } from "react";
import { Card, Empty } from "antd";
import AccountTable from "./AccountTable";
import { ExpandIcon, Table, Typography } from "../../../../design-systems";
import { ExpandedModalView } from "./ExpandedViewModal";
import { fixAmountFormat, formatDateWithoutTime } from "../../../../utils";
import moment from "moment";

const renderAccountDetails = (data, open, setOpen) => {
  return (
    <Card bordered={false} className={"account-holder-cards"}>
      {data && Object.keys(data)?.length > 0 ? (
        <>
          <div className="d-flex justify-space-between">
            {!open && (
              <>
                <Typography
                  variant="subhead-3"
                  label="Account balance"
                  className="account-holder-card-header"
                />
                <div onClick={() => setOpen(true)}>
                  <ExpandIcon />
                </div>
              </>
            )}
          </div>
          <AccountTable data={data} open={open} />
        </>
      ) : (
        <div>
          <Typography
            variant="subhead-3"
            label="Account balance"
            className="account-holder-card-header mb-20"
          />
          <Empty />
        </div>
      )}
    </Card>
  );
};

const renderAccountTable = (columns, dataSource) => {
  return (
    <Card bordered={false}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        className="activity-logs-table"
      />
    </Card>
  );
};

function AccountDetailsSection({ data }) {
  const [open, setOpen] = useState(false);

  const columns = [
    {
      title: "Type",
      dataIndex: "accountType",
      key: "accountType",
      width: 150,
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      render: (year) => year ?? "-",
      width: 300,
      align: "center",
    },
    {
      title: "Account number",
      dataIndex: "accountNumber",
      key: "accountNumber",
      align: "center",
    },
    {
      title: "Available balance",
      dataIndex: "availableBalance",
      key: "availableBalance",
      render: (amount) => fixAmountFormat(amount),
      align: "right",
      width: 150,
    },
    {
      title: "Settled balance",
      dataIndex: "totalBalance",
      key: "totalBalance",
      render: (amount) => fixAmountFormat(amount),
      align: "right",
    },
  ];

  const updateData = useMemo(() => {
    if (data && Object?.keys(data)?.length) {
      const availablePlans = Object?.keys(data);
      
      const hasActiveHSA = availablePlans.includes("HSA") && data.HSA.account_status === "ACTIVE";
      const hasActiveEveryday = availablePlans.includes("Everyday") && data.Everyday.account_status === "ACTIVE";
  
      if (!hasActiveHSA || !hasActiveEveryday) {
        if (availablePlans.includes("Everyday")) {
          delete data["Everyday"];
        }
      }
    }
    return data;
  }, [data]);
  

  const dataSource = updateData
    ? Object.keys(updateData)
      .flatMap((accountType) => {
        const account = data[accountType];
        const isPlan =
          typeof account === "object" && account[Object.keys(account)[0]]?.plan_config_id;

        if (isPlan) {
          const balances = [];
          // If it's a plan, map each plan separately
          Object.keys(account).forEach((planKey) => {
            const plan = account[planKey];
            if(new Date(plan.plan_start_date) > new Date()){
              return;
            }   
            balances.push({
              key: planKey,
              accountType: plan.account_type,
              availableBalance:
                plan.available_balance !== undefined
                  ? plan.available_balance
                  : plan.available_notional_balance,
              totalBalance:
                plan.total_balance !== undefined
                  ? plan.total_balance
                  : plan.total_notional_balance,
              year: plan.plan_start_date
                ? formatDateWithoutTime(plan.plan_start_date) +
                " - " +
                formatDateWithoutTime(plan.plan_end_date)
                : null,
              accountNumber: plan?.account_number,
              planStartDate: plan.plan_start_date, // For sorting
            });
          });
          return balances;
        } else {
          // If it's not a plan, handle it as usual
          return account.account_status === "ACTIVE" ? [{
            key: accountType,
            accountType: accountType,
            availableBalance: account.available_balance,
            totalBalance: account.total_balance,
            year: null,
            accountNumber: account.account_number
          }] : [];
        }
      })
      // Sort by HSA first, then by plan start date (most recent first), then by account type
      .sort((a, b) => {
        if (a.accountType === "HSA" && b.accountType !== "HSA") return -1;
        if (a.accountType !== "HSA" && b.accountType === "HSA") return 1;
      
        if (a.accountType === "Everyday" && b.accountType !== "Everyday") return -1;
        if (a.accountType !== "Everyday" && b.accountType === "Everyday") return 1;
      
        const dateA = a.planStartDate ? moment(a.planStartDate) : moment(0);
        const dateB = b.planStartDate ? moment(b.planStartDate) : moment(0);
        if (dateA.isBefore(dateB)) return 1;
        if (dateA.isAfter(dateB)) return -1;

        return a.accountType.localeCompare(b.accountType);
      })
    : [];

  return (
    <>
      {renderAccountDetails(dataSource, false, setOpen)}
      <ExpandedModalView
        open={open}
        setOpen={setOpen}
        title={"Account balance"}
        renderJSX={() => renderAccountTable(columns, dataSource)}
      />
    </>
  );
}

export { AccountDetailsSection };
