import React, { useEffect, useState } from "react";
import { Empty, Tag } from "antd";
import { SectionHeader } from "./SectionHeader";
import { Typography } from "../../../../design-systems";
import { SpinLoader } from "../../../common/SpinLoader";
import MaskData from "../../../common/MaskData";

export const BankInfo = ({ data }) => {
  const {banks_account_details} = data?.bank_information || {};

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (Object.keys(data).length) {
      setLoading(false)
    }
  }, [data])

  return (
    <>
      {
        loading ?
          <SpinLoader showContent={!!data}> </SpinLoader>
          :
          <div className="content">
            <SectionHeader name={"Bank information"} />
            {banks_account_details?.map((bank_info) => (
              <div className="division-wrapper">
                {
                  bank_info.division_identifier !== "default" &&
                  <Typography
                    className={"section-title"}
                    variant={"section-title-2"}
                    label={bank_info.division_identifier}
                  />
                }
                <div className="division-section">
                  <div className="column-wrapper-left">
                    <Typography
                      className={"section-title"}
                      variant={"body-2"}
                      label={"Routing number"}
                    />

                    <MaskData
                      number={bank_info?.routing_number}
                    />
                    <Tag className={`status-bank ${bank_info.verification_status}`}>
                      {bank_info.verification_status}
                    </Tag>
                  </div>

                  <div className="column-wrapper-left">
                    <Typography
                      className={"section-title"}
                      variant={"body-2"}
                      label={"Account number"}
                    />
                    <MaskData
                      number={bank_info?.account_number}
                    />
                  </div>
                  <div className="column-wrapper-left">
                    <Typography
                      className={"section-title"}
                      variant={"body-2"}
                      label={"Name of account"}
                    />
                    <Typography
                      className={"section-data"}
                      variant={"body-3"}
                      label={bank_info.name_of_account}
                    />
                  </div>
                </div>
              </div>
            ))}
            {banks_account_details?.length === 0 &&
              <div className="empty-container">
                <Empty />
              </div>
            }

          </div>
      }
    </>
  );
};
