import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../utils/router/customRouterHook";
import {
  cancelUploadedFile,
  deleteEmployeeDetailsFromFile,
  executeUploadedFile,
  getEmployeeDetailsFromFile,
  getUploadedFileDetails,
  showSuccessMessage,
} from "../../../redux/actions";
import {
  UPLOADED_FILE_EMPLOYEE_STATUSES,
  UPLOADED_FILE_STATUSES,
  DATE_FORMAT,
} from "../../../utils/constants";
import {
  renderUploadedFileEmployeeStatusTag,
  getLocalTimezone,
} from "../../../utils";
import { langs } from "../../../i18n";
import moment from "moment";

import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { SpinLoader } from "../../../components/common/SpinLoader";

import { Button, Card, Modal, Space, Table } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { StatusTag } from "../../../components/admin/employees/EmployeeDetails/v2/EmployeeDetails/StatusTag";

const WarningDeleteFieldModal = ({
  showWarningModal,
  onOk,
  onCancel,
  isOkButtonDisabled,
}) => {
  return (
    <Modal
      visible={showWarningModal}
      closable={false}
      okText="Yes"
      cancelText="No"
      onOk={onOk}
      onCancel={onCancel}
      className="common-modal-style"
      okButtonProps={{ disabled: isOkButtonDisabled }}
    >
      Are you sure you want to delete the employee?
    </Modal>
  );
};

const WarningCancelFileUploadModal = ({ showWarningModal, onOk, onCancel }) => {
  return (
    <Modal
      visible={showWarningModal}
      closable={false}
      okText="Yes"
      cancelText="No"
      onOk={onOk}
      onCancel={onCancel}
      className="common-modal-style"
    >
      Are you sure? You will have to re-upload your file. Any edit you made will
      not be saved.
    </Modal>
  );
};

function VerificationEmployeeCensusFile() {
  const dispatch = useDispatch();
  const router = useRouter();

  const id = router.query?.id;
  const currentPage = router.query?.page;
  const currentLimit = router.query?.limit;

  const file = useSelector((s) => s.employees.uploadedFiles?.data)?.find(
    (file) => file.file_id === id,
  );

  const defaultPage = 1;
  const defaultLimit = 10;

  const [showCancelingModal, setShowCancelingModal] = useState(false);
  const [showRemovingModal, setShowRemovingModal] = useState("");
  const [enabledContinue, setEnabledContinue] = useState(false);
  const [enabledConfirm, setEnabledConfirm] = useState(false);
  const [employees, setEmployees] = useState(null);

  useEffect(() => {
    getFileDetails(
      Number(currentPage ?? defaultPage),
      Number(currentLimit ?? defaultLimit),
    );
    // eslint-disable-next-line
  }, [currentPage, currentLimit]);

  const getFileDetails = (page, limit) => {
    dispatch(
      getUploadedFileDetails(id, page, limit, (res) => {
        setEmployees(res.data.employees);
        setEnabledContinue(res.data.is_continue_btn_enabled);
      }),
    );
  };

  const cancelFileUpload = () => {
    dispatch(
      cancelUploadedFile(id, (res) => {
        dispatch(
          showSuccessMessage(langs.messages.file_upload_has_been_cancelled),
        );

        router.push({
          pathname: "/admin/employees",
          state: { showCensusFile: true }
        });
      }),
    );
  };

  const continueFileUpload = () => {
    dispatch(
      executeUploadedFile(id, (res) => {
        if (res instanceof Error) {
          return;
        } else {
          dispatch(
            showSuccessMessage(langs.messages.execution_has_been_started),
          );

          router.push({
            pathname: "/admin/employees",
          });
        }
      }),
    );
  };

  const editEmployee = (empID) => {
    dispatch(
      getEmployeeDetailsFromFile(id, empID, (res) => {
        router.push({
          pathname: "/admin/employees/edit-employee",
          search: `?fileID=${id}&empID=${empID}`,
          state: { employee: res.data },
        });
      }),
    );
  };

  const deleteEmployee = () => {
    setEnabledConfirm(true);
    dispatch(
      deleteEmployeeDetailsFromFile(id, showRemovingModal, (res) => {
        setShowRemovingModal("");
        setEnabledConfirm(false);
        dispatch(showSuccessMessage(langs.messages.employee_has_been_deleted));
        getFileDetails();
      }),
    );
  };

  const onChange = (pagination, filters, sorter, extra) => {

    router.history.push({
      pathname: router.pathname,
      search: `?page=${pagination.current}&limit=${pagination.pageSize}`,
    });
  };

  const disableContinue = () => {
    if (file?.execution_status === UPLOADED_FILE_STATUSES.SUCCESS) {
      return true;
    }

    if (file?.execution_status === UPLOADED_FILE_STATUSES.VALIDATION_ERROR) {
      return !enabledContinue;
    }
  };

  const disableCancel = () => {
    if (file?.execution_status === UPLOADED_FILE_STATUSES.SUCCESS) {
      return true;
    }
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Employee ID",
      key: "employeeID",
      dataIndex: "employee_id",
    },
    {
      title: "Work Email",
      key: "email",
      dataIndex: "email",
    },
    // {
    //   title: "Coverage Type",
    //   key: "coverageType",
    //   dataIndex: "coverage",
    // },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status, row) =>
        renderUploadedFileEmployeeStatusTag(status, row.errors),
    },
    // {
    //   title: "Account type",
    //   key: "account_type",
    //   dataIndex: "account_type",
    // },
    // {
    //   title: "KYC status",
    //   key: "status",
    //   dataIndex: "status",
    //   render: (status, row) => {
    //     console.log(status, "234s");
    //     return <StatusTag status={status} />
    //   },
    //   // sorter: (a, b) => a?.employment_status?.localeCompare(b?.employment_status),
    // },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        record.status === UPLOADED_FILE_EMPLOYEE_STATUSES.ERROR ? (
          <>
            <Space size="middle">
              {/* <EditFilled
                onClick={() => editEmployee(record.id)}
                className="action-icon"
              /> */}
              <DeleteFilled
                onClick={() => setShowRemovingModal(record.id)}
                className="action-icon delete"
              />
            </Space>
          </>
        ) : null,
    },
  ];

  const navigateToUserDetail = useCallback(
    ({ status }) => {
      return (
        (file?.execution_status === UPLOADED_FILE_STATUSES.SUCCESS ||
          (file?.execution_status === UPLOADED_FILE_STATUSES.VALIDATION_ERROR &&
            enabledContinue)) &&
        status === UPLOADED_FILE_EMPLOYEE_STATUSES.SUCCESS
      );
    },
    [file?.execution_status, enabledContinue],
  );

  return (
    <HeaderWithContent>
      <Card
        title="Employee data verification"
        bordered={false}
        className="data-grid upload-census-file"
      >
        <SpinLoader showContent={!!employees}>
          <div className="search-grid-data">
            <div className="left-block file-info">
              <h3 className="file-name">{file?.bulk_upload_name}</h3>
              <p className="text-muted file-date">
                {`Uploaded ${moment
                  .utc(file?.created_dtm)
                  .local()
                  .format(
                    DATE_FORMAT.FOR_UI_DATE_TIME,
                  )} ${getLocalTimezone()} by ${file?.uploaded_by}`}
              </p>
            </div>
            <div className="right-block file-actions">
              <Button
                disabled={disableCancel()}
                onClick={() => setShowCancelingModal(true)}
                type="default"
                className="button-secondary mr-10"
              >
                Cancel
              </Button>
              <Button
                disabled={disableContinue()}
                onClick={continueFileUpload}
                type="default"
                className="button-primary"
              >
                Continue
              </Button>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={employees}
            onChange={onChange}
            pagination={{
              total: file?.total_employees ? file.total_employees : 0,
              defaultCurrent: defaultPage,
              defaultPageSize: defaultLimit,
              current: Number(currentPage ?? defaultPage),
              pageSize: Number(currentLimit ?? defaultLimit),
            }}
            bordered
            scroll={employees?.length ? { x: 1000 } : undefined}
            rowClassName={(record) => navigateToUserDetail(record) && "pointer"}
            onRow={(record) => ({
              onClick: (e) => {
                if (navigateToUserDetail(record)) {
                  router.push(`/admin/employee-detail/${record.employee_id}`);
                }
              },
            })}
          />

          <WarningCancelFileUploadModal
            showWarningModal={showCancelingModal}
            onOk={() => cancelFileUpload()}
            onCancel={() => setShowCancelingModal(false)}
          />
          <WarningDeleteFieldModal
            showWarningModal={showRemovingModal}
            onOk={() => deleteEmployee()}
            onCancel={() => setShowRemovingModal("")}
            isOkButtonDisabled={enabledConfirm}
          />
        </SpinLoader>
      </Card>
    </HeaderWithContent>
  );
}

export { VerificationEmployeeCensusFile };
