import { Image } from "antd";
import { useState } from "react";

export const ImageViewer = ({
  imageUrl= "",
  className
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Image
      src={imageUrl}
      className={className}
      preview={{
        visible,
        src: imageUrl,
        onVisibleChange: (value) => {
          setVisible(value);
        },
      }}
    />
  );
};
