import { Collapse as AntCollapse } from "antd";
import {
  contactInformationKeys,
  contactInformationStep,
  validateFields,
} from "../../../../../utils";
import { PanelHeader } from "../PanelHeader";
import { useMemo, useState } from "react";
import { Collapse } from "../../../../../design-systems";
import { useEffect } from "react";
import { useRouter } from "../../../../../utils/router";
import { useSelector } from "react-redux";

const { Panel } = AntCollapse;

export const ContactInformation = ({ form }) => {
  const { showAdditionalContact } = useSelector(state => state.employer)
  const [additionalContact, setAdditionalContact] = useState(showAdditionalContact);
  const [allowPlanDocument, setAllowPlanDocument] = useState(true)
  const [isSuperAdmin, setIsSuperAdmin] = useState(true)
  const [expandedPanels, setExpandedPanels] = useState(
    contactInformationStep.map(({ key }) => key),
  );
  const router = useRouter()
  const { employerId } = useSelector((state) => state.employer)
  useEffect(() => {
    if (!employerId) {
      router.push('/admin/employer/add/1')
    }
  }, [employerId])

  const onChange = async (updatedPanels) => {
    if (updatedPanels?.length < expandedPanels.length) {
      // try {
      // const recentKey = expandedPanels.find(
      //   (key) => !updatedPanels.includes(key),
      // );

      // const values = await form?.validateFields(validateFields[recentKey]);
      // if (Object.keys(values).length) {
      // }
      // } catch (err) { }
      setExpandedPanels(updatedPanels);
    } else {
      setExpandedPanels(updatedPanels);
    }
  };

  const filteredContactInformationStep = useMemo(() => {
    return contactInformationStep.filter(
      ({ key }) =>
        additionalContact ||
        (!additionalContact && key === contactInformationKeys.PRIMARY_CONTACT),
    );
  }, [additionalContact]);

  return (
    <Collapse
      activeKey={expandedPanels}
      onChange={onChange}
      panels={filteredContactInformationStep.map(({ content, title, key }) => (
        <Panel header={<PanelHeader label={title} />} key={key}>
          {content({ form, setAdditionalContact, additionalContact, allowPlanDocument, setAllowPlanDocument, isSuperAdmin, setIsSuperAdmin })}
        </Panel>
      ))}
    />
  );
};
