import {
  DATE_FORMAT,
  EMPLOYEE_STATUSES,
  KYC_STATUSES,
  PLAN_STATUSES,
  ROLE_CONSTANT,
  UPLOADED_FILE_EMPLOYEE_STATUSES,
  UPLOADED_FILE_STATUSES
} from "../utils/constants";
import { Tag, Select, Popover } from "antd";
import md5 from "md5";
import { Typography, renderTitle, Edit } from "../design-systems";
import { capitalizeFirstLetter, fixAmountFormat, getLocalTimezone } from "./common";
import moment from "moment";
import { StatusTag } from "../components/admin/employees/EmployeeDetails/v2/EmployeeDetails/StatusTag";
import { PersonalInformation } from "../components/admin/employees/AddEmployee/PersonalInformation";
import { BasicDetails } from "../components/admin/employees/AddEmployee/PersonalInformation/BasicDetails";
import { ResidentialAddress } from "../components/admin/employees/AddEmployee/PersonalInformation/ResidentialAddress";
import { EmploymentInformation } from "../components/admin/employees/AddEmployee/EmploymentInformation";
import { EmploymentDetails } from "../components/admin/employees/AddEmployee/EmploymentInformation/EmploymentDetails";
import { PlanInformation } from "../components/admin/employees/AddEmployee/PlanInformation";
import { SelectPlanInfo } from "../components/admin/employees/AddEmployee/PlanInformation/SelectPlanInfo";
import { planOptions } from "./employer";
import { FSAEmpPlanDetails } from "../components/admin/employees/AddEmployee/PlanInformation/FSAEmpPlanDetails";
import { HSAEmpPlanDetails } from "../components/admin/employees/AddEmployee/PlanInformation/HSAEmpPlanDetails";
import { DCFSAEmpPlanDetails } from "../components/admin/employees/AddEmployee/PlanInformation/DCFSAEmpPlanDetails";
import { LFSAEmpPlanDetails } from "../components/admin/employees/AddEmployee/PlanInformation/LFSAEmpPlanDetails";
import { HSANewAddPlan } from "../components/admin/employees/AddPlan/HSANewAddPlan";

export function renderEmployeeStatusTag(tag) {
  let color = "green";


  return <Tag color={color}>{tag.toUpperCase()}</Tag>;
}

const convertToLowerCase = (value) => String(value).toLocaleLowerCase();

export const validateCSVHeaders = (headers) => {
  const validHeaders = [
    "DOB",
    "First Name",
    "Division",
    "Middle Name",
    "Last Name",
    "Mobile Phone Number",
    "Employee ID",
    "SSN",
    "Benefits Account Type",
    "Employment Start Date",
    "Work Email",
    "Employment Status",
    "Employment Term Date",
    "Home Address 1",
    "Home Address 2",
    "City",
    "State",
    "Postal Code",
    "Coverage Tier",
    "Plan Effective Date",
    "Plan Term Date",
    "Employer Amount",
    "Employee Amount",
    "Benefits Amount",
  ].map(convertToLowerCase);

  const headersLowerCase = headers.map(convertToLowerCase);
  const incorrect = headersLowerCase.filter((h) => !validHeaders.includes(h));
  const missing = validHeaders.filter(
    (h) => h !== "division" && !headersLowerCase.includes(h),
  );

  return { incorrect, missing };
};

export function renderDivisions(option) {
  return option.map(({ division_id, division_name }) => {
    return (
      <Select.Option key={division_id} value={division_id}>
        {division_name}
      </Select.Option>
    );
  });
}

export function renderOptions(option) {
  return Object.keys(option).map((key) => {
    return (
      <Select.Option key={key} value={key}>
        {key}
      </Select.Option>
    );
  });
}

export function renderStates(options) {
  return options.map((option) => {
    return (
      <Select.Option key={option.abbreviation} value={option.abbreviation}>
        {option.abbreviation}
      </Select.Option>
    );
  });
}

export function renderKYCStatuses(userStatuses) {
  return Object.keys(userStatuses).map((elem) => (
    <Select.Option key={elem} value={userStatuses[elem]}>
      {elem}
    </Select.Option>
  ));
}

export function renderValidationErrors(errors) {
  let invalid = Object.keys(errors).filter(
    (key, index) => errors[key] !== "valid",
  );

  if (invalid.length) {
    return Object.keys(errors).map((key) => {
      if (errors[key] !== "valid") {
        return (
          <div className="mb-10">
            <Tag color={"volcano"}>{errors[key].toUpperCase()}</Tag>
          </div>
        );
      }

      return undefined;
    });
  } else {
    return <Tag color={"green"}>{"Pass"}</Tag>;
  }
}

export const downloadEmployeesCsv = () => {
  const csvString = `Employee ID,First Name,Middle Name,Last Name,SSN,DOB,Home Address 1,Home Address 2,City,State,Postal Code,Mobile Phone Number,Work Email,Employment Start Date,Employment Term Date,Employment Status,Division,Benefits Account Type,Coverage Tier,Plan Effective Date,Plan Term Date,Employer Amount,Employee Amount,Benefits Amount`;

  const blob = new Blob([csvString]);

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, "New_Employees.csv");
  } else {
    const a = window.document.createElement("a");

    a.href = "data:text/csv;charset=utf-8," + encodeURI(csvString);
    a.target = "_blank";
    a.download = "New_Employees.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export function renderUploadedFileStatusLabel(tag) {
  let label;

  switch (tag?.toLowerCase()) {
    case UPLOADED_FILE_STATUSES.VALIDATION_ERROR:
      label = "Validation error";
      break;
    case UPLOADED_FILE_STATUSES.VALIDATION_IN_PROGRESS:
      label = "Validation in progress";
      break;
    default:
      label = tag;
      break;
  }
  const val = label ? label.charAt(0).toUpperCase() + label.slice(1).toLowerCase() : "";
  return val;
}

export function renderUploadedFileStatusTag(tag) {
  let color;

  switch (tag?.toLowerCase()) {
    case UPLOADED_FILE_STATUSES.SUCCESS:
      color = "green";
      break;
    case UPLOADED_FILE_STATUSES.CANCELLED:
    case UPLOADED_FILE_STATUSES.VALIDATION_ERROR:
      color = "volcano";
      break;
    default:
      color = "orange";
      break;
  }

  return (
    <Tag color={color}>{renderUploadedFileStatusLabel(capitalizeFirstLetter(tag))}</Tag>
  );
}

export function renderUploadedFileEmployeeStatusLabel(tag) {
  let label;

  switch (tag?.toLowerCase()) {
    case UPLOADED_FILE_EMPLOYEE_STATUSES.ALREADY_EXIST:
      label = "Already exist";
      break;
    default:
      label = tag;
      break;
  }

  return label;
}

export function renderUploadedFileEmployeeStatusTag(status, errors) {
  let color;

  switch (status?.toLowerCase()) {
    case UPLOADED_FILE_EMPLOYEE_STATUSES.SUCCESS:
      color = "green";
      break;
    case UPLOADED_FILE_EMPLOYEE_STATUSES.ERROR:
      color = "volcano";
      break;
    default:
      color = "orange";
      break;
  }

  const errorList = Object.values(errors).flatMap((n) => n);

  const content = (
    <div className="error-popover-content">
      {errorList.map((error) => (
        <span>&#x2022; {error === true ? "Invalid record" : error}</span>
      ))}
    </div>
  );

  return errorList.length ? (
    <Popover
      title="Error details"
      content={content}
      trigger="hover"
      placement="top"
      overlayClassName="error-popover"
    >
      <Tag color={color}>
        {renderUploadedFileEmployeeStatusLabel(status).toUpperCase()}
      </Tag>
    </Popover>
  ) : (
    <Tag color={color}>
      {renderUploadedFileEmployeeStatusLabel(status).toUpperCase()}
    </Tag>
  );
}

export function getMD5hash(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async (e) => {
      const binary = e.target.result;
      const hash = md5(binary).toString();

      resolve(hash);
    };

    reader.readAsBinaryString(file);
  });
}

export const getEmployeeListingColumns = (filteredInfo) => {
  return [
    {
      title: (titleProps) => renderTitle(titleProps, "Name", "name"),
      key: "name",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      render: (_, row) => {
        return (
          <div className="label-name employee-name">{capitalizeFirstLetter(row?.name)}</div>
        );
      },
      sortDirections: ["descend", "ascend"],
      sorter: true
      // sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: () => <Typography className={"text-transform-initial"} variant="body-4" label="Email" />,
      key: "work_email",
      dataIndex: "work_email",
      sorter: false
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Account holder status", "account_holder_status"),
      key: "account_holder_status",
      dataIndex: "account_holder_status",
      width: 200,
      render: (tag) => {
        return renderEmployeeAccountStatusTag(tag)
      },
      sortDirections: ["descend", "ascend"],
      sorter: true,
      filterOnClose: false,
      filteredValue: filteredInfo?.account_holder_status || [],
      filters: Object.values(EMPLOYEE_STATUSES).map((status) => ({
        text: status,
        value: status,
      })),
    },
    {
      title: () => <Typography className={"text-transform-initial"} variant="body-4" label="Enrolled in" />,
      key: "enrolled_in",
      dataIndex: "enrolled_in",
      align: "center",
      render: (_, row) => {
        return row?.enrolled_in?.join(", ")
      }
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employment status", "employment_status"),
      key: "employment_status",
      dataIndex: "employment_status",
      render: (_, row) => {
        return renderEmployeeAccountStatusTag(row.employment_status)
      },
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "KYC status", "kyc_status"),
      key: "kyc_status",
      dataIndex: "kyc_status",
      filteredValue: filteredInfo?.kyc_status || [],
      width: 120,
      filters: Object.entries(KYC_STATUSES).map(([key, value]) => {
        return {
          text: key,
          value: value
        };
      }),
      render: (status, row) => {
        return <StatusTag status={`${row.kyc_status}`} />
      },
      sortDirections: ["descend", "ascend"],
      sorter: true
    },
  ]
}

export const getEmployeeCensusFileListingColumns = () => [
  {
    title: (titleProps) =>
      renderTitle(titleProps, "Census batch name", "bulk_upload_name"),
    key: "bulk_upload_name",
    dataIndex: "bulk_upload_name",
    sortDirections: ["descend", "ascend"],
    sorter: false,
    render: (_, row) => {
      return (
        <div className="label-name employee-name">{capitalizeFirstLetter(row?.bulk_upload_name)}</div>
      );
    },
  },
  {
    title: (titleProps) =>
      renderTitle(titleProps, "Upload status", "execution_status"),
    key: "status",
    dataIndex: "execution_status",
    render: (tag) => renderUploadedFileStatusTag(tag),
    sortDirections: ["descend", "ascend"],
    sorter: false
  },
  {
    title: (titleProps) =>
      renderTitle(titleProps, "# Records processed", "total_employees", "justify-end"),
    key: "total_employees",
    dataIndex: "total_employees",
    sorter: false,
    align: "right",
    render: (amount) => amount?.toLocaleString('en-US')

  },
  {
    title: (titleProps) =>
      renderTitle(titleProps, "Upload date", "created_timestamp"),
    key: "created_timestamp",
    dataIndex: "created_timestamp",
    render: (date) => {
      const localTime = moment
        .utc(date)
        .local()
        .format(DATE_FORMAT.FOR_UI_DATE_TIME);

      return date && `${localTime} ${getLocalTimezone()}`;
    }
  },
  {
    title: (titleProps) =>
      renderTitle(titleProps, "Uploaded by", "uploaded_by"),
    key: "uploaded_by",
    dataIndex: "uploaded_by",
  },
];

export const getPlanInformationColumns = ({ role, record, onEdit, showEditColumn }) => {
  const cols = [
    {
      title: (titleProps) => renderTitle(titleProps, "Plan", "plan_type"),
      key: "plan_type",
      dataIndex: "plan_type",
      render: (plan_type, row) => {
        if (plan_type === "HSA") {
          return <div>
            <div>{plan_type}</div>
            <div>{row?.coverage_tier ?? ""}</div>
          </div>
        }
        return plan_type
      }
    },
    {
      title: (titleProps) => renderTitle(titleProps, "Plan year", "plan_start_date"),
      key: "plan_start_date",
      dataIndex: "plan_start_date",
      render: (_, row) => renderPlanYear(row)
    },
    {
      title: (titleProps) => renderTitle(titleProps, "Status", "account_status"),
      key: "account_status",
      dataIndex: "account_status",
      render: (status, obj) => renderPlanInformationStatusTag(status, obj),
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Effective date", "plan_effective_date"),
      key: "plan_effective_date",
      dataIndex: "plan_effective_date",
      render: (plan_effective_date) => plan_effective_date ? moment(plan_effective_date).format("MM/DD/YYYY") : ""
    },
    {
      title: (titleProps) => renderTitle(titleProps, "End date", "plan_termination_date"),
      key: "plan_termination_date",
      dataIndex: "plan_termination_date",
      render: (plan_termination_date) => plan_termination_date ? moment(plan_termination_date).format("MM/DD/YYYY") : ""
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Election amount", "election_amount"),
      key: "election_amount",
      className: "align-amount-fields",
      dataIndex: "election_amount",
      render: (amount) => (amount != undefined || amount != null) ? `${fixAmountFormat(amount)}` : "",
      align: "right"
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Employer contribution", "employer_amount"),
      key: "employer_amount",
      className: "align-amount-fields",
      dataIndex: "employer_amount",
      render: (amount) =>  (amount != undefined || amount != null) ? `${fixAmountFormat(amount)}` : "",
      align: "right"
    },
    {
      title: (titleProps) =>
        renderTitle(titleProps, "Account balance", "available_balance"),
      key: "available_balance",
      className: "align-amount-fields",
      dataIndex: "available_balance",
      render: (amount, row) => {
        return amount !== null ? row?.plan_type === "HSA" ? "--" : `${fixAmountFormat(amount)}` : ""
      },
      align: "right",
    }
  ];
  if (showEditColumn) {
    cols.push({
      title: "",
      key: "plan",
      dataIndex: "plan",
      render: (_, row) => {
        const isAdmin = role === ROLE_CONSTANT.ZENDA_ADMIN;
        const isNotSeparated = record?.account_holder_status !== "Separated";
        const isNotSuspended = row?.account_status !== "PENDING";
        const isSuspended = row?.account_status === "SUSPENDED"
        const isClosed = (row?.plan_termination_date && moment(row?.plan_termination_date).isBefore(moment(), "day") && row.account_status === "PENDING")

        if (isSuspended) return null;
        if (isAdmin && (isNotSeparated || isNotSuspended) && !isClosed) {
          return (
            <Edit
              stroke="#989FAE"
              width="15"
              height="15"
              onClick={() => onEdit(row)}
              className="edit-emp-card"
            />
          )
        }
        return null;
      },
      width: 1,
    });
  }
  return cols;
};

export const getYTDContributionsLabel = (label, plan) => {
  return plan === "HSA" ? getYTDHSALabels[label] : getYTDLabels[label]
}
const getYTDLabels = {
  employee_contribution: "YTD Employee contributions",
  employer_contribution: "YTD Employer contributions",
  total_contribution: "YTD Total contributions",
  planned_contribution: "Planned contribution"
}

const getYTDHSALabels = {
  employee_contribution: "YTD Employee contributions",
  employer_contribution: "YTD Employer contributions",
  total_contribution: "YTD Total contributions",
  planned_contribution: "Contribution limit"
}

export const getExpenseGroupLabel = {
  "All": "All",
  "TAKE_ACTION": "Take action",
  "SETTLED": "Settled",
  "CANCELLED": "Cancelled",
  "AWAITING_REVIEW": "Awaiting review",
  "DENIED": "Denied",
  "REIMBURSE": "Reimburse",
  "TRAVEL_POTENTIAL": "Travel Potential"
}

export const getTransactionsLabel = {
  hsa: "HSA",
  dcfsa: "DCFSA",
  fsa: "FSA",
  lfsa: "LFSA",
  everyday: "Everyday"
}

export function renderPlanYear(row) {
  const { plan_end_date, plan_start_date } = row || {}
  if (plan_end_date && plan_start_date) {
    return `${moment(plan_start_date).format("MM/DD/YYYY")} - ${moment(plan_end_date).format("MM/DD/YYYY")}`
  }
  return ""
}

export function renderPlanInformationStatusTag(tag, obj) {
  let color;

  let tagKey = null;
  if (obj?.plan_type === "HSA") {
    tagKey = "account_status";
  } else {
    tagKey = "plan_status";
  }

  switch (obj?.[tagKey]?.toLowerCase()) {
    case PLAN_STATUSES.ACTIVE:
      color = "green";
      break;
    case PLAN_STATUSES.SUSPENDED_CARD_ONLY:
    default:
      color = "orange";
      break;
  }

  return (
    <Tag className={`plan-information-tag ${color}`}>
      {renderUploadedFileStatusLabel(obj?.[tagKey])}
    </Tag>
  );
}


export function renderEmployeeAccountStatusTag(tag) {
  let color;

  switch (tag?.toLowerCase()) {
    case "registered":
    case "enrolled":
    case "activated":
      color = "green";
      break;
    case "closed":
      color = "red";
      break;
    case "active":
      color = "grey";
      break;
    case "suspended":
    default:
      color = "orange";
      break;
  }

  return (
    <Tag className={`employee-status-tag ${color}`}>
      {renderUploadedFileStatusLabel(tag)}
    </Tag>
  );
}
export const lowerObjectKeys = (obj) =>
  Object.keys(obj).reduce((acc, k) => {
    acc[k.toLocaleLowerCase()] = obj[k];
    return acc;
  }, {});

export const employeeSteps = [
  {
    name: "Personal information",
    key: "personal-information",
    isActive: true,
    isComplete: false,
  },
  {
    name: "Employment information",
    key: "employment-information",
    isActive: false,
    isComplete: false,
  },
  {
    name: "Plan information",
    key: "plan-information",
    isActive: false,
    isComplete: false,
  },
];

export const renderEmployeeSteps = ({ step, form, formData, setFormData, dateRange }) => {
  switch (String(step)) {
    case "1":
      return <PersonalInformation form={form} formData={formData} setFormData={setFormData} />;
    case "2":
      return <EmploymentInformation form={form} formData={formData} setFormData={setFormData} />;
    case "3":
      return <PlanInformation form={form} formData={formData} setFormData={setFormData} dateRange={dateRange} />
    default:
      return <></>;
  }
};

export const personalInformationKeys = {
  BASIC_DETAILS: "basic-details",
  RESIDENTIAL_ADDRESS: "residential-address",
};

export const employmentInformationKeys = {
  EMPLOYMENT_DETAILS: "employment-details",
};

export const planInformationEmpKeys = {
  PLAN_INFO: "plan-info",
  HSA_PLAN_DETAIL: "hsa-plan-details",
  FSA_PLAN_DETAIL: "fsa-plan-details",
  LFSA_PLAN_DETAIL: "lfsa-plan-details",
  DCFSA_PLAN_DETAIL: "dcfsa-plan-details",
};

export const employmentInformationStep = [
  {
    title: "Employment details",
    key: employmentInformationKeys.EMPLOYMENT_DETAILS,
    content: ({ form, formData, setFormData }) => <EmploymentDetails form={form} formData={formData} setFormData={setFormData} />,
  },
];

export const personalInformationStep = [
  {
    title: "Basic details",
    key: personalInformationKeys.BASIC_DETAILS,
    content: ({ form, formData, setFormData }) => <BasicDetails form={form} formData={formData} setFormData={setFormData} />,
  },
  {
    title: "Residential address",
    key: personalInformationKeys.RESIDENTIAL_ADDRESS,
    content: ({ form, formData, setFormData }) => <ResidentialAddress form={form} formData={formData} setFormData={setFormData} />,
  },
];
export const planInformationEmpStep = [
  {
    title: "Select plan",
    key: planInformationEmpKeys.PLAN_INFO,
    content: ({ form, plansExt, setPlansExt }) => (
      <SelectPlanInfo form={form} plansExt={plansExt} setPlansExt={setPlansExt} />
    ),
  },
  {
    title: "HSA plan details",
    key: planInformationEmpKeys.HSA_PLAN_DETAIL,
    content: ({ form, formData, setFormData }) => <HSAEmpPlanDetails form={form} formData={formData} setFormData={setFormData} />,
    planKey: planOptions.find(({ value }) => value === "HSA").value,
  },
  {
    title: "FSA plan details",
    key: planInformationEmpKeys.FSA_PLAN_DETAIL,
    content: ({ form, formData, setFormData, dateRange }) => <FSAEmpPlanDetails form={form} formData={formData} setFormData={setFormData} dateRange={dateRange} />,
    planKey: planOptions.find(({ value }) => value === "FSA").value,
  },
  {
    title: "DCFSA plan details",
    key: planInformationEmpKeys.DCFSA_PLAN_DETAIL,
    content: ({ form, formData, setFormData, dateRange }) => <DCFSAEmpPlanDetails form={form} formData={formData} setFormData={setFormData} dateRange={dateRange} />,
    planKey: planOptions.find(({ value }) => value === "DCFSA").value,
  },
  {
    title: "LFSA plan details",
    key: planInformationEmpKeys.LFSA_PLAN_DETAIL,
    content: ({ form, formData, setFormData, dateRange }) => <LFSAEmpPlanDetails form={form} formData={formData} setFormData={setFormData} dateRange={dateRange} />,
    planKey: planOptions.find(({ value }) => value === "LFSA").value,
  },
];

export const formatEmployeeDataForStep = (inputData, step, form) => {
  switch (step) {
    case 1:
      let formattedData = {
        payload: {
          first_name: inputData?.first_name,
          middle_name: inputData?.middle_name ? inputData?.middle_name : null,
          last_name: inputData?.last_name,
          date_of_birth: moment(inputData?.date_of_birth).format(DATE_FORMAT.FOR_SERVER),
          ssn: inputData?.ssn,
          work_email: inputData?.work_email,
          mobile_phone_num: inputData?.mobile_phone_num ? inputData?.mobile_phone_num : null,
          mailing_address_1: inputData?.mailing_address_1,
          mailing_address_2: inputData?.mailing_address_2 ? inputData?.mailing_address_2 : null,
          mailing_state: inputData?.state,
          mailing_city: inputData?.city,
          mailing_postal_code: inputData?.zip_code,
          residential_address_1: inputData?.mailing_address_1,
          residential_address_2: inputData?.mailing_address_2 ? inputData?.mailing_address_2 : null,
          residential_state: inputData?.state,
          residential_city: inputData?.city,
          residential_postal_code: inputData?.zip_code,
        }
      };
      return {
        ...formattedData,
      };
    case 2:
      let formattedDataStep2 = {
        payload: {
          employee_id: inputData?.employee_id,
          division_id: inputData?.division ? inputData?.division : "default",
          employment_start_date: moment(inputData?.employement_start_Date).format(DATE_FORMAT.FOR_SERVER)
        }
      }
      return {
        ...formattedDataStep2
      }
    case 3:

      const formatDataForStep3 = {
        payload: {
          plan: {}
        },
      };

      const planKeys = inputData.plans;

      planKeys.forEach((planKey) => {
        const planData = {
          plan_effective_date: moment(inputData[`${planKey.toLowerCase()}_plan_effective_date`]).format(DATE_FORMAT.FOR_SERVER),
        };

        if (planKey === "HSA") {
          planData.coverage_tier_id = inputData.hsa_coverage_type;
        }
        if (planKey !== "HSA") {
          planData.employer_amount = parseFloat(inputData[`${planKey.toLowerCase()}_employer_amount`] ?? 0)
          planData.employee_amount = parseFloat(inputData[`${planKey.toLowerCase()}_employee_amount`])
        }
        formatDataForStep3.payload.plan[planKey] = planData;
      });

      return { ...formatDataForStep3 };

    default:
      return null;
  }
}


export const preventAlphanumericPaste = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData('Text');
  if (/[a-zA-Z0-9]/.test(pastedText)) {
    e.preventDefault();
  }
};

export const addNewPlanSteps = [
  {
    title: "HSA",
    key: "HSA",
    content: ({ form, planInfo, formData, setFormData, isInputRequired, expandedPanels, dateRange }) =>
      <HSANewAddPlan
        form={form}
        formData={formData}
        planInfo={planInfo}
        setFormData={setFormData}
        isInputRequired={isInputRequired}
        expandedPanels={expandedPanels}
        dateRange={dateRange}
      />,
    planKey: planOptions.find(({ value }) => value === "HSA").value,
  },
  {
    title: "FSA",
    key: "FSA",
    content: ({ form, planInfo, formData, setFormData, isInputRequired, expandedPanels, dateRange }) =>
      <FSAEmpPlanDetails
        page="addPlanPage"
        form={form}
        planInfo={planInfo}
        formData={formData}
        setFormData={setFormData}
        isInputRequired={isInputRequired}
        expandedPanels={expandedPanels}
        dateRange={dateRange}
      />,
    planKey: planOptions.find(({ value }) => value === "FSA").value,
  },
  {
    title: "DCFSA",
    key: "DCFSA",
    content: ({ form, planInfo, formData, setFormData, isInputRequired, expandedPanels, dateRange }) =>
      <DCFSAEmpPlanDetails
        page="addPlanPage"
        form={form}
        planInfo={planInfo}
        formData={formData}
        setFormData={setFormData}
        isInputRequired={isInputRequired}
        expandedPanels={expandedPanels}
        dateRange={dateRange}
      />,
    planKey: planOptions.find(({ value }) => value === "DCFSA").value,
  },
  {
    title: "LFSA",
    key: "LFSA",
    content: ({ form, planInfo, formData, setFormData, isInputRequired, expandedPanels, dateRange }) =>
      <LFSAEmpPlanDetails
        page="addPlanPage"
        form={form}
        planInfo={planInfo}
        formData={formData}
        setFormData={setFormData}
        isInputRequired={isInputRequired}
        expandedPanels={expandedPanels}
        dateRange={dateRange}
      />,
    planKey: planOptions.find(({ value }) => value === "LFSA").value,
  },
];

export const formatAddPlanData = (formData) => {
  const apiPayload = {};

  if (formData?.HSA) {
    apiPayload.hsa_details = {
      account_type: "HSA",
      coverage_tier_id: formData?.hsa_coverage_type,
      plan_effective_date: formData?.hsa_plan_effective_date ? moment(formData?.hsa_plan_effective_date).format(DATE_FORMAT.FOR_SERVER) : null
    };
  }

  const benefitsDetails = formData?.plans?.filter(plan => plan !== "HSA")?.map(plan => {
    const planLowerCase = plan?.toLowerCase();
    const planEffectiveDate = formData[`${planLowerCase}_plan_effective_date`];
    const employeeAmount = parseFloat(formData[`${planLowerCase}_employee_amount`]);
    const employerAmount = parseFloat(formData[`${planLowerCase}_employer_amount`] || 0);

    return {
      account_type: plan,
      plan_effective_date: planEffectiveDate ? moment(planEffectiveDate).format(DATE_FORMAT.FOR_SERVER) : null,
      plan_termination_date: null,
      employee_amount: isNaN(employeeAmount) ? 0 : employeeAmount,
      employer_amount: isNaN(employerAmount) ? 0 : employerAmount
    };
  });

  if (benefitsDetails && benefitsDetails.length > 0) {
    apiPayload.benefits_details = benefitsDetails;
  }

  apiPayload.plan_config_id = formData?.hsa_plan_config_id || formData?.fsa_plan_config_id || formData?.lfsa_plan_config_id || formData?.dcfsa_plan_config_id || ""

  return apiPayload;
}

export const formatAddExpenseData = (step, values) => {
  const {
    expense_type,
    start_date,
    end_date,
    name_of_merchant,
    health_expense_amount,
    expense_for,
    notes,
    mileage,
    transaction_date,
    other_travel_amount
  } = values || {};

  const isExpenseDependentCare = expense_type === "Dependent Care"

  const apiPayload = {
    parent_expense_id: "",
    is_dental_vision_expense: "",
    expense_type,
    transaction_id: "",
    name_of_merchant,
    health_expense_amount,
    expense_for,
    notes,
    travel_expense_details: {
      mileage: mileage ? mileage : 0,
      other_travel_amount: other_travel_amount ? other_travel_amount : 0
    }
  };

  if (isExpenseDependentCare) {
    apiPayload.travel_expense_details = {
      mileage: 0,
      other_travel_amount: 0
    }
  }

  const modifiedTimestamp = (date) => {
    return moment(date)
      .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss[Z]")
  };

  if (start_date && end_date && isExpenseDependentCare) {
    apiPayload.start_date = start_date ? modifiedTimestamp(start_date) : "";
    apiPayload.end_date = end_date ? modifiedTimestamp(end_date) : "";
    apiPayload.transaction_date = "";
  }

  if (!isExpenseDependentCare && !end_date) {
    apiPayload.transaction_date = modifiedTimestamp(transaction_date);
    apiPayload.start_date = "";
    apiPayload.end_date = "";
  }

  return apiPayload;
}

export const statusDescriptions = {
  "Registered": "Enrollment received, account is not active yet or Enrollment received pending effective date.",
  "Enrolled": "Account open, Invite Email sent to create login.",
  "Activated": "Account active, user created a login.",
  "Separated": "Account frozen and all access is revoked.",
  "Suspended": "Account frozen and all access is revoked.",
  "Closed": "Account closed, funds at a $0 balance, user can still access statements, documents & expense history."
};

export const statusColors = {
  "Registered": "#2B8FFB",
  "Enrolled": "#01E396",
  "Activated": "#FDB019",
  "Suspended": "#FE4560",
  "Closed": "#775DD0",
  "Separated": "#FE4560"
};

export const accountHolderIdMap = {
  Registered: 1,
  Enrolled: 2,
  Activated: 3,
  Suspended: 4,
  Closed: 5
}

export const planIdMap = {
  1: "HSA",
  2: "DDA",
  4: "LFSA",
  8: "FSA",
  9: "DCFSA"
}


export const accountStatusMap = {
  1: "Requested",
  2: "Opened",
  3: "Closed"
}

export const titleWidthMap = {
  "Account balance": 1400,
  "External account": 1400,
  "Card details": 1000,
  "Transactions": "auto",
  "Expenses": "auto",
  "Pending ACH": 1400,
  "KYC": 1400
}

export const searchFields = [
  { label: "Email", value: "email" },
  { label: "First name", value: "first_name" },
  { label: "Last name", value: "last_name" }
]

export const isEveryValueNull = (arr) => {
  return Array.isArray(arr) && arr.every(date => date === null)
}
