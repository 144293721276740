import axios from "./axiosInterceptors";
import defaultAxios from "axios";
import { Auth as CAuth } from "aws-amplify";
import { STATUS_CODES } from "../utils/constants";
import { setAuthorization, formatDate } from "../utils";

const apiBaseURL = process.env.REACT_APP_PUBLIC_BIZ_API_BASE_URL;

const getLoggedInAdminData = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/admin/me",
    };

    const response = await axios(request);

    return { error: false, data: response.data.body };
  } catch (error) {
    throw error;
  }
};

export const loginService = async ({ email, password }) => {
  try {
    const currUser = await CAuth.signIn(email, password);
    return { cognitoUser: currUser };
  } catch (error) {
    throw error;
  }
};

export const loginByOTPService = async ({ OTP = "", user = null }) => {
  try {
    const currUser = await CAuth.confirmSignIn(user.cognitoUser, OTP);

    setAuthorization({
      accessToken: currUser.signInUserSession.accessToken.jwtToken,
      idToken: currUser.signInUserSession.idToken.jwtToken,
    });

    const adminData = await getLoggedInAdminData();
    const role = !adminData.error && adminData.data.type;
    const userData = { cognitoUser: currUser, role };

    return userData;
  } catch (error) {
    throw error;
  }
};

export const loginWithoutOTPService = async (loggedInUser) => {
  try {
    setAuthorization({
      accessToken: loggedInUser.signInUserSession.accessToken.jwtToken,
      idToken: loggedInUser.signInUserSession.idToken.jwtToken,
    });

    const adminData = await getLoggedInAdminData();
    const role = !adminData.error && adminData.data.type;
    const userData = { cognitoUser: loggedInUser, role };

    return userData;
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordService = async ({ email }) => {
  try {
    return await CAuth.forgotPassword(email);
  } catch (error) {
    throw error;
  }
};

export const resetPasswordService = async ({ OTP, password, username }) => {
  try {
    return await CAuth.forgotPasswordSubmit(username, OTP, password);
  } catch (error) {
    throw error;
  }
};

export const setNewPasswordService = async ({
  email,
  password,
  firstName,
  lastName,
  newPassword,
}) => {
  try {
    const user = await CAuth.signIn(email, password);
    const requiredAttr = { name: firstName, family_name: lastName };
    const updatedUser = await CAuth.completeNewPassword(
      user,
      newPassword,
      requiredAttr,
    );

    return updatedUser;
  } catch (error) {
    throw error;
  }
};

export const completeAccountSetupService = async (userData) => {
  try {
    const request = {
      method: "put",
      url: apiBaseURL.concat("/employer/payroll/firstlogin/admin"),
      data: {
        payload: [
          {
            first_name: userData.firstName,
            last_name: userData.lastName,
            sent_at: formatDate(),
          },
        ],
      },
      headers: {
        Authorization:
          userData.updatedUser.signInUserSession.accessToken.jwtToken,
        idToken: userData.updatedUser.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      },
    };

    await defaultAxios(request);

    setAuthorization({
      accessToken: userData.updatedUser.signInUserSession.accessToken.jwtToken,
      idToken: userData.updatedUser.signInUserSession.idToken.jwtToken,
    });

    const adminData = await getLoggedInAdminData();
    const role = !adminData.error && adminData.data.type;
    const newUserData = { cognitoUser: userData.updatedUser, role };

    return { error: false, userData: newUserData };
  } catch (error) {
    return { error: true };
  }
};

export const loginWithSSOService = async () => {
  try {
    const request = {
      method: "put",
      url: "/employer/payroll/sso/login",
      data: { sent_at: formatDate() },
    };

    const response = await axios(request);

    if (response.status === STATUS_CODES.OK) return { error: false };
  } catch (error) {
    const showMessage =
      error.response.status === STATUS_CODES.BAD_REQUEST ||
      error.response.status === STATUS_CODES.FORBIDDEN;

    return showMessage
      ? { error: true, message: error.response.data.error.message }
      : { error: true };
  }
};

export const tokenRevokeService = async () => {
  try {
    const currentSession = await CAuth.currentSession();
    const refreshToken = currentSession.refreshToken.token;

    const request = {
      method: "post",
      url: "/employer/payroll/logout",
      data: { refreshToken },
    };

    await axios(request);
  } catch (error) {
    throw error;
  }
};

export const setPhoneNumberService = async ({ user, formattedPhone }) => {
  try {
    await CAuth.updateUserAttributes(user, { phone_number: formattedPhone });

    if (user.attributes.phone_number) {
      await CAuth.verifyCurrentUserAttribute("phone_number");
    }
  } catch (error) {
    throw error;
  }
};

export const loginWithPhoneNumberVerificationService = async ({
  user,
  OTP,
}) => {
  try {
    await CAuth.verifyCurrentUserAttributeSubmit("phone_number", OTP);
    await CAuth.setPreferredMFA(user, "SMS");

    setAuthorization({
      accessToken: user.signInUserSession.accessToken.jwtToken,
      idToken: user.signInUserSession.idToken.jwtToken,
    });

    const adminData = await getLoggedInAdminData();
    const role = !adminData.error && adminData.data.type;
    const userData = { cognitoUser: user, role };

    return userData;
  } catch (error) {
    throw error;
  }
};

export const resendOTPService = async () => {
  try {
    await CAuth.verifyCurrentUserAttribute("phone_number");
  } catch (error) {
    throw error;
  }
};
