import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { logout, tokenRevoke } from "../../redux/actions";

import { Modal } from "antd";

function IdleTimer({ children }) {
  const dispatch = useDispatch();

  const interfaceTimeout = 780000;
  const modalTimeout = 120000;

  const [showModal, setShowModal] = useState(false);

  const logOut = () => {
    dispatch(
      tokenRevoke(() => {
        dispatch(logout());
      }),
    );

    idleTimer.pause();
    setShowModal(false);
  };

  const stayLoggedIn = () => {
    idleTimer.reset();
    setShowModal(false);
  };

  const onPrompt = () => {
    setShowModal(true);
  };

  const onIdle = () => {
    logOut();
    setShowModal(false);
  };

  const idleTimer = useIdleTimer({
    timeout: interfaceTimeout,
    promptTimeout: modalTimeout,
    onPrompt: onPrompt,
    onIdle: onIdle,
  });

  return (
    <React.Fragment>
      {children}

      <Modal
        title="Session Expired"
        visible={showModal}
        closable={false}
        maskClosable={false}
        okText="Logout"
        cancelText="Stay Logged in"
        onOk={() => logOut()}
        onCancel={() => stayLoggedIn()}
        className="common-modal-style"
      >
        You have been inactive for a while.
      </Modal>
    </React.Fragment>
  );
}

export { IdleTimer };
