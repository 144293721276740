import React from "react";
import { DatePicker as AntDatePicker, Form } from "antd";

export const DatePicker = ({
  name,
  onChange,
  label,
  format = "DD-MM-YYYY",
  useWeekdaysShort = false,
  rules,
  disabledDate,
  disabled,
  inputReadOnly = true,
  onKeyDown,
  onInput,
  onKeyPress,
  id,
  placeholder
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      className="date-picker-wrapper"
    >
      <AntDatePicker
        id={id}
        disabled={disabled}
        useWeekdaysShort={useWeekdaysShort}
        placeholder={placeholder ? placeholder: format}
        onChange={onChange}
        format={format}
        disabledDate={disabledDate}
        inputReadOnly={inputReadOnly}
        onKeyDown={onKeyDown}
        onInput={onInput}
        onKeyPress={onKeyPress}
      />
    </Form.Item>
  );
};
