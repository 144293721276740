import { Col, Row } from "antd";
import { Select, TextField } from "../../../../../design-systems";
import { mandatory, maxLength, validateZipCode } from "../../../../../utils/validations";
import { validateCity } from "../../../../../utils/validations";
import { STATE_NAMES } from "../../../../../utils/constants";

export const CorporateAddress = () => (
  <Row gutter={16}>
    <Col xs={24} lg={8}>&nbsp;</Col>
    <Col xs={24} lg={16}>
      <div className="should-update-col division-layout">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <TextField
              label="Address line 1"
              placeholder="Enter address line 1"
              name="corporate_address_line_1"
              maxLength={100}
              rules={[mandatory({ message: "Please enter a valid address line 1" }), maxLength(100)]}
            />
          </Col>
          <Col xs={24} md={12}>
            <TextField
              label="Address line 2"
              placeholder="Enter address line 2"
              name="corporate_address_line_2"
            />
          </Col>
          <Col xs={24} md={12}>
            <TextField
              label="City"
              placeholder="Enter city"
              name="corporate_city"
              onKeyPress={(e) => {
                if (!/^[A-Za-z\s-]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              rules={[mandatory({ message: "Please enter a valid city" }),]}
            />
          </Col>
          <Col xs={24} md={12}>
            <Select
              label="State"
              placeholder="Select state"
              name="corporate_state"
              options={STATE_NAMES.map(({ abbreviation }) => ({
                label: abbreviation,
                value: abbreviation,
              }))}
              rules={[mandatory({ message: "Please select a valid state" })]}
            />
          </Col>
          <Col xs={24} md={12}>
            <TextField
              label="Zip code"
              placeholder="Enter zip code"
              name="corporate_zip_code"
              onKeyPress={(event) => {
                if (!/[0-9-]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={(event) => {
                const clipboardData = event.clipboardData || window.clipboardData;
                const pastedText = clipboardData.getData('Text');
            
                if (!/^\d+$/.test(pastedText)) {
                  event.preventDefault();
                }
              }}
              maxLength={5}
              rules={[mandatory({ message: "Please enter a valid zip code" }), validateZipCode()]}
            />
          </Col>
        </Row>
      </div>
    </Col>

  </Row>
);
