import React, { useState } from "react";
import { fixAmountFormat, getExpenseGroupLabel, removeDuplicates } from "../../../../utils";
import { Spin, Card, Radio, Empty } from "antd";
import loaderLogo from "../../../../assets/img/incomm-loader.svg"
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { getExpenseGroupDetails } from "../../../../redux/actions";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Table, ExpandIcon } from "../../../../design-systems";
import { ExpandedModalView } from "./ExpandedViewModal";
import { SearchInput } from "../../../../pages/admin/employee/SearchInput";

const renderExpensesDetails = (
  data,
  handleFilterChange,
  filter,
  expenseData,
  columns,
  expenses,
  loading,
  open,
  isModalOpen,
  applySearchFilter,
  modalCurrentPage,
  currentPage,
  setModalCurrentPage,
  setCurrentPage,
  setOpen,
  search,
  setSearch,
  totalCount,
  limit,
  customLoader,
  modalExpenses,
  modalFilter,
  modalLoading
) => {
  const getFilteredExpenses = (isModalOpen, modalFilter, modalExpenses, expenses, filter) => {
    let filteredExpenses;

    if (isModalOpen) {
      filteredExpenses = modalFilter === "ALL"
        ? removeDuplicates(modalExpenses?.["TABLE_DATA"], "expense_id")
        : removeDuplicates(modalExpenses?.[modalFilter], "expense_id") ?? [];
    } else {
      filteredExpenses = removeDuplicates(expenses?.[filter], "expense_id")?.slice(0, 5) ?? [];
    }

    return filteredExpenses.sort((a, b) => new Date(b.updated_on) - new Date(a.updated_on));
  };

  const currentPageValue = isModalOpen ? modalCurrentPage : currentPage;
  const setPage = isModalOpen ? setModalCurrentPage : setCurrentPage;
  return (
    <Card bordered={false} className={!isModalOpen ? "account-holder-cards" : ""}>
      <div className="d-flex align-items-center justify-space-between">
        {
          !isModalOpen && <Typography
            variant="subhead-3"
            label={"Expenses"}
            className="employer-name"
          />
        }
        {
          !isModalOpen &&
          <div onClick={() => setOpen(true)}>
            <ExpandIcon />
          </div>
        }
      </div>
      <div className="d-flex align-items-center justify-space-between gap-30 mt-20 flex-wrap">
        <Radio.Group
          onChange={handleFilterChange}
          value={isModalOpen ? modalFilter : filter}
          name="expenses-filters"
          defaultValue="ALL"
          className="transactions-radio d-flex align-items-center  gap-30"
        >
          {expenseData && Object.keys(expenseData)?.length > 0 && <Radio.Button className="transactions-filters" value="ALL">
            All
          </Radio.Button>}
          {
            expenseData && Object.keys(expenseData)?.length > 0 && Object.keys(expenseData)?.map(filter => (
              <Radio.Button className="transactions-filters" value={filter}>
                {`${getExpenseGroupLabel[filter]} (${Number(data[filter])})`}
              </Radio.Button>
            ))
          }
        </Radio.Group>
      </div>
      {isModalOpen && <div className="mt-30">
        {
          getFilteredExpenses(isModalOpen, modalFilter, modalExpenses, expenses, filter)?.length && !modalLoading ?
            <Table
              bordered
              columns={columns}
              dataSource={getFilteredExpenses(isModalOpen, modalFilter, modalExpenses, expenses, filter)}
              pagination={isModalOpen ? {
                onChange: (page) => setPage(page),
                total: totalCount,
                current: currentPageValue,
                defaultCurrentPage: 1,
                defaultPageSize: limit,
              } : false}
              scroll={
                data?.length ? { x: 1200 } : undefined
              }
              tableLayout="auto"
              className="compact-table mb-15 expensev2-table expenses-section-table activity-logs-table"
            /> :
              !modalLoading ? <Empty /> : customLoader
        }
      </div>}
     {!isModalOpen && <div className="mt-30">
        {
          getFilteredExpenses(isModalOpen, modalFilter, modalExpenses, expenses, filter)?.length && !loading ?
            <Table
              bordered
              columns={columns}
              dataSource={getFilteredExpenses(isModalOpen, modalFilter, modalExpenses, expenses, filter)}
              pagination={isModalOpen ? {
                onChange: (page) => setPage(page),
                total: totalCount,
                current: currentPageValue,
                defaultCurrentPage: 1,
                defaultPageSize: limit,
              } : false}
              scroll={
                data?.length ? { x: 1200 } : undefined
              }
              tableLayout="auto"
              className="compact-table mb-15 expensev2-table expenses-section-table activity-logs-table"
            /> :
              !loading ? <Empty /> : customLoader

        }
      </div>}
    </Card>
  )
}

function ExpensesSectionV2({ data, zan }) {
  const dispatch = useDispatch()
  const handleFilterChange = (e) => {
    open ? setModalFilter(e.target.value) : setFilter(e.target.value);
  };
  const [filter, setFilter] = useState('ALL');
  const [modalFilter, setModalFilter] = useState('ALL');
  const [expenseData, setExpenseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [expenses, setExpenses] = useState({
    "TAKE_ACTION": [],
    "SETTLED": [],
    "CANCELLED": [],
    "AWAITING_REVIEW": [],
    "DENIED": [],
    "REIMBURSE": [],
    "ALL": [],
    "TABLE_DATA": []
  })
  const [modalExpenses, setModalExpenses] = useState({
    "TAKE_ACTION": [],
    "SETTLED": [],
    "CANCELLED": [],
    "AWAITING_REVIEW": [],
    "DENIED": [],
    "REIMBURSE": [],
    "ALL": [],
    "TABLE_DATA": []
  })

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit] = useState(25)

  const sortExpesesByTransactionDate = (expenses) => {
    return expenses.sort((a, b) => new Date(b.updated_on) - new Date(a.updated_on));
  }

  const getExpenseForAll = () => {
    const currentFilter = open ? modalFilter : filter;
    if (currentFilter === "ALL") {
      const filters = data && Object.keys(data).filter(key => data[key] > 0);
      if (filters && filters?.length > 0) {
        open ? setModalLoading(true) : setLoading(true);
        const promises = filters.map(f => {
          const apiData = { zan: zan, expense_group: f };
          return new Promise((resolve, reject) => {
            dispatch(getExpenseGroupDetails(apiData, {
              limit, page: modalCurrentPage
            }, (res) => {
              if (!(res instanceof Error)) {
                resolve(res);
              } else {
                reject(res);
              }
            }));
          });
        });

        Promise.all(promises)
          .then((all_expenses) => {
            const exp = !open ? { ...expenses } : { ...modalExpenses };
            const uniqueExpenses = removeDuplicates(all_expenses.flat(), "expense_id")
            exp["ALL"] = sortExpesesByTransactionDate(uniqueExpenses);
            if (!open) {
              setModalExpenses({
                ...modalExpenses,
                ["TABLE_DATA"]: sortExpesesByTransactionDate(uniqueExpenses)
              });
            } else {
              exp["TABLE_DATA"] = sortExpesesByTransactionDate(uniqueExpenses);
            }
            open ? setModalExpenses(exp) : setExpenses(exp);
          })
          .finally(() => {
            open ? setModalLoading(false) : setLoading(false);
          });
      }
    } else {
      getExpensesByFilter(currentFilter, "line 189")
    }
  }


  useEffect(() => {
    getExpenseForAll("line 194")
    setModalCurrentPage(1)
    if (modalFilter !== "ALL") {
      setTotalCount(removeDuplicates(data[modalFilter], "expense_id"))
    } else {
      if (data !== null) {
        setTotalCount(Object?.values(data)?.reduce((acc, curr) => acc + curr, 0))
      }
    }
  }, [modalFilter, filter, data])

  useEffect(() => {
    getExpenseForAll();
  }, [modalCurrentPage]);


  const getExpensesByFilter = (key, from) => {
    open ? setModalLoading(true) : setLoading(true);
    const apiData = { zan: zan, expense_group: key };
    dispatch(getExpenseGroupDetails(apiData, {
      limit, page: modalCurrentPage
    }, (res => {
      if (!(res instanceof Error)) {
        open ? setModalLoading(false) : setLoading(false);
        const exp = !open ? { ...expenses } : { ...modalExpenses };
        const uniqueExpenses = removeDuplicates(res, "expense_id");
        exp[key] = sortExpesesByTransactionDate(uniqueExpenses);
        open ? setModalExpenses(exp) : setExpenses(exp);
      }
    })))
  }

  const handleClaimClick = (e, id) => {
    e.preventDefault();
    const newTab = window.open(`/admin/claims/details/${id}`, '_blank');
    if (newTab !== null) {
      newTab.propValue = "accountHolder";
    }
  }

  const renderClaimIDLink = (id) => {
    if (!id) return ""
    return <Link
      to={{ pathname: `/admin/claims/details/${id}` }}
      target="_blank"
      onClick={(e) => handleClaimClick(e, id)}
    >
      {id}
    </Link>
  }

  useEffect(() => {
    if (open) {
      setModalCurrentPage(currentPage);
      setModalFilter(filter);
      if (!modalExpenses[filter] || modalExpenses[filter].length === 0) {
        const currentFilteredData = expenses[filter] || [];
        // setTotalCount(removeDuplicates(expenses["ALL"], "expense_id")?.length)
        setModalExpenses({
          ...modalExpenses,
          [filter]: currentFilteredData
        });
      }
    }
  }, [open, filter, expenses])

  useEffect(() => {
    if (data !== null) {
      const filteredExpensesCount = Object.fromEntries(
        Object.entries(data)?.filter(([key, value]) => value !== 0)
      );
      setExpenseData(filteredExpensesCount)
    }
  }, [data])

  const customLoader = (
    <Spin
      spinning={loading}
      tip={<img src={loaderLogo} alt="Incomm Benefits-logo" className="loader-logo" />}
      className="page-loader-expense"
      size="large"
    >
    </Spin>
  )

  const customModalLoader = (
    <Spin
      spinning={modalLoading}
      tip={<img src={loaderLogo} alt="Incomm Benefits-logo" className="loader-logo" />}
      className="page-loader-expense"
      size="large"
    >
    </Spin>
  )

  const applySearchFilter = (value) => {
    setSearch(value)
    setModalCurrentPage(1)
  };

  useEffect(() => {
    setModalCurrentPage(1)
    if (search) {
      const updatedExpenses = { ...modalExpenses };
      const currentFilter = open ? modalFilter : filter;
      updatedExpenses["TABLE_DATA"] = modalExpenses[currentFilter].filter(item => {
        return (
          item?.claim_id === search.toLowerCase() ||
          item?.expense_id === search.toLowerCase() ||
          item?.merchant?.toLowerCase().includes(search.toLowerCase())
        )
      });
      setModalExpenses(updatedExpenses);
    } else {
      getExpenseForAll("line 316")
    }
  }, [search])

  const collapsedColumns = [
    {
      title: "Transaction date",
      dataIndex: "updated_on",
      render: (date) => (date ? moment(date).format("MM/DD/YYYY") : null),
    },
    {
      title: "Claim ID",
      dataIndex: "claim_id",
      render: (id) => renderClaimIDLink(id),
    },
    {
      title: "Merchant",
      dataIndex: "merchant",
    },
    {
      title: "Expense amount",
      dataIndex: "expense_amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right",
    },
    {
      title: "Expense ID",
      dataIndex: "expense_id",
    },
  ];

  const modalColumns = [
    {
      title: "Type",
      dataIndex: "claim_status",
    },
    ...collapsedColumns,
  ];

  return (
    <>
      {
        renderExpensesDetails(
          data,
          handleFilterChange,
          filter,
          expenseData,
          collapsedColumns,
          expenses,
          loading,
          open,
          false,
          applySearchFilter,
          modalCurrentPage,
          currentPage,
          setModalCurrentPage,
          setCurrentPage,
          setOpen,
          search,
          setSearch,
          totalCount,
          limit,
          customLoader,
          modalExpenses,
          modalFilter,
          modalLoading
        )
      }
      <ExpandedModalView
        open={open}
        setOpen={setOpen}
        title={"Expenses"}
        afterClose={() => {
          setModalCurrentPage(1);
          setSearch("");
        }}
        renderJSX={() => renderExpensesDetails(
          data,
          handleFilterChange,
          filter,
          expenseData,
          modalColumns,
          expenses,
          loading,
          open,
          true,
          applySearchFilter,
          modalCurrentPage,
          currentPage,
          setModalCurrentPage,
          setCurrentPage,
          setOpen,
          search,
          setSearch,
          totalCount,
          limit,
          customModalLoader,
          modalExpenses,
          modalFilter,
          modalLoading
        )}
      />
    </>
  );
}

export { ExpensesSectionV2 };
