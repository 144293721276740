import { CLAIMS_CONTANT } from "../../utils/constants";

const initialState = {
  claimsSummary: {},
  claimsStatus: [],
  claimsDetails: {},
  claimsDocs: [],
  internalNotes: [],
  claimsSignedURLs: [],
  claimActionReasons: [],
  currentClaimId: "",
  claimListApiData: {},
  activityLogs: []
};

export function claims(state = initialState, action) {
  switch (action.type) {
    case CLAIMS_CONTANT.SAVE_CLAIMS_SUMMARY:
      return { ...state, claimsSummary: action.payload.data };
    case CLAIMS_CONTANT.SAVE_CLAIMS_STATUS:
      return { ...state, claimsStatus: action.payload.data }
    case CLAIMS_CONTANT.RESET_CLAIMS_SUMMARY:
      return { ...state, claimsSummary: [] }
    case CLAIMS_CONTANT.SAVE_CLAIM_DETAILS_BY_ID:
      return {
        ...state,
        claimsDetails: action?.payload?.data,
        internalNotes: action?.payload?.data?.internal_notes
      }
    case CLAIMS_CONTANT.SAVE_INTERNAL_NOTES:
      const notes = [...state.internalNotes]
      notes.unshift(action?.payload?.data)
      return { ...state, internalNotes: notes }
    case CLAIMS_CONTANT.SAVE_CLAIMS_DOCUMENTS:
      return { ...state, claimsDocs: action?.payload?.data }
    case CLAIMS_CONTANT.SAVE_CLAIMS_PRE_SIGNED_URL:
      return { ...state, claimsSignedURLs: action.payload.data }
    case CLAIMS_CONTANT.SAVE_CURRENT_CLAIM_ID:
      return { ...state, currentClaimId: action.payload }
    case CLAIMS_CONTANT.RESET_CLAIMS_DETAILS:
      return {
        ...state,
        claimsDetails: {},
        claimsDocs: [],
        internalNotes: [],
        claimsSignedURLs: []
      }
    case CLAIMS_CONTANT.SAVE_CLAIM_ACTIVITY_LOG:
      return  {
        ...state,
        activityLogs: action.payload
      }
    case CLAIMS_CONTANT.SAVE_CLAIM_LIST_API_DATA:
      return {
        ...state,
        claimListApiData: action.payload
      }
    case CLAIMS_CONTANT.SAVE_CLAIM_ACTION_REASONS:
      return {
        ...state,
        claimActionReasons: action.payload
      }
    default:
      return state;
  }
}
