export const ExpandIcon = ({style}) => (
    <svg style={{ cursor: "pointer" }} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4088_22299)">
            <path d="M16 4.51367H20V8.51367" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 10.5137L20 4.51367" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 20.5137H4V16.5137" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 20.5137L10 14.5137" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 20.5137H20V16.5137" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 14.5137L20 20.5137" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 4.51367H4V8.51367" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 4.51367L10 10.5137" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_4088_22299">
                <rect width="24" height="24" fill="white" transform="translate(0 0.513672)" />
            </clipPath>
        </defs>
    </svg>
)