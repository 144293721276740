import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setShowSecondSection } from "../redux/actions/index";
import { ROLE_CONSTANT } from "../utils/constants";

const useShowSecondSection = () => {
    const dispatch = useDispatch()
    const role = useSelector((state) => state?.userDetails?.roleType);
    useEffect(() => {
        if (role === ROLE_CONSTANT.ZENDA_ADMIN && sessionStorage.getItem("isSwitchEmpClicked")) {
            dispatch(setShowSecondSection(true))
        }
    }, [dispatch]);

}

export default useShowSecondSection
