import { Col, Row } from "antd";
import { CheckBox, Typography } from "../../../../design-systems";

export const PanelHeader = ({ page,label, form, expandedPanels, setExpandedPanels }) => {
  let plans;
  if(form) {
    plans = form.getFieldValue("plans");
  }
  const isHSAOnly = plans?.length === 1 && plans?.every(plan => plan.toLowerCase() === "hsa")
  if (label === "Mailing address") {
    return (
      <div className="add-divisions-header">
        <Row gutter={16}>
          <Col xs={24} lg={8}>
            <Typography variant="subtitle-large" label={label} />
          </Col>
          <Col xs={24} lg={16}>
            <CheckBox
              label="Same as corporate address"
              name="same_as_corporate"
              onChange={(e) => {
                // !e.target.checked && form.setFieldsValue({ divisions: [] })
                const expPanels = [...expandedPanels];
                expPanels.push("mailing-address")
                setExpandedPanels(expPanels)
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
  if (label === "Add divisions") {
    return (
      <div className="add-divisions-header">
        <Row gutter={16}>
          <Col xs={24} lg={8}>
            <Typography variant="subtitle-large" label={label} />
          </Col>
          <Col xs={24} lg={16}>
            <CheckBox
              label="Add Divisions"
              name="add_division"
              onChange={(e) => {
                !e.target.checked && form.setFieldsValue({ divisions: [] })
                const expPanels = [...expandedPanels];
                expPanels.push("add-division")
                setExpandedPanels(expPanels)
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
  // Add copays
  if (label === "Add copays") {
    return (
      <div className="add-divisions-header">

        <Row gutter={16}>
          <Col xs={24} lg={8}>
            <Typography variant="subtitle-large" label={label} />
          </Col>
          <Col xs={24} lg={16}>
            <div>
              <CheckBox
                label="Add Copays"
                name="add_copay"
                disabled={isHSAOnly}
                onChange={(e) => {
                  !e.target.checked && form.setFieldsValue({ copays: [], add_copay: e.target.checked })
                  const expPanels = [...expandedPanels];
                  expPanels.push("add-copays")
                  setExpandedPanels(expPanels)
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <Typography variant="subtitle-large" label={label} />
  )
};
