import { ApiErrors } from "../../utils";
import { Auth as CAuth } from "aws-amplify";
import { AUTH_CONSTANT, USER_CONSTANT } from "../../utils/constants";
import { clearLocalStorage } from "../../utils";
import { disableGlobalLoader, enableGlobalLoader } from ".";
import {
  completeAccountSetupService,
  forgotPasswordService,
  loginByOTPService,
  loginWithoutOTPService,
  loginService,
  resendOTPService,
  resetPasswordService,
  setNewPasswordService,
  setPhoneNumberService,
  loginWithPhoneNumberVerificationService,
  loginWithSSOService,
  tokenRevokeService,
} from "../../services";

export function login(reqData, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    loginService(reqData)
      .then((user) => {
        dispatch({ type: USER_CONSTANT.SAVE_NON_VERIFIED_USER, user });
        callback && callback(user);
      })
      .catch((err) => {
        callback && callback(err);
        dispatch(disableGlobalLoader());
      })
      .finally(() => {});
  };
}

export function loginByOTP(reqData, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    loginByOTPService(reqData)
      .then((user) => {
        dispatch({ type: USER_CONSTANT.SAVE_LOGGED_IN_DETAILS, user });
        dispatch({ type: USER_CONSTANT.SAVE_ROLE_TYPE, role: user.role });
        callback && callback(user);
      })
      .catch((err) => {
        callback && callback(err);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}

export function loginWithoutOTP(loggedInUser, callback) {
  return (dispatch) => {
    loginWithoutOTPService(loggedInUser)
      .then((user) => {
        dispatch({ type: USER_CONSTANT.SAVE_LOGGED_IN_DETAILS, user });
        dispatch({ type: USER_CONSTANT.SAVE_ROLE_TYPE, role: user.role });
        callback && callback(user);
      })
      .catch((err) => {
        callback && callback(err);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}

export function loginWithSSO(userData, callback) {
  return (dispatch) => {
    const nonVerifiedUser = { cognitoUser: userData.currentUser };
    const user = { cognitoUser: userData.currentUser, role: userData.role };
    dispatch({
      type: USER_CONSTANT.SAVE_NON_VERIFIED_USER,
      user: nonVerifiedUser,
    });

    loginWithSSOService()
      .then((res) => {
        if (!res.error) {
          dispatch({ type: USER_CONSTANT.SAVE_LOGGED_IN_DETAILS, user });
          dispatch({ type: USER_CONSTANT.SAVE_ROLE_TYPE, role: user.role });
        }
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {});
  };
}

export function forgotPassword(reqData, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    forgotPasswordService(reqData)
      .then((user) => {
        callback && callback(user);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}

export function resetPassword(reqData, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    resetPasswordService(reqData)
      .then((user) => {
        callback && callback(user);
      })
      .catch((err) => {
        callback && callback(err);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(enableGlobalLoader());

    CAuth.signOut();
    clearLocalStorage();
    window.location.assign("/auth/login");
    dispatch({ type: AUTH_CONSTANT.LOGOUT });

    dispatch(enableGlobalLoader());
  };
}

export function setNewPassword(reqData, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    setNewPasswordService(reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        dispatch(disableGlobalLoader());
      })
      .finally(() => {});
  };
}

export function completeSetup(cognitoUser, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    completeAccountSetupService(cognitoUser)
      .then((res) => {
        dispatch({
          type: USER_CONSTANT.SAVE_LOGGED_IN_DETAILS,
          user: res.userData,
        });
        dispatch({
          type: USER_CONSTANT.SAVE_ROLE_TYPE,
          role: res.userData.role,
        });
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}

export function tokenRevoke(callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    tokenRevokeService()
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}

export function setPhoneNumber(reqData, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    setPhoneNumberService(reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}

export function loginWithPhoneNumberVerification(reqData, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    loginWithPhoneNumberVerificationService(reqData)
      .then((user) => {
        dispatch({ type: USER_CONSTANT.SAVE_LOGGED_IN_DETAILS, user });
        dispatch({ type: USER_CONSTANT.SAVE_ROLE_TYPE, role: user.role });
        callback && callback(user);
      })
      .catch((err) => {
        callback && callback(err);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}

export function resendOTP(callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    resendOTPService()
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableGlobalLoader());
      });
  };
}
