import { Col, Form, Row } from "antd";
import { Radio } from "../../../../../design-systems";
import { mandatory } from "../../../../../utils/validations";

export const AddAdditionalDetails = () => {
    return (
        <Row gutter={16}>
            <Col xs={24} md={24}>
                <Form.Item
                    shouldUpdate
                    className="should-update-form-item"
                >
                    <Radio
                        label="Where would you like to create this employer ?"
                        rules={[
                            mandatory({
                                message: "Please select a valid option",
                            }),
                        ]}
                        data={[
                            { label: "Serve", value: "SERVE" },
                            { label: "Galileo", value: "Galileo" },
                        ]}
                        name="processor"
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};
