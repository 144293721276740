import { reactLocalStorage } from "reactjs-localstorage";

// Get Stored user credentials and login detail in sessionStorage variable
export const getLocalStorage = () => {
  const authDetails = reactLocalStorage.get("AuthenticationResult");

  return {
    authDetails: authDetails && JSON.parse(authDetails),
  };
};

// Get Stored user credentials and login detail in sessionStorage variable
export const clearLocalStorage = () => {
  reactLocalStorage.clear();
};

// Store tokens after authorization
export const setAuthorization = (res) => {
  reactLocalStorage.set("AuthenticationResult", JSON.stringify(res));
};
