import { Form, Input } from "antd";

export const TextField = ({
  type,
  name,
  label,
  rules,
  placeholder,
  supportiveText,
  suffix,
  prefix,
  disabled,
  showLarge,
  allowClear = false,
  id,
  maxLength,
  ...props
}) => (
  <Form.Item
    id={id}
    name={name}
    label={label}
    rules={rules}
    help={supportiveText}
    autoComplete="off"
    className={`text-field-wrapper ${showLarge && "large"}`}
  >
    <Input
     autoComplete="off"
      maxLength={maxLength}
      type={type}
      allowClear={allowClear}
      placeholder={placeholder}
      suffix={suffix}
      prefix={prefix}
      disabled={disabled}
      {...props}
    />
  </Form.Item>
);
