export const ErrorNotification = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 12C2 6.47 6.47 2 12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12ZM14.8907 16.2987C15.2807 16.6888 15.9107 16.6888 16.3007 16.2987C16.6807 15.9087 16.6807 15.2688 16.3007 14.8888L13.4107 11.9988L16.3007 9.10875C16.6907 8.71875 16.6907 8.08875 16.3007 7.69875C15.9107 7.30875 15.2807 7.30875 14.8907 7.69875L12.0007 10.5887L9.11069 7.69875C8.72069 7.30875 8.09069 7.30875 7.70069 7.69875C7.51344 7.88558 7.4082 8.13923 7.4082 8.40375C7.4082 8.66827 7.51344 8.92192 7.70069 9.10875L10.5907 11.9988L7.70069 14.8888C7.51344 15.0756 7.4082 15.3292 7.4082 15.5938C7.4082 15.8583 7.51344 16.1119 7.70069 16.2987C8.09069 16.6888 8.72069 16.6888 9.11069 16.2987L12.0007 13.4088L14.8907 16.2987Z"
      fill="#AA232C"
    />
  </svg>
);
