import React, { useEffect, useMemo, useState } from "react";
import { useRouter } from "../../../utils/router/customRouterHook";
import {
  accountHolderIdMap,
  comparisonValues,
  getEmployeeCensusFileListingColumns,
  getEmployeeListingColumns,
} from "../../../utils";
import { getAllUploadedFiles, getAllUploadedFilesWithoutLoader, getEmployeesListWithFilters, getEmploymentStatuses, showInfoMessage } from "../../../redux/actions";

import { SpinLoader } from "../../../components/common/SpinLoader";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { Card, Col, Form, Select, Row } from "antd";
import { useDispatch } from "react-redux";
import {
  Button,
  Tabs,
  Typography,
  Table,
  TextField
} from "../../../design-systems";
import { UPLOADED_FILE_STATUSES } from "../../../utils/constants";
import { DownloadFileTemplateButton } from "../../../components/common/DownloadFileTemplateButton";
import { useSelector } from "react-redux";
import { langs } from "../../../i18n";
import useShowSecondSection from "../../../hooks/useShowSection";
import { SearchInput } from "./SearchInput";

export const List = () => {
  const dispatch = useDispatch();
  const [lastChangedFilter, setLastChangedFilter] = useState(null);
  const router = useRouter();
  const { employeesList, uploadedFiles } = useSelector((state) => state.employees);
  const [form] = Form.useForm();
  const [search, setSearch] = useState("")
  const [search1, setSearch1] = useState("")
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit] = useState(10)
  const [sortFields, setSortFields] = useState({
    sort: "",
    order: ""
  })
  const [searchBy, setSearchBy] = useState(null);
  const [empList, setEmpList] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [searchRes, setSearchRes] = useState(null);
  const [selectedTab, setSelectedTab] = useState(router?.history?.location?.state?.showCensusTab ? "2" : "1");
  const [intervalId, setIntervalId] = useState(null);
  const [filter, setFilter] = useState(null);
  const [formValues, setFormValues] = useState({});
  
  const [selectedOptions, setSelectedOptions] = useState(["first_name", "last_name"]);
  
  const handleChange = (value) => {
    setSelectedOptions(value);
  };
  const controller = new AbortController();
  const signal = controller.signal;

  const { Option } = Select

  const isEmailSelected = useMemo(() => selectedOptions.includes('email'), [selectedOptions]);
  const isNameSelected = useMemo(() => selectedOptions.includes('first_name') || selectedOptions.includes('last_name'), [selectedOptions]);

  let checkFileStatusIntervalId;

  useShowSecondSection();

  useEffect(() => {
    const initializeFiltersAndFetchData = async () => {
      if (router.location?.state?.userStatus) {
        setLastChangedFilter("account_holder_status_id");
        setFilter({ "account_holder_status": [router.location.state.userStatus], kyc_status: [] });
      }

      dispatch(getEmploymentStatuses());

      if (router?.history?.location?.state?.showCensusTab) {
        setSelectedTab("2");
      } else {
        setSelectedTab("1");
      }
    };

    initializeFiltersAndFetchData();

    return () => {
      clearInterval(intervalId || checkFileStatusIntervalId);
    };
  }, [router.location, router.history, dispatch]);


  useEffect(() => {
    setSearch1("")
    setSearch("")
    setCurrentPage(1)
    setTotalCount(0)
    if (selectedTab === "2") {
      getAllFiles();
    } else if (selectedTab === "1") {
      clearInterval(intervalId || checkFileStatusIntervalId);
      getAllEmployee(signal)
    }
  }, [selectedTab])

  useEffect(() => {
    if (selectedTab === "1") {
      setEmpList(employeesList?.employees)
      setSearchRes(employeesList?.employees);
      setTotalCount(employeesList?.total_records)
    }
  }, [employeesList, selectedTab])

  useEffect(() => {
    if (selectedTab === "2") {
      setEmpList(uploadedFiles?.data)
      setSearchRes(uploadedFiles?.data);
      setTotalCount(uploadedFiles?.total_records)
    }
  }, [uploadedFiles, selectedTab])

  useEffect(() => {
    setCurrentPage(1)
    clearInterval(intervalId || checkFileStatusIntervalId)
  }, [search, sortFields])

  const getStatusFilterKey = () => {
    if (filter && Object.keys(filter)?.length > 0) {
      if (lastChangedFilter) {
        return lastChangedFilter;
      }
    }
    return null;
  };

  const getAccountHolderId = () => {
    let result = [];
    if (filter && Object?.keys(filter)?.length) {
      if (filter?.account_holder_status?.length) {
        for (const status of filter?.account_holder_status) {
          if (accountHolderIdMap[status]) {
            result.push(accountHolderIdMap[status]);
          }
        }
      }
    }
    return result;
  };

  const applySearchFilter = (value) => {
    setSearch(value)
  };

  const getAllEmployee = (signal) => {
    const payload = {
      page: currentPage,
      limit: limit,
      account_holder_filter: getAccountHolderId() ?? [],
      kyc_status_filter: filter?.kyc_status ?? []
    };
    if (sortFields.sort) {
      payload.sort = sortFields.sort;
      payload.order = sortFields.order;
    }
    if (selectedOptions?.length && (search && Object.keys(search)?.length)) {
      payload.search = search
    }
    dispatch(getEmployeesListWithFilters(payload, (res) => {
      setSearchRes(res.employees);
      setTotalCount(res.total_records);
      if (router.query.searchTerm) {
        router.replace("/admin/employees/employee-list");
      } else {
        setSearchRes(res.employees);
      }
    }, signal));
  };


  const getAllFiles = () => {
    const data = {
      search: search,
      page: currentPage,
      limit: limit,
      sort: sortFields.sort,
      order: sortFields.order,
    }
    dispatch(getAllUploadedFiles(data, (res) => {
      setFileList(res.data.data);
      setSearchRes(res.data.data);
      checkFileStatus(res.data.data);
    }))
  };
  const currentSelectedTab = useMemo(() => {
    if (router?.history?.location?.state?.showCensusTab) {
      return "2"
    } else {
      return "1"
    }
  }, [selectedTab, router?.history?.location?.state?.showCensusTab]);
  useEffect(() => {
    clearInterval(intervalId || checkFileStatusIntervalId)
    selectedTab === "1" ? getAllEmployee(signal) : getAllFiles()
    return () => {
      controller.abort()
    };
  }, [currentPage, limit, sortFields, search, filter]);

  const updatedFilters = useMemo(() => {
    return filter
  }, [filter]);


  const activeTabColumns = useMemo(() => {
    if (selectedTab === "2") {
      return getEmployeeCensusFileListingColumns();
    }

    return getEmployeeListingColumns(updatedFilters);
  }, [selectedTab, filter, updatedFilters]);

  const checkFileStatus = (files) => {
    const needCheck = files?.some(
      (file) =>
        file?.execution_status === UPLOADED_FILE_STATUSES.VALIDATION_IN_PROGRESS,
    );

    if (needCheck && !checkFileStatusIntervalId) {
      checkFileStatusIntervalId = setInterval(getAllFilesWithoutLoader, 5000);
      setIntervalId(checkFileStatusIntervalId)
    }

    if (!needCheck) {
      clearInterval(intervalId || checkFileStatusIntervalId);
      setIntervalId(null);
    }
  };

  const getAllFilesWithoutLoader = () => {
    const data = {
      search: search,
      page: currentPage,
      limit: limit,
      sort: sortFields.sort,
      order: sortFields.order,
    }
    dispatch(
      getAllUploadedFilesWithoutLoader(data, (res) => {
        setFileList(res?.data?.data);
        setSearchRes(res?.data?.data);

        checkFileStatus(res?.data?.data);
      }),
    );
  };



  const handleTableChange = (pagination, filters, sorter, { _, action }) => {
    switch (action) {
      case 'sort': {
        if (!sorter?.column) {
          setSortFields({
            sort: "",
            order: ""
          });
          return;
        }
        setSortFields({
          sort: sorter.field,
          order: sorter.order === "ascend" ? "asc" : "desc"
        })
        break;
      }
      case 'filter':
        setFilter(filters);
        break;

      default:
        break;
    }
  }


  const onRowClick = (file) => {
    if (
      file.execution_status === UPLOADED_FILE_STATUSES.VALIDATION_IN_PROGRESS
    ) {
      dispatch(showInfoMessage(langs.messages.file_validation_in_progress));
    } else {
      clearInterval(intervalId || checkFileStatusIntervalId);
      router.push(`/admin/employees/verification-census-file/${file.file_id}`);
    }
  };


  useEffect(() => {
    if (!searchBy?.length) {
      setSearch("");
      setSearch1("");
    }
  }, [searchBy]);

  const onFinish = (values) => {
  }

  const disableButtons = useMemo(() => {
    return formValues && Object.values(formValues)?.some(value => value && value.trim().length > 0);
  }, [formValues])


  return (
    <HeaderWithContent>
      <Row gutter={[4, 4]}>
        <Col span={24}>
          <Card title="" bordered={false} className="data-grid employee-listing-v2">
            <Typography
              label="Employee"
              variant="subhead-1"
              className="employee-listing-title"
            />
            <div className="employee-tabs-button-wrapper">
              <Col span="12">
                <Tabs
                  destroyInactiveTabPane={true}
                  className="employee-listing-tabs"
                  activeKey={selectedTab}
                  onTabChange={(activeKey) => {
                    setSearch("")
                    setSelectedTab(activeKey);
                  }}
                  tabs={[
                    {
                      key: "1",
                      title: "Employees",
                    },
                    {
                      key: "2",
                      title: "Employee census file",
                    },
                  ]}
                />
              </Col>
              <Col span="12">
                <div className="employee-actions-buttons">
                  <Button
                    variant="secondary"
                    onclickCallback={() => {
                      clearInterval(intervalId || checkFileStatusIntervalId);
                      router.push("/admin/employee/add/1")
                    }}
                    label="Add employee"
                  />
                  <DownloadFileTemplateButton employeeList />
                  <Button
                    variant="primary"
                    onclickCallback={() => {
                      clearInterval(intervalId || checkFileStatusIntervalId);
                      router.push("/admin/employees/upload-census-file")
                    }}
                    label="Upload census"
                  />
                </div>
              </Col>
            </div>
            <Form
              form={form}
              name="employee-search"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onValuesChange={(value, allValues) => {
                setFormValues(allValues)
              }}
            >
              <Row gutter={[8]}>
                {
                  selectedTab === "1" &&
                  <Col sm={6}>
                    <Form.Item>
                      <Select
                        mode="multiple"
                        className={"select-field-search"}
                        placeholder="Select field to search"
                        value={selectedOptions}
                        defaultValue={["first_name", "last_name"]}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      >
                        <Option value="email" disabled={isNameSelected}>
                          Email
                        </Option>
                        <Option value="first_name" disabled={isEmailSelected}>
                          First name
                        </Option>
                        <Option value="last_name" disabled={isEmailSelected}>
                          Last name
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                }
                {
                  selectedTab === "1" && selectedOptions.includes('first_name') &&
                  <Col sm={6}>
                    <Form.Item
                      name="first_name"
                    >
                      <TextField
                        placeholder="Enter first name"
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  selectedTab === "1" && selectedOptions.includes('last_name') &&
                  <Col sm={6}>
                    <Form.Item
                      name="last_name"
                    >
                      <TextField
                        placeholder="Enter last name"
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  selectedTab === "1" && selectedOptions.includes('email') &&
                  <Col sm={6}>
                    <Form.Item
                      name="email"
                    >
                      <TextField
                        placeholder="Enter email"
                      />
                    </Form.Item>
                  </Col>
                }
              </Row>
              {
                selectedTab === "1" &&
                <div className="d-flex gap-15">
                  <Button
                    disabled={!disableButtons}
                    variant="secondary"
                    label="Clear"
                    onclickCallback={() => {
                      form.resetFields();
                      setSearch({});
                      setFormValues({});
                    }}
                  />
                  <Button
                    disabled={!disableButtons}
                    variant="primary"
                    label="Get results"
                    onclickCallback={() => {
                      setSearch(formValues)
                    }}
                  />
                </div>
              }
              {
                selectedTab === "2" &&
                <Col xs={24}>
                  <SearchInput
                    value={search1}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setSearch1("")
                        setSearch("")
                      }
                      setSearch1(e.target.value)
                    }}
                    onSearch={(e) => applySearchFilter(e)}
                    placeholder="Search by census batch name"
                  />
                </Col>
              }
              <SpinLoader >
                <Col xs={24}>
                  <Table
                    className="claims-list-table employee-table"
                    columns={activeTabColumns}
                    dataSource={searchRes || []}
                    onChange={handleTableChange}
                    onRow={
                      selectedTab === "1" ?
                        (record) => ({
                          onClick: (e) => {
                            router.push({
                              pathname: `/admin/employee-detail/${record.employee_id}`,
                              state: record
                            });
                          },
                        }) :
                        (record) => ({
                          onClick: (e) => {
                            onRowClick(record)
                          },
                        })
                    }
                    rowClassName={"pointer"}
                    pagination={{
                      onChange: (page) => setCurrentPage(page),
                      total: totalCount,
                      current: currentPage,
                      defaultCurrentPage: 1,
                      defaultPageSize: limit,
                    }}
                  />
                </Col>
              </SpinLoader>

            </Form>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent >
  );
};
