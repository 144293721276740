import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout, tokenRevoke } from "../../redux/actions";

import { Menu, Dropdown, Divider } from "antd";
import { DownOutlined } from "@ant-design/icons";

function UserDropdown() {
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.userDetails?.adminDetails);

  const renderUserName = () => {
    const fullName = `${admin?.first_name.value} ${admin?.last_name.value}`;

    return admin ? fullName : "";
  };

  const userLogout = async () => {
    dispatch(
      tokenRevoke(() => {
        dispatch(logout());
      }),
    );
  };

  const handleMailClick = () => {
    const mailLink = document.createElement("a");
    mailLink.target = "blank"
    mailLink.href = "mailto:support@incommbenefits.com";
    mailLink.click();

  }

  const userDropdown = (
    <Menu>
      <h6 className="text-overflow m-0">Welcome!</h6>
      <Menu.Item key={1}>
        <Link to="/admin/profile" rel="noopener noreferrer">
          <i className="ni ni-single-02" />
          <span>My profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <div onClick={handleMailClick}>
          <i className="ni ni-support-16" />
          <span className="">Support</span>
        </div>
      </Menu.Item>
      <Divider />
      <Menu.Item key={3}>
        <Link to="/" rel="noopener noreferrer" onClick={userLogout}>
          <i className="ni ni-user-run" />
          <span>Logout</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={userDropdown} overlayClassName="user-dropdown-menu">
      <Link
        className="ant-dropdown-link"
        onClick={(e) => e.preventDefault()}
        to="/"
      >
        {renderUserName()} <DownOutlined />
      </Link>
    </Dropdown>
  );
}

export { UserDropdown };
