import axios from "./axiosInterceptors";

export const getExpensesTypeService = async (employee_id) => {
    try {
        const request = {
            method: "get",
            url: `/employer/payroll/${employee_id}/expense/types`
        }

        const response = await axios(request);
        return { error: false, data: response.data.data.types || null }
    } catch (error) {
        throw error;
    }
}


export const saveExpensesAsDraftService = async (id, data) => {
    try {
        const request = {
            method: "post",
            url: `/employer/payroll/${id}/expense/draft`,
            data
        }
        const response = await axios(request);
        return { error: false, data: response.data || null }
    } catch (error) {
        throw error;
    }
}

export const updateExpenseDraftService = async (id, draftId, data) => {
    try {
        const request = {
            method: "patch",
            url: `/employer/payroll/${id}/expense/draft/${draftId}`,
            data
        }

        const response = await axios(request);
        return { error: false, data: response.data || null }
    } catch (error) {
        throw error;
    }
}

export const uploadDraftExpenseDocumentService = async (id, draftId, data) => {
    try {
        const request = {
            method: "post",
            url: `/employer/payroll/${id}/expense/draft/${draftId}/documents`,
            data
        }

        const response = await axios(request);
        return { error: false, data: response.data || null }
    } catch (error) {
        throw error;
    }
}

export const deleteExpenseDraftDocumentsService = async (id, draftId, data) => {
    try {
        const request = {
            method: "delete",
            url: `/employer/payroll/${id}/expense/draft/${draftId}/documents`,
            data
        }
        const response = await axios(request);
        return { error: false, data: response.data || null }
    } catch (error) {
        throw error
    }
}

export const evaluateExpenseDraftService = async (id, data) => {
    try {
        const request = {
            method: "post",
            url: `/employer/payroll/${id}/expense/evaluate`,
            data
        }
        const response = await axios(request);
        return { error: false, data: response.data || null }
    } catch (error) {
        throw error
    }
}

export const createExpenseService = async (id, data) => {
    try {
        const request = {
            method: "post",
            url: `/employer/payroll/${id}/expense`,
            data
        }
        const response = await axios(request);
        return { error: false, data: response.data || null }
    } catch (error) {
        throw error
    }
}