import { Col, Row, Layout } from "antd";
import { Button } from "../../../../design-systems";

const { Footer: AntFooter } = Layout;

export const CopyPlanFooter = ({
  onNext,
  action,
  loading,
  saveAction
}) => (
  <AntFooter className="custom-add-employer-footer">
    <Row gutter={0} align="middle" justify="space-between">
      <Col xs={24} sm={24} md={12} lg={12} align="end">
        
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        className="text-right next-previous-btns"
      >
        <Button
          className="button-wrapper-child"
          variant="primary"
          onclickCallback={() => {
            saveAction("SAVE_PROGRESS")
            onNext()
          }}
          disabled={loading}
          loading={loading && action === "SAVE_PROGRESS"}
          label={"Save progress"}
        />
        <Button
          className="button-wrapper-child"
          variant="primary"
          onclickCallback={() => {
            saveAction("SIGN_OFF")
            onNext()
          }}
          disabled={loading}
          loading={loading && action === "SIGN_OFF"}
          label={"Submit"}
        />
      </Col>
    </Row>
  </AntFooter>
);
