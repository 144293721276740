import React from 'react';
import { Pagination } from 'antd';
const Paginations = ({
    currentPage,
    onChange,
    total,
    limit
}) => {
    return <Pagination
        total={total}
        onChange={onChange}
        current={currentPage}
        defaultCurrentPage={1}
        defaultPageSize={limit}
        showSizeChanger={false}
    />;
};
export default Paginations;