import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getAccountHolderDetails } from "../../../../redux/actions";

import { SpinLoader } from "../../../common/SpinLoader";
import { KYCSection } from "./KYCSection";
import { CardSection } from "./CardSection";
import { ProfileSection } from "./ProfileSection";
import { InvestmentSection } from "./InvestmentSection";
import { TransactionsSection } from "./TransactionsSection";
import { ContributionsSection } from "./ContributionsSection";
import { AccountDetailsSection } from "./AccountDetailsSection";
import { ExternalAccountSection } from "./ExternalAccountSection";
import { PendingACHSection } from "./PendingACHSection";

import { Col, Row } from "antd";
import { ExpensesSectionV2 } from "./ExpenseSectionV2";
import { EditAccountIcon } from "../../../../design-systems";
import { ProfileInformationForm } from "./ProfileForm";
import useShowSecondSection from "../../../../hooks/useShowSection";
import { SuccessModal } from "./SuccessModal";

function AccountHolder({ emailID, employee, details, setDetails }) {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);


  const [editProfileInformation, setEditProfileInformation] = useState(false);
  useShowSecondSection();

  useEffect(() => {
    // dispatch(
    //   getAccountHolderDetails(emailID, (res) => {
    //     setDetails(res);
    //   }),
    // );

    //eslint-disable-next-line
  }, []);

  const onChange = (key) => { };

  const panelHeader = (
    <React.Fragment>
      <div className="d-flex justify-space-between mr-5">
        {
          (details?.profile?.account_holder_status === "Activated" || details?.profile?.account_holder_status === "Closed") &&
          <div style={{ marginRight: 5 }} onClick={event => event.stopPropagation()}><EditAccountIcon onClick={() => setEditProfileInformation(true)} className="edit-emp-card" /></div>
        }
      </div>
    </React.Fragment>
  )

  const hasHSAPlan = useMemo(() => {
    if (!details || details.account_balance && !Object?.keys(details.account_balance)?.length) {
      return false;
    }
    const plans = Object.keys(details.account_balance);
    return plans.length > 0 && plans.includes("HSA");
  }, [details?.account_balance]);

  return (
    <SpinLoader>
      <ProfileInformationForm
        data={details?.profile ? { ...details.profile, ...details?.mfa } : null}
        visible={editProfileInformation}
        setVisible={setEditProfileInformation}
        emailID={emailID}
        setDetails={setDetails}
        setSuccess={setSuccess}
      />
      <SuccessModal
        open={success}
        onPrimaryActionClick={() => setSuccess(false)}
      />
      <Row gutter={[20, 20]}>
        <Col sm={24} md={24} lg={6}>
          <ProfileSection panelHeader={panelHeader} employee={employee} data={details?.profile ? details.profile : null} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={18}>
          <div>
            <Row gutter={[15, 20]}>
              <Col sm={24} md={24} lg={8}>
                <AccountDetailsSection
                  data={details?.account_balance ? details.account_balance : null}
                />
              </Col>
              <Col sm={24} md={24} lg={8}>
                <CardSection data={details?.card ? details.card : null} />
              </Col>
              <Col sm={24} md={24} lg={8}>
                <ExternalAccountSection
                  data={details?.external_accounts ? details.external_accounts : null}
                />
              </Col>
            </Row>
            <div className="mt-20">
              <TransactionsSection
                data={details?.transaction ? details.transaction : null}
                accountBalance={details?.account_balance ? details?.account_balance : null}
                zan={details?.profile?.zenda_account_num ? details?.profile?.zenda_account_num : null}
              />
            </div>
            <div className="mt-20">
              <ExpensesSectionV2 data={details?.expense_counts ? details.expense_counts : null} zan={details?.profile?.zenda_account_num} />
            </div>
            <Row gutter={[20, 20]} className="mt-20">
              {hasHSAPlan &&
                <>
                {
                 details?.account_balance && Object.keys(details?.account_balance)?.length > 0 ? 
                  <Col sm={24} md={24} lg={12}>
                    <ContributionsSection
                      accountBalance={details?.account_balance ? details?.account_balance : null}
                      data={details?.payroll_contribution ? details.payroll_contribution : null}
                    />
                  </Col> :
                  null
                }
                  {
                    details?.investment && Object.keys(details?.investment)?.length > 0 ?
                      <Col sm={24} md={24} lg={12}>
                        <InvestmentSection
                          data={details?.investment ? details.investment : null}
                          accountBalance={details?.account_balance ? details?.account_balance : null}
                        />
                      </Col> :
                      null
                  }
                </>
              }
              <Col sm={24} md={24} lg={24}>
                <PendingACHSection
                  data={details?.pending_ach_txns ? details.pending_ach_txns : null}
                />
              </Col>
              <Col sm={24} md={24} lg={24}>
                <KYCSection data={details?.KYC ? details.KYC : null} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </SpinLoader>
  );
}

export { AccountHolder };
