import { Typography } from "../../design-systems";
import React, { useState } from 'react'
import { hideBankingData, stripLetters } from '../../utils';
import {
    EyeOutlined,
    EyeInvisibleOutlined,
} from "@ant-design/icons";

const MaskData = ({ number, date }) => {
    const [showMaskedValue, setShowMaskedValue] = useState(false);
    const maskedDOB = '*********';
    return (
        <div className="d-flex">
            {number && <Typography
                variant="body-3"
                label={(showMaskedValue)
                    ? stripLetters(number)
                    : hideBankingData(number)}
                className={"section-data "}
            />}
            {
                date && <Typography
                    variant="body-3"
                    label={(showMaskedValue)
                        ? date
                        : maskedDOB}
                    className={"section-data account-holder-value"}
                />
            }
            {showMaskedValue ? (
                <EyeInvisibleOutlined
                    onClick={() => {
                        setShowMaskedValue(!showMaskedValue)
                    }}
                    style={{ cursor: "pointer", padding: "3px", paddingLeft: "10px" }}
                />
            ) : (
                <EyeOutlined
                    onClick={() => {
                        setShowMaskedValue(!showMaskedValue)
                    }}
                    style={{ cursor: "pointer", padding: "3px", paddingLeft: "10px" }}
                />
            )}
        </div>
    )
}

export default MaskData