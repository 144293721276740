export const SuccessNotication = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5.7046 12.9095L9.29459 16.4995C9.6846 16.8895 10.3246 16.8895 10.7046 16.4995L18.2946 8.90953C18.6846 8.51953 18.6846 7.88953 18.2946 7.49953C17.9046 7.10953 17.2746 7.10953 16.8846 7.49953L10.0046 14.3795L7.11459 11.4995C6.7246 11.1095 6.09459 11.1095 5.7046 11.4995C5.51734 11.6864 5.41211 11.94 5.41211 12.2045C5.41211 12.469 5.51734 12.7227 5.7046 12.9095Z"
      fill="#02880F"
    />
  </svg>
);
